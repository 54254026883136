<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <TagsList
                :tags="member.tags"
                :show-add="!member.is_archived"
                tag-url-prefix="/admin/system-settings/tags/tags/edit"
                @add="showTagsForm"
            />
        </template>
        <template v-slot:extra>
            <div id="edit-tags-form">
                <TagsEdit
                    v-if="showingTagsForm"
                    :url="'/admin/users/members/' + member.id + '/edit-tags'"
                    :tags="member.tags"
                    @closed="hideTagsForm"
                />
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import TagsEdit from "@components/utilities/TagsEdit"
import TagsList from "@components/utilities/TagsList"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const { showing: showingTagsForm, show: showTagsForm, hide: hideTagsForm } = useToggleable('edit-tags-form')

</script>
