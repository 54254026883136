<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:action>
            <div v-if="!user.is_archived" class="col">
                <button @click="showUserForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update User
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                    <div class="col">
                        Last Login:
                        <span class="font-semibold">{{ user.last_login }}</span>
                    </div>
                    <div class="col">
                        Last Update:
                        <span class="font-semibold">{{ user.updated_at }}</span>
                    </div>
                </div>
                <div class="profile-grid">
                    <div class="col p-6">
                        Account Status
                        <h6 v-if="user.is_archived" class="font-semibold mt-1 text-secondary">Archived</h6>
                        <h6 v-else-if="user.status === 'approved'" class="font-semibold mt-1">Approved</h6>
                        <h6 v-else class="font-semibold mt-1 text-secondary">Pending</h6>
                    </div>
                    <div class="col p-6">
                        Availability Status
                        <h6 v-if="user.availability?.is_available" class="font-semibold mt-1">Available</h6>
                        <h6 v-else class="font-semibold mt-1 text-secondary">Unavailable</h6>
                    </div>
                    <div class="col p-6">
                        Total Bookings
                        <h6 class="font-semibold mt-1">{{ user.statistics?.bookings_count }}</h6>
                    </div>
                    <div class="col p-6">
                        Join Date
                        <h6 class="font-semibold mt-1">{{ user.created_at_short }}</h6>
                    </div>
                </div>
                <div class="mt-10">
                    <h5 class="sub-title">Overview</h5>
                    <table class="transaction-table">
                        <tbody>
                        <tr v-if="user.is_archived">
                            <th class="text-secondary">Archived</th>
                            <td class="text-secondary">{{ user.archived_at }}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>
                                <UserListStatusIcon :is-approved="user.status === 'approved'" />
                            </td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>{{ user.gender ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Legal Name</th>
                            <td>{{ user.legal_name ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Professional Name</th>
                            <td>{{ user.professional_name ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Date of Birth</th>
                            <td>{{ user.date_of_birth + ' ' + '(' + user.age + ')' }}</td>
                        </tr>
                        <tr>
                            <th>Nationality</th>
                            <td>{{ user.nationality ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Terms Signed on</th>
                            <td>{{ user.terms_signed_at ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Terms File</th>
                            <td>
                                <a v-if="user.terms_file_url && user.terms_filename" :href="user.terms_file_url" target="_blank" class="text-primary"><i class="fas fa-file primary"></i></a>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th>App Access</th>
                            <td>
                                <UserListAccessStatusIcon :type="user.is_archived ? 'archived' : (user.is_locked_out ? 'locked' : 'access')" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-user-form">
                <div v-if="showingUserForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit User</h4>
                            </div>
                            <div class="col">
                                <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitUserForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Title</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.title" :class="{'error' : userForm.errors?.title}" placeholder="Title"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">First Name</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.first_name" :class="{'error' : userForm.errors?.first_name}" placeholder="First Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Last Name</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.last_name" :class="{'error' : userForm.errors?.last_name}" placeholder="Last Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Gender</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.gender" :class="{'error' : userForm.errors?.gender}" placeholder="Gender"
                                                   type="text">
                                        </div>
                                    </div>
                                    <hr class="mb-10 mt-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Email</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="far fa-envelope"></i>
                                            </div>
                                            <input v-model="userForm.email" :class="{'error' : userForm.errors?.email}" placeholder="Email"
                                                   type="email">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">{{ userForm.has_app_access ? 'Access Granted' : 'Access Suspended' }}</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.has_app_access">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div v-if="userForm.has_app_access">
                                        <hr class="mb-10 mt-10">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">New Password</label>
                                            <div class="input-holder input-holder--icon">
                                                <div class="input-holder-icon">
                                                    <i class="fas fa-lock"></i>
                                                </div>
                                                <input v-model="userForm.password" :class="{'error' : userForm.errors?.password}"
                                                       placeholder="Password" type="password">
                                            </div>
                                        </div>
                                        <div class="input-wrapper mb-10">
                                            <label class="input-label">New Password Confirmation</label>
                                            <div class="input-holder input-holder--icon">
                                                <div class="input-holder-icon">
                                                    <i class="fas fa-lock"></i>
                                                </div>
                                                <input v-model="userForm.password_confirmation"
                                                       :class="{'error' : userForm.errors?.password_confirmation}" placeholder="Password"
                                                       type="password">
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="mb-10 mt-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Professional First Name</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.professional_first_name" :class="{'error' : userForm.errors?.professional_first_name}" placeholder="Professional First Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Professional Last Name</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.professional_last_name" :class="{'error' : userForm.errors?.professional_last_name}" placeholder="Professional Last Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Date of Birth</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.date_of_birth" :class="{'error' : userForm.errors?.date_of_birth}" placeholder="Date of Birth"
                                                   type="date">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Nationality</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-user"></i>
                                            </div>
                                            <input v-model="userForm.nationality" :class="{'error' : userForm.errors?.nationality}" placeholder="Nationality" type="text">
                                        </div>
                                    </div>
                                    <div v-if="userForm.status === 'pending'">
                                        <hr class="mb-10 mt-10">
                                        <div class="input-wrapper mb-10">
                                            <label class="input-label">Application Status</label>
                                            <select v-model="userForm.application_status" :class="{'error' : userForm.errors.application_status}">
                                                <option value="received">Application received</option>
                                                <option value="interview">Invited for interview</option>
                                                <option value="referencing">Successful. Referencing</option>
                                                <option value="awaiting_profile">Passed. Awaiting Profile</option>
                                                <option value="awaiting_dbs">Waiting on DBS</option>
                                                <option value="ready">Ready to Onboard</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr class="mb-10 mt-10">
                                    <div class="input-wrapper mb-10">
                                        <label class="input-label">Status</label>
                                        <select v-model="userForm.status" :class="{'error' : userForm.errors.status}">
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                        </select>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Mark as Available</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.is_available">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Mark as Star</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.is_star">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Mark as Flagged</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.is_flagged">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <hr class="mt-10 mb-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Terms Sign Date</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-calendar-alt"></i>
                                            </div>
                                            <input v-model="userForm.terms_signed_at" :class="{'error' : userForm.errors?.terms_signed_at}" placeholder="Terms Sign Date" type="date">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="userForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SoftDelete
                :is-archived="user.is_archived"
                :archive-url="'/admin/users/creatives/' + user.id + '/archive'"
                :restore-url="'/admin/users/creatives/' + user.id + '/restore'"
                type="Creative"
            >
                <p>Archived Creatives cannot access the application.</p>
            </SoftDelete>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import SoftDelete from "@components/utilities/SoftDelete"
import UserListAccessStatusIcon from "@components/utilities/UserListAccessStatusIcon"
import UserListStatusIcon from "@components/utilities/UserListStatusIcon"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const userForm = useForm({
    title: props.user.title,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    gender: props.user.gender,
    email: props.user.email,
    password: null,
    password_confirmation: null,
    has_app_access: !props.user.is_locked_out,

    professional_first_name: props.user.professional_first_name,
    professional_last_name: props.user.professional_last_name,
    date_of_birth: props.user.date_of_birth_raw,
    nationality: props.user.nationality,

    application_status: props.user.application_status,
    status: props.user.status,
    is_available: props.user.availability?.is_available,
    is_star: props.user.is_star,
    is_flagged: props.user.is_flagged,

    terms_signed_at: props.user.terms_signed_at_raw,
})

const submitUserForm = () => {
    userForm.post('/admin/users/creatives/' + props.user.id + '/update/overview', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')

</script>
