<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Update Daily Availability</h4>
                </div>
                <div v-if="showClose" class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <table class="table table-weekly-stats mt-10">
                    <thead>
                        <tr>
                            <th class="text-left">
                                <div class="text-gray-600">Day</div>
                            </th>
                            <td>Morning</td>
                            <td>Afternon</td>
                            <td>Evening</td>
                            <td>Night</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(day, index) in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']" :key="index">
                            <th class="col-day">{{ upperFirst(day) }}</th>
                            <td v-for="(time, tIndex) in ['morning', 'afternoon', 'evening', 'night']" :key="tIndex" @click="toggleValue(day + '_' + time)">
                                <div class="col-day-time">Morning</div>
                                <div v-if="form[day + '_' + time] === 'available'" class="stat-icon">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div v-else-if="form[day + '_' + time] === 'unavailable'" class="stat-icon stat-icon--maybe text-secondary">
                                    <i class="fas fa-times"></i>
                                </div>
                                <div v-else-if="form[day + '_' + time] === 'definitely unavailable'" class="stat-icon stat-icon--no">
                                    <i class="fas fa-exclamation"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <div class="input-submit mt-10">
                <button :disabled="!canSubmit" @click="submitForm" class="btn">
                    SAVE CHANGES
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {upperFirst} from "lodash"
import {useForm} from "@inertiajs/inertia-vue3";
import {computed} from "vue";

const props = defineProps({
    schedule: {
        type: Object,
        required: true
    },
    url: {
        type: String,
        required: true
    },
    showClose: {
        type: Boolean,
        default: true
    },
})

const emits = defineEmits(['closed'])

const form = useForm({
    monday_morning: props.schedule.monday_morning,
    monday_afternoon: props.schedule.monday_afternoon,
    monday_evening: props.schedule.monday_evening,
    monday_night: props.schedule.monday_night,

    tuesday_morning: props.schedule.tuesday_morning,
    tuesday_afternoon: props.schedule.tuesday_afternoon,
    tuesday_evening: props.schedule.tuesday_evening,
    tuesday_night: props.schedule.tuesday_night,

    wednesday_morning: props.schedule.wednesday_morning,
    wednesday_afternoon: props.schedule.wednesday_afternoon,
    wednesday_evening: props.schedule.wednesday_evening,
    wednesday_night: props.schedule.wednesday_night,

    thursday_morning: props.schedule.thursday_morning,
    thursday_afternoon: props.schedule.thursday_afternoon,
    thursday_evening: props.schedule.thursday_evening,
    thursday_night: props.schedule.thursday_night,

    friday_morning: props.schedule.friday_morning,
    friday_afternoon: props.schedule.friday_afternoon,
    friday_evening: props.schedule.friday_evening,
    friday_night: props.schedule.friday_night,

    saturday_morning: props.schedule.saturday_morning,
    saturday_afternoon: props.schedule.saturday_afternoon,
    saturday_evening: props.schedule.saturday_evening,
    saturday_night: props.schedule.saturday_night,

    sunday_morning: props.schedule.sunday_morning,
    sunday_afternoon: props.schedule.sunday_afternoon,
    sunday_evening: props.schedule.sunday_evening,
    sunday_night: props.schedule.sunday_night,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty
})

const toggleValue = (period) => {
    form[period] = form[period] === 'available' ? 'unavailable' : (form[period] === 'unavailable' ? 'definitely unavailable' : 'available')
}

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

</script>

<style lang="postcss" scoped>
table.table td:last-child,
table.table td:last-child {
    @apply text-center;
}
</style>
