<template>
    <AdminUsersMemberUsersDetailsLayout :member-user="memberUser">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Contact Details</h5>
                    </div>
                    <div v-if="!memberUser.is_archived" class="col">
                        <button @click="showUserForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <table class="transaction-table">
                    <tbody>
                    <tr>
                        <th>Email</th>
                        <td>{{ memberUser.user?.email ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Mobile Telephone</th>
                        <td>{{ memberUser.mobile_telephone ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Home Telephone</th>
                        <td>{{ memberUser.home_telephone ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Work Telephone</th>
                        <td>{{ memberUser.work_telephone ?? '-' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-user-form">
                <div v-if="showingUserForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit User</h4>
                            </div>
                            <div class="col">
                                <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitUserForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Mobile Telephone</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-user"></i>
                                            </div>
                                            <input v-model="userForm.mobile_telephone" :class="{'error' : userForm.errors?.mobile_telephone}" placeholder="Mobile Telephone" type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Home Telephone</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-user"></i>
                                            </div>
                                            <input v-model="userForm.home_telephone" :class="{'error' : userForm.errors?.home_telephone}" placeholder="Home Telephone" type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Work Telephone</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-user"></i>
                                            </div>
                                            <input v-model="userForm.work_telephone" :class="{'error' : userForm.errors?.work_telephone}" placeholder="Work Telephone" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="userForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersMemberUsersDetailsLayout>
</template>

<script setup>
import AdminUsersMemberUsersDetailsLayout from "@components/admin/AdminUsersMemberUsersDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    memberUser: {
        type: Object,
        required: true
    }
})

const userForm = useForm({
    mobile_telephone: props.memberUser.mobile_telephone,
    home_telephone: props.memberUser.home_telephone,
    work_telephone: props.memberUser.work_telephone,
})

const submitUserForm = () => {
    userForm.post('/admin/users/members/' + props.memberUser.member?.id + '/member-users/' + props.memberUser.id + '/update/contact', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')

</script>
