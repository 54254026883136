// require('./bootstrap');

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import BaseLayout from './components/layout/BaseLayout'
import AppLayout from './components/layout/AppLayout'
import { Link } from "@inertiajs/inertia-vue3"

createInertiaApp({
    resolve: name => {
        const page = require(`./pages/${name}`).default
        if (page.layout === undefined) {
            if (!name.startsWith('Auth/')) {
                page.layout = [BaseLayout, AppLayout]
            } else {
                page.layout = BaseLayout
            }
        }
        return page
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(PerfectScrollbar)
            .component('InertiaLink', Link)
            .mount(el)
    },
})
