<template>
    <MemberUserDetailsLayout :member-user="memberUser">
        <template v-slot:action>
            <div v-if="memberUser.can_edit" class="col">
                <button @click="showUserForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update User
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <h5 class="sub-title">Overview</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Gender</th>
                            <td>{{ memberUser.user?.gender ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Relation</th>
                            <td>{{ memberUser.relation ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Nationality</th>
                            <td>{{ memberUser.nationality ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Primary</th>
                            <td v-if="memberUser.is_primary" class="text-primary">Primary</td>
                            <td v-else class="text-secondary">N/A</td>
                        </tr>
                        <tr>
                            <th>Emergency Contact</th>
                            <td v-if="memberUser.is_emergency_contact" class="text-primary">Emergency Contact</td>
                            <td v-else class="text-secondary">N/A</td>
                        </tr>
                        <tr>
                            <th>App Access</th>
                            <td>
                                <UserListAccessStatusIcon :type="memberUser.user?.is_archived ? 'archived' : (memberUser.user?.is_locked_out ? 'locked' : 'access')" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-user-form">
                <div v-if="showingUserForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit User</h4>
                            </div>
                            <div class="col">
                                <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitUserForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Title</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.title" :class="{'error' : userForm.errors?.title}" placeholder="Title"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">First Name</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.first_name" :class="{'error' : userForm.errors?.first_name}" placeholder="First Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Last Name</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.last_name" :class="{'error' : userForm.errors?.last_name}" placeholder="Last Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Gender</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.gender" :class="{'error' : userForm.errors?.gender}" placeholder="Gender"
                                                   type="text">
                                        </div>
                                    </div>
                                    <hr class="mb-10 mt-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Email</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="far fa-envelope"></i>
                                            </div>
                                            <input v-model="userForm.email" :class="{'error' : userForm.errors?.email}" placeholder="Email"
                                                   type="email">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">{{ userForm.has_app_access ? 'Access Granted' : 'Access Suspended' }}</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.has_app_access">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div v-if="userForm.has_app_access">
                                        <hr class="mb-10 mt-10">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">New Password</label>
                                            <div class="input-holder input-holder--icon">
                                                <div class="input-holder-icon">
                                                    <i class="fas fa-lock"></i>
                                                </div>
                                                <input v-model="userForm.password" :class="{'error' : userForm.errors?.password}"
                                                       placeholder="Password" type="password">
                                            </div>
                                        </div>
                                        <div class="input-wrapper mb-10">
                                            <label class="input-label">New Password Confirmation</label>
                                            <div class="input-holder input-holder--icon">
                                                <div class="input-holder-icon">
                                                    <i class="fas fa-lock"></i>
                                                </div>
                                                <input v-model="userForm.password_confirmation"
                                                       :class="{'error' : userForm.errors?.password_confirmation}" placeholder="Password"
                                                       type="password">
                                            </div>
                                        </div>
                                    </div>

                                    <hr class="mb-10 mt-10">

                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Primary</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.is_primary">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Emergency Contact</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="userForm.is_emergency_contact">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Relation</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.relation" :class="{'error' : userForm.errors?.relation}" placeholder="Relation" type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Nationality</label>
                                        <div class="input-holder">
                                            <input v-model="userForm.nationality" :class="{'error' : userForm.errors?.nationality}" placeholder="Nationality" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="userForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                        <div>
                            <div class="pb-10"></div>
                            <hr>
                            <div class="pb-10"></div>
                            <div class="flex flex-wrap items-center justify-between gap-4">
                                <div class="col">
                                    <h4>Manage Avatar</h4>
                                </div>
                            </div>
                            <div class="pb-10"></div>
                            <div>
                                You can change or remove the avatar.
                            </div>
                            <div class="pb-10"></div>
                            <FormErrorsGroup class="pb-10" :errors="avatarForm.errors"/>
                            <input id="new-avatar" type="file" hidden @input="avatarForm.avatar = $event.target.files[0]" @change="changeAvatar()" />
                            <button @click="selectAvatar()" class="btn btn--primary">Change Avatar</button>
                            <button @click="removeAvatar()" class="btn btn--secondary ml-10">Remove Avatar</button>
                        </div>
                    </div>
                </div>
            </div>
            <SoftDelete
                v-if="memberUser.can_edit"
                :is-archived="false"
                :archive-url="'/member/profile/users/' + memberUser.id + '/destroy'"
                :restore-url="'/member/profile/users/' + memberUser.id + '/destroy'"
                type="User"
                action="Remove"
            >
                <p>Please note that this action is irreversible.</p>
            </SoftDelete>
        </template>
    </MemberUserDetailsLayout>
</template>

<script setup>
import MemberUserDetailsLayout from "@components/member/MemberUserDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import SoftDelete from "@components/utilities/SoftDelete"
import UserListAccessStatusIcon from "@components/utilities/UserListAccessStatusIcon"

import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    memberUser: {
        type: Object,
        required: true
    }
})

const userForm = useForm({
    title: props.memberUser.user?.title,
    first_name: props.memberUser.user?.first_name,
    last_name: props.memberUser.user?.last_name,
    email: props.memberUser.user?.email,
    gender: props.memberUser.user?.gender,
    password: null,
    password_confirmation: null,
    has_app_access: !props.memberUser.user?.is_locked_out,
    is_emergency_contact: props.memberUser.is_emergency_contact,
    is_primary: props.memberUser.is_primary,
    relation: props.memberUser.relation,
    nationality: props.memberUser.nationality,
})

const submitUserForm = () => {
    userForm.post('/member/profile/users/' + props.memberUser.id + '/update/overview', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')

const avatarForm = useForm({
    avatar: null,
})
const selectAvatar = () => {
    document.getElementById("new-avatar").click()
}
const changeAvatar = () => {
    if (!avatarForm.avatar) {
        return
    }
    avatarForm.post('/member/profile/users/' + props.memberUser.id + '/avatar/replace', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}
const removeAvatar = () => {
    Inertia.post('/member/profile/users/' + props.memberUser.id + '/avatar/remove', {},{
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

</script>
