<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Repeat Booking</h4>
                </div>
            </div>
            <div class="pb-10"></div>
            <div>
                <div class="input-wrapper mb-10">
                    <label class="input-label">Repeat Booking</label>
                    <label class="switch">
                        <input type="checkbox" v-model="repeatForm.repeat_booking">
                        <span class="switch-slider"></span>
                    </label>
                </div>
                <div v-show="repeatForm.repeat_booking">
                    <div class="input-wrapper mb-10">
                        <label class="input-label">Repeat Until</label>
                        <input v-model="repeatForm.repeat_until" :class="{'error' : repeatForm.errors.repeat_until}" type="date" :min="minDate">
                    </div>
                    <div class="input-wrapper mb-10">
                        <label class="input-label">Repeat Frequency</label>
                        <select name="value" v-model="repeatForm.repeat_frequency" :class="{'error' : repeatForm.errors.repeat_frequency}">
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>
                    <FormErrorsGroup :errors="repeatForm.errors"/>
                    <div class="input-submit mt-10">
                        <button @click="repeatBooking()" :disabled="!canRepeatBooking" class="btn btn--primary">Create Repeat Bookings</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3";
import {computed} from "vue";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"



const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const repeatForm = useForm({
    repeat_booking: false,
    repeat_until: "",
    repeat_frequency: "daily",
})

const minDate = computed(() => {
    let d = new Date(props.booking.start_timestamp_raw)
    d.setDate(d.getDate() + 1)
    return d.toISOString().split("T")[0]
})

const canRepeatBooking = computed(() => {
    return repeatForm.repeat_booking && repeatForm.repeat_until && repeatForm.repeat_frequency
})

const repeatBooking = () => {
    if (canRepeatBooking.value) {
        repeatForm.post('/admin/bookings/' + props.booking.id + '/repeat', {
            preserveScroll: (page) => Object.keys(page.props.errors).length
        })
    }
}
</script>
