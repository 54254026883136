<template>
    <CreativeAccountFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Financial Details</h4>
                </div>
                <div class="col">
                    <button @click="showFinancialForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Details
                    </button>
                </div>
            </div>
            <div class="mt-10">
                <table class="transaction-table">
                    <tbody>
                    <tr>
                        <th>NI Number</th>
                        <td>{{ user.financial?.ni_number ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>UTR</th>
                        <td>{{ user.financial?.utr ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Bank - Account Name</th>
                        <td>{{ user.financial?.bank_account_name ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Bank - Sort Code</th>
                        <td>{{ user.financial?.bank_sort_code ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Bank - Account Number</th>
                        <td>{{ user.financial?.bank_account_number ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Bank - Bank Name</th>
                        <td>{{ user.financial?.bank_name ?? '-' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div id="edit-user-form">
        <div v-if="showingFinancialForm" class="page-card mt-10">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Edit Financial</h4>
                    </div>
                    <div class="col">
                        <button @click="hideFinancialForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="pb-10"></div>
                <form @submit.prevent="submitFinancialForm">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Financial - NI Number</label>
                                <div class="input-holder">
                                    <input v-model="financialForm.financial_ni_number" :class="{'error' : financialForm.errors?.financial_ni_number}" placeholder="Financial - NI Number" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Financial - UTR</label>
                                <div class="input-holder">
                                    <input v-model="financialForm.financial_utr" :class="{'error' : financialForm.errors?.financial_utr}" placeholder="Financial - UTR" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Financial - Bank Account Name</label>
                                <div class="input-holder">
                                    <input v-model="financialForm.financial_bank_account_name" :class="{'error' : financialForm.errors?.financial_bank_account_name}" placeholder="Financial - Bank Account Name" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Financial - Bank Sort Code</label>
                                <div class="input-holder">
                                    <input v-model="financialForm.financial_bank_sort_code" :class="{'error' : financialForm.errors?.financial_bank_sort_code}" placeholder="Financial - Bank Sort Code" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Financial - Bank Account Number</label>
                                <div class="input-holder">
                                    <input v-model="financialForm.financial_bank_account_number" :class="{'error' : financialForm.errors?.financial_bank_account_number}" placeholder="Financial - Bank Account Number" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Financial - Bank Name</label>
                                <div class="input-holder">
                                    <input v-model="financialForm.financial_bank_name" :class="{'error' : financialForm.errors?.financial_bank_name}" placeholder="Financial - Bank Name" type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="financialForm.errors"/>
                    <div class="input-submit mt-10">
                        <input :disabled="!financialForm.isDirty || financialForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                               type="submit" value="Update">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import useToggleable from "@composables/useToggleable"
import DocumentForm from "@components/utilities/DocumentForm"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import CreativeAccountFilters from "@components/creative/CreativeAccountFilters"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})
const { showing: showingFinancialForm, show: showFinancialForm, hide: hideFinancialForm } = useToggleable('edit-user-form')

const financialForm = useForm({
    financial_ni_number: props.user.financial?.ni_number,
    financial_utr: props.user.financial?.utr,
    financial_bank_account_name: props.user.financial?.bank_account_name,
    financial_bank_sort_code: props.user.financial?.bank_sort_code,
    financial_bank_account_number: props.user.financial?.bank_account_number,
    financial_bank_name: props.user.financial?.bank_name,
})

const submitFinancialForm = () => {
    financialForm.post('/creative/account/financial', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideFinancialForm(),
    })
}
</script>

<style lang="postcss">
body {
}
</style>
