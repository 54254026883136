<template>
    <div class="px-5">
        <div class="">
            <div class="space30 d-lg-none d-xl-none"></div>
            <div class="centered-div px-5">
                <div class="flex flex-col gap-5 items-center md:flex-row mt-12">
                    <div class="w-full md:w-1/3">
                        <div v-for="item in processes" class="flex text-left items-center mb-4 gap-4">
                            <span class="icon w-10 h-10 leading-10 rounded-full bg-default text-white text-center flex-shrink-0">
                                <span class="icon-img text-2xl leading-10">
                                    <i style="vertical-align: initial;" class="fas fa-check"></i>
                                </span>
                            </span>
                            <div class="text-md font-semibold">{{ item }}</div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/3">
                        <div v-for="item in processesTwo" class="flex text-left items-center mb-4 gap-4">
                            <span class="icon w-10 h-10 leading-10 rounded-full bg-default text-white text-center flex-shrink-0">
                                <span class="icon-img text-2xl leading-10"><i style="vertical-align: initial;" class="fas fa-check"></i></span>
                            </span>
                            <div class="text-md font-semibold">{{ item }}</div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/3">
                        <div v-for="item in processesThree" class="flex text-left items-center mb-4 gap-4">
                            <span class="icon w-10 h-10 leading-10 rounded-full bg-default text-white text-center flex-shrink-0">
                                <span class="icon-img text-2xl leading-10"><i style="vertical-align: initial;" class="fa fa-check"></i></span>
                            </span>
                            <div class="text-md font-semibold">{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space50"></div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const processes = [
    "Flexibility",
    "Car drivers",
    "Managed service",
]
const processesTwo = [
    "Dedicated support",
    "Paediatric First aid",
    "Government DBS checked",
]
const processesThree = [
    "ID, address & bank verified",
    "Optional PLI cover",
    "Referenced & personally interviewed",
]
</script>

<style scoped>
span.active {
    background-color: #F4F4F4 !important;
    color: #a1ede3 !important;
}
.flex-wrap-cols {
    flex-wrap: wrap;
}
.hide {display: none;}
@media (min-width: 1280px) {
    .xl\:flex-nowrap {
        flex-wrap: nowrap;
    }
    .xl-show {display: block;}
}
</style>
