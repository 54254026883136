<template>
    <div class="page-filters-section mb-10 lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/member/account/statement" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/account/statement')}">Statement</InertiaLink>
                        </li>
                        <li>
                            <a href="/member/account/billing-portal"><button class="filter-nav-btn">Billing Portal</button></a>
                        </li>
                        <li>
                            <InertiaLink href="/member/account/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/account/notifications')}">Notifications</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/member/account/details" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/account/details')}">Details</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>

</style>
