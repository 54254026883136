<template>
    <div>
        <h5 class="sub-title">{{ title }}</h5>
        <LineChart v-bind="lineChartProps" :options="options" />
    </div>
</template>

<script setup>
import {Chart, registerables} from 'chart.js'
import { LineChart, useLineChart } from 'vue-chart-3';
import {computed, ref} from 'vue'

Chart.register(...registerables)

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    monthLabels: {
        type: Array,
        required: true
    },
    yearLabels: {
        type: Array,
        required: true
    },
    dataKey: {
        type: String,
        required: true
    },
    datasets: {
        type: Object,
        required: true
    },
    tooltip: {
        type: String,
        default: null, // null || money || percentage
    }
})

const options = ref({
    responsive: true,
    plugins: {
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context) {
                    return context.dataset.label + ': ' + (props.tooltip === 'money' ? '£' : '') + context.raw + (props.tooltip === 'percentage' ? '%' : (props.tooltip !== null && props.tooltip !== 'money' ? ' ' + props.tooltip : ''));
                }
            }
        },
    },
})
const chartData = computed(() => ({
    labels: props.monthLabels,
    datasets: [
        {
            label: props.yearLabels[props.yearLabels.length - 1],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 1]][props.dataKey],
            backgroundColor: '#fe3562',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: props.yearLabels[props.yearLabels.length - 2],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 2]][props.dataKey],
            backgroundColor: '#9CABC7',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: props.yearLabels[props.yearLabels.length - 3],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 3]][props.dataKey],
            backgroundColor: '#8ff0e8',
            tension: 0.4,
            responsive: true,
            legend:false
        }
    ]
}))

const {lineChartProps, lineChartRef} = useLineChart({
    chartData,
})
</script>

<style>
</style>
