<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Team</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showTeamForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div v-if="user.team.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th style="width: 200px;">Member</th>
                            <th style="width: 200px;">Date Added</th>
                            <th>Next Review</th>
                            <th></th>
                        </tr>
                        <tr v-for="(member, index) in user.team" :key="index">
                            <td style="width: 200px;">
                                <InertiaLink :href="'/admin/users/members/' + member.id">
                                    <div class="">{{ member.name }} <i v-if="member.is_vip" class="fas fa-check-circle primary"></i></div>
                                </InertiaLink>
                            </td>
                            <td style="width: 200px;"><div class="text-gray-600 font-normal">{{ member.created_at_short }}</div></td>
                            <td>
                                <div :class="{'text-secondary': member.needs_review}">{{ member.review_at }}</div>
                                <span class="font-normal" :class="{'text-secondary': member.needs_review}">{{ member.review_at_short }}</span>
                            </td>
                            <td v-if="!user.is_archived">
                                <div class="flex flex-wrap gap-4">
                                    <div class="col">
                                        <button @click="approveTeam(member.team_id)" class="btn btn--sm"><i class="fas fa-check"></i> Extend</button>
                                    </div>
                                    <div class="col">
                                        <button @click="removeTeam(member.team_id)" class="btn btn--sm btn--danger"><i class="fas fa-times"></i> Remove</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Blacklist</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showBlacklistForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div v-if="user.blacklist.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th style="width: 200px;">Member</th>
                            <th style="width: 200px;">Date Added</th>
                            <th></th>
                        </tr>
                        <tr v-for="(member, index) in user.blacklist" :key="index">
                            <td style="width: 200px;">
                                <InertiaLink :href="'/admin/users/members/' + member.id">
                                    <div class="">{{ member.name }} <i v-if="member.is_vip" class="fas fa-check-circle primary"></i></div>
                                </InertiaLink>
                            </td>
                            <td style="width: 200px;"><div class="text-gray-600 font-normal">{{ member.created_at_short }}</div></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-team-form">
                <UsersPicker
                    v-if="showingTeamForm"
                    target="Members"
                    :show-avatars="false"
                    :source-url="'/admin/users/creatives/' + user.id + '/teams/suitable-to-approve'"
                    :url="'/admin/users/creatives/' + user.id + '/teams/approved'"
                    :users="user.team"
                    @closed="hideTeamForm"
                />
            </div>
            <div id="edit-blacklist-form">
                <UsersPicker
                    v-if="showingBlacklistForm"
                    title="Edit Blacklist"
                    target="Members"
                    :show-avatars="false"
                    :source-url="'/admin/users/creatives/' + user.id + '/teams/suitable-to-blacklist'"
                    :url="'/admin/users/creatives/' + user.id + '/teams/blacklist'"
                    :users="user.blacklist"
                    @closed="hideBlacklistForm"
                />
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout";
import useToggleable from "@composables/useToggleable"
import UsersPicker from "@components/utilities/UsersPicker"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { showing: showingTeamForm, show: showTeamForm, hide: hideTeamForm } = useToggleable('edit-team-form')
const { showing: showingBlacklistForm, show: showBlacklistForm, hide: hideBlacklistForm } = useToggleable('edit-blacklist-form')

const approveTeam = (teamId) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/teams/' + teamId + '/approve', {}, {
        preserveScroll: true
    })
}
const removeTeam = (teamId) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/teams/' + teamId + '/remove', {}, {
        preserveScroll: true
    })
}
</script>
