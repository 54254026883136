<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Add Relation</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Title</label>
                            <div class="input-holder">
                                <input v-model="form.title" :class="{'error' : form.errors?.title}" placeholder="Title" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">First Name</label>
                            <div class="input-holder">
                                <input v-model="form.first_name" :class="{'error' : form.errors?.first_name}" placeholder="Name" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Last Name</label>
                            <div class="input-holder">
                                <input v-model="form.last_name" :class="{'error' : form.errors?.last_name}" placeholder="Name" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Email</label>
                            <div class="input-holder">
                                <input v-model="form.email" :class="{'error' : form.errors?.email}" placeholder="Email" type="email">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Telephone</label>
                            <div class="input-holder">
                                <input v-model="form.telephone" :class="{'error' : form.errors?.telephone}" placeholder="Telephone" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Relation</label>
                            <div class="input-holder">
                                <input v-model="form.relation" :class="{'error' : form.errors?.relation}" placeholder="Relation" type="text">
                            </div>
                        </div>
                        <hr class="mb-10 mt-10">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Street</label>
                            <div class="input-holder">
                                <input v-model="form.street" :class="{'error' : form.errors?.street}" placeholder="Street" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Town / City</label>
                            <div class="input-holder">
                                <input v-model="form.city" :class="{'error' : form.errors?.city}" placeholder="Town or City" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Country</label>
                            <div class="input-holder">
                                <input v-model="form.country" :class="{'error' : form.errors?.country}" placeholder="Country" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Postcode</label>
                            <div class="input-holder">
                                <input v-model="form.postcode" :class="{'error' : form.errors?.postcode}" placeholder="Postcode" type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="flex flex-wrap gap-4">
                    <div class="col">
                        <div class="input-submit mt-10">
                            <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                                   type="submit" @click="form.type = 'emergency_contact'" value="Add as Emergency Contact">
                        </div>
                    </div>
                    <div class="col">
                    <div class="input-submit mt-10">
                        <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                               type="submit" @click="form.type = 'referee'" value="Add as Referee">
                    </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    }
})

const emits = defineEmits(['closed'])

const form = useForm({
    title: null,
    first_name: null,
    last_name: null,
    email: null,
    telephone: null,
    relation: null,
    type: 'emergency_contact',

    name: 'Address',
    street: null,
    city: null,
    country: null,
    postcode: null,
    is_primary: true,
})

const canSubmit = computed(() => {
    return !form.processing && form.title && form.first_name && form.last_name && form.telephone && form.relation && form.type && form.name && form.street && form.city && form.country && form.postcode
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})
</script>
