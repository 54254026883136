<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Edit Tag Type</h4>
                </div>
                <div class="col">
                    Last Update: <span class="font-semibold">{{ type.updated_at }}</span>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="profile-grid">
                <div class="col p-6">
                    ID
                    <h6 class="font-semibold mt-1">#{{ type.id }}</h6>
                </div>
                <div class="col p-6">
                    Name
                    <h6 class="font-semibold mt-1">{{ type.name }}</h6>
                </div>
                <div class="col p-6">
                    Total Categories
                    <h6 class="font-semibold mt-1">{{ type.categories_count }}</h6>
                </div>
                <div class="col p-6">
                    Total Tags
                    <h6 class="font-semibold mt-1">{{ type.tags_count }}</h6>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="form.post('/admin/system-settings/tags/types/edit/' + type.id)">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Name</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-tags"></i>
                                </div>
                                <input v-model="form.name" :class="{'error' : form.errors.name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Remove Tag Type</h4>
                </div>
            </div>
            <div class="pb-10"></div>
                <div>
                    <p>Please note that this action is irreversible.</p>
                </div>
                <div class="input-submit mt-10">
                    <button @click="remove" :disabled="form.processing" class="btn btn--secondary">Remove Tag Type</button>
                </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    type: {
        type: Object,
        required: true
    }
})

const form = useForm({
    name: props.type.name,
})

const canSubmit = computed(() => {
    return form.isDirty && !form.processing && form.name !== ''
})

const remove = () => {
    Inertia.post('/admin/system-settings/tags/types/remove/' + props.type.id, null, {
        onBefore: () => confirm('Are you sure you want to delete this Tag Type?')
    })
}


</script>
