<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <CreativeBookingFilters  />
        </div>
    </div>
    <div class="pt-10"></div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="(item, index) in items" :key="index" class="col">
            <BookingCard :booking="item" url-prefix="/creative/bookings/" :is-for-creative="true" />
        </div>
    </div>
    <div v-if="hasMore">
        <div class="pt-10"></div>
        <div class="text-center">
            <button class="btn btn--gray btn--sm" @click="loadMore">
                Load More
            </button>
        </div>
    </div>
    <div class="pb-5"></div>

</template>

<script setup>
import {computed, ref, watch} from "vue"
import CreativeBookingFilters from "@components/creative/CreativeBookingFilters"
import usePaginator from "@composables/usePaginator"

import BookingCard from '@/components/utilities/BookingCard.vue'

const props = defineProps({
    type: {
        type: String,
        required: true
    }
})

const isUpcoming = computed(() => props.type === 'upcoming')
const isPast = computed(() => props.type === 'past')
const isFind = computed(() => props.type === 'find')

const apiUrl = computed(() => {
    return '/creative/bookings/api/' + props.type
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
<style lang="postcss">
</style>
