<template>
    <button
        class="menu-item"
        type="button"
        :class="{ 'is-active': isActive ? isActive(): null }"
        @click="action"
        :title="title"
    >
        <svg class="remix">
            <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
        </svg>
    </button>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

export default {
    props: {
        icon: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        action: {
            type: Function,
            required: true,
        },

        isActive: {
            type: Function,
            default: null,
        },
    },

    data() {
        return {
            remixiconUrl,
        }
    },
}
</script>

<style lang="postcss">
.menu-item {
    @apply w-8 h-7 text-black px-1.5 py-0.5 mr-2 rounded-lg bg-transparent border-0;
}
.menu-item svg {
    fill: currentColor;
    @apply w-full h-full;
}
.menu-item.is-active,
.menu-item:hover {
    @apply bg-secondary text-white;
}
</style>
