<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Images</h5>
                    </div>
                    <div v-if="!user.is_archived && user.images?.length < 3" class="col">
                        <button @click="selectAvatar(0)" class="btn btn--icon"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
                <div class="flex flex-wrap gap-4 text-center">
                    <div v-for="(image, index) in user.images" :key="index">
                        <a class="col" :href="image.url" target="_blank">
                            <div class="avatar avatar--xl cursor-zoom-in" v-bind:style="{ backgroundImage: 'url(' + image.url + ')' }"></div>
                        </a>
                        <br>
                        <button @click="selectAvatar(image.id)"><i class="fas fa-edit primary"></i></button>
                        <button @click="removeAvatar(image.id)" class="pl-5"><i class="fas fa-trash secondary"></i></button>
                    </div>
                </div>
                <input id="new-avatar" type="file" hidden @input="avatarForm.avatar = $event.target.files[0]" @change="changeAvatar()" />
                <FormErrorsGroup :errors="avatarForm.errors"/>
            </div>
        </template>
        <template v-slot:extra>

        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout";
import useToggleable from "@composables/useToggleable"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const avatarNumber = ref(null)
const avatarForm = useForm({
    avatar: null,
})
const selectAvatar = (number) => {
    if (number === 0 ) {
        if (props.user.images?.find(i => i.id === 2)) {
            number = 3
        } else {
            number = 2
        }
    }

    avatarForm.avatar = null
    avatarNumber.value = number
    document.getElementById("new-avatar").click()
}
const changeAvatar = () => {
    if (!avatarForm.avatar || !avatarNumber.value) {
        return
    }
    avatarForm.post('/admin/users/creatives/' + props.user.id + '/avatar/' + avatarNumber.value + '/replace', {
        preserveScroll: (page) => Object.keys(page.props.errors).length
    })
}
const removeAvatar = (number) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/avatar/' + number + '/remove/', {},{
        preserveScroll: (page) => Object.keys(page.props.errors).length
    })
}

</script>
