<template>
    <CreativeProfileFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center gap-10">
                <div class="col">
                    <div class="page-card-avatar avatar avatar--xl" v-bind:style="{ backgroundImage: 'url(' + user.avatar + ')' }"></div>
                </div>
                <div class="col">
                    <h3 class="page-title">{{ user.professional_name }}</h3>
                </div>
                <div v-if="!user.is_archived" class="col ml-auto">
                    <button @click="showAddRelationForm" class="btn">
                        <i class="fas fa-plus"></i>
                        Add Relation
                    </button>
                </div>
            </div>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Relations</h5>
                    </div>
                </div>
                <div v-if="user.relations?.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Type</th>
                            <th>Relationship</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Telephone</th>
                            <th>Address</th>
                        </tr>
                        <tr v-for="(relation, index) in user.relations" :key="index">
                            <td>
                                <div v-if="relation.type === 'emergency_contact'" class="text-secondary">Emergency Contact</div>
                                <div v-else class="text-gray-600 font-normal">Referee</div>
                            </td>
                            <td><div class="text-gray-600 font-normal">{{ relation.relationship }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ relation.name }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ relation.email }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ relation.telephone }}</div></td>
                            <td>
                                <a :href="relation.address?.map_url">
                                    <div class="text-gray-600 font-normal">{{ relation.address?.street }}</div>
                                    <div class="text-gray-600 font-normal">{{ relation.address?.city }}</div>
                                    <div class="text-gray-600 font-normal">{{ relation.address?.country }}</div>
                                    <div class="text-gray-600 font-normal">{{ relation.address?.postcode }}</div>
                                </a>
                            </td>
                            <td v-if="!user.is_archived">
                                <button @click="removeRelation(relation.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div id="add-relation-form">
        <div v-if="showingAddRelationForm">
            <div class="mt-10"></div>
            <RelationForm
                url="/creative/profile/relation/add"
                @closed="hideAddRelationForm"
            />
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3"
import {Inertia} from "@inertiajs/inertia"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import CreativeProfileFilters from "@components/creative/CreativeProfileFilters"
import RelationForm from "@components/utilities/RelationForm"
import useToggleable from "@composables/useToggleable"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})
const { showing: showingAddRelationForm, show: showAddRelationForm, hide: hideAddRelationForm } = useToggleable('add-relation-form')
const removeRelation = (relationId) => {
    Inertia.post('/creative/profile/relation/' + relationId + '/remove', {}, {
        onBefore: () => confirm('Are you sure you want to remove the Relation?'),
        preserveScroll: true
    })
}
</script>

<style lang="postcss">
body {
}
</style>
