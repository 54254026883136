<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:action>
            <div v-if="!member.is_archived" class="col">
                <button @click="showMemberForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update Member
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                    <div class="col">
                        Last Booking:
                        <span class="font-semibold">{{ member.last_booking_timestamp }}</span>
                    </div>
                    <div class="col">
                        Last Update:
                        <span class="font-semibold">{{ member.updated_at }}</span>
                    </div>
                </div>
                <div class="profile-grid">
                    <div class="col p-6">
                        Children
                        <h6 class="font-semibold mt-1">{{ member.statistics?.children_total }}</h6>
                    </div>
                    <div class="col p-6">
                        Team
                        <h6 class="font-semibold mt-1">{{ member.statistics?.team_total }}</h6>
                    </div>
                    <div class="col p-6">
                        Member Since
                        <h6 class="font-semibold mt-1">{{ member.created_at_short }}</h6>
                    </div>
                    <div class="col p-6">
                        Other Users
                        <h6 class="font-semibold mt-1">{{ member.statistics?.users_count }}</h6>
                    </div>
                </div>
                <div class="mt-10">
                    <h5 class="sub-title">Overview</h5>
                    <table class="transaction-table">
                        <tbody>
                        <tr v-if="member.is_archived">
                            <th class="text-secondary">Archived</th>
                            <td class="text-secondary">{{ member.archived_at }}</td>
                        </tr>
                        <tr>
                            <th>Subscription Status</th>
                            <td>{{ member.subscription_status }}</td>
                        </tr>
                        <tr v-if="member.subscription_status === 'Trial'">
                            <th>Trial Ends</th>
                            <td>{{ member.trial_ends_at }}</td>
                        </tr>
                        <tr>
                            <th>Last Check-in on</th>
                            <td>{{ member.last_check_in_at ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Terms Signed on</th>
                            <td>{{ member.terms_signed_at }}</td>
                        </tr>
                        <tr>
                            <th>Terms File</th>
                            <td>
                                <a v-if="member.terms_file_url && member.terms_filename" :href="member.terms_file_url" target="_blank" class="text-primary"><i class="fas fa-file primary"></i></a>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-member-form">
                <div v-if="showingMemberForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit Member</h4>
                            </div>
                            <div class="col">
                                <button @click="hideMemberForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitMemberForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Name</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="far fa-user"></i>
                                            </div>
                                            <input v-model="memberForm.name" :class="{'error' : memberForm.errors?.name}" placeholder="Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-10">
                                        <label class="input-label">VIP Member</label>
                                        <label class="switch">
                                            <input type="checkbox" v-model="memberForm.is_vip">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div v-if="member.subscription_status === 'Trial' || member.subscription_status === 'N/A'" class="input-wrapper mb-5">
                                        <label class="input-label">Trial End Date</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-calendar-alt"></i>
                                            </div>
                                            <input v-model="memberForm.trial_ends_at" :class="{'error' : memberForm.errors?.trial_ends_at}" placeholder="Trial End Date" type="date">
                                        </div>
                                    </div>
                                    <hr class="mt-10 mb-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Last Check-in Date</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-calendar-alt"></i>
                                            </div>
                                            <input v-model="memberForm.last_check_in_at" :class="{'error' : memberForm.errors?.last_check_in_at}" placeholder="Last Check-in Date" type="date">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Terms Sign Date</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-calendar-alt"></i>
                                            </div>
                                            <input v-model="memberForm.terms_signed_at" :class="{'error' : memberForm.errors?.terms_signed_at}" placeholder="Terms Sign Date" type="date">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="memberForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!memberForm.isDirty || memberForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SoftDelete
                :is-archived="member.is_archived"
                :archive-url="'/admin/users/members/' + member.id + '/archive'"
                :restore-url="'/admin/users/members/' + member.id + '/restore'"
                type="Member"
            >
                <p>Archived Members cannot access the application.</p>
            </SoftDelete>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import SoftDelete from "@components/utilities/SoftDelete"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const memberForm = useForm({
    name: props.member.name,
    is_vip: props.member.is_vip,
    last_check_in_at: props.member.last_check_in_at_raw,
    terms_signed_at: props.member.terms_signed_at_raw,
    trial_ends_at: props.member.trial_ends_at_raw,
})

const submitMemberForm = () => {
    memberForm.post('/admin/users/members/' + props.member.id + '/update/overview', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideMemberForm(),
    })
}

const { showing: showingMemberForm, show: showMemberForm, hide: hideMemberForm } = useToggleable('edit-member-form')


</script>
