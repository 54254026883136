<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/account/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/account/notifications')}">Notifications</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/account/details" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/account/details')}">Details</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div v-if="types?.length">
        <div class="page-filters-section">
            <div class="filter-bar">
                <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
                    <div class="col">
                        <div class="filter-select">
                            <select v-model="searchType">
                                <option selected value="">All</option>
                                <option v-for="(type, index) in types" :key="index" :value="type.slug">{{ type.label }}</option>
                            </select>
                            <div class="filter-select-icon">
                                <i class="fas fa-caret-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Notifications</h4>
                </div>
                <div class="col text-right">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                    <!--
                    <br>
                    Total Unread: <span class="font-semibold">{{ $page.props?.notificationsCount }}</span>
                    -->
                </div>
            </div>
            <div class="pt-10"></div>
            <div v-for="(notification, index) in items" :key="index">
                <div class="p-4 rounded flex bg-gray-50 justify-between items-center mb-6">
                    <div class="col">
                        <div class="font-semibold leading-1">{{ notification.text }}</div>
                        <span class="text-gray-400">{{ notification.created_at }}</span>
                        <div class="mt-5 font-normal">{{ notification.subtext }}</div>
                    </div>
                    <div v-if="notification.action_url && notification.action_label" class="col">
                        <InertiaLink :href="notification.action_url" class="btn btn--sm btn--circle">{{ notification.action_label }}</InertiaLink>
                    </div>
                </div>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePaginator from "@composables/usePaginator"
import {computed, ref, watch} from "vue"

const props = defineProps({
    types: {
        type: Array,
        default: []
    }
})

const searchType = ref("")

const apiUrl = computed(() => {
    let url = '/admin/account/notifications/api'

    let params = new URLSearchParams()
    if (searchType.value !== "") {
        params.append('type', encodeURIComponent(searchType.value))
    }
    let suffix = params.toString()
    return url + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
<style lang="postcss">
</style>
