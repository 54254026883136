<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Add Child</h4>
                </div>
                <div v-if="showClose" class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Non-Member Child</label>
                            <label class="switch">
                                <input type="checkbox" v-model="form.is_non_member">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                        <div v-show="!form.is_non_member" class="input-wrapper mb-10">
                            <label class="input-label">Select Child</label>
                            <select name="value" v-model="form.child_id" :class="{'error' : form.errors.child_id}">
                                <option disabled>Select Child</option>
                                <option v-for="(child, index) in children" :key="index" :value="child.id">
                                    {{ child.id + ' ' + child.name }}
                                </option>
                            </select>
                        </div>


                        <div v-if="canEditBlock">
                            <div class="input-wrapper mb-5 mt-5">
                                <label class="input-label">Edit Block</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="form.edit_block">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div v-if="form.edit_block" class="alert-box alert-box--error mt-10 mb-10">
                                <div class="alert-box-icon">
                                    <i class="fas fa-info-circle"></i>
                                </div>
                                <p>This action will update all of the Bookings within the Block.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Add Child">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    showClose: {
        type: Boolean,
        default: true
    },
    children: {
        type: Array,
        default: []
    },
    canEditBlock: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['closed'])

const form = useForm({
    is_non_member: false,
    child_id: null,
    edit_block: false,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})
</script>
