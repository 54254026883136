<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center gap-10">
                <div class="col">
                    <div class="page-card-avatar avatar avatar--xl" v-bind:style="{ backgroundImage: 'url(' + user.avatar + ')' }"></div>
                </div>
                <div class="col">
                    <h3 class="page-title">{{ user.name }}</h3>
                    <h6>{{ user.id }}</h6>
                    <h6 v-if="user.is_archived" class="text-secondary font-extrabold">ARCHIVED</h6>
                </div>
                <div v-if="!isOwnPage && !user.is_archived" class="col ml-auto">
                    <button @click="showUserForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update User
                    </button>
                </div>
            </div>
            <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                <div class="col">
                    Created:
                    <span class="font-semibold">{{ user.created_at }}</span>
                </div>
                <div class="col">
                    Last Update:
                    <span class="font-semibold">{{ user.updated_at }}</span>
                </div>
            </div>
            <div class="mt-14">
                <h5 class="sub-title">Overview</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr v-if="user.is_archived">
                            <th class="text-secondary">Archived</th>
                            <td class="text-secondary">{{ user.archived_at }}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{{ user.email }}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{{ user.formatted_role }}</td>
                        </tr>
                        <tr>
                            <th>Last Login</th>
                            <td>{{ user.last_login }}</td>
                        </tr>
                        <tr>
                            <th>App Access</th>
                            <td>
                                <UserListAccessStatusIcon :type="user.is_archived ? 'archived' : (user.is_locked_out ? 'locked' : 'access')" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div v-if="!isOwnPage">

        <div id="edit-user-form">
            <div v-if="showingUserForm" class="page-card mt-10">
                <div class="page-card-content">
                    <div class="flex flex-wrap items-center justify-between gap-4">
                        <div class="col">
                            <h4>Edit User</h4>
                        </div>
                        <div class="col">
                            <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <div class="pb-10"></div>
                    <form @submit.prevent="submitUserForm">
                        <div class="grid grid-cols-1 md:grid-cols-2">
                            <div class="col">
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">Title</label>
                                    <div class="input-holder">
                                        <input v-model="userForm.title" :class="{'error' : userForm.errors?.title}" placeholder="Title"
                                               type="text">
                                    </div>
                                </div>
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">First Name</label>
                                    <div class="input-holder input-holder--icon">
                                        <div class="input-holder-icon">
                                            <i class="far fa-user"></i>
                                        </div>
                                        <input v-model="userForm.first_name" :class="{'error' : userForm.errors?.first_name}" placeholder="First Name"
                                               type="text">
                                    </div>
                                </div>
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">Last Name</label>
                                    <div class="input-holder input-holder--icon">
                                        <div class="input-holder-icon">
                                            <i class="far fa-user"></i>
                                        </div>
                                        <input v-model="userForm.last_name" :class="{'error' : userForm.errors?.last_name}" placeholder="Last Name"
                                               type="text">
                                    </div>
                                </div>
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">Select Type</label>
                                    <select v-model="userForm.type" :class="{'error' : userForm.errors.type}">
                                        <option value="admin">Standard Admin</option>
                                        <option value="super admin">Super Admin</option>
                                    </select>
                                </div>
                                <hr class="mb-10 mt-10">
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">Email</label>
                                    <div class="input-holder input-holder--icon">
                                        <div class="input-holder-icon">
                                            <i class="far fa-envelope"></i>
                                        </div>
                                        <input v-model="userForm.email" :class="{'error' : userForm.errors?.email}" placeholder="Email"
                                               type="email">
                                    </div>
                                </div>
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">{{ userForm.has_app_access ? 'Access Granted' : 'Access Suspended' }}</label>
                                    <label class="switch">
                                        <input type="checkbox" v-model="userForm.has_app_access">
                                        <span class="switch-slider"></span>
                                    </label>
                                </div>
                                <div v-if="userForm.has_app_access">
                                    <hr class="mb-10 mt-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">New Password</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-lock"></i>
                                            </div>
                                            <input v-model="userForm.password" :class="{'error' : userForm.errors?.password}"
                                                   placeholder="Password" type="password">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-10">
                                        <label class="input-label">New Password Confirmation</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-lock"></i>
                                            </div>
                                            <input v-model="userForm.password_confirmation"
                                                   :class="{'error' : userForm.errors?.password_confirmation}" placeholder="Password"
                                                   type="password">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormErrorsGroup :errors="userForm.errors"/>
                        <div class="input-submit mt-10">
                            <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                   type="submit" value="Update">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <SoftDelete
            :is-archived="user.is_archived"
            :archive-url="'/admin/users/admins/' + user.id + '/archive'"
            :restore-url="'/admin/users/admins/' + user.id + '/restore'"
            type="User"
        >
            <p>Archived Users cannot access the application.</p>
        </SoftDelete>
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import {Inertia} from "@inertiajs/inertia"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import SoftDelete from "@components/utilities/SoftDelete"
import useToggleable from "@composables/useToggleable"
import UserListAccessStatusIcon from "@components/utilities/UserListAccessStatusIcon"

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
    isOwnPage: {
        type: Boolean,
        required: true
    }
})

const userForm = useForm({
    title: props.user.title,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    gender: props.user.gender,
    email: props.user.email,
    password: null,
    password_confirmation: null,
    has_app_access: !props.user.is_locked_out,
    type: props.user.role,
})
const submitUserForm = () => {
    userForm.post('/admin/users/admins/' + props.user.id + '/update', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}
const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')

</script>

<style lang="postcss">
body {
}
</style>
