<template>
    <MemberChildDetailsLayout :child="child">
        <template v-slot:action>
            <div v-if="!child.is_archived" class="col">
                <button @click="showChildForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update School
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <h5 class="sub-title">School Details</h5>
                <table class="transaction-table">
                    <tbody>
                    <tr>
                        <th>Name</th>
                        <td>{{ child.school?.name ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Teachers</th>
                        <td>{{ child.school?.teachers ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Gate Access Codes</th>
                        <td>{{ child.school?.gate_access_codes ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Verbal Access Codes</th>
                        <td>{{ child.school?.verbal_access_codes ?? '-' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-child-form">
                <div v-if="showingChildForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit Child</h4>
                            </div>
                            <div class="col">
                                <button @click="hideChildForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitChildForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">School Name</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="far fa-building"></i>
                                            </div>
                                            <input v-model="childForm.school_name" :class="{'error' : childForm.errors?.school_name}" placeholder="School Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">School Teachers</label>
                                            <textarea v-model="childForm.school_teachers" :class="{'error' : childForm.errors?.school_teachers}" name="school_teachers" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Gate Access Codes</label>
                                            <textarea v-model="childForm.gate_access_codes" :class="{'error' : childForm.errors?.gate_access_codes}" name="gate_access_codes" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Verbal Access Codes</label>
                                            <textarea v-model="childForm.verbal_access_codes" :class="{'error' : childForm.errors?.verbal_access_codes}" name="verbal_access_codes" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="childForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!childForm.isDirty || childForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </MemberChildDetailsLayout>
</template>

<script setup>
import MemberChildDetailsLayout from "@components/member/MemberChildDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    child: {
        type: Object,
        required: true
    }
})

const childForm = useForm({
    school_name: props.child.school_name,
    school_teachers: props.child.school_teachers,
    gate_access_codes: props.child.gate_access_codes,
    verbal_access_codes: props.child.verbal_access_codes,
})

const submitChildForm = () => {
    childForm.post('/member/profile/children/' + props.child.id + '/update/school', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideChildForm(),
    })
}

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('edit-child-form')

</script>
