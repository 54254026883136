<template>
    <AdminBookingsFilters/>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <input v-model="searchTerm" type="text">
                        <button :disabled="true">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="flex justify-between items-center">
                <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                    <div class="col">
                        <Dropdown :filters="statusFilters" v-model="searchStatus" />
                    </div>
                    <div class="col">
                        <Dropdown :filters="creativeFilters" v-model="searchCreativeStatus" />
                    </div>
                    <div class="col">
                        <Dropdown :filters="memberFilters" v-model="searchMemberStatus" />
                    </div>
                </div>
                <div class="col pt-4 lg:pt-0">
                    <InertiaLink href="/admin/bookings/kanban" class="btn btn--sm btn--secondary">
                        <i class="fa fa-filter mr-3"></i>
                        Column View
                    </InertiaLink>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-10"></div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Bookings</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Member</th>
                        <th>Creative</th>
                        <th>Member Total</th>
                        <th>Creative Total</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    <tr v-for="(booking, index) in items" :key="index">
                        <td>
                            <InertiaLink :href="'/admin/users/members/' + booking.member.id">
                                {{ booking.member.name }} <i v-if="booking.member.is_vip" class="fas fa-check-circle primary"></i>
                            </InertiaLink>
                        </td>
                        <td>
                            <InertiaLink :href="'/admin/users/creatives/' + booking.creative.id">
                                <div class="text-gray-600">{{ booking.creative.name }}</div>
                            </InertiaLink>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ booking.finances?.member_total }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ booking.finances?.creative_total }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600 font-normal">{{ booking.start_timestamp }}</div>
                            <div class="text-gray-600 font-normal">{{ booking.end_timestamp }}</div>
                            <span class="text-gray-600">({{ booking.duration }})</span>
                        </td>
                        <td>
                            <div v-if="['Unfulfilled', 'Pending'].includes(booking.status.overall)" class="text-secondary">{{ booking.status.overall }}</div>
                            <div v-else-if="['Upcoming', 'Approved'].includes(booking.status.overall)" class="text-primary">{{ booking.status.overall }}</div>
                            <div v-else>{{ booking.status.overall }}</div>
                        </td>
                        <td>
                            <div class="flex gap-2">
                                <div class="col">
                                    <InertiaLink :href="'/admin/bookings/' + booking.id" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-pen"></i>
                                        Details
                                    </InertiaLink>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePaginator from "@composables/usePaginator"

import {computed, ref, watch} from "vue"
import AdminBookingsFilters from "@components/admin/AdminBookingsFilters"

import Dropdown from "@components/utilities/Dropdown"

const props = defineProps({
    type: {
        type: String,
        required: true
    }
})

const searchTerm = ref("")

const searchStatus = ref("")
const statusFilters = [
    {label: 'Status', value: ''},
    {label: 'Unfulfilled', value: 'unfulfilled'},
    {label: 'Upcoming', value: 'upcoming'},
    {label: 'Pending', value: 'pending'},
    {label: 'Approved', value: 'approved'},
    {label: 'Completed', value: 'completed'},
    {label: 'Cancelled (No charge)', value: 'cancelled-no-charge'},
    {label: 'Cancelled (With charge)', value: 'cancelled-with-charge'},
]

const searchCreativeStatus = ref("")
const creativeFilters = [
    {label: 'Creative Payment', value: ''},
    {label: 'N/A', value: 'NA'},
    {label: 'Processing', value: 'processing'},
    {label: 'Awaiting', value: 'awaiting'},
    {label: 'Paid', value: 'paid'},
    {label: 'Special Instructions', value: 'SI'},
]

const searchMemberStatus = ref("")
const memberFilters = [
    {label: 'Member Payment', value: ''},
    {label: 'N/A', value: 'NA'},
    {label: 'Processing', value: 'processing'},
    {label: 'Awaiting', value: 'awaiting'},
    {label: 'Settled', value: 'settled'},
    {label: 'Special Instructions', value: 'SI'},
]



const apiUrl = computed(() => {
    let url = '/admin/bookings/api/' + props.type

    let params = new URLSearchParams()

    if (searchTerm.value !== "") {
        params.append('terms', encodeURIComponent(searchTerm.value))
    }
    if (searchStatus.value !== "") {
        params.append('status', encodeURIComponent(searchStatus.value))
    }
    if (searchCreativeStatus.value !== "") {
        params.append('creative_status', encodeURIComponent(searchCreativeStatus.value))
    }
    if (searchMemberStatus.value !== "") {
        params.append('member_status', encodeURIComponent(searchMemberStatus.value))
    }

    let suffix = params.toString()

    return url + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
<style lang="postcss">
</style>
