<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col">
                    <div class="flex">
                        <div class="col">
                            <InertiaLink href="/admin/users/broadcast/create" class="btn btn--secondary">
                                <i class="fas fa-plus"></i>
                                New Broadcast
                            </InertiaLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Past Broadcasts</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Subject</th>
                        <th>Recipients Count</th>
                        <th>Admin</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                    <tr v-for="(broadcast, index) in items" :key="index">
                        <td>
                            <div class="text-gray-600 font-normal">{{ broadcast.subject }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600 font-normal">{{ broadcast.recipients_count }}</div>
                        </td>
                        <td>
                            <InertiaLink :href="'/admin/users/admins/' + broadcast.author.id">
                                <div class="text-gray-600 font-normal">{{ broadcast.author.name }}</div>
                            </InertiaLink>
                        </td>
                        <td>
                            <div class="text-gray-600 font-normal">{{ broadcast.created_at }}</div>
                        </td>
                        <td>
                            <div class="flex gap-2">
                                <div class="col">
                                    <InertiaLink :href="'/admin/users/broadcast/' + broadcast.id" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-pen"></i>
                                        Details
                                    </InertiaLink>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePaginator from "@composables/usePaginator"
import {computed} from "vue"

const apiUrl = computed(() => {
    return '/admin/users/broadcast/api'
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
<style lang="postcss">
</style>
