<template>
    <div class="home">
        <div class="container">
            <div class="spacer50"></div>
            <h2>Creating forever memories for your child’s trip!</h2>
            <div class="message mt-10" style="font-size: 22px;">
                Our impeccable Hotel & Homestay sitters are the go-to for business people, celebrities and HNW families when visiting London and the wider UK, and our highly professional, discreet team have been trusted by the UK’s most exclusive hotels, country clubs and homestay properties for over a decade.
            </div>
            <div class="message mt-10">
                Once we have your requirements we set to work matching your family profile to our pool of m/nannies, and sitters — each bringing a unique skill or sensibility, from a wealth of experience in teaching, nursery and creative backgrounds.
            </div>
            <div class="message mt-10">
                You can trust our team will arrive suitably dressed and prepared for every location, be it a countryside adventure or an indoor picnic; always with a plan and full of energy for the adventure ahead.  And when it comes to travel, our team are experts in touring on complex travel schedules, and are masters of finding magic in the face of any delay.
            </div>
            <FactorsProcessComponent />
        </div>
        <div class="text-center" style="width: 100%; margin-top: 20px;">
            <InertiaLink class="btn btn--outline btn--rounded nb-gold-button" href="/contact?subject=hotels" style="min-width: 180px; padding: 10px 15px; display: inline-block;">Call us</InertiaLink>
        </div>
        <div class="spacer50"></div>
        <GalleryComponent :images="images" />
        <div id="fees" class="spacer50"></div>
        <div>
            <PricingTableHotels />
        </div>
        <div class="spacer70"></div>
        <TestimonialsComponent :show-gallery="true" title="Testimonials" />
        <div class="container">
            <div class="spacer100"></div>
            <OurFamilyComponent />
            <div class="text-center" style="width: 100%; margin-top: 30px;">
                <InertiaLink class="btn btn--outline btn--rounded nb-gold-button" href="/contact?subject=hotels" style="min-width: 180px; padding: 10px 15px; display: inline-block;">Contact us</InertiaLink>
            </div>
            <hr class="sep mt-12">
        </div>
        <div class="spacer50"></div>
        <div class="container">
            <TeamsComponent :team="team" :show-apply-button="false" text="Our team of over a hundred creative sitters & nannies all have backgrounds in teaching, theatre, art & design, nursery care, film & TV, music and dance. They are great storytellers, skilled communicators, and quick thinkers. They are experts at finding adventure round every corner!" />
        </div>
    </div>
</template>

<script setup>
import TeamsComponent from "@components/web/TeamsComponent";
import OurFamilyComponent from "@components/web/OurFamilyComponent";
import TestimonialsComponent from "@components/web/TestimonialsComponent";
import FactorsProcessComponent from "@components/web/FactorsProcessComponent";
import PricingTableHotels from "@components/web/PricingTableHotels";
import GalleryComponent from "@components/web/GalleryComponent";

const images = [
    "img/web/hotels_gallery_1.jpg",
    "img/web/hotels_gallery_2.jpg",
    "img/web/hotels_gallery_3.jpg",
    "img/web/hotels_gallery_4.jpg",
    "img/web/hotels_gallery_5.jpg",
    "img/web/hotels_gallery_6.jpg",
]

const team = [
    {
        id: 1,
        name: "Laura",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_1.jpg",
        photo_two: "/img/web/home_team_1.jpg",
    },
    {
        id: 2,
        name: "Nathan",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_2.jpg",
        photo_two: "/img/web/home_team_2.jpg",
    },
    {
        id: 3,
        name: "Molly",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_3.jpg",
        photo_two: "/img/web/home_team_3.jpg",
    },
    {
        id: 4,
        name: "Boni",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_4.jpg",
        photo_two: "/img/web/home_team_4.jpg",
    },
    {
        id: 5,
        name: "Josh",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_5.jpg",
        photo_two: "/img/web/home_team_5.jpg",
    },
    {
        id: 6,
        name: "Imogen",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_6.jpg",
        photo_two: "/img/web/home_team_6.jpg",
    },
    {
        id: 7,
        name: "Sara",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_7.jpg",
        photo_two: "/img/web/home_team_7.jpg",
    },
    {
        id: 8,
        name: "Madelaine",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_8.jpg",
        photo_two: "/img/web/home_team_8.jpg",
    },
    {
        id: 9,
        name: "Hope",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_9.jpg",
        photo_two: "/img/web/home_team_9.jpg",
    },
    {
        id: 10,
        name: "Eubha",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_10.jpg",
        photo_two: "/img/web/home_team_10.jpg",
    },
    {
        id: 11,
        name: "Conor",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_11.jpg",
        photo_two: "/img/web/home_team_11.jpg",
    },
    {
        id: 12,
        name: "Ella",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_12.jpg",
        photo_two: "/img/web/home_team_12.jpg",
    },
    {
        id: 13,
        name: "Grace",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_13.jpg",
        photo_two: "/img/web/home_team_13.jpg",
    },
    {
        id: 14,
        name: "Caitlin",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_14.jpg",
        photo_two: "/img/web/home_team_14.jpg",
    },
    {
        id: 15,
        name: "Nicole",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_15.jpg",
        photo_two: "/img/web/home_team_15.jpg",
    },
    {
        id: 16,
        name: "Eliza",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_16.jpg",
        photo_two: "/img/web/home_team_16.jpg",
    },
    {
        id: 17,
        name: "Jake",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_17.jpg",
        photo_two: "/img/web/home_team_17.jpg",
    },
    {
        id: 18,
        name: "Charlotte",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_18.jpg",
        photo_two: "/img/web/home_team_18.jpg",
    },
    {
        id: 19,
        name: "Hannah",
        title: "Actor Musician",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_19.jpg",
        photo_two: "/img/web/home_team_19.jpg",
    },
    {
        id: 20,
        name: "Will",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_20.jpg",
        photo_two: "/img/web/home_team_20.jpg",
    },
]

</script>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";

export default {
    layout: [BaseLayout, WebLayout]
}
</script>

<style scoped>
.nb-gold-button {
    @apply bg-white text-black border-gold hover:bg-gold hover:text-white hover:border-gold;
}

</style>
