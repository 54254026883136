<template>
    <div class="vld-parent">
        <loading :active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
        />
        <div class="page-card">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>New Broadcast</h4>
                    </div>
                </div>
                <div class="pb-20"></div>
                <form @submit.prevent="form.post('/admin/users/broadcast/create')">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-10">
                                <label class="input-label">Subject</label>
                                <input v-model="form.subject" :class="{'error' : form.errors.subject}" type="text">
                            </div>
                            <div class="input-wrapper mb-9">
                                <label class="input-label">Email Body</label>
                                <TipTapEditor v-model="form.body" />
                            </div>
                            <hr class="mt-10 mb-10">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">User Type</label>
                                <select v-model="user_type">
                                    <option value="all">All</option>
                                    <option value="admins">Admins</option>
                                    <option value="creatives">Creatives</option>
                                    <option value="members">Members</option>
                                </select>
                            </div>

                            <div class="input-wrapper">
                                <label class="input-label">Tags</label>
                                <TagPicker v-model="tags" :allow-create="false" />
                            </div>
                            <div class="input-wrapper mb-5 mt-5">
                                <label class="input-label">Select All Matching Users</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="form.select_all">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div v-if="!form.select_all" class="input-wrapper mb-5">
                                <label class="input-label">Select Recipients</label>
                                <Multiselect
                                    ref="selector"
                                    v-model="form.users"
                                    mode="tags"
                                    placeholder="Select Recipients"
                                    :filterResults="false"
                                    :createTag="false"
                                    :minChars="1"
                                    :resolveOnLoad="false"
                                    :delay="1"
                                    :searchable="true"
                                    :object="true"
                                    trackBy="name"
                                    label="name"
                                    :options="async function(query) {
                                      return await fetchUsers(query)
                                    }"
                                >
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag p-10" :class="option.role === 'admin' || option.role === 'super admin' ? 'multiselect-tag--user-admin' : (option.role === 'member' ? 'multiselect-tag--user-member' : '')">
                                            <img class="user-image" :src="option.avatar"> {{ option.name }}  <i v-if="option.is_star" class="fas fa-star text-white ml-1"></i> <i v-if="option.is_vip" class="fas fa-check-circle text-white ml-1"></i> <i v-if="option.is_flagged" class="fas fa-exclamation-circle text-white"></i>
                                            <span
                                                v-if="!disabled"
                                                class="multiselect-tag-remove"
                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                            >
                                                <span class="multiselect-tag-remove-icon"></span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <div class="btn btn--sm btn" :class="option.role === 'admin' || option.role === 'super admin' ? 'multiselect-tag--user-admin' : (option.role === 'member' ? 'multiselect-tag--user-member' : '')">
                                            <img class="user-image" :src="option.avatar"> {{ option.name }}  <i v-if="option.is_star" class="fas fa-star text-white ml-1"></i> <i v-if="option.is_vip" class="fas fa-check-circle text-white ml-1"></i> <i v-if="option.is_flagged" class="fas fa-exclamation-circle text-white"></i>
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>
                            <div class="input-wrapper mt-5 mb-10">
                                <label class="input-label">Recipients Count</label>
                                <input disabled :value="form.users?.length" type="text">
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="form.errors"/>
                    <div class="input-submit mt-10">
                        <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300" type="submit" value="Send">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {computed, onUnmounted, ref, watch} from "vue"
import TipTapEditor from "@components/utilities/TipTapEditor"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import Multiselect from '@vueform/multiselect'
import axios from "axios";
import TagPicker from "@components/utilities/TagsPicker"

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

let isLoading = ref(false)

const selector = ref(null)

const tags = ref([])
const searchTags = computed(() => tags.value?.map(tag => tag.id))

const users = ref([])

const emits = defineEmits(['closed'])

const user_type = ref('all')

const form = useForm({
    subject: null,
    body: null,
    users: null,
    select_all: false,
})
const canSubmit = computed(() => {
    return !form.processing
        && form.isDirty
        && form.body
        && form.users?.length
})

onUnmounted(() => {
    form.reset()
})

watch(searchTags, () => {
    form.select_all = false
    selector.value?.refreshOptions()
})
watch(user_type, () => {
    form.select_all = false
    selector.value?.refreshOptions()
})


const selectAllToggled = async () => {
    isLoading.value = true
    selector.value?.clear()
    form.users = null

    if (form.select_all) {
        await fetchAllUsers()
    }
    isLoading.value = false
}

watch(() => form.select_all, () => selectAllToggled())

const fetchUsers = async (terms) => {
    isLoading.value = true
    let options = []

    let url = "/admin/users/broadcast/create/suitable"

    if (user_type.value && user_type.value !== 'all') {
        url += '/' + user_type.value
    }

    let params = new URLSearchParams();

    if (terms) {
        params.append('terms', encodeURIComponent(terms))
    }
    if (searchTags.value?.length) {
        params.append('tags', encodeURIComponent(searchTags.value))
    }

    let suffix = params.toString()
    url +=  suffix ? '?' + suffix : ''

    await axios.get(url)
        .then((resp) => {
            options = resp.data
            isLoading.value = false
        })
        .catch((err) => {
            console.log(err)
            isLoading.value = false
        })

    return options
}

const fetchAllUsers = async () => {
    isLoading.value = true
    let options = []

    let url = "/admin/users/broadcast/create/suitable"

    if (user_type.value && user_type.value !== 'all') {
        url += '/' + user_type.value
    }

    let params = new URLSearchParams();

    if (searchTags.value?.length) {
        params.append('tags', encodeURIComponent(searchTags.value))
    }

    params.append('select_all', encodeURIComponent("1"))

    let suffix = params.toString()
    url +=  suffix ? '?' + suffix : ''

    await axios.get(url)
        .then((resp) => {
            options = resp.data
            isLoading.value = false
        })
        .catch((err) => {
            console.log(err)
            isLoading.value = false
        })

    form.users = options ?? null
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="postcss">
.multiselect-tag {
    @apply bg-primary;
}
.multiselect-tag--not-approved {
    @apply bg-secondary-100 hover:bg-secondary-300 text-light;
}
.multiselect-tag--admin {
    @apply bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600;
}
.multiselect-tag--user-admin {
    @apply bg-black text-white hover:bg-black hover:text-white;
}
.multiselect-tag--user-member {
    @apply bg-secondary-100 hover:bg-secondary-300 text-light;
}
.user-image {
    height: 22px;
    margin: 0 6px 0 0;
    border-radius: 50%;
    display: inline;
}
</style>
