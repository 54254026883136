<template>
    <div class="home">
        <div class="container">
            <div class="spacer50"></div>
            <h2>Flexible sitters & nannies powered by creativity!</h2>
            <div class="message mt-10" style="font-size: 22px;">
                For over a decade we have been on a mission to help busy parents find the very best creatively-minded childcarers in the space between Nursery and an employed m/nanny.
            </div>
            <div class="message mt-10">
                We are driven to make your family's childcare simple and enjoyable!
            </div>
            <div class="message mt-10">
                We hand-pick a team of sitters based on your families needs, preferences and interests. Our unique processes give you maximum flexibility whilst maintaining consistency.
            </div>
            <div class="message mt-10">
                Our brand of childcare is different. We are more than just childcare. Our sitters are natural storytellers, quick on their feet and experts at finding the adventure around every corner! It is why we refer to them as “creatives”
            </div>
            <HomeFactorsProcessComponent />
        </div>
        <GalleryComponent :images="images" />
        <div id="fees" class="spacer50"></div>
        <div>
            <PricingTableHomeChildCare />
        </div>
        <div class="spacer50"></div>
        <TestimonialsComponent :show-gallery="true" title="a few words from our existing families" />

        <div class="container">
            <ProcessComponent />
        </div>
        <div class="container">
            <div class="spacer100"></div>
            <OurFamilyComponent />
            <div class="text-center" style="width: 100%; margin-top: 30px;">
                <InertiaLink class="btn btn--outline btn--rounded" href="/contact?subject=Home Childcare" style="min-width: 180px; padding: 10px 15px; display: inline-block;">Contact us</InertiaLink>
            </div>
            <hr class="sep mt-12">
        </div>
        <div class="spacer50"></div>
        <div class="container">
            <TeamsComponent :team="team" text="Our team of over a hundred creative sitters & nannies all have backgrounds in teaching, theatre, art & design, nursery care, film & TV, music and dance. They are great storytellers, skilled communicators, and quick thinkers. They are experts at finding adventure round every corner!" />
        </div>
    </div>
</template>

<script setup>
import TeamsComponent from "@components/web/TeamsComponent";
import OurFamilyComponent from "@components/web/OurFamilyComponent";
import TestimonialsComponent from "@components/web/TestimonialsComponent";
import HomeFactorsProcessComponent from "@components/web/HomeFactorsProcessComponent";
import PricingTableHomeChildCare from "@components/web/PricingTableHomeChildCare";
import GalleryComponent from "@components/web/GalleryComponent";
import ProcessComponent from "@components/web/ProcessComponent";

const images = [
    "/img/web/childcare_gallery_1.jpg",
    "/img/web/childcare_gallery_2.jpg",
    "/img/web/childcare_gallery_3.jpg",
    "/img/web/childcare_gallery_4.jpg",
    "/img/web/childcare_gallery_5.jpg",
    "/img/web/childcare_gallery_6.jpg",
]

const prices = [
    {
        title: 'Home',
        description: 'Home membership covers childcare at your primary residence or any other set location.',
        text_one: "From £50 per 3 hour booking",
        text_two: 240,
        text_three: 240,
        pricing_list: [],
    },
    {
        title: 'Town',
        description: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        text_one: 12,
        text_two: 120,
        text_three: 120,
        highlighted: true,
        pricing_list: [],
    },
    {
        title: 'ADHOC',
        description: 'XXXXX',
        text_one: 12,
        text_two: 120,
        text_three: 120,
        pricing_list: [],
    },
    // {
    //     title: 'City',
    //     description: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    //     text_one: 12,
    //     text_two: 120,
    //     text_three: 120,
    //     pricing_list: [],
    // },
];

const team = [
    {
        id: 1,
        name: "Laura",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_1.jpg",
        photo_two: "/img/web/home_team_1.jpg",
    },
    {
        id: 2,
        name: "Nathan",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_2.jpg",
        photo_two: "/img/web/home_team_2.jpg",
    },
    {
        id: 3,
        name: "Molly",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_3.jpg",
        photo_two: "/img/web/home_team_3.jpg",
    },
    {
        id: 4,
        name: "Boni",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_4.jpg",
        photo_two: "/img/web/home_team_4.jpg",
    },
    {
        id: 5,
        name: "Josh",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_5.jpg",
        photo_two: "/img/web/home_team_5.jpg",
    },
    {
        id: 6,
        name: "Imogen",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_6.jpg",
        photo_two: "/img/web/home_team_6.jpg",
    },
    {
        id: 7,
        name: "Sara",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_7.jpg",
        photo_two: "/img/web/home_team_7.jpg",
    },
    {
        id: 8,
        name: "Madelaine",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_8.jpg",
        photo_two: "/img/web/home_team_8.jpg",
    },
    {
        id: 9,
        name: "Hope",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_9.jpg",
        photo_two: "/img/web/home_team_9.jpg",
    },
    {
        id: 10,
        name: "Eubha",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_10.jpg",
        photo_two: "/img/web/home_team_10.jpg",
    },
    {
        id: 11,
        name: "Conor",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_11.jpg",
        photo_two: "/img/web/home_team_11.jpg",
    },
    {
        id: 12,
        name: "Ella",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_12.jpg",
        photo_two: "/img/web/home_team_12.jpg",
    },
    {
        id: 13,
        name: "Grace",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_13.jpg",
        photo_two: "/img/web/home_team_13.jpg",
    },
    {
        id: 14,
        name: "Caitlin",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_14.jpg",
        photo_two: "/img/web/home_team_14.jpg",
    },
    {
        id: 15,
        name: "Nicole",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_15.jpg",
        photo_two: "/img/web/home_team_15.jpg",
    },
    {
        id: 16,
        name: "Eliza",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_16.jpg",
        photo_two: "/img/web/home_team_16.jpg",
    },
    {
        id: 17,
        name: "Jake",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_17.jpg",
        photo_two: "/img/web/home_team_17.jpg",
    },
    {
        id: 18,
        name: "Charlotte",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_18.jpg",
        photo_two: "/img/web/home_team_18.jpg",
    },
    {
        id: 19,
        name: "Hannah",
        title: "Actor Musician",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_19.jpg",
        photo_two: "/img/web/home_team_19.jpg",
    },
    {
        id: 20,
        name: "Will",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_20.jpg",
        photo_two: "/img/web/home_team_20.jpg",
    },
]

</script>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";

export default {
    layout: [BaseLayout, WebLayout]
}
</script>

<style scoped>

</style>
