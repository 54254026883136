<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Add Document</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Type</label>
                            <select v-model="form.type" :class="{'error' : form.errors.type}">
                                <option value="Passport">Passport</option>
                                <option value="Drivers License">Drivers License</option>
                                <option value="DBS">DBS</option>
                                <option value="First Aid Certificate">First Aid Certificate</option>
                                <option value="Chaperone License">Chaperone License</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Issuing Authority</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-building"></i>
                                </div>
                                <input v-model="form.issuing_authority" :class="{'error' : form.errors?.issuing_authority}" placeholder="Issuing Authority"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Number</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-passport"></i>
                                </div>
                                <input v-model="form.number" :class="{'error' : form.errors?.number}" placeholder="Number"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Expiry Date</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <input v-model="form.expiry_date" :class="{'error' : form.errors?.expiry_date}" type="date">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">File</label>
                            <div class="input-holder">
                                <input type="file" @input="form.file = $event.target.files[0]" :class="{'error' : form.errors?.expiry_date}" />
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Add Document">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    }
})

const emits = defineEmits(['closed'])

const form = useForm({
    type: 'Passport',
    number: null,
    issuing_authority: null,
    expiry_date: null,
    file: null,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty && form.type && form.expiry_date && form.file !== null
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})
</script>
