<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Team</h5>
                    </div>
                    <div v-if="!member.is_archived" class="col">
                        <button @click="showTeamForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div v-if="member.team.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th style="width: 75px;">Avatar</th>
                            <th style="width: 200px;">Name</th>
                            <th style="width: 200px;">Date Added</th>
                            <th>Next Review</th>
                            <th></th>
                        </tr>
                        <tr v-for="(creative, index) in member.team" :key="index">
                            <td style="width: 75px;">
                                <InertiaLink :href="'/admin/users/creatives/' + creative.id">
                                    <div class="avatar avatar--sm" v-bind:style="{ backgroundImage: 'url(' + creative.avatar + ')' }"></div>
                                </InertiaLink>
                            </td>
                            <td style="width: 200px;">
                                <InertiaLink :href="'/admin/users/creatives/' + creative.id">
                                    <div class="">{{ creative.professional_name }} <i v-if="creative.is_star" class="fas fa-star primary"></i> <i v-if="creative.is_flagged" class="fas fa-exclamation-circle secondary"></i></div>
                                    <span class="text-gray-400 font-normal">{{ creative.name }}</span>
                                </InertiaLink>
                            </td>
                            <td style="width: 200px;"><div class="text-gray-600 font-normal">{{ creative.created_at_short }}</div></td>
                            <td>
                                <div :class="{'text-secondary': creative.needs_review}">{{ creative.review_at }}</div>
                                <span class="font-normal" :class="{'text-secondary': creative.needs_review}">{{ creative.review_at_short }}</span>
                            </td>
                            <td v-if="!member.is_archived">
                                <div class="flex flex-wrap gap-4">
                                    <div class="col">
                                        <button @click="approveTeam(creative.team_id)" class="btn btn--sm"><i class="fas fa-check"></i> Extend</button>
                                    </div>
                                    <div class="col">
                                        <button @click="removeTeam(creative.team_id)" class="btn btn--sm btn--danger"><i class="fas fa-times"></i> Remove</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="mt-20">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Blacklist</h5>
                    </div>
                    <div v-if="!member.is_archived" class="col">
                        <button @click="showBlacklistForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div v-if="member.blacklist.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th style="width: 75px;">Avatar</th>
                            <th style="width: 200px;">Name</th>
                            <th style="width: 200px;">Date Added</th>
                            <th></th>
                        </tr>
                        <tr v-for="(creative, index) in member.blacklist" :key="index">
                            <td style="width: 75px;">
                                <InertiaLink :href="'/admin/users/creatives/' + creative.id">
                                    <div class="avatar avatar--sm" v-bind:style="{ backgroundImage: 'url(' + creative.avatar + ')' }"></div>
                                </InertiaLink>
                            </td>
                            <td style="width: 200px;">
                                <InertiaLink :href="'/admin/users/creatives/' + creative.id">
                                    <div class="">{{ creative.professional_name }} <i v-if="creative.is_star" class="fas fa-star primary"></i> <i v-if="creative.is_flagged" class="fas fa-exclamation-circle secondary"></i></div>
                                </InertiaLink>
                            </td>
                            <td style="width: 200px;"><div class="text-gray-600 font-normal">{{ member.created_at_short }}</div></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-team-form">
                <UsersPicker
                    v-if="showingTeamForm"
                    target="Creatives"
                    :source-url="'/admin/users/members/' + member.id + '/teams/suitable-to-approve'"
                    :url="'/admin/users/members/' + member.id + '/teams/approved'"
                    :users="member.team"
                    @closed="hideTeamForm"
                />
            </div>
            <div id="edit-blacklist-form">
                <UsersPicker
                    v-if="showingBlacklistForm"
                    title="Edit Blacklist"
                    target="Creatives"
                    :source-url="'/admin/users/members/' + member.id + '/teams/suitable-to-blacklist'"
                    :url="'/admin/users/members/' + member.id + '/teams/blacklist'"
                    :users="member.blacklist"
                    @closed="hideBlacklistForm"
                />
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import UsersList from "@components/utilities/UsersList"
import UsersPicker from "@components/utilities/UsersPicker"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const { showing: showingTeamForm, show: showTeamForm, hide: hideTeamForm } = useToggleable('edit-team-form')
const { showing: showingBlacklistForm, show: showBlacklistForm, hide: hideBlacklistForm } = useToggleable('edit-blacklist-form')

const approveTeam = (teamId) => {
    Inertia.post('/admin/users/members/' + props.member.id + '/teams/' + teamId + '/approve', {}, {
        preserveScroll: true
    })
}
const removeTeam = (teamId) => {
    Inertia.post('/admin/users/members/' + props.member.id + '/teams/' + teamId + '/remove', {}, {
        preserveScroll: true
    })
}
</script>
