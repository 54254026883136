<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:default>
            <TagsList
                :tags="booking.tags"
                tag-url-prefix="/admin/system-settings/tags/tags/edit"
                @add="showTagsForm"
            />
        </template>
        <template v-slot:extra>
            <div id="edit-tags-form">
                <TagsEdit
                    v-if="showingTagsForm"
                    :url="'/admin/bookings/' + booking.id + '/edit-tags'"
                    :tags="booking.tags"
                    @closed="hideTagsForm"
                />
            </div>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import TagsEdit from "@components/utilities/TagsEdit"
import TagsList from "@components/utilities/TagsList"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})
const { showing: showingTagsForm, show: showTagsForm, hide: hideTagsForm } = useToggleable('edit-tags-form')

</script>
