<template>
    <div class="user-page">
        <div class="card card--user">
            <div class="logo w-24 h-24 mx-auto">
                <img :src="logoUrl" alt="">
            </div>
            <div class="pb-12"></div>
            <div class="text-center mb-14">
                <h3 class="font-semibold">Verify Email</h3>
            </div>
            <FormErrorsGroup :errors="form.errors"/>
            <div class="input-submit mt-10">
                <input type="submit" value="RESEND VERIFICATION EMAIL" @click="resendEmail" :disabled="form.processing" class="bg-secondary hover:bg-primary transition duration-300 w-full">
            </div>
            <div class="sep-text">
                <span class="sep-line"></span>
            </div>
            <div class="text-center">
                <InertiaLink as="button" class="text-primary" href="/logout" method="post">Log out</InertiaLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import useRole from "@composables/useRole"
import useToast from "@composables/useToast"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    role: {
        type: String,
        default: 'member'
    }
})

const {logoUrl} = useRole(props.role)
const {toast} = useToast()

const form = useForm({})

const resendEmail = () => {
    form.post('/email/verification-notification', {
        onSuccess: visit => {
            toast('success', "The Verification email has been sent!")
        },
    })
}

</script>

<style lang="postcss">
.user-page {
    @apply h-screen w-full flex flex-wrap items-center justify-center overflow-y-auto p-5;
}

.card--user {
    @apply xl:w-3/12 lg:w-6/12 md:w-8/12 w-full;
}

.sep-text {
    @apply flex items-center my-5 text-gray-400;
}

.sep-line {
    @apply h-0.5 block bg-gray-100 flex-1;
}
</style>
