<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ isAdd ? 'Add Address' : 'Edit Address' }}</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Street</label>
                            <div class="input-holder">
                                <input v-model="form.street" :class="{'error' : form.errors?.street}" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Town / City</label>
                            <div class="input-holder">
                                <input v-model="form.city" :class="{'error' : form.errors?.city}" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Country</label>
                            <div class="input-holder">
                                <input v-model="form.country" :class="{'error' : form.errors?.country}" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Postcode</label>
                            <div class="input-holder">
                                <input v-model="form.postcode" :class="{'error' : form.errors?.postcode}" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Label</label>
                            <div class="input-holder">
                                <input v-model="form.name" :class="{'error' : form.errors?.name}" type="text" placeholder="e.g. Home">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5 mt-5">
                            <label class="input-label">Primary Address</label>
                            <label class="switch">
                                <input type="checkbox" v-model="form.is_primary">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" :value="isAdd ? 'Add Address' : 'Update'">
                </div>
            </form>
            <div v-if="!isAdd">
                <div class="pb-10"></div>
                <hr>
                <div class="pb-10"></div>
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Remove Address</h4>
                    </div>
                </div>
                <div class="pb-10"></div>
                <div>
                    Please note that this action is irreversible.
                </div>
                <div class="pb-10"></div>
                <button @click="remove()" class="btn btn--secondary">Remove Address</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    removeUrl: {
        type: String,
        default: null
    },
    address: {
        type: Object,
        default: () => {}
    },
    isAdd: {
        type: Boolean,
        default: true
    },
})

const emits = defineEmits(['closed'])

const form = useForm({
    name: props.isAdd ? '' : props.address.name,
    street: props.isAdd ? '' : props.address.street,
    city: props.isAdd ? '' : props.address.city,
    country: props.isAdd ? '' : props.address.country,
    postcode: props.isAdd ? '' : props.address.postcode,
    is_primary: props.isAdd ? true : props.address.is_primary,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty && form.name !== '' && form.street !== '' && form.city !== '' && form.country !== '' && form.postcode !== ''
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

const remove = () => {
    if (props.removeUrl) {
        Inertia.post(props.removeUrl, {}, {
            preserveScroll: (page) => Object.keys(page.props.errors).length,
            onSuccess: () => emits('closed')
        })
    }
}

onUnmounted(() => {
    form.reset()
})
</script>
