<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <MemberAccountFilters  />
        </div>
    </div>
    <div class="page-filters-section mb-10 block lg:hidden">
        <div class="filter-bar">
            <InertiaLink href="/logout" method="post" as="button" class="btn btn--secondary">
                <i class="fas fa-sign-out-alt"></i> Log out
            </InertiaLink>
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center gap-10">
                <div class="col">
                    <div class="page-card-avatar avatar avatar--xl" v-bind:style="{ backgroundImage: 'url(' + memberUser.user?.avatar + ')' }"></div>
                </div>
                <div class="col">
                    <h3 class="page-title">{{ memberUser.user?.name }}</h3>
                </div>
                <div class="col ml-auto">
                    <button @click="showUserForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Details
                    </button>
                </div>
            </div>
            <div class="mt-10">
                <h5 class="sub-title">Overview</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{{ memberUser.user?.name }}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>{{ memberUser.user?.gender ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Relation</th>
                            <td>{{ memberUser.relation ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Nationality</th>
                            <td>{{ memberUser.nationality ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Primary</th>
                            <td v-if="memberUser.is_primary" class="text-primary">Primary</td>
                            <td v-else class="text-secondary">N/A</td>
                        </tr>
                        <tr>
                            <th>Emergency Contact</th>
                            <td v-if="memberUser.is_emergency_contact" class="text-primary">Emergency Contact</td>
                            <td v-else class="text-secondary">N/A</td>
                        </tr>
                        <tr>
                            <th>App Access</th>
                            <td>
                                <UserListAccessStatusIcon :type="memberUser.user?.is_archived ? 'archived' : (memberUser.user?.is_locked_out ? 'locked' : 'access')" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-10">
                <h5 class="sub-title">Contact Details</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Email</th>
                            <td>{{ memberUser.user?.email ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Mobile Telephone</th>
                            <td>{{ memberUser.mobile_telephone ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Home Telephone</th>
                            <td>{{ memberUser.home_telephone ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Work Telephone</th>
                            <td>{{ memberUser.work_telephone ?? '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <AddressesList
                :addresses="memberUser.addresses"
                @add="showAddAddressForm"
                @edit="editAddress"
                :show-add="true"
                :show-edit="true"
            />
        </div>
    </div>

    <div id="edit-user-form">
        <div v-if="showingUserForm" class="page-card mt-10">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Edit User</h4>
                    </div>
                    <div class="col">
                        <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="pb-10"></div>
                <form @submit.prevent="submitUserForm">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Title</label>
                                <div class="input-holder">
                                    <input v-model="userForm.title" :class="{'error' : userForm.errors?.title}" placeholder="Title"
                                           type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">First Name</label>
                                <div class="input-holder">
                                    <input v-model="userForm.first_name" :class="{'error' : userForm.errors?.first_name}" placeholder="First Name"
                                           type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Last Name</label>
                                <div class="input-holder">
                                    <input v-model="userForm.last_name" :class="{'error' : userForm.errors?.last_name}" placeholder="Last Name"
                                           type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Gender</label>
                                <div class="input-holder">
                                    <input v-model="userForm.gender" :class="{'error' : userForm.errors?.gender}" placeholder="Gender"
                                           type="text">
                                </div>
                            </div>
                            <hr class="mb-10 mt-10">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Email</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="far fa-envelope"></i>
                                    </div>
                                    <input v-model="userForm.email" :class="{'error' : userForm.errors?.email}" placeholder="Email"
                                           type="email">
                                </div>
                            </div>
                            <div v-if="userForm.has_app_access">
                                <hr class="mb-10 mt-10">
                                <div class="input-wrapper mb-5">
                                    <label class="input-label">New Password</label>
                                    <div class="input-holder input-holder--icon">
                                        <div class="input-holder-icon">
                                            <i class="fas fa-lock"></i>
                                        </div>
                                        <input v-model="userForm.password" :class="{'error' : userForm.errors?.password}"
                                               placeholder="Password" type="password">
                                    </div>
                                </div>
                                <div class="input-wrapper mb-10">
                                    <label class="input-label">New Password Confirmation</label>
                                    <div class="input-holder input-holder--icon">
                                        <div class="input-holder-icon">
                                            <i class="fas fa-lock"></i>
                                        </div>
                                        <input v-model="userForm.password_confirmation"
                                               :class="{'error' : userForm.errors?.password_confirmation}" placeholder="Password"
                                               type="password">
                                    </div>
                                </div>
                            </div>

                            <hr class="mb-10 mt-10">

                            <div class="input-wrapper mb-5">
                                <label class="input-label">Primary</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="userForm.is_primary">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Emergency Contact</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="userForm.is_emergency_contact">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Relation</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <input v-model="userForm.relation" :class="{'error' : userForm.errors?.relation}" placeholder="Relation" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Nationality</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <input v-model="userForm.nationality" :class="{'error' : userForm.errors?.nationality}" placeholder="Nationality" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Mobile Telephone</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <input v-model="userForm.mobile_telephone" :class="{'error' : userForm.errors?.mobile_telephone}" placeholder="Mobile Telephone" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Home Telephone</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <input v-model="userForm.home_telephone" :class="{'error' : userForm.errors?.home_telephone}" placeholder="Home Telephone" type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Work Telephone</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <input v-model="userForm.work_telephone" :class="{'error' : userForm.errors?.work_telephone}" placeholder="Work Telephone" type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="userForm.errors"/>
                    <div class="input-submit mt-10">
                        <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                               type="submit" value="Update">
                    </div>
                </form>
                <div>
                    <div class="pb-10"></div>
                    <hr>
                    <div class="pb-10"></div>
                    <div class="flex flex-wrap items-center justify-between gap-4">
                        <div class="col">
                            <h4>Manage Avatar</h4>
                        </div>
                    </div>
                    <div class="pb-10"></div>
                    <div>
                        You can change or remove the avatar.
                    </div>
                    <div class="pb-10"></div>
                    <FormErrorsGroup class="pb-10" :errors="avatarForm.errors"/>
                    <input id="new-avatar" type="file" hidden @input="avatarForm.avatar = $event.target.files[0]" @change="changeAvatar()" />
                    <button @click="selectAvatar()" class="btn btn--primary">Change Avatar</button>
                    <button @click="removeAvatar()" class="btn btn--secondary ml-10">Remove Avatar</button>
                </div>
            </div>
        </div>
    </div>
    <div id="add-address-form">
        <div v-if="showingAddAddressForm">
            <div class="mt-10"></div>
            <AddressForm
                :url="'/member/account/details/address/add'"
                :is-add="true"
                @closed="hideAddAddressForm"
            />
        </div>
    </div>
    <div id="edit-address-form">
        <div v-if="showingEditAddressForm">
            <div class="mt-10"></div>
            <AddressForm
                :url="'/member/account/details/address/' + addressToEdit.id + '/edit'"
                :remove-url="'/member/account/details/address/' + addressToEdit.id + '/remove'"
                :is-add="false"
                :address="addressToEdit"
                @closed="hideEditAddressForm"
            />
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import {Inertia} from "@inertiajs/inertia"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import SoftDelete from "@components/utilities/SoftDelete"
import useToggleable from "@composables/useToggleable"
import AddressForm from "@components/utilities/AddressForm"
import AddressesList from "@components/utilities/AddressesList"
import MemberAccountFilters from "@components/member/MemberAccountFilters"
import UserListAccessStatusIcon from "@components/utilities/UserListAccessStatusIcon"

const props = defineProps({
    memberUser: {
        type: Object,
        required: true
    }
})


const userForm = useForm({
    title: props.memberUser.user?.title,
    first_name: props.memberUser.user?.first_name,
    last_name: props.memberUser.user?.last_name,
    email: props.memberUser.user?.email,
    password: null,
    password_confirmation: null,
    has_app_access: !props.memberUser.user?.is_locked_out,
    is_emergency_contact: props.memberUser.is_emergency_contact,
    is_primary: props.memberUser.is_primary,
    relation: props.memberUser.relation,
    nationality: props.memberUser.nationality,
    mobile_telephone: props.memberUser.mobile_telephone,
    home_telephone: props.memberUser.home_telephone,
    work_telephone: props.memberUser.work_telephone,
})

const submitUserForm = () => {
    userForm.post('/member/account/details/update', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const avatarForm = useForm({
    avatar: null,
})
const selectAvatar = () => {
    document.getElementById("new-avatar").click()
}
const changeAvatar = () => {
    if (!avatarForm.avatar) {
        return
    }
    avatarForm.post('/member/account/details/avatar/replace', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}
const removeAvatar = () => {
    Inertia.post('/member/account/details/avatar/remove', {},{
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')
const { showing: showingTagsForm, show: showTagsForm, hide: hideTagsForm } = useToggleable('edit-tags-form')
const { showing: showingAddAddressForm, show: showAddAddressForm, hide: hideAddAddressForm } = useToggleable('add-address-form')
const { showing: showingEditAddressForm, show: showEditAddressForm, hide: hideEditAddressForm } = useToggleable('edit-address-form')
const addressToEdit = ref(null)
const editAddress = (address) => {
    hideEditAddressForm()
    addressToEdit.value = address
    setTimeout(() => showEditAddressForm(), 10)
}
</script>

<style lang="postcss">
body {
}
</style>
