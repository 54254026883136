<template>
    <CreativeBookingDetailsLayout :booking="booking">
        <template v-slot:action>
            <div v-if="booking.can_edit" class="col">
                <button @click="showBookingForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update Booking
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="profile-grid">
                <div class="col p-6">
                    <InertiaLink :href="booking.member?.can_see ? '/creative/member/' + booking.member?.id : '#'" :class="{'cursor-default' : !booking.member?.can_see}">
                        Member
                        <h6 class="font-semibold mt-1">{{ booking.member.name }} <i v-if="booking.member.is_vip" class="fas fa-check-circle primary"></i></h6>
                    </InertiaLink>
                </div>
                <div class="col p-6">
                    Duration
                    <h6 class="font-semibold mt-1">{{ booking.duration }}</h6>
                </div>
                <div class="col p-6">
                    Total
                    <h6 class="font-semibold mt-1">{{ booking.creative_total ?? '-' }}</h6>
                </div>
                <div class="col p-6">
                    Status
                    <h6 class="font-semibold mt-1">{{ upperFirst(booking.status) }}</h6>
                </div>
            </div>
            <div class="mt-10">
                <h5 class="sub-title">Overview</h5>
                <table class="transaction-table">
                    <tbody>
                    <tr>
                        <th>Start Timestamp</th>
                        <td>{{ booking.start_timestamp }}</td>
                    </tr>
                    <tr>
                        <th>End Timestamp</th>
                        <td>{{ booking.end_timestamp }}</td>
                    </tr>
                    <tr>
                        <th>Submit Timestamp</th>
                        <td>{{ booking.submitted_timestamp ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Location</th>
                        <td>{{ booking.location ?? '-' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <TagsList
                :tags="booking.tags"
                :show-add="false"
                :is-simple="true"
            />
        </template>
        <template v-slot:extra>
            <div id="edit-booking-form" class="">
                <BookingForm
                    v-if="showingBookingForm"
                    class="mt-10"
                    title="Update Booking"
                    :url="'/creative/bookings/' + booking.id + '/update'"
                    :active-member="booking.member"
                    :active-start="booking.start_timestamp_raw"
                    :active-end="booking.end_timestamp_raw"
                    :active-location="booking.location"
                    :show-close="true"
                    :is-edit="true"
                    @closed="hideBookingForm"
                />
            </div>
            <SubmitBooking
                v-if="!showingBookingForm && booking.can_submit"
                :url="'/creative/bookings/' + booking.id + '/submit'"
            />
            <ApplyBooking
                v-if="!showingBookingForm && booking.can_apply"
                :url="'/creative/bookings/' + booking.id + '/apply'"
            />
            <SoftDelete
                v-if="!showingBookingForm && booking.can_remove"
                :is-archived="false"
                :archive-url="'/creative/bookings/' + booking.id + '/destroy'"
                :restore-url="'/creative/bookings/' + booking.id + '/destroy'"
                type="Booking"
                action="Remove"
            >
                <p>Please note that this action is irreversible.</p>
            </SoftDelete>
        </template>
    </CreativeBookingDetailsLayout>
</template>

<script setup>
import CreativeBookingDetailsLayout from "@components/creative/CreativeBookingDetailsLayout";
import useToggleable from "@composables/useToggleable"
import BookingForm from "@components/utilities/BookingForm"
import {upperFirst} from "lodash"
import SubmitBooking from "@components/creative/SubmitBooking"
import ApplyBooking from "@components/creative/ApplyBooking"
import SoftDelete from "@components/utilities/SoftDelete"
import TagsList from "@components/utilities/TagsList"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingBookingForm, show: showBookingForm, hide: hideBookingForm } = useToggleable('edit-booking-form')


</script>
