<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/finance/transactions" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/finance/transactions')}">Transactions</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/invoices" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/finance/invoices')}">Invoices</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/remittances" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/finance/remittances')}">Remittance</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div class="page-filters-section">
        <div class="filter-bar">

            <div class="flex flex-wrap justify-between items-center">
                <div class="col">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="col">
                            <div class="search-form">
                                <input v-model="searchTerm" type="text">
                                <button :disabled="true">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="filter-select">
                                <select v-model="searchType">
                                    <option selected value="">Type</option>
                                    <option value="all">All</option>
                                    <option value="invoice">Invoices</option>
                                    <option value="remittance">Remittances</option>
                                </select>
                                <div class="filter-select-icon">
                                    <i class="fas fa-caret-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-10"></div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Transactions</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Total</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                    <tr v-for="(transaction, index) in items" :key="index">
                        <td>
                            <InertiaLink :href="transaction.targetable?.url">
                                <div class="avatar avatar--sm" :style="{ backgroundImage: 'url(' + transaction.targetable?.avatar + ')' }"></div>
                            </InertiaLink>
                        </td>
                        <td>
                            <InertiaLink :href="transaction.targetable?.url">
                                <div class="text-gray-600 font-normal">{{ transaction.targetable?.name }}</div>
                            </InertiaLink>
                        </td>
                        <td>
                            <div v-if="transaction.is_invoice" class="text-primary">Invoice</div>
                            <div v-else class="text-secondary">Remittance</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ transaction.total }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600 font-normal">{{ transaction.created_at_timestamp }}</div>
                            <span class="text-gray-400 font-normal">{{ transaction.created_at }}</span>
                        </td>
                        <td>
                        </td>
                        <td>
                            <div class="flex gap-2">
                                <div class="col">
                                    <InertiaLink :href="transaction.transactionable?.url" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-pen"></i>
                                        Details
                                    </InertiaLink>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePaginator from "@composables/usePaginator"
import {computed, ref, watch} from "vue"

const searchTerm = ref("")
const searchType = ref("")

const apiUrl = computed(() => {
    let url = '/admin/finance/transactions/api'

    let params = new URLSearchParams();
    if (searchTerm.value !== "") {
        params.append('terms', encodeURIComponent(searchTerm.value))
    }
    if (searchType.value === "invoice" || searchType.value === "remittance") {
        params.append('type', encodeURIComponent(searchType.value))
    }
    let suffix = params.toString()
    return url + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)
fetchItems(false)
</script>

<style lang="postcss">
</style>
