<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:action>
            <div class="col">
                <button @click="showBookingForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update Booking
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <h5 class="sub-title">Status</h5>
                <table class="transaction-table">
                    <tbody>
                    <tr>
                        <th>Overall Status</th>
                        <td>{{ booking.status.overall }}</td>
                    </tr>
                    <tr>
                        <th>Creative Payment Status</th>
                        <td>{{ booking.status.creative }}</td>
                    </tr>
                    <tr>
                        <th>Member Payment Status</th>
                        <td>{{ booking.status.member }}</td>
                    </tr>
                    <tr class="border-t-2">
                        <th>Column View Status</th>
                        <td>{{ booking.status.kanban }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="booking.logs?.length" class="mt-20">
                <h5 class="sub-title">Log History</h5>
                <ul class="pointer-list mt-5">
                    <li v-for="(log, index) in booking.logs" :key="index" class="whitespace-pre-wrap mb-2">
                        <span class=" text-sm">
                            {{ log.user_name }}, {{ log.created_at }}
                        </span>
                        <br>
                        <span class="text-secondary">{{ log.type }}</span>
                        <hr class="mb-2 mt-2">
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-booking-form" class="">
                <div v-if="showingBookingForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Update Booking</h4>
                            </div>
                            <div class="col">
                                <button @click="hideBookingForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-5"></div>
                        <div class="pb-5"></div>
                        <form @submit.prevent="submitForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Overall Status</label>
                                        <select v-model="form.overall_status" :class="{'error' : form.errors.overall_status}" :disabled="!booking.can_unapprove && !booking.can_approve && !booking.can_complete && !booking.can_cancel_with_charge && !booking.can_cancel_without_charge">
                                            <option :disabled="!booking.can_unapprove" value="pending">Pending</option>
                                            <option :disabled="!booking.can_approve" value="approved">Approved</option>
                                            <option :disabled="!booking.can_complete" value="completed">Completed</option>
                                            <option :disabled="!booking.can_cancel_with_charge" value="cancelled (non-charge)">Cancelled (non-charge)</option>
                                            <option :disabled="!booking.can_cancel_without_charge" value="cancelled (with-charge)">Cancelled (with-charge)</option>
                                        </select>
                                    </div>
                                    <div v-if="form.overall_status === booking.overall_status_raw" class="input-wrapper mb-5">
                                        <label class="input-label">Creative Payment Status</label>
                                        <select v-model="form.creative_payment_status" :class="{'error' : form.errors.creative_payment_status}" :disabled="!booking.can_update_creative_status">
                                            <option value="NA">NA</option>
                                            <option value="processing">Processing</option>
                                            <option value="awaiting">Awaiting</option>
                                            <option value="paid">Paid</option>
                                            <option value="SI">SI</option>
                                        </select>
                                    </div>
                                    <div v-if="form.overall_status === booking.overall_status_raw" class="input-wrapper mb-5">
                                        <label class="input-label">Member Payment Status</label>
                                        <select v-model="form.member_payment_status" :class="{'error' : form.errors.member_payment_status}" :disabled="!booking.can_update_member_status">
                                            <option value="NA">NA</option>
                                            <option value="processing">Processing</option>
                                            <option value="awaiting">Awaiting</option>
                                            <option value="settled">Settled</option>
                                            <option value="SI">SI</option>
                                        </select>
                                    </div>
                                    <hr class="mt-10 mb-10">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Column Status</label>
                                        <select v-model="form.kanban_status" :class="{'error' : form.errors.kanban_status}">
                                            <option value="requested">Booking request</option>
                                            <option value="in_progress">In progress</option>
                                            <option value="creatives_applied">Creatives applied</option>
                                            <option value="awaiting_confirmation">Awaiting Confirmation</option>
                                            <option value="hold">Hold</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="form.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300" type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import {computed, watch} from "vue";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingBookingForm, show: showBookingForm, hide: hideBookingForm } = useToggleable('edit-booking-form')

const form = useForm({
    overall_status: props.booking.overall_status_raw,
    creative_payment_status: props.booking.creative_payment_status_raw,
    member_payment_status: props.booking.member_payment_status_raw,
    kanban_status: props.booking.kanban_status_raw,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty
})

watch(() => form.overall_status, (newVal, oldVal) => {
    form.reset('creative_payment_status', 'member_payment_status')
})

const submitForm = () => {
    form.post('/admin/bookings/' + props.booking.id + '/update-status', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => {
            hideBookingForm()
            form.overall_status = props.booking.overall_status_raw
            form.creative_payment_status = props.booking.creative_payment_status_raw
            form.member_payment_status = props.booking.member_payment_status_raw
            form.kanban_status = props.booking.kanban_status_raw
        }
    })
}
</script>
