<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Add Member</h4>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="form.post('/admin/users/members/create')">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Name</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="far fa-user"></i>
                                </div>
                                <input v-model="form.name" :class="{'error' : form.errors?.name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Add Member">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {computed} from "vue"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const form = useForm({
    name: '',
})

const canSubmit = computed(() => {
    return !form.processing && form.name !== ''
})

</script>

<style lang="postcss">
body {
}
</style>
