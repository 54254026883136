<template>
    <AdminUsersMemberUsersDetailsLayout :member-user="memberUser">
        <template v-slot:default>
            <TagsList
                :tags="memberUser.tags"
                tag-url-prefix="/admin/system-settings/tags/tags/edit"
                @add="showTagsForm"
            />
        </template>
        <template v-slot:extra>
            <div id="edit-tags-form">
                <TagsEdit
                    v-if="showingTagsForm"
                    :url="'/admin/users/members/' + memberUser.member.id + '/member-users/' + memberUser.id + '/edit-tags'"
                    :tags="memberUser.tags"
                    @closed="hideTagsForm"
                />
            </div>
        </template>
    </AdminUsersMemberUsersDetailsLayout>
</template>

<script setup>
import AdminUsersMemberUsersDetailsLayout from "@components/admin/AdminUsersMemberUsersDetailsLayout"
import useToggleable from "@composables/useToggleable"
import TagsList from "@components/utilities/TagsList"
import TagsEdit from "@components/utilities/TagsEdit"

const props = defineProps({
    memberUser: {
        type: Object,
        required: true
    }
})

const { showing: showingTagsForm, show: showTagsForm, hide: hideTagsForm } = useToggleable('edit-tags-form')

</script>
