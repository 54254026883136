<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <MemberProfileFilters  />
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h3 class="page-title">{{ member.name }}</h3>
                </div>
                <div class="col">
                    <button @click="showMemberForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Details
                    </button>
                </div>
            </div>
            <div class="profile-grid">
                <div class="col p-6">
                    Children
                    <h6 class="font-semibold mt-1">{{ member.statistics?.children_total }}</h6>
                </div>
                <div class="col p-6">
                    Users
                    <h6 class="font-semibold mt-1">{{ member.statistics?.users_count }}</h6>
                </div>
                <div class="col p-6">
                    Bookings
                    <h6 class="font-semibold mt-1">{{ member.statistics?.bookings_total }}</h6>
                </div>
                <div class="col p-6">
                    Member Since
                    <h6 class="font-semibold mt-1">{{ member.created_at_short }}</h6>
                </div>
            </div>
            <div class="mt-10">
                <h5 class="sub-title">Overview</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Payment Plan</th>
                            <td>{{ startCase(toLower(member.payment_plan)) }}</td>
                        </tr>
                        <tr>
                            <th>Payment Method</th>
                            <td>{{ startCase(toLower(member.payment_method)) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div id="edit-member-form">
        <div v-if="showingMemberForm" class="page-card mt-10">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Edit Details</h4>
                    </div>
                    <div class="col">
                        <button @click="hideMemberForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="pb-10"></div>
                <form @submit.prevent="submitMemberForm">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Name</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="far fa-user"></i>
                                    </div>
                                    <input v-model="memberForm.name" :class="{'error' : memberForm.errors?.name}" placeholder="Name"
                                           type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Payment Plan</label>
                                <select v-model="memberForm.payment_plan" :class="{'error' : memberForm.errors.payment_plan}">
                                    <option value="weekly">Weekly</option>
                                    <option value="fortnightly">Fortnightly</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Payment Method</label>
                                <select v-model="memberForm.payment_method" :class="{'error' : memberForm.errors.payment_method}">
                                    <option value="direct debit">Direct Debit</option>
                                    <option value="bank transfers">Bank Transfers</option>
                                    <option value="card">Card</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="memberForm.errors"/>
                    <div class="input-submit mt-10">
                        <input :disabled="!memberForm.isDirty || memberForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                               type="submit" value="Update">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import useToggleable from "@composables/useToggleable";
import MemberProfileFilters from "@components/member/MemberProfileFilters"
import {toLower, startCase } from "lodash";

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const memberForm = useForm({
    name: props.member.name,
    payment_plan: props.member.payment_plan,
    payment_method: props.member.payment_method
})

const submitMemberForm = () => {
    memberForm.post('/member/profile/update', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideMemberForm(),
    })
}

const { showing: showingMemberForm, show: showMemberForm, hide: hideMemberForm } = useToggleable('edit-member-form')

</script>

<style lang="postcss">
body {
}
</style>
