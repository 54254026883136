<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <UsersList
                :users="member.users"
                title="Family"
                :url-prefix="'/admin/users/members/' + member.id + '/member-users/'"
                @add="showAddUserForm"
                :show-add="!member.is_archived"
            />
        </template>
        <template v-slot:extra>
            <div id="add-user-form">
                <div v-if="showingAddUserForm">
                    <div class="mt-10"></div>
                    <UserAdd
                        :url="'/admin/users/members/' + member.id + '/member-users/add'"
                        :member-id="member.id"
                        @closed="hideAddUserForm"
                        title="Add Member User"
                    />
                </div>
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import UsersList from "@components/utilities/UsersList"
import UserAdd from "@components/utilities/UserAdd"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})
const { showing: showingAddUserForm, show: showAddUserForm, hide: hideAddUserForm } = useToggleable('add-user-form')

</script>
