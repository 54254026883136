<template>
    <div class="section">
        <div class="row services-row">
            <div class="col-lg-12">
                <div>
                    <iframe src="https://www.youtube.com/embed/0kHB1KwBfkE" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="centered-div" height="450"
                            style="display: block"
                            width="800"></iframe>
                    <div class="spacer20"></div>
                </div>
                <hr class="sep">
                <div v-for="(story, index) in stories" :key="index">
                    <div class="row align-items-center">
                        <div class="col-sm-4">
                            <div :style="'background-image: url('+story.file+');'"
                                 class="about-image-div"></div>
                        </div>
                        <div class="col-sm-6 offset-sm-1">
                            <h2>{{ story.title }}</h2>
                            <p class="message">{{ story.description }}</p>
                        </div>
                    </div>
                    <hr class="sep">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const stories = [
    {
        file: "/img/web/apply_process_1.jpg",
        title: "Born in 2012",
        description: "Bea & Co was born in 2012 after our founder, Amy, began to see through her regular work with child actors that a highly creative environment was hugely valuable in their well-rounded development. The company grew steadily and began to recruit like-minded close friends to carry the core values forward. We were a small team with a shared vision to make creative-led childcare for dual-career nannies more accessible to busy parents. ",
    },
    {
        file: "/img/web/apply_process_2.jpg",
        title: "Growing up",
        description: "After spending six months testing our concept of enriching regular child-sitting with play, imagination and creativity, we were noticing happy parents and happy children. By bringing in our influences in theatre, music and the creative industries, we were having a great affect! We began to scale to meet demand and established a larger and more diverse team. They were from this point to be known as our ‘creatives’. In 2013 we introduced our hotel & homestay partnerships and began to expand our premium & VIP services to international families visiting London. And in 2014 we launched our first partnership to provide licensed chaperones for theatre, film & TV— a department that has taken our team across the globe supporting children, their parents and production companies on set and on stage. ",
    },
    {
        file: "/img/web/apply_process_3.jpg",
        title: "Today",
        description: "Today our team of sitters, nannies and chaperones is stronger and more diverse than ever. Just as it was at the start, only those with a proven excellence make the grade— but now the grade is even tougher! After a decade providing thousands of families from across the globe with the very best in creative childcare, we feel like we know a thing or two, and have refined our service as we continue our journey of growth. We are proud of our support team, some of whom have been with us for nearly half of the company’s life, and we’ve taken credit for marriages (and multiple children!) from within the Bea & Co family. Many of our team have accepted Godparent responsibilities for their young charges, and of course we still shed a tear when a once toddler, now teen, perhaps doesn’t need us quite as much as they used to…! Now instead of crocodile hunts and creative homework, we pitch them the magic of Bea & Co for their future children 20-some years in advance!",
    },
]
</script>
<style lang="scss" scoped>
hr.sep {
    opacity: 0.3;
    margin: 2em 0;
}

.about-image-div {
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 200px;
}

@media(max-width: 991px) {
    iframe {
        max-width: 100%;
    }
}
</style>
