<template>
    <div class="mt-10">
        <div class="flex flex-wrap items-center justify-between gap-4">
            <div class="col">
                <h5 class="sub-title">{{ title }}</h5>
            </div>
            <div v-if="showAdd" class="col">
                <button @click="$emit('add')" class="btn btn--icon"><i class="fas" :class="'fa-' + addIcon"></i></button>
            </div>
        </div>
        <div class="flex flex-wrap gap-4 text-center mt-5">
            <div v-for="(user, index) in users" :key="index" class="col">
                <div class="inline-block text-center" @click="goToUser(user.id)">
                    <div class="avatar avatar--xl mx-auto shadow-xl" :class="{'cursor-pointer': user.id, 'border-4': user.is_primary || user.is_archived, 'border-primary': user.is_primary, 'border-danger': user.is_archived}" v-bind:style="{ backgroundImage: 'url(' + user[avatarAttr] + ')' }"></div>
                    <br>
                    <span>{{ user[nameAttr] }} <i v-if="user[starAttr] || user[vipAttr]" class="fas fa-star primary"></i> <i v-if="user[flagAttr]" class="fas fa-exclamation-circle secondary"></i> </span>
                    <!--
                    <h6 v-if="user.is_primary" class="text-primary font-extrabold text-sm">PRIMARY</h6>
                    <h6 v-if="user.is_archived" class="text-secondary font-extrabold text-sm">ARCHIVED</h6>
                    -->
                </div>
                <div v-if="showRemove">
                    <button @click="$emit('remove', user)">
                        <h6 class="text-secondary font-extrabold">REMOVE</h6>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    users: {
        type: Array,
        required: true
    },
    title: {
        type: String,
        default: 'Users'
    },
    showAdd: {
        type: Boolean,
        default: true
    },
    addIcon: {
        type: String,
        default: 'plus'
    },
    urlPrefix: {
        type: String,
        required: true
    },
    avatarAttr: {
        type: String,
        default: 'avatar'
    },
    nameAttr: {
        type: String,
        default: 'name'
    },
    starAttr: {
        type: String,
        default: 'is_star'
    },
    flagAttr: {
        type: String,
        default: 'is_flagged'
    },
    vipAttr: {
        type: String,
        default: 'is_vip'
    },
    showRemove: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['add', 'remove'])

const goToUser = (userId) => {
    if (userId) {
        Inertia.visit(props.urlPrefix + userId)
    }
}
</script>

