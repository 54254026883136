<template>
    <MemberAccountFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Statement</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Date</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    <tr v-for="(remittance, index) in items" :key="index">
                        <td>
                            <div class="text-gray-600 font-normal">{{ remittance.completed_at_timestamp }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ remittance.breakdown?.total }}</div>
                            <div class="text-gray-600 font-normal">{{ '(' + remittance.breakdown?.bookings_count + ' Booking' + (remittance.breakdown?.bookings_count > 1 ? 's' : '') + ')' }}</div>
                        </td>
                        <td>
                            <div>{{ remittance.status }}</div>
                        </td>
                        <td>
                            <div class="flex gap-2">
                                <div class="col">
                                    <InertiaLink :href="'/member/account/statement/' + remittance.id" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-search"></i>
                                        Details
                                    </InertiaLink>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePaginator from "@composables/usePaginator"
import {computed} from "vue";
import MemberAccountFilters from "@components/member/MemberAccountFilters"

const apiUrl = computed(() => {
    return '/member/account/statement/api'
})
const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)
fetchItems(false)
</script>

<style lang="postcss">
</style>
