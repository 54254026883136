<template>
    <div class="page-filters-section mb-10">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/analytics/overview" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/analytics/overview'}">Overview</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/analytics/clients-and-creatives" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/analytics/clients-and-creatives'}">Clients & Creatives</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/analytics/hours-and-bookings" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/analytics/hours-and-bookings'}">Hours & Bookings</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>
