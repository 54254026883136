<template>
    <div class="home">
        <div class="container">
            <div class="spacer50"></div>
            <div class="message mt-10" style="font-size: 22px;">
                If you find yourself on this page you may be considering working with us. That’s great! We looking forward to meeting you and hearing your story.
            </div>
            <div class="message mt-10">
                For over a decade we have been dedicated to delivering the very best childcare solutions for families who want something different from the norm. We believe in creativity— in the power of limitless imagination, play and good communication. We live for the chance to make happy memories for generations of children to come.
            </div>
            <ApplyProcessComponent />
        </div>
        <div class="spacer50"></div>
<!--        <GalleryComponent />-->
        <div class="spacer50"></div>
    </div>
</template>

<script setup>
import GalleryComponent from "@components/web/GalleryComponent";
import ApplyProcessComponent from "@components/web/ApplyProcessComponent";
</script>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";

export default {
    layout: [BaseLayout, WebLayout]
}
</script>
