<template>
    <div class="home">
        <div class="container">
            <div class="spacer50"></div>
            <div class="spacer10"></div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="spacer50"></div>
                    <h2 class="message" style="color: #A1EDE3; font-weight: 600;">CONTACT US DIRECT</h2>
                    <div class="spacer20"></div>
                    <a class="message" href="tel:+44(0)2034881184" style="font-size: 16px;"><i
                        class="fas fa-phone-square"
                        style="margin-right: 10px; color: #A1EDE3;"></i>+44(0)
                        20 3488 1184</a>
                    <div class="spacer10"></div>
                    <a class="message" href="mailto:hello@beaandco.com" style="font-size: 16px;"><i
                        class="fas fa-envelope"
                        style="margin-right: 10px; color: #A1EDE3;"></i>hello@beaandco.com</a>
                    <div class="spacer10"></div>
                    <a class="message" href="/apply" style="font-size: 16px;"><i class="fas fa-briefcase"
                                                                                 style="margin-right: 10px; color: #A1EDE3;"></i>
                        Apply to work with us</a>
                    <div class="spacer50"></div>
                    <div class="spacer10"></div>
                    <div class="text-centers">
                        <div
                            style="background-image: url('/img/web/contact_box_1.jpg');background-size: cover; background-position: center center; height: 100%; min-height: 250px; max-width: 500px;"></div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="spacer50"></div>
                    <h2 class="">Say Hello!</h2>
                    <div class="spacer20"></div>
                    <div class="input-wrapper">
                        <label for="name">Name</label>
                        <input id="name" v-model="form.name" type="text">
                        <div v-if="$page.props.errors.name" class="alert-required mt-2">
                            {{ $page.props.errors.name }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label for="phone">Phone Number</label>
                        <input id="phone" v-model="form.phone" type="text">
                        <div v-if="$page.props.errors.phone" class="alert-required mt-2">
                            {{ $page.props.errors.phone }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label for="email">Email</label>
                        <input id="email" v-model="form.email" type="text">
                        <div v-if="$page.props.errors.email" class="alert-required mt-2">
                            {{ $page.props.errors.email }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label for="location">Location</label>
                        <input id="location" v-model="form.location" type="text">
                        <div v-if="$page.props.errors.location" class="alert-required mt-2">
                            {{ $page.props.errors.location }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label for="referred">Have you been referred by anyone?</label>
                        <input id="referred" v-model="form.referral" type="text">
                        <div v-if="$page.props.errors.referral" class="alert-required mt-2">
                            {{ $page.props.errors.referral }}
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label for="message">Message</label>
                        <textarea id="message" v-model="form.message" rows="3" style="font-size: 1rem;"></textarea>
                        <div v-if="$page.props.errors.message" class="alert-required mt-2">
                            {{ $page.props.errors.message }}
                        </div>
                    </div>
                    <div class="spacer50"></div>
                    <div class="button-wrapper">
                        <div class="btn btn--outline btn--rounded" style="min-width: 180px; padding: 10px 15px; display: inline-block;" type="submit" @click="submitForm">Send</div>
                    </div>
                </div>
            </div>
            <div class="spacer100"></div>
        </div>
    </div>
</template>


<script setup>
import {computed, onMounted, ref} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";

const props = defineProps({
    banner_header_contact: {
        type: Object,
        default() {
            return null
        }
    },
    intro_text_contact: {
        type: Object,
        default() {
            return null
        }
    },
})

const form = useForm({
    name: '',
    phone: '',
    referral: '',
    location: '',
    email: '',
    message: '',
})

const submitForm = () => {
    form.post("/contact", {
        preserveState: (page) => Object.keys(page.props.errors).length,
        preserveScroll: (page) => Object.keys(page.props.errors).length,
    })
}

</script>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";

export default {
    layout: [BaseLayout, WebLayout],
    name: "Contact",
}
</script>

<style scoped>
.alert-required {
    color: red;
}

.alert-success {
    color: green;
    border: 2px solid greenyellow;
    background-color: floralwhite;
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    white-space: pre-wrap;
}
</style>
