<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Tier {{ tier.tier + ': ' + tier.label }}</h4>
                    Impacts: <span class="font-semibold">{{ tier.members }} Members</span>
                </div>
                <div class="col">
                    Last Update: <span class="font-semibold">{{ tier.updated_at }}</span>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="form.post('/admin/system-settings/rates/' + tier.id)">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Creative Rate: 1-2 Children (First 3 Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.creative_first_3_hours_upto_two_children" :class="{'error' : form.errors.creative_first_3_hours_upto_two_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Creative Rate: 3 Children (First 3 Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.creative_first_3_hours_upto_three_children" :class="{'error' : form.errors.creative_first_3_hours_upto_three_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Creative Rate: 4+ Children (First 3 Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.creative_first_3_hours_over_four_children" :class="{'error' : form.errors.creative_first_3_hours_over_four_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Creative Rate: 1-2 Children (Additional Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.creative_additional_hour_upto_two_children" :class="{'error' : form.errors.creative_additional_hour_upto_two_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Creative Rate: 3 Children (Additional Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.creative_additional_hour_upto_three_children" :class="{'error' : form.errors.creative_additional_hour_upto_three_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Creative Rate: 4+ Children (Additional Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.creative_additional_hour_over_four_children" :class="{'error' : form.errors.creative_additional_hour_over_four_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <hr class="mb-10">
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Member Rate: 1-2 Children (First 3 Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.member_first_3_hours_upto_two_children" :class="{'error' : form.errors.member_first_3_hours_upto_two_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Member Rate: 3 Children (First 3 Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.member_first_3_hours_upto_three_children" :class="{'error' : form.errors.member_first_3_hours_upto_three_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Member Rate: 4+ Children (First 3 Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.member_first_3_hours_over_four_children" :class="{'error' : form.errors.member_first_3_hours_over_four_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Member Rate: 1-2 Children (Additional Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.member_additional_hour_upto_two_children" :class="{'error' : form.errors.member_additional_hour_upto_two_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Member Rate: 3 Children (Additional Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.member_additional_hour_upto_three_children" :class="{'error' : form.errors.member_additional_hour_upto_three_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Member Rate: 4+ Children (Additional Hours)</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.member_additional_hour_over_four_children" :class="{'error' : form.errors.member_additional_hour_over_four_children}"
                                       min="0" placeholder="10.99" type="text">
                            </div>
                        </div>
<!--                        <hr class="mb-10">-->
<!--                        <div class="input-wrapper mb-10">-->
<!--                            <label class="input-label">Special Rate (multiplier)</label>-->
<!--                            <div class="input-holder input-holder&#45;&#45;icon">-->
<!--                                <div class="input-holder-icon">-->
<!--                                    <i class="fas fa-pound-sign"></i>-->
<!--                                </div>-->
<!--                                <input v-model="form.special_rate_multiplier"-->
<!--                                       :class="{'error' : form.errors.special_rate_multiplier}" min="0"-->
<!--                                       placeholder="2.00" type="text">-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {computed} from "vue"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    tier: {
        type: Object,
        required: true
    }
})

const form = useForm({
    creative_first_3_hours_upto_two_children: props.tier.creative_first_3_hours_upto_two_children,
    creative_first_3_hours_upto_three_children: props.tier.creative_first_3_hours_upto_three_children,
    creative_first_3_hours_over_four_children: props.tier.creative_first_3_hours_over_four_children,
    creative_additional_hour_upto_two_children: props.tier.creative_additional_hour_upto_two_children,
    creative_additional_hour_upto_three_children: props.tier.creative_additional_hour_upto_three_children,
    creative_additional_hour_over_four_children: props.tier.creative_additional_hour_over_four_children,
    member_first_3_hours_upto_two_children: props.tier.member_first_3_hours_upto_two_children,
    member_first_3_hours_upto_three_children: props.tier.member_first_3_hours_upto_three_children,
    member_first_3_hours_over_four_children: props.tier.member_first_3_hours_over_four_children,
    member_additional_hour_upto_two_children: props.tier.member_additional_hour_upto_two_children,
    member_additional_hour_upto_three_children: props.tier.member_additional_hour_upto_three_children,
    member_additional_hour_over_four_children: props.tier.member_additional_hour_over_four_children,
})

const canSubmit = computed(() => {
    return !form.processing && form.first_3_hours !== ''
})


</script>

<style lang="postcss">
body {
}
</style>
