<template>
    <div>
        <h5 class="sub-title">Client Dependency (Hours / Members)</h5>
        <PieChart v-bind="lineChartProps" :options="options" />
    </div>
</template>

<script setup>
import {Chart, registerables} from 'chart.js'
import {PieChart, useLineChart} from 'vue-chart-3'
import {computed, defineComponent, ref} from 'vue'

Chart.register(...registerables)

const props = defineProps({
    dataset: {
        type: Object,
        required: true
    }
})

const options = ref({
    responsive: true,
    plugins: {
        legend: {
            position: 'left'
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context) {
                    return context.label + ' hours: ' + context.raw + ' Members'
                }
            }
        },
    },
})

const chartData = computed(() => ({
    labels: ['1-5', '6-10', '11-20', '21-30', '31-40', '41-50', '51-75', '75+'],
    datasets: [
        {
            label: ['1-5', '6-10', '11-20', '21-30', '31-40', '41-50', '51-75', '75+'],
            data: [props.dataset['1-5'], props.dataset['6-10'], props.dataset['11-20'], props.dataset['21-30'], props.dataset['31-40'], props.dataset['41-50'], props.dataset['51-75'], props.dataset['75+']],
            backgroundColor: ['#FE3562', '#9CABC7', '#8ff0E8', '#FA8072', '#14121F', '#add666', '#66A3A3', '#231825' ],
            tension: 0.4,
            responsive: true,
            legend:false
        }
    ]
}))

const {lineChartProps, lineChartRef} = useLineChart({
    chartData,
})
</script>

<style>
</style>
