<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Submit Booking</h4>
                </div>
            </div>
            <div class="pb-10"></div>
            <div>
                <p>Please note that this action is irreversible.</p>
            </div>
            <div class="input-submit mt-10">
                <button @click="submit()" class="btn btn--primary">Submit Booking</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    },
})

const submit = () => {
    Inertia.post(props.url, null, {
        onBefore: () => confirm('Are you sure you want to submit the Booking?'),
    })
}
</script>
