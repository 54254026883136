<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Booking Blocks</h5>
                    </div>
                    <div v-if="!member.is_archived" class="col">
                        <button @click="showAddBlockForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div v-if="member.blocks.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Name</th>
                            <th>Date Added</th>
                            <th>Bookings Count</th>
                            <th></th>
                        </tr>
                        <tr v-for="(block, index) in member.blocks" :key="index">
                            <td>
                                <component :is="block.bookings_count ? 'InertiaLink' : 'div'" :href="'/admin/bookings/kanban?block_id=' + block.id" class="text-gray-600 font-normal" :class="{'text-primary': block.bookings_count}">
                                    {{ block.name }}
                                </component>
                            </td>
                            <td><div class="text-gray-600 font-normal">{{ block.created_at_short }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ block.bookings_count }}</div></td>
                            <td v-if="!member.is_archived">
                                <button @click="removeBlock(block.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="add-block-form">
                <div v-if="showingAddBlockForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Add Block</h4>
                            </div>
                            <div class="col">
                                <button @click="hideAddBlockForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitAddBlockForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Name</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="far fa-user"></i>
                                            </div>
                                            <input v-model="blockForm.name" :class="{'error' : blockForm.errors?.name}" placeholder="Name"
                                                   type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="blockForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!blockForm.name || !blockForm.isDirty || blockForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Add Block">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import {Inertia} from "@inertiajs/inertia";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const { showing: showingAddBlockForm, show: showAddBlockForm, hide: hideAddBlockForm } = useToggleable('add-block-form')

const blockForm = useForm({
    name: null,
})
const submitAddBlockForm = () => {
    blockForm.post('/admin/users/members/' + props.member.id + '/blocks/add', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => {
            hideAddBlockForm()
            blockForm.reset()
        },
    })
}
const removeBlock = (blockId) => {
    Inertia.post('/admin/users/members/' + props.member.id + '/blocks/' + blockId + '/remove', {}, {
        onBefore: () => confirm('Are you sure you want to remove the Booking Block?'),
        preserveScroll: true
    })
}
</script>
