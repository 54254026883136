<template>
    <div class="home">
        <div class="container">
            <div class="spacer50"></div>
            <h2>Flexible creative babysitters and nannies</h2>
            <div class="message" style="font-size: 22px;">
                We curate and manage unrivalled childcare solutions for families who want something different from the norm. Whether part time & ad-hoc nannies, or wrap-around care, we build teams specially focussed on your family’s interests and passions.
            </div>
            <div class="message mt-10">
                We believe in creativity— in the power of limitless imagination, play and good communication. We live for the chance to make happy memories for generations of children to come.
            </div>
            <ServicesProcessComponent />
        </div>
        <div class="spacer50"></div>
        <TestimonialsComponent :show-gallery="true" title="Happy People" />
        <div class="spacer100"></div>
        <PartnersComponent />
        <div class="container">
            <div class="spacer100"></div>
            <OurFamilyComponent />
            <hr class="sep mt-12">
        </div>
        <div id="our-team" class="spacer50"></div>
        <div class="container">
            <TeamsComponent :team="team" text="Our team of over a hundred creative sitters & nannies all have backgrounds in teaching, theatre, art & design, nursery care, film & TV, music and dance. They are great storytellers, skilled communicators, and quick thinkers. They are experts at finding adventure round every corner!" />
        </div>
    </div>
</template>

<script setup>
import TeamsComponent from "@components/web/TeamsComponent";
import OurFamilyComponent from "@components/web/OurFamilyComponent";
import TestimonialsComponent from "@components/web/TestimonialsComponent";
import ServicesProcessComponent from "@components/web/ServicesProcessComponent";
import PartnersComponent from "@components/web/PartnersComponent";


const team = [
    {
        id: 1,
        name: "Laura",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_1.jpg",
        photo_two: "/img/web/home_team_1.jpg",
    },
    {
        id: 2,
        name: "Nathan",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_2.jpg",
        photo_two: "/img/web/home_team_2.jpg",
    },
    {
        id: 3,
        name: "Molly",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_3.jpg",
        photo_two: "/img/web/home_team_3.jpg",
    },
    {
        id: 4,
        name: "Boni",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_4.jpg",
        photo_two: "/img/web/home_team_4.jpg",
    },
    {
        id: 5,
        name: "Josh",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_5.jpg",
        photo_two: "/img/web/home_team_5.jpg",
    },
    {
        id: 6,
        name: "Imogen",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_6.jpg",
        photo_two: "/img/web/home_team_6.jpg",
    },
    {
        id: 7,
        name: "Sara",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_7.jpg",
        photo_two: "/img/web/home_team_7.jpg",
    },
    {
        id: 8,
        name: "Madelaine",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_8.jpg",
        photo_two: "/img/web/home_team_8.jpg",
    },
    {
        id: 9,
        name: "Hope",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_9.jpg",
        photo_two: "/img/web/home_team_9.jpg",
    },
    {
        id: 10,
        name: "Eubha",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_10.jpg",
        photo_two: "/img/web/home_team_10.jpg",
    },
    {
        id: 11,
        name: "Conor",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_11.jpg",
        photo_two: "/img/web/home_team_11.jpg",
    },
    {
        id: 12,
        name: "Ella",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_12.jpg",
        photo_two: "/img/web/home_team_12.jpg",
    },
    {
        id: 13,
        name: "Grace",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_13.jpg",
        photo_two: "/img/web/home_team_13.jpg",
    },
    {
        id: 14,
        name: "Caitlin",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_14.jpg",
        photo_two: "/img/web/home_team_14.jpg",
    },
    {
        id: 15,
        name: "Nicole",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_15.jpg",
        photo_two: "/img/web/home_team_15.jpg",
    },
    {
        id: 16,
        name: "Eliza",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_16.jpg",
        photo_two: "/img/web/home_team_16.jpg",
    },
    {
        id: 17,
        name: "Jake",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_17.jpg",
        photo_two: "/img/web/home_team_17.jpg",
    },
    {
        id: 18,
        name: "Charlotte",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_18.jpg",
        photo_two: "/img/web/home_team_18.jpg",
    },
    {
        id: 19,
        name: "Hannah",
        title: "Actor Musician",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_19.jpg",
        photo_two: "/img/web/home_team_19.jpg",
    },
    {
        id: 20,
        name: "Will",
        title: "Actor",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a odio sit amet diam pulvinar ullamcorper. Fusce a turpis tincidunt, dictum odio vel, pulvinar urna.",
        has_photo: true,
        photo: "/img/web/home_team_20.jpg",
        photo_two: "/img/web/home_team_20.jpg",
    },
]
</script>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";

export default {
    layout: [BaseLayout, WebLayout]
}
</script>

<style scoped>

</style>
