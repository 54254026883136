<template>
    <div class="">
        <div class="space100"></div>
        <div class="col-lg-3 centered-div">
            <h2 class="text-center mb-12">Next Steps</h2>
        </div>
        <div class="px-6">
            <div class="space30 d-lg-none d-xl-none"></div>
            <div class="centered-div">
                <div class="row gutter-50 nb-process-wrapper line text-center justify-content-center">
                    <div class="col-md-2 nb-col-md-2" v-for="(item, index) in processes" :key="'Process_'+index" @click="toggleActive(item)" @mouseover="toggleActive(item)" style="cursor: pointer;">
                        <span class="icon icon-bg bg-default mb-20" :class="{active: active.title === item.title}"><span class="icon-img" style="font-size: 24px;"><i :class="item.icon"></i></span></span>
                        <div class="font-bold" style="font-size: 18px;">{{item.title}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space50"></div>
        <div>
            <div v-if="active">
                <div class="text-center container">
                  <div class="h-80 sm:h-56 md:h-40 overflow-auto">
                    <div class="message">{{ active.description }}</div>
                  </div>
                </div>
                <div v-if="active.bannerImages?.length" class="mt-12">
                    <slider-banner-component :images="active.bannerImages" :logo="false" title="" />
                </div>
                <div class="button-wrapper text-center mt-12">
                    <a class="btn btn--outline btn--rounded nb-pop-up-button" href="mailto:london@beaandco.com?subject=New%20Home%20Childcare%20Enquiry">Join</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import SliderBannerComponent from "@components/web/SliderBannerComponent";

const processes = [
    {
        title: "Get in touch",
        description: "It all starts with a hello! We want to hear about your family and what your perfect solution looks like. Once you’ve reached out to us by phone or via the form below, one of our team will be in touch.",
        icon: "fas fa-calendar",
        bannerImages: ['home_childcare_next_1.jpg', 'home_childcare_next_2.jpg', 'home_childcare_next_3.jpg', 'home_childcare_next_4.jpg']
    },
    {
        title: "Your family",
        description: "We like to fully understand your requirements and the nuances of your family so that we can start to make suitable recommendations. This is based on your family’s location, children’s ages, family/ child hobbies and interests, plus a host of other factors that we use to form the basis of your childcare team.",
        icon: "fas fa-calendar",
        bannerImages: [
            'home_childcare_next_5.jpg',
            'home_childcare_next_6.jpg',
            'home_childcare_next_7.jpg',
            'home_childcare_next_8.jpg',
            'home_childcare_next_9.jpg',
            'home_childcare_next_10.jpg',
            'home_childcare_next_11.jpg',
        ]
    },
    {
        title: "Recommendations",
        description: "We will walk you through the recommendations made, and be on hand to answer any questions about each sitter or nanny. We can guide you on any number of factors including likely travel considerations, scheduling, sitter experience and skill-set, and how that all may affect your requirements.",
        icon: "fas fa-calendar",
        bannerImages: [
            'home_childcare_next_12.jpg',
            'home_childcare_next_13.jpg',
            'home_childcare_next_14.jpg',
            'home_childcare_next_15.jpg',
            'home_childcare_next_16.jpg',
            'home_childcare_next_17.jpg',
            'home_childcare_next_18.jpg',
            'home_childcare_next_19.jpg',
        ]
    },
    {
        title: "Your team",
        description: "You decide who you would like to meet or invite to a trial, and we continue to manage the growth and development of your childcare team as your children get older or your lifestyle changes. If your circumstances or preferences change, we remain on hand to recalibrate on your behalf. ",
        icon: "fas fa-calendar",
        bannerImages: ['home_childcare_next_20.jpg', 'home_childcare_next_21.jpg', 'home_childcare_next_22.jpg']
    },
    {
        title: "Management",
        description: "We take great pride in our personable, family-run support. We continue to manage your family’s needs for the duration of your membership with full office support 5 days a week and a reduced service at weekends. We will always have someone on hand to help, and always have a friendly ear at the end of the phone when needed.",
        icon: "fas fa-calendar",
        bannerImages: ['home_childcare_next_23.jpg', 'home_childcare_next_24.jpg', 'home_childcare_next_25.jpg', 'home_childcare_next_26.jpg']
    },
]
const active = ref(processes[0])
const toggleActive = (process) => active.value = process

</script>

<style scoped>
  span.active {
    background-color: #F4F4F4 !important;
    color: #a1ede3 !important;
  }
  .nb-pop-up-button {
      @apply shadow-xl text-xl;
      width: 275px
  }


  .nb-col-md-2 {
      display: flex;         /* New */
      flex-direction: column; /* New */
      align-items: center;    /* New */
  }

  @media (max-width: 1000px) {
      .nb-col-md-2 {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center !important;
      }
      .nb-col-md-2:not(:last-child) {
          margin-bottom: 25px;  /* Adjust this value as per your need */
      }
  }

  @media(min-width: 1001px) {
      .nb-process-wrapper.line [class*="col-"] {
          position: relative;
      }
      .nb-process-wrapper.line [class*="col-"]:before,
      .nb-process-wrapper.line [class*="col-"]:after {
          width: calc(50% - 66px);
          position: absolute;
          content: "";
          height: 1px;
          background: rgba(21, 21, 21, 0.15);
          top: 33px;
          z-index: 1;
          left: 0;
          margin-left: 0;
      }
      .nb-process-wrapper.line [class*="col-"]:after {
          right: 0;
          left: auto;
          margin-right: 0;
          margin-left: 0;
      }
      .nb-process-wrapper.line [class*="col-"]:first-child:before,
      .nb-process-wrapper.line [class*="col-"]:last-child:after {
          display: none;
      }
  }
</style>
