<template>
    <AdminUsersChildrenDetailsLayout :child="child">
        <template v-slot:action>
            <div v-if="!child.is_archived" class="col">
                <button @click="showChildForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update Child
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                    <div class="col">
                        Last Booking:
                        <span class="font-semibold">{{ child.last_booking_timestamp }}</span>
                    </div>
                    <div class="col">
                        Last Update:
                        <span class="font-semibold">{{ child.updated_at }}</span>
                    </div>
                </div>
                <div class="profile-grid">
                    <div class="col p-6">
                        <InertiaLink :href="'/admin/users/members/' + child.member?.id">
                            Member
                            <h6 class="font-semibold mt-1">{{ child.member?.name }} <i v-if="child.member?.is_vip" class="fas fa-check-circle primary"></i></h6>
                        </InertiaLink>
                    </div>
                    <div class="col p-6">
                        Date of Birth
                        <h6 class="font-semibold mt-1">{{ child.date_of_birth + ' ' + '(' + child.age + ')' }}</h6>
                    </div>
                    <div class="col p-6">
                        Bookings Count
                        <h6 class="font-semibold mt-1">{{ child.statistics?.bookings_count }}</h6>
                    </div>
                    <div class="col p-6">
                        Join Date
                        <h6 class="font-semibold mt-1">{{ child.created_at_short }}</h6>
                    </div>
                </div>
                <div class="mt-10">
                    <h5 class="sub-title">Overview</h5>
                    <table class="transaction-table">
                        <tbody>
                        <tr v-if="child.is_archived">
                            <th class="text-secondary">Archived</th>
                            <td class="text-secondary">{{ child.archived_at }}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>{{ child.gender ?? '-' }}</td>
                        </tr>
                        <tr>
                            <th>Nickname</th>
                            <td>{{ child.nickname }}</td>
                        </tr>
                        <tr>
                            <th>Date of Birth</th>
                            <td>{{ child.date_of_birth + ' ' + '(' + child.age + ')' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-child-form">
                <div v-if="showingChildForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit Child</h4>
                            </div>
                            <div class="col">
                                <button @click="hideChildForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitChildForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">First Name</label>
                                        <div class="input-holder">
                                            <input v-model="childForm.first_name" :class="{'error' : childForm.errors?.first_name}" placeholder="First Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Last Name</label>
                                        <div class="input-holder">
                                            <input v-model="childForm.last_name" :class="{'error' : childForm.errors?.last_name}" placeholder="Last Name"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Nickname</label>
                                        <div class="input-holder">
                                            <input v-model="childForm.nickname" :class="{'error' : childForm.errors?.nickname}" placeholder="Nickname"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Gender</label>
                                        <div class="input-holder">
                                            <input v-model="childForm.gender" :class="{'error' : childForm.errors?.gender}" placeholder="Gender"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Date of Birth</label>
                                        <div class="input-holder">
                                            <input v-model="childForm.date_of_birth" :class="{'error' : childForm.errors?.date_of_birth}" placeholder="Date of Birth"
                                                   type="date">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="childForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!childForm.isDirty || childForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                        <div>
                            <div class="pb-10"></div>
                            <hr>
                            <div class="pb-10"></div>
                            <div class="flex flex-wrap items-center justify-between gap-4">
                                <div class="col">
                                    <h4>Manage Avatar</h4>
                                </div>
                            </div>
                            <div class="pb-10"></div>
                            <div>
                                You can change or remove the avatar.
                            </div>
                            <div class="pb-10"></div>
                            <FormErrorsGroup class="pb-10" :errors="avatarForm.errors"/>
                            <input id="new-avatar" type="file" hidden @input="avatarForm.avatar = $event.target.files[0]" @change="changeAvatar()" />
                            <button @click="selectAvatar()" class="btn btn--primary">Change Avatar</button>
                            <button @click="removeAvatar()" class="btn btn--secondary ml-10">Remove Avatar</button>
                        </div>
                    </div>
                </div>
            </div>
            <SoftDelete
                :is-archived="child.is_archived"
                :archive-url="'/admin/users/children/' + child.id + '/archive'"
                :restore-url="'/admin/users/children/' + child.id + '/restore'"
                type="Child"
            >
                <p>Archived Children cannot access the application.</p>
            </SoftDelete>
        </template>
    </AdminUsersChildrenDetailsLayout>
</template>

<script setup>
import AdminUsersChildrenDetailsLayout from "@components/admin/AdminUsersChildrenDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import SoftDelete from "@components/utilities/SoftDelete"

import {ref} from "vue";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    child: {
        type: Object,
        required: true
    }
})

const childForm = useForm({
    first_name: props.child.first_name,
    last_name: props.child.last_name,
    nickname: props.child.nickname,
    gender: props.child.gender,
    date_of_birth: props.child.date_of_birth_raw,
})

const submitChildForm = () => {
    childForm.post('/admin/users/children/' + props.child.id + '/update/overview', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideChildForm(),
    })
}

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('edit-child-form')


const avatarForm = useForm({
    avatar: null,
})
const selectAvatar = () => {
    document.getElementById("new-avatar").click()
}
const changeAvatar = () => {
    if (!avatarForm.avatar) {
        return
    }
    avatarForm.post('/admin/users/children/' + props.child.id + '/avatar/replace', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideChildForm(),
    })
}
const removeAvatar = () => {
    Inertia.post('/admin/users/children/' + props.child.id + '/avatar/remove', {},{
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideChildForm(),
    })
}

</script>
