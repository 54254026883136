<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10 whitespace-pre-wrap">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Contact Details</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showUserForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <table class="transaction-table">
                    <tbody>
                    <tr>
                        <th>Email</th>
                        <td>{{ user.email ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Telephone 1</th>
                        <td>{{ user.telephone_one ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Telephone 2</th>
                        <td>{{ user.telephone_two ?? '-' }}</td>
                    </tr>
                    <tr>
                        <th>Closest Transport Station</th>
                        <td>{{ user.closest_transport_station ?? '-' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-user-form">
                <div v-if="showingUserForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit User</h4>
                            </div>
                            <div class="col">
                                <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitUserForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Closest Transport Station</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-map"></i>
                                            </div>
                                            <input v-model="userForm.closest_transport_station" :class="{'error' : userForm.errors?.closest_transport_station}" placeholder="Closest Transport Station" type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Telephone One</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-phone"></i>
                                            </div>
                                            <input v-model="userForm.telephone_one" :class="{'error' : userForm.errors?.telephone_one}" placeholder="Telephone One" type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Telephone Two</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-phone"></i>
                                            </div>
                                            <input v-model="userForm.telephone_two" :class="{'error' : userForm.errors?.telephone_two}" placeholder="Telephone Two" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="userForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const userForm = useForm({
    closest_transport_station: props.user.closest_transport_station,
    telephone_one: props.user.telephone_one,
    telephone_two: props.user.telephone_two,
})

const submitUserForm = () => {
    userForm.post('/admin/users/creatives/' + props.user.id + '/update/contact', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')

</script>
