<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <MemberProfileFilters  />
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h3 class="page-title">{{ member.name }}</h3>
                </div>
                <div class="col">
                    <button @click="showAddUserForm" class="btn">
                        <i class="fas fa-plus"></i>
                        Add User
                    </button>
                </div>
            </div>
            <UsersList
                :users="member.users"
                title="Users"
                url-prefix="/member/profile/users/"
                :show-add="false"
            />
        </div>
    </div>
    <div id="add-user-form">
        <div v-if="showingAddUserForm">
            <div class="mt-10"></div>
            <UserAdd
                url="/member/profile/users/add"
                :member-id="member.id"
                @closed="hideAddUserForm"
                title="Add User"
            />
        </div>
    </div>
</template>

<script setup>
import UsersList from "@components/utilities/UsersList"
import UserAdd from "@components/utilities/UserAdd"
import useToggleable from "@composables/useToggleable";
import MemberProfileFilters from "@components/member/MemberProfileFilters"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const { showing: showingAddUserForm, show: showAddUserForm, hide: hideAddUserForm } = useToggleable('add-user-form')
</script>

<style lang="postcss">
body {
}
</style>
