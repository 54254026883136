<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ actionDisplay + ' ' + type }}</h4>
                </div>
            </div>
            <div class="pb-10"></div>
            <div>
                <slot></slot>
            </div>
            <div class="input-submit mt-10">
                <button v-if="isArchived" @click="restore()" class="btn btn--primary">{{ actionDisplay + ' ' + type }}</button>
                <button v-else @click="archive()" class="btn btn--secondary">{{ actionDisplay + ' ' + type }}</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {Inertia} from "@inertiajs/inertia";
import {computed} from "vue";

const props = defineProps({
    isArchived: {
        type: Boolean,
        required: true
    },
    archiveUrl: {
        type: String,
        required: true
    },
    restoreUrl: {
        type: String,
        required: true
    },
    type: {
        type: String,
        default: "User"
    },
    action: {
        type: String,
        default: null
    }
})

const actionDisplay = computed(() => props.action ??( props.isArchived ? 'Restore' : 'Archive'))

const archive = () => {
    Inertia.post(props.archiveUrl, null, {
        onBefore: () => confirm('Are you sure you want to ' + actionDisplay.value + ' the ' + props.type + '?'),
    })
}
const restore = () => {
    Inertia.post(props.restoreUrl, null, {
        onBefore: () => confirm('Are you sure you want to ' + actionDisplay.value + ' the ' + props.type + '?'),
    })
}
</script>
