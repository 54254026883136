<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto lg:hidden mb-10">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/member/bookings/type/upcoming" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/bookings/type/upcoming')}">Upcoming</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/member/bookings/type/past" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/bookings/type/past')}">Past</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>

</style>
