<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <MemberProfileFilters  />
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h3 class="page-title">{{ member.name }}</h3>
                </div>
                <div class="col">
                    <button @click="showAddAddressForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Add Address
                    </button>
                </div>
            </div>
            <AddressesList
                :addresses="member.addresses"
                :show-add="false"
                @edit="editAddress"
            />
        </div>
    </div>
    <div id="add-address-form">
        <div v-if="showingAddAddressForm">
            <div class="mt-10"></div>
            <AddressForm
                :url="'/member/profile/address/add'"
                :is-add="true"
                @closed="hideAddAddressForm"
            />
        </div>
    </div>
    <div id="edit-address-form">
        <div v-if="showingEditAddressForm">
            <div class="mt-10"></div>
            <AddressForm
                :url="'/member/profile/address/' + addressToEdit.id + '/edit'"
                :remove-url="'/member/profile/address/' + addressToEdit.id + '/remove'"
                :is-add="false"
                :address="addressToEdit"
                @closed="hideEditAddressForm"
            />
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue"
import AddressesList from "@components/utilities/AddressesList"
import AddressForm from "@components/utilities/AddressForm"
import useToggleable from "@composables/useToggleable";
import MemberProfileFilters from "@components/member/MemberProfileFilters"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})
const { showing: showingAddAddressForm, show: showAddAddressForm, hide: hideAddAddressForm } = useToggleable('add-address-form')
const { showing: showingEditAddressForm, show: showEditAddressForm, hide: hideEditAddressForm } = useToggleable('edit-address-form')
const addressToEdit = ref(null)
const editAddress = (address) => {
    hideEditAddressForm()
    addressToEdit.value = address
    setTimeout(() => showEditAddressForm(), 10)
}
</script>

<style lang="postcss">
body {
}
</style>
