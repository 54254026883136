<template>
	<div class="navigation-wrapper" :class="{'sticky-nav':sticky, 'nav-open':mobileNav}">
		<div class="container">
			<div class="nav">
				<div class="nav-row">
					<div class="nav__left" style="z-index: 1;">
						<div class="nav__logo">
							<a href="/">
								<img :src="logo" alt="" v-if="!sticky && !mobileNav">
								<img :src="logoDark" alt="" v-else>
							</a>
						</div>
					</div>
					<div class="nav__cente nav__menu" :class="{'nav__menu-visible':mobileNav}">
						<ul class="menu">
							<li :class="{active: $page.url === '/home-childcare'}"><a href="/home-childcare">Home Childcare</a></li>
							<li :class="{active: $page.url === '/hotels-and-homestay'}"><a href="/hotels-and-homestay">Hotels & Homestay</a></li>
							<li :class="{active: $page.url === '/about'}"><a href="/about">About</a></li>
							<li :class="{active: $page.url === '/contact'}" class="menu-btn">
								<a href="/contact" class="btn btn--outline btn--rounded" style="min-width: 180px; padding: 5px 10px; display: inline-block;">Contact</a>
							</li>
						</ul>
					</div>
					<div class="nav__right nav__btn">
						<a href="/contact" class="btn btn--outline btn--rounded" style="min-width: 180px; padding: 5px 10px; display: inline-block;">Contact</a>
					</div>
					<div class="nav__hamburger">
						<div class="hamburger" @click="mobileNav = !mobileNav">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				logo:'./img/web/logo.png',
				logoDark:'./img/web/logo-dark.png',
				windowTop:null,
				sticky:false,
				mobileNav:false,
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			handleScroll () {
				this.windowTop = window.top.scrollY
				if(this.windowTop > 100) {
					this.sticky = true
				} else {
					this.sticky = false
				}
			}
		}
	}
</script>
<style lang="scss">
	.nav {
		&-row {display: flex; justify-content: space-between; align-items: center;
			.btn {padding: 8px 15px; color: #fff;
				&:hover {color: #333;}
			}
		}
		&__left,
		&__right {width: 180px;}
		&__center {flex:1; text-align: center;}
		&__logo {
			img {max-height: 60px;}
		}
		&__hamburger {display: none;}
		.menu {list-style:none; padding: 0; margin: 0;
			li {display: inline-block;
				a {display: block; padding: 10px 15px; color: inherit; text-transform: uppercase; font-weight: 600;}
				&.menu-btn {display: none;}
        &.active {
          text-decoration: underline;
        }
			}
		}
		@media(max-width: 991px) {
			&__btn {display: none;}
			&__menu {width: 280px; position: fixed; top: 72px; left: 0; bottom: 0; background: #fff; box-shadow: -1px 1px 3px rgba(#000, 0.1); transform: translateX(-300px); transition: 333ms;
				.menu {padding: 20px 15px; display: block;
					li {display: block;
						a {text-decoration: none;}
						&.menu-btn {display: block; margin-top: 30px;}
					}
				}
			}
			&__hamburger {display: block;}
			&__menu-visible {transform: translateX(0);}
		}
	}
	.hamburger {width: 24px; height: 24px; cursor: pointer; padding: 3px 0;
		span {display: block; background: #fff; height: 3px; border-radius: 3px; margin-bottom: 4px;
			&:last-child {margin-bottom: 0;}
		}
	}
	.navigation-wrapper {position: fixed; top: 0; left: 0; width: 100%; z-index: 999; padding: 16px 0; color: #fff; background: transparent; transition: 333ms;
		&.sticky-nav,
		&.nav-open {background: #fff; color: #333; box-shadow: 0 1px 3px rgba(#000, 0.1);
			.btn {color: #333;}
			.hamburger {
				span {background: #000;}
			}
		}
	}
</style>
