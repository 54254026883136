<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col">
                    <a v-if="remittance.file" class="btn" :href="remittance.file" target="_blank">
                        <i class="fas fa-print"></i>
                        Download PDF
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h3 class="page-title">Remittance {{ remittance.id }}</h3>
                </div>
                <div v-if="canEditRemittance" class="col">
                    <button @click="showForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Remittance
                    </button>
                </div>
            </div>
            <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                <div class="col">
                    Created:
                    <span class="font-semibold">{{ remittance.created_at }}</span>
                </div>
                <div class="col">
                    Last Edit:
                    <span class="font-semibold">{{ remittance.updated_at }}</span>
                </div>
            </div>
            <div class="profile-grid">
                <div class="col p-6">
                    Creative
                    <h6 class="font-semibold mt-1">{{ remittance.creative.name }}</h6>
                </div>
                <div class="col p-6">
                    Total
                    <h6 class="font-semibold mt-1">{{ remittance.breakdown.total }}</h6>
                </div>
                <div class="col p-6">
                    Bookings
                    <h6 class="font-semibold mt-1">{{ remittance.breakdown.bookings_count }}</h6>
                </div>
                <div class="col p-6">
                    Status
                    <h6 class="font-semibold mt-1">{{ remittance.status }}</h6>
                </div>
            </div>
            <div class="mt-14">
                <h5 class="sub-title">Dates</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Issued</th>
                            <td>{{ remittance.created_at_timestamp }}</td>
                        </tr>
                        <tr v-if="remittance.status !== 'Cancelled'">
                            <th>Completed</th>
                            <td>{{ remittance.completed_at_timestamp ?? '-' }}</td>
                        </tr>
                        <tr v-else>
                            <th>Cancelled</th>
                            <td>{{ remittance.updated_at_timestamp }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-14">
                <h5 class="sub-title">Financial Breakdown</h5>
                <table class="transaction-table">
                    <tbody>
                    <tr v-for="(booking, bookingIndex) in remittance.breakdown?.bookings" :key="bookingIndex">
                        <th>
                            <InertiaLink :href="'/admin/bookings/' + booking.id">
                                <span v-if="booking.block_id">
                                    Block: {{ booking.block_id }}
                                    <br>
                                </span>
                                {{ booking.id }}
                                <br>
                                {{ booking.start_timestamp }}
                                <br>
                                {{ booking.duration }}
                                <br>
                                Member: {{ booking.member_name }}
                            </InertiaLink>
                        </th>
                        <td>
                            £{{ booking.total }}
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{{ remittance.breakdown?.total }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <NotesList
                :notes="remittance.notes"
                :show-add="true"
                @add="showNoteForm"
            />
        </div>
    </div>
    <div id="edit-remittance-form">
        <div v-if="showingForm" class="page-card mt-10">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Edit Remittance</h4>
                    </div>
                    <div class="col">
                        <button @click="hideForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="pb-10"></div>
                <form @submit.prevent="submitForm">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-10">
                                <label class="input-label">Status</label>
                                <select name="value" v-model="form.status" :class="{'error' : form.errors.status}">
                                    <option disabled>Select Type</option>
                                    <option v-for="(type, index) in statuses" :key="index" :value="type.value">{{ type.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="form.errors"/>
                    <div class="input-submit mt-10">
                        <input :disabled="form.processing || !form.isDirty" class="bg-secondary hover:bg-primary transition duration-300"
                               type="submit" value="Update">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div id="add-note-form">
        <NotesAdd
            v-if="showingNoteForm"
            :url="'/admin/finance/remittances/' + remittance.id + '/add-note'"
            @closed="hideNoteForm"
        />
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import NotesList from "@components/utilities/NotesList"
import NotesAdd from "@components/utilities/NotesAdd"
import useToggleable from "@composables/useToggleable"

const props = defineProps({
    remittance: {
        type: Object,
        required: true
    }
})

const { showing: showingNoteForm, show: showNoteForm, hide: hideNoteForm } = useToggleable('add-note-form')
const { showing: showingForm, show: showForm, hide: hideForm } = useToggleable('edit-remittance-form')

const canEditRemittance = computed(() => {
    return props.remittance.raw?.status === 'pending'
})

const form = useForm({
    status: props.remittance.raw?.status,
})

const submitForm = () => {
    form.post('/admin/finance/remittances/' + props.remittance.id + '/update', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideForm(),
    })
}

const statuses = [
    {name: 'Pending', value: 'pending'},
    {name: 'Paid', value: 'paid'},
    {name: 'Cancelled', value: 'cancelled'},
]

</script>

<style lang="postcss">
body {
}
</style>
