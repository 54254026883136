<template>
    <div class="vld-parent">
        <loading :active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
        />
        <slot></slot>
    </div>
</template>

<script setup>
import {computed, watch, ref} from "vue"
import { usePage } from "@inertiajs/inertia-vue3"
import useToast from "@composables/useToast"
import {Inertia} from "@inertiajs/inertia"

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

function useProgressIndicators() {
    let isLoading = ref(false)
    let timeout = null

    Inertia.on('start', () => {
        timeout = setTimeout(() => isLoading.value = true, 250)
    })

    Inertia.on('finish', (event) => {
        clearTimeout(timeout)
        if (!isLoading.value) {
            return
        } else if (event.detail.visit.completed) {
            isLoading.value = false
        } else if (event.detail.visit.interrupted) {
            isLoading.value = false
            isLoading.value = true
        } else if (event.detail.visit.cancelled) {
            isLoading.value = false
        }
    })
    return {isLoading}
}

const {isLoading} = useProgressIndicators()


const {toast} = useToast()

function useFlashMessages() {
    const flash = computed(() => usePage().props.value.flash)

    watch(flash, (newV, oldV) => {
        if (newV && newV.type && newV.message) {
            toast(newV.type, newV.message)
        }
    }, {
        immediate: true
    })
}
useFlashMessages()
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

#app {min-height: 100%;}
</style>
