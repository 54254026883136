<template>
    <CreativeAccountFilters />
    <div class="page-filters-section mb-10 block lg:hidden">
        <div class="filter-bar">
            <InertiaLink href="/logout" method="post" as="button" class="btn btn--secondary">
                <i class="fas fa-sign-out-alt"></i> Log out
            </InertiaLink>
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Edit Details</h4>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="userForm.post('/creative/account/details')">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Title</label>
                            <div class="input-holder">
                                <input v-model="userForm.title" :class="{'error' : userForm.errors?.title}" placeholder="Title"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">First Name</label>
                            <div class="input-holder">
                                <input v-model="userForm.first_name" :class="{'error' : userForm.errors?.first_name}" placeholder="First Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Last Name</label>
                            <div class="input-holder">
                                <input v-model="userForm.last_name" :class="{'error' : userForm.errors?.last_name}" placeholder="Last Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Gender</label>
                            <div class="input-holder">
                                <input v-model="userForm.gender" :class="{'error' : userForm.errors?.gender}" placeholder="Gender"
                                       type="text">
                            </div>
                        </div>
                        <hr class="mb-10 mt-10">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Email</label>
                            <div class="input-holder">
                                <input v-model="userForm.email" :class="{'error' : userForm.errors?.email}" placeholder="Email"
                                       type="email">
                            </div>
                        </div>
                        <hr class="mb-10 mt-10">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">New Password</label>
                            <div class="input-holder">
                                <input v-model="userForm.password" :class="{'error' : userForm.errors?.password}"
                                       placeholder="Password" type="password">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">New Password Confirmation</label>
                            <div class="input-holder">
                                <input v-model="userForm.password_confirmation"
                                       :class="{'error' : userForm.errors?.password_confirmation}" placeholder="Password"
                                       type="password">
                            </div>
                        </div>
                        <hr class="mb-10 mt-10">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Professional First Name</label>
                            <div class="input-holder">
                                <input v-model="userForm.professional_first_name" :class="{'error' : userForm.errors?.professional_first_name}" placeholder="Professional First Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Professional Last Name</label>
                            <div class="input-holder">
                                <input v-model="userForm.professional_last_name" :class="{'error' : userForm.errors?.professional_last_name}" placeholder="Professional Last Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Date of Birth</label>
                            <div class="input-holder">
                                <input v-model="userForm.date_of_birth" :class="{'error' : userForm.errors?.date_of_birth}" placeholder="Date of Birth"
                                       type="date">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Nationality</label>
                            <div class="input-holder">
                                <input v-model="userForm.nationality" :class="{'error' : userForm.errors?.nationality}" placeholder="Nationality" type="text">
                            </div>
                        </div>
                        <hr class="mb-10 mt-10">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Closest Transport Station</label>
                            <div class="input-holder">
                                <input v-model="userForm.closest_transport_station" :class="{'error' : userForm.errors?.closest_transport_station}" placeholder="Closest Transport Station" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Telephone One</label>
                            <div class="input-holder">
                                <input v-model="userForm.telephone_one" :class="{'error' : userForm.errors?.telephone_one}" placeholder="Telephone One" type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Telephone Two</label>
                            <div class="input-holder">
                                <input v-model="userForm.telephone_two" :class="{'error' : userForm.errors?.telephone_two}" placeholder="Telephone Two" type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="userForm.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import useToggleable from "@composables/useToggleable"
import DocumentForm from "@components/utilities/DocumentForm"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import CreativeAccountFilters from "@components/creative/CreativeAccountFilters"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const userForm = useForm({
    title: props.user.title,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    gender: props.user.gender,
    email: props.user.email,
    password: null,
    password_confirmation: null,

    professional_first_name: props.user.professional_first_name,
    professional_last_name: props.user.professional_last_name,
    date_of_birth: props.user.date_of_birth_raw,
    nationality: props.user.nationality,
    closest_transport_station: props.user.closest_transport_station,

    telephone_one: props.user.telephone_one,
    telephone_two: props.user.telephone_two,
})
</script>

<style lang="postcss">
body {
}
</style>
