<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/system-settings/rates" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/rates')}">Rates</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/members-team-review-frequency" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/members-team-review-frequency')}">Frequency</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/tags" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/tags')}">Tags</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/notifications')}">Alerts</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div class="page-filters-section">
        <div class="filter-bar">
            <AdminSystemSettingsTagsPages />
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <input type="text" v-model="searchTerm">
                        <button :disabled="true">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div v-if="types.length" class="col">
                    <div class="filter-select">
                        <select v-model="searchType">
                            <option value="" selected>All Types</option>
                            <option v-for="(type, index) in types" :key="index" :value="type.id">{{ type.name }}</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Categories</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Tags Count</th>
                        <th></th>
                    </tr>
                    <tr v-for="(category, index) in items" :key="index">
                        <td>
                            <div class="text-gray-600">{{ category.name }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ category.type }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ category.tags_count }}</div>
                        </td>
                        <td>
                            <InertiaLink :href="'/admin/system-settings/tags/categories/edit/' + category.id" class="btn btn--sm btn--nowrap">
                                <i class="fas fa-pen"></i>
                                Edit
                            </InertiaLink>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import AdminSystemSettingsTagsPages from "@components/admin/AdminSystemSettingsTagsPages"
import usePaginator from "@composables/usePaginator"

const props = defineProps({
    types: {
        type: Array,
        default: []
    }
})

const searchTerm = ref("")
const searchType = ref("")

const apiUrl = computed(() => {
    let url = '/admin/system-settings/tags/categories/list'
    if (searchTerm.value !== "") {
        url += '?search=' + searchTerm.value
        if (searchType.value !== "") {
            url += '&type=' + searchType.value
        }
    } else {
        if (searchType.value !== "") {
            url += '?type=' + searchType.value
        }
    }
    return url
})

const {items, hasMore, loadMore, fetchItems } = usePaginator(apiUrl)

fetchItems(false)


</script>

<style lang="postcss">
</style>
