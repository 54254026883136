<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/system-settings/rates" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/rates')}">Rates</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/members-team-review-frequency" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/members-team-review-frequency')}">Frequency</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/tags" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/tags')}">Tags</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/notifications')}">Alerts</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div class="page-filters-section">
        <div class="filter-bar">
            <AdminSystemSettingsTagsPages />
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <input type="text" v-model="searchTerm">
                        <button :disabled="true">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div v-if="types.length" class="col">
                    <div class="filter-select">
                        <select v-model="searchType">
                            <option value="" selected>All Types</option>
                            <option v-for="(type, index) in types" :key="index" :value="type.id">{{ type.name }}</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                </div>
                <div v-if="filteredCategories.length" class="col">
                    <div class="filter-select">
                        <select v-model="searchCategory">
                            <option value="" selected>All Categories</option>
                            <option v-for="(category, index) in filteredCategories" :key="index" :value="category.id">{{ category.name }}</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchAdmin">
                            <option value="" selected>Admin Status</option>
                            <option value="true">Only Admin Tags</option>
                            <option value="false">Only Non-Admin Tags</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchApproved">
                            <option value="" selected>Approved Status</option>
                            <option value="true">Only Approved</option>
                            <option value="false">Only Unapproved</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Tags</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Type</th>
                        <th style="text-align: center">Admin Only</th>
                        <th style="text-align: center">Status</th>
                    </tr>
                    <tr v-for="(tag, index) in items" :key="index">
                        <td>
                            <div class="text-gray-600">{{ tag.name }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ tag.category }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ tag.type }}</div>
                        </td>
                        <td style="text-align: center">
                            <UserListAccessStatusIcon :type="tag.is_admin_only ? 'locked' : 'access'" />
                        </td>
                        <td style="text-align: center">
                            <UserListStatusIcon :is-approved="tag.is_approved" />
                        </td>
                        <td>
                            <div class="flex gap-2">
                                <div class="col">
                                    <InertiaLink :href="'/admin/system-settings/tags/tags/edit/' + tag.id" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-pen"></i>
                                        Edit
                                    </InertiaLink>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from "vue"
import AdminSystemSettingsTagsPages from "@components/admin/AdminSystemSettingsTagsPages"
import usePaginator from "@composables/usePaginator"
import UserListAccessStatusIcon from "../../components/utilities/UserListAccessStatusIcon";
import UserListStatusIcon from "../../components/utilities/UserListStatusIcon";

const props = defineProps({
    types: {
        type: Array,
        default: []
    },
    categories: {
        type: Array,
        default: []
    }
})

const filteredCategories = computed(() => {
    if (searchType.value === "") {
        return []
    }
    return props.categories.filter(cat => cat.tag_type_id === searchType.value)
})

const searchTerm = ref("")
const searchType = ref("")
const searchCategory = ref("")
const searchAdmin = ref("")
const searchApproved = ref("")

watch(searchType, () => searchCategory.value = '')

const apiUrl = computed(() => {
    let url = '/admin/system-settings/tags/tags/list'
    if (searchTerm.value !== "") {
        url += '?search=' + searchTerm.value
        if (searchType.value !== "") {
            url += '&type=' + searchType.value
            if (searchCategory.value !== "") {
                url += '&category=' + searchCategory.value
            }
        }
        if (searchAdmin.value !== "") {
            url += '&admin=' + searchAdmin.value
        }
        if (searchApproved.value !== "") {
            url += '&approved=' + searchApproved.value
        }
    } else {
        if (searchType.value !== "") {
            url += '?type=' + searchType.value
            if (searchCategory.value !== "") {
                url += '&category=' + searchCategory.value
            }
            if (searchAdmin.value !== "") {
                url += '&admin=' + searchAdmin.value
            }
            if (searchApproved.value !== "") {
                url += '&approved=' + searchApproved.value
            }
        } else {
            if (searchAdmin.value !== "") {
                url += '?admin=' + searchAdmin.value
                if (searchApproved.value !== "") {
                    url += '&approved=' + searchApproved.value
                }
            } else {
                if (searchApproved.value !== "") {
                    url += '?approved=' + searchApproved.value
                }
            }
        }
    }
    return url
})

const {items, hasMore, loadMore, fetchItems } = usePaginator(apiUrl)

fetchItems(false)
</script>

<style lang="postcss">
</style>
