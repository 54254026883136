<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Relations</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showAddRelationForm" class="btn btn--icon"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
                <div v-if="user.relations?.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Type</th>
                            <th>Relationship</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Telephone</th>
                            <th>Address</th>
                        </tr>
                        <tr v-for="(relation, index) in user.relations" :key="index">
                            <td>
                                <div v-if="relation.type === 'emergency_contact'" class="text-secondary">Emergency Contact</div>
                                <div v-else class="text-gray-600 font-normal">Referee</div>
                            </td>
                            <td><div class="text-gray-600 font-normal">{{ relation.relationship }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ relation.name }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ relation.email }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ relation.telephone }}</div></td>
                            <td>
                                <a :href="relation.address?.map_url">
                                    <div class="text-gray-600 font-normal">{{ relation.address?.street }}</div>
                                    <div class="text-gray-600 font-normal">{{ relation.address?.city }}</div>
                                    <div class="text-gray-600 font-normal">{{ relation.address?.country }}</div>
                                    <div class="text-gray-600 font-normal">{{ relation.address?.postcode }}</div>
                                </a>
                            </td>
                            <td v-if="!user.is_archived">
                                <button @click="removeRelation(relation.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="add-relation-form">
                <div v-if="showingAddRelationForm">
                    <div class="mt-10"></div>
                    <RelationForm
                        :url="'/admin/users/creatives/' + user.id + '/relations/add'"
                        @closed="hideAddRelationForm"
                    />
                </div>
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout";
import useToggleable from "@composables/useToggleable"
import {Inertia} from "@inertiajs/inertia";
import RelationForm from "@components/utilities/RelationForm"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { showing: showingAddRelationForm, show: showAddRelationForm, hide: hideAddRelationForm } = useToggleable('add-relation-form')
const removeRelation = (relationId) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/relations/' + relationId + '/remove', {}, {
        onBefore: () => confirm('Are you sure you want to remove the Relation?'),
        preserveScroll: true
    })
}
</script>
