<template>
    <div class="page-filters-section mb-10 lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/creative/account/availability" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/account/availability')}">Availability</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/account/financial" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/account/financial')}">Financial Details</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/account/documents" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/account/documents')}">Documents</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/account/statement" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/account/statement')}">Statement</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/account/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/account/notifications')}">Notifications</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/account/details" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/account/details')}">Details</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>

</style>
