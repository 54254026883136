<template>
    <div class="page-sidebar">
        <div class="logo">
            <InertiaLink href="/home">
                <img :src="logoUrl" alt="" class="sm:w-14 md:w-24">
            </InertiaLink>
        </div>
        <div class="sibar-nav">
            <div class="nav-group" v-for="(item, index) in navigation" :key="index">
                <h3 v-if="item.groupName" class="nav-group-name">{{item.groupName}}</h3>
                <ul class="nav">
                    <li v-for="(nav, index) in item.navs" :key="index">
                        <component :is="nav.label === 'Billing Portal' ? 'a' : 'InertiaLink'" :href="nav.url" :class="{'active': $page.url.startsWith(nav.url)}">
                            <div class="nav-icon">
                                <i :class="nav.icon"></i>
                            </div>
                            <div class="nav-label">
                                <span>{{nav.label}}</span>
                            </div>
                            <span
                                v-if="item.groupName === 'My Account' && nav.label === 'Notifications' && $page.props?.auth?.user?.notifications"
                                class="bg-primary text-white"
                                style="border-radius: 50%; padding: 3px; width: 30px; height: 30px; position:absolute;top: 5px;right: 0;"
                            >
                                    {{ $page.props?.auth.user.notifications > 99 ? '99+' : $page.props?.auth.user.notifications }}
                                </span>
                        </component>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidebar-bottom">
            <InertiaLink :href="'/' + role + '/account/details'" class="nav-user-data">
                <div class="avatar avatar--sm mx-auto" v-bind:style="{ backgroundImage: 'url(' + avatar + ')' }"></div>
                <div class="nav-user-right-col">
                    <div class="nav-user-name">{{ family }}</div>
                    <div class="nav-user-type">{{ role }}</div>
                </div>
            </InertiaLink>
        </div>
    </div>
</template>
<script setup>
import {computed} from "vue"
import {Inertia} from "@inertiajs/inertia"
import { Link, usePage } from "@inertiajs/inertia-vue3"
import useRole from "@composables/useRole"

const { logoUrl, role, isAdmin, isSuperAdmin, isCreative, isMember } = useRole(null)

const avatar = usePage().props?.value?.auth?.user?.avatar ?? null
const family = usePage().props?.value?.auth?.user?.family ?? null

const memberNavigation = [
    {
        groupName:'Bookings',
        navs: [
            {icon:'fas fa-calendar-check', label: 'Upcoming', url:'/member/bookings/type/upcoming'},
            {icon:'fas fa-calendar-times', label: 'Past', url:'/member/bookings/type/past'},
        ]
    },
    {groupName:'Profile',
        navs: [
            {icon:'fas fa-user', label: 'Overview', url:'/member/profile/view'},
            {icon:'fas fa-baby', label: 'Children', url:'/member/profile/children'},
            {icon:'fas fa-users', label: 'Users', url:'/member/profile/users'},
            {icon:'fas fa-address-book', label: 'Addresses', url:'/member/profile/addresses'},
            {icon:'fas fa-user-friends', label: 'Team', url:'/member/profile/team'},
        ]
    },
    {
        groupName:'Account',
        navs: [
            {icon:'fas fa-coins', label: 'Statement', url:'/member/account/statement'},
            {icon:'fas fa-address-card', label: 'Billing Portal', url:'/member/account/billing-portal'},
            {icon:'fas fa-bell', label: 'Notifications', url:'/member/account/notifications'},
            {icon:'fas fa-user-cog', label: 'Details', url:'/member/account/details'},
        ]
    },
]

const creativeNavigation = []

if (usePage().props?.value?.auth?.user?.is_approved) {
    creativeNavigation.push(
        {
            groupName:'Bookings',
            navs: [
                {icon:'fas fa-calendar-check', label: 'Upcoming', url:'/creative/bookings/type/upcoming'},
                {icon:'fas fa-calendar-times', label: 'Past', url:'/creative/bookings/type/past'},
                {icon:'fas fa-search', label: 'Find Bookings', url:'/creative/bookings/type/find'},
                {icon:'fas fa-check-circle', label: 'Create Booking', url:'/creative/bookings/create'},
            ]
        })
}

creativeNavigation.push(
    {groupName:'Profile',
        navs: [
            {icon:'fas fa-user', label: 'Overview', url:'/creative/profile/view'},
            {icon:'fas fa-address-book', label: 'Addresses', url:'/creative/profile/addresses'},
            {icon:'fas fa-camera', label: 'Images', url:'/creative/profile/images'},
            {icon:'fas fa-address-card', label: 'About', url:'/creative/profile/about'},
            {icon:'fas fa-users', label: 'Relations', url:'/creative/profile/relations'},
            {icon:'fas fa-user-friends', label: 'Team', url:'/creative/profile/team'},
            {icon:'fas fa-clipboard-check', label: 'Skills & Preferences', url:'/creative/profile/skills'},
        ]
    },
    {
        groupName:'Account',
        navs: [
            {icon:'fas fa-calendar-check', label: 'Availability', url:'/creative/account/availability'},
            {icon:'fas fa-credit-card', label: 'Financial Details', url:'/creative/account/financial'},
            {icon:'fas fa-id-card', label: 'Documents', url:'/creative/account/documents'},
            {icon:'fas fa-coins', label: 'Statement', url:'/creative/account/statement'},
            {icon:'fas fa-bell', label: 'Notifications', url:'/creative/account/notifications'},
            {icon:'fas fa-user-cog', label: 'Details', url:'/creative/account/details'},
        ]
    }
)


const adminNavigation = [
    {groupName:'Users',
        navs: [
            {icon:'fas fa-user-shield', label: 'Admin', url:'/admin/users/admins'},
            {icon:'fas fa-user', label: 'Creative', url:'/admin/users/creatives'},
            {icon:'fas fa-users', label: 'Members', url:'/admin/users/members'},
            {icon:'fas fa-baby', label: 'Children', url:'/admin/users/children'},
        ]
    },
    {groupName:'Bookings',
        navs: [
            {icon:'fas fa-calendar-alt', label: 'View Bookings', url:'/admin/bookings'},
            {icon:'fas fa-envelope-open-text', label: 'Applications', url:'/admin/users/creatives/applications'},
        ]
    },
    {groupName:'Finance',
        navs: [
            {icon:'fas fa-coins', label: 'Transactions', url:'/admin/finance/transactions?type=all'},
            {icon:'fas fa-file-invoice', label: 'Invoices', url:'/admin/finance/invoices'},
            {icon:'fas fa-receipt', label: 'Remittance', url:'/admin/finance/remittances'},
        ]
    },
    {groupName:'System Settings',
        navs: [
            {icon:'fas fa-id-card', label: 'Rates', url:'/admin/system-settings/rates'},
            {icon:'fas fa-calendar-day', label: 'Review Frequency', url:'/admin/system-settings/members-team-review-frequency'},
            {icon:'fas fa-tags', label: 'Tags', url:'/admin/system-settings/tags'},
            {icon:'fas fa-bell', label: 'Alerts', url:'/admin/system-settings/notifications'},
        ]
    },
    {groupName:'Broadcast',
        navs: [
            {icon:'fas fa-bullhorn', label: 'New Broadcast', url:'/admin/users/broadcast/create'},
            {icon:'fas fa-reply', label: 'Past Broadcasts', url:'/admin/users/broadcast/past'}
        ]
    }
]

if (isSuperAdmin.value) {
    adminNavigation.push(
        {groupName:'Analytics',
            navs: [
                {icon:'fas fa-project-diagram', label: 'Overview', url:'/admin/analytics/overview'},
                {icon:'fas fa-chart-pie', label: 'Clients & Creatives', url:'/admin/analytics/clients-and-creatives'},
                {icon:'fas fa-chart-bar', label: 'Hours & Bookings', url:'/admin/analytics/hours-and-bookings'},
            ]
        })
}
adminNavigation.push(
    {groupName:'My Account',
        navs: [
            {icon:'fas fa-exclamation-circle', label: 'Notifications', url:'/admin/account/notifications'},
            {icon:'fas fa-user-cog', label: 'Settings', url:'/admin/account/details'},
        ]
    }
)

const navigation = isAdmin.value ? adminNavigation : (isCreative.value ? creativeNavigation : memberNavigation)

</script>
<style lang="postcss">
.page-sidebar {
    @apply text-center p-5 h-full flex flex-col;
}
.page-sidebar .logo {
    @apply mb-10 mx-auto
}
.sibar-nav {
    @apply flex-1
}
.sibar-nav .nav {
    @apply block;
}
.sibar-nav .nav li {
    @apply block mb-1
}
.sibar-nav .nav li a {
    @apply block py-1 pl-2 pr-3 text-gray-500 hover:text-secondary text-lg relative transition flex items-center rounded-lg font-medium;
}
.sibar-nav .nav li a.active,
.sibar-nav .nav li a.router-link-exact-active {
    @apply bg-blue-50 text-black
}
.sibar-nav .nav li a.active .nav-icon,
.sibar-nav .nav li a.router-link-exact-active .nav-icon {
    @apply text-secondary;
}
.sibar-nav .nav li .nav-label {
    @apply text-base pl-1
}
.sibar-nav .nav li a .nav-icon {
    @apply w-10 h-10 leading-10 text-center
}
.nav-group {
    @apply mb-5 pb-5
}
.nav-group-name {
    @apply text-lg font-semibold text-gray-400 text-left mb-5;
}
.sidebar-bottom {
    @apply text-left;
}
.nav-user-data {
    @apply block hover:bg-gray-50 p-3 rounded-lg pl-16 relative;
}
.nav-user-data .avatar {
    @apply absolute left-2 top-2
}
.nav-user-name {
    @apply font-medium overflow-hidden overflow-ellipsis whitespace-nowrap;
}
.nav-user-type {
    @apply text-gray-300 text-left text-sm;
}

@media(max-width:1399px) {
    .sibar-nav .nav li a {
        @apply block py-5 px-2;
    }
    .sibar-nav .nav li a.active,
    .sibar-nav .nav li a.router-link-exact-active {
        @apply bg-transparent;
    }
    .sibar-nav .nav li a:after {
        content:'';
        transition: width 333ms;
        @apply w-0 h-0.5 rounded bg-transparent absolute left-0 right-0 bottom-2.5 mx-auto;
    }
    .sibar-nav .nav li a.active:after {
        @apply bg-secondary w-6;
    }
    .nav-group {
        @apply mb-0 pb-0 border-b border-gray-50;
    }
    .sidebar-bottom {
        @apply text-center;
    }
    .nav-user-data {
        @apply p-0 inline-block;
    }
    .nav-user-data .avatar {
        @apply static mb-3;
    }
    .nav-group-name,
    .nav-label,
    .nav-user-right-col {
        @apply hidden;
    }
}
</style>
