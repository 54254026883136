<template>
    <AdminUsersMemberUsersDetailsLayout :member-user="memberUser">
        <template v-slot:default>
            <AddressesList
                :addresses="memberUser.addresses"
                @add="showAddAddressForm"
                @edit="editAddress"
            />
        </template>
        <template v-slot:extra>
            <div id="add-address-form">
                <div v-if="showingAddAddressForm">
                    <div class="mt-10"></div>
                    <AddressForm
                        :url="'/admin/users/members/' + memberUser.member.id + '/member-users/' + memberUser.id + '/address/add'"
                        :is-add="true"
                        @closed="hideAddAddressForm"
                    />
                </div>
            </div>
            <div id="edit-address-form">
                <div v-if="showingEditAddressForm">
                    <div class="mt-10"></div>
                    <AddressForm
                        :url="'/admin/users/members/' + memberUser.member.id + '/member-users/' + memberUser.id + '/address/' + addressToEdit.id + '/edit'"
                        :remove-url="'/admin/users/members/' + memberUser.member.id + '/member-users/' + memberUser.id + '/address/' + addressToEdit.id + '/remove'"
                        :is-add="false"
                        :address="addressToEdit"
                        @closed="hideEditAddressForm"
                    />
                </div>
            </div>
        </template>
    </AdminUsersMemberUsersDetailsLayout>
</template>

<script setup>
import AdminUsersMemberUsersDetailsLayout from "@components/admin/AdminUsersMemberUsersDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import AddressForm from "@components/utilities/AddressForm"
import AddressesList from "@components/utilities/AddressesList"
import {ref} from "vue";

const props = defineProps({
    memberUser: {
        type: Object,
        required: true
    }
})

const { showing: showingAddAddressForm, show: showAddAddressForm, hide: hideAddAddressForm } = useToggleable('add-address-form')
const { showing: showingEditAddressForm, show: showEditAddressForm, hide: hideEditAddressForm } = useToggleable('edit-address-form')
const addressToEdit = ref(null)
const editAddress = (address) => {
    hideEditAddressForm()
    addressToEdit.value = address
    setTimeout(() => showEditAddressForm(), 10)
}

</script>
