<template>
    <div class="user-page">
        <div class="card card--user">
            <div class="logo w-24 h-24 mx-auto">
                <img :src="logoUrl" alt="">
            </div>
            <div class="pb-12"></div>
            <div class="text-center mb-14">
                <h3 class="font-semibold">Reset Password</h3>
            </div>
            <form @submit.prevent="form.post('/reset-password')">
                <div class="input-wrapper mb-5">
                    <label class="input-label">Email</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="far fa-envelope"></i>
                        </div>
                        <input v-model="form.email" :class="{'error' : form.errors.email}" placeholder="Email" type="email">
                    </div>
                </div>
                <div class="input-wrapper mb-5">
                    <label class="input-label">Password</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <input v-model="form.password" :class="{'error' : form.errors.password}" placeholder="Password" type="password">
                    </div>
                </div>
                <div class="input-wrapper mb-1">
                    <label class="input-label">Password Confirmation</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <input v-model="form.password_confirmation" :class="{'error' : form.errors.password_confirmation}" placeholder="Password" type="password">
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors" />
                <div class="input-submit mt-10">
                    <input :disabled="form.processing || form.email === '' || form.password === '' || form.password_confirmation === ''" class="bg-secondary hover:bg-primary transition duration-300 w-full" type="submit" value="RESET PASSWORD">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import useRole from "@composables/useRole"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    role: {
        type: String,
        default: 'member'
    },
    token: {
        type: String,
        required: true
    }
})

const {logoUrl} = useRole(props.role)

const form = useForm({
    email: "",
    password: "",
    password_confirmation: "",
    token: props.token
})
</script>

<style lang="postcss">
.user-page {
    @apply h-screen w-full flex flex-wrap items-center justify-center overflow-y-auto p-5;
}

.card--user {
    @apply xl:w-3/12 lg:w-6/12 md:w-8/12 w-full;
}

.sep-text {
    @apply flex items-center my-10 text-gray-400;
}

.sep-line {
    @apply h-0.5 block bg-gray-100 flex-1;
}
</style>
