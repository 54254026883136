<template>
    <CreativeBookingDetailsLayout :booking="booking">
        <template v-slot:action>
            <div v-if="booking.can_edit" class="col">
                <button @click="showChildForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Add Child
                </button>
            </div>
        </template>
        <template v-slot:default>
            <UsersList
                :users="booking.children?.details"
                title="Children"
                :url-prefix="'/creative/member/' + booking.member?.id + '/child/'"
                :show-add="booking.can_edit"
                @add="showChildForm"
                :show-remove="booking.can_edit"
                @remove="removeChild"
            />
        </template>
        <template v-slot:extra>
            <div id="add-child-form">
                <div v-if="showingChildForm">
                    <div class="mt-10"></div>
                    <BookingChildAdd
                        :url="'/creative/bookings/' + booking.id + '/children/add'"
                        :children="otherMemberChildren"
                        @closed="hideChildForm"
                        title="Add Child"
                    />
                </div>
            </div>
        </template>
    </CreativeBookingDetailsLayout>
</template>

<script setup>
import CreativeBookingDetailsLayout from "@components/creative/CreativeBookingDetailsLayout";
import useToggleable from "@composables/useToggleable"
import {Inertia} from "@inertiajs/inertia";

import BookingChildAdd from "@components/utilities/BookingChildAdd"
import UsersList from "@components/utilities/UsersList"
const props = defineProps({
    booking: {
        type: Object,
        required: true
    },
    otherMemberChildren: {
        type: Array,
        default: null
    }
})

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('add-child-form')
const removeChild = (child) => {
    if (child?.booking_child_id) {
        Inertia.post('/creative/bookings/' + props.booking.id + '/children/' + child.booking_child_id + '/remove')
    }
}
</script>
