<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ hasExpense ? 'Update' : 'Add' }} Expense</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Title</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-building"></i>
                                </div>
                                <input v-model="form.title" :class="{'error' : form.errors?.title}" placeholder="Title"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Price</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-pound-sign"></i>
                                </div>
                                <input v-model="form.price" :class="{'error' : form.errors?.price}" placeholder="Price"
                                       type="text">
                            </div>
                        </div>
                        <template v-if="isAdmin">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Creative Amount</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-pound-sign"></i>
                                    </div>
                                    <input v-model="form.creative_amount" :class="{'error' : form.errors?.creative_amount}" placeholder="Price"
                                           type="text">
                                </div>
                            </div>
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Member Amount</label>
                                <div class="input-holder input-holder--icon">
                                    <div class="input-holder-icon">
                                        <i class="fas fa-pound-sign"></i>
                                    </div>
                                    <input v-model="form.member_amount" :class="{'error' : form.errors?.member_amount}" placeholder="Price"
                                           type="text">
                                </div>
                            </div>
                        </template>
                        <div class="input-wrapper mb-5">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">Notes</label>
                                <textarea v-model="form.notes" :class="{'error' : form.errors?.notes}" name="notes" rows="5"></textarea>
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">File</label>
                            <div class="input-holder">
                                <input type="file" @input="form.file = $event.target.files[0]" :class="{'error' : form.errors?.expiry_date}" />
                            </div>
                        </div>

                        <div v-if="isAdmin && canEditBlock">
                            <div class="input-wrapper mb-5 mt-5">
                                <label class="input-label">Edit Block</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="form.edit_block">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div v-if="form.edit_block" class="alert-box alert-box--error mt-10 mb-10">
                                <div class="alert-box-icon">
                                    <i class="fas fa-info-circle"></i>
                                </div>
                                <p>This action will update all of the Bookings within the Block.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" :value="hasExpense ? 'Update' : 'Add Expense'">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    expense: {
        type: Object,
        default: null
    },
    isAdmin: {
        type: Boolean,
        default: false
    },
    canEditBlock: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['closed'])

const hasExpense = computed(() => props.expense !== null)

const form = useForm({
    title: props.expense?.title ?? null,
    price: props.expense?.price_raw ?? null,
    notes: props.expense?.notes ?? null,
    creative_amount: props.expense?.creative_amount_raw ?? null,
    member_amount: props.expense?.member_amount_raw ?? null,
    file: null,
    edit_block: false,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty && form.title && form.price
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})
</script>
