<template>
    <div class="user-page">
        <div class="card card--user">
            <div class="logo w-24 h-24 mx-auto">
                <img :src="logoUrl" alt="">
            </div>
            <div class="pb-12"></div>
            <div class="text-center mb-14">
                <h3 class="font-semibold">Select Subscription Package</h3>
            </div>
            <a href="/member/subscribe/home" class="btn btn--primary w-full">Home - £{{ homePrice }} / year</a>
            <div class="pt-10"></div>
            <a href="/member/subscribe/town" class="btn btn--primary w-full">Town - £{{ townPrice }} / year</a>
            <div class="pt-10"></div>
            <div class="sep-text">
                <span class="sep-line"></span>
            </div>
            <div class="text-center">
                <InertiaLink as="button" class="text-primary" href="/logout" method="post">Log out</InertiaLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import useRole from "@composables/useRole"

const props = defineProps({
    homePrice: {
        type: String,
        required: true
    },
    townPrice: {
        type: String,
        required: true
    },
})
const {logoUrl} = useRole('member')
</script>

<style lang="postcss">
.user-page {
    @apply h-screen w-full flex flex-wrap items-center justify-center overflow-y-auto p-5;
}

.card--user {
    @apply xl:w-3/12 lg:w-6/12 md:w-8/12 w-full;
}

.sep-text {
    @apply flex items-center my-5 text-gray-400;
}

.sep-line {
    @apply h-0.5 block bg-gray-100 flex-1;
}
</style>
