<template>
  <div class="partners-div" v-if="partners.length > 0">
    <h2 class="text-center mb-12">Clients & Partners</h2>
    <Swiper class="partners-slider" v-bind="settings">
      <SwiperSlide v-for="(item, index) in partners" :key="index">
        <a :href="item.url" target="_blank"><img class="partners-slide" :src="'/img/web/'+item.logo"></a>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

const partners = [
    {
        name: 'Working Title',
        url: 'https://www.workingtitlefilms.com/',
        logo: 'partners_1.jpg',
    },
    {
        name: 'TheConduit',
        url: 'https://www.theconduit.com',
        logo: 'partners_2.jpg',
    },
    {
        name: 'Accor Hotels',
        url: 'https://group.accor.com',
        logo: 'partners_3.jpg',
    },
    {
        name: 'Royal Court',
        url: 'https://royalcourttheatre.com/',
        logo: 'partners_4.jpg',
    },
    {
        name: 'Onefinestay',
        url: 'https://www.onefinestay.com/',
        logo: 'partners_5.jpg',
    },
    {
        name: 'the PLUM GUIDE',
        url: 'https://www.plumguide.com',
        logo: 'partners_6.jpg',
    },
    {
        name: 'HBO',
        url: 'https://www.hbo.com/',
        logo: 'partners_7.jpg',
    },
    {
        name: 'BBC',
        url: 'https://www.bbc.com/',
        logo: 'partners_8.jpg',
    },
    {
        name: 'ATG',
        url: 'https://www.atg.co.uk',
        logo: 'partners_9.jpg',
    },
    {
        name: 'DOMVS',
        url: 'https://www.domus21suites.com',
        logo: 'partners_10.jpg',
    },
    {
        name: '45 PARK LANE',
        url: 'https://www.dorchestercollection.com',
        logo: 'partners_11.jpg',
    },
]

const settings = {
    modules: [Autoplay],
    speed: 5000,
    autoplay: {
        delay: 1000
    },
    loop: true,
    slidesPerView: 3,
    initialSlide: 0,
    breakpoints: {
        320: {
            slidesPerView: 1
        },
        1024: {
            slidesPerView: 3
        }
    }
}

</script>

<style scoped>
.partners-slide {
  max-height: 100px !important;
  display: block !important;
  margin: auto;
}
</style>
