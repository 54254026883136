<template>
    <MemberAccountFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Notifications</h4>
                </div>
                <div class="col text-right">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-10"></div>
            <div v-for="(notification, index) in items" :key="index">
                <div class="p-4 rounded flex bg-gray-50 justify-between items-center mb-6 gap-5">
                    <div class="col">
                        <div class="font-semibold leading-1">{{ notification.text }}</div>
                        <span class="text-gray-400">{{ notification.created_at }}</span>
                        <div class="mt-5 font-normal">{{ notification.subtext }}</div>
                    </div>
                    <div v-if="notification.action_url && notification.action_label" class="col">
                        <InertiaLink :href="notification.action_url" class="btn btn--sm btn--circle" style="width: 100px;">{{ notification.action_label }}</InertiaLink>
                    </div>
                </div>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue"
import usePaginator from "@composables/usePaginator"
import MemberAccountFilters from "@components/member/MemberAccountFilters"

const apiUrl = computed(() => {
    return '/member/account/notifications/api'
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
<style lang="postcss">
</style>
