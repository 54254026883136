<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <NotesList
                :notes="member.notes"
                :show-add="!member.is_archived"
                @add="showNoteForm"
            />
        </template>
        <template v-slot:extra>
            <div id="add-note-form">
                <NotesAdd
                    v-if="showingNoteForm"
                    :url="'/admin/users/members/' + member.id + '/add-note'"
                    @closed="hideNoteForm"
                />
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import NotesList from "@components/utilities/NotesList"
import NotesAdd from "@components/utilities/NotesAdd"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const { showing: showingNoteForm, show: showNoteForm, hide: hideNoteForm } = useToggleable('add-note-form')

</script>
