<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Expenses</h5>
                    </div>
                    <div v-if="booking.can_edit" class="col">
                        <button @click="showExpenseForm" class="btn btn--icon"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
                <div class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Title</th>
                            <th>Price</th>
                            <th>Creative Pays</th>
                            <th>Member Pays</th>
                            <th>Receipt</th>
                            <th>Notes</th>
                        </tr>
                        <tr v-for="(expense, index) in booking.finances.expenses" :key="index">
                            <td>
                                <div class="text">{{ expense.title }}</div>
                            </td>
                            <td>
                                <div class="">{{ expense.price }}</div>
                            </td>
                            <td>
                                <div class="">{{ expense.creative_amount }}</div>
                                <span v-if="expense.creative_amount_percentage" class="text-gray-400 font-normal">({{ expense.creative_amount_percentage }}%)</span><br>
                            </td>
                            <td>
                                <div class="">{{ expense.member_amount }}</div>
                                <span v-if="expense.member_amount_percentage" class="text-gray-400 font-normal">({{ expense.member_amount_percentage }}%)</span><br>
                            </td>
                            <td>
                                <a v-if="expense.receipt" :href="expense.receipt" target="_blank" class="text-primary">View Receipt</a>
                                <span v-else>-</span>
                            </td>
                            <td>
                                <div class="">{{ expense.notes }}</div>
                            </td>
                            <td v-if="booking.can_edit" class="!text-center">
                                <button @click="editExpense(expense)" class="btn btn--icon btn--primary"><i class="fas fa-edit"></i></button>
                                <button @click="removeExpense(expense.id)" class="btn btn--icon btn--danger ml-3"><i class="fas fa-trash"></i></button>
                            </td>
                        </tr>
                        <tfoot>
                        <tr>
                            <td>Total</td>
                            <td style="text-align: left">{{ booking.finances?.expenses_total }}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="add-expense-form">
                <div v-if="showingExpenseForm">
                    <div class="mt-10"></div>
                    <ExpenseForm
                        :url="'/admin/bookings/' + booking.id + '/expenses/add'"
                        :is-admin="true"
                        :can-edit-block="booking.block?.id !== undefined"
                        @closed="hideExpenseForm"
                    />
                </div>
            </div>
            <div id="edit-expense-form">
                <div v-if="showingEditExpenseForm">
                    <div class="mt-10"></div>
                    <ExpenseForm
                        :url="'/admin/bookings/' + booking.id + '/expenses/' + activeExpense.id + '/edit'"
                        :is-admin="true"
                        :expense="activeExpense"
                        @closed="hideEditExpenseForm"
                    />
                </div>
            </div>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {Inertia} from "@inertiajs/inertia";
import ExpenseForm from "@components/utilities/ExpenseForm"
import {ref} from "vue";

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingExpenseForm, show: showExpenseForm, hide: hideExpenseForm } = useToggleable('add-expense-form')
const removeExpense = (expenseId) => {
    Inertia.post('/admin/bookings/' + props.booking.id + '/expenses/' + expenseId + '/remove')
}

const { showing: showingEditExpenseForm, show: showEditExpenseForm, hide: hideEditExpenseForm } = useToggleable('edit-expense-form')

const activeExpense = ref(null)

const editExpense = (expense) => {
    activeExpense.value = expense
    showEditExpenseForm()
}
const closeEditExpense = () => {
    activeExpense.value = null
    hideEditExpenseForm()
}
</script>
