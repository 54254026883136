<template>
    <div class="container">
        <div class="pricing-header text-left mb-10">
            <h2 class="text-left mb-12">Fees</h2>
            <div class="message">
                We operate on a membership + hourly pricing model. Instead of charging per booking, we offer a monthly or annual all-inclusive membership fee. Our hourly charge for childcare varies depending on the number of children and your sitter’s experience.
            </div>
            <div class="message mt-10">
                All bookings and payments are management by us on your behalf, saving you the hassle and expense of employing your sitter directly.
            </div>
        </div>
        <div class="spacer20"></div>
        <div class="pricing-table-wrapper">
            <div class="flex flex-col gap-5 items-center md:flex-row">
                <div class="pricing-col md:w-1/2">
                    <div class="border border-gray-200 rounded-lg">
                        <div class="py-5 px-8 text-center border-b border-gray-200 text-xl font-semibold rounded-t-lg">Monthly</div>
                        <div class="py-5 px-6">
                            <div class="pricing-table__price flex justify-center gap-2">
                                <div class="super self-start text-xl">£</div>
                                <div class="text-6xl">27.50</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-col md:w-1/2">
                    <div class="border border-gray-200 rounded-lg" style="border-color: #A1EDE3; border-width: 3px;">
                        <div class="py-5 px-8 text-center border-b border-gray-200 text-xl font-semibold rounded-t-lg" style="border-color: #A1EDE3">Paid annually</div>
                        <div class="py-5 px-6">
                            <div class="pricing-table__price flex justify-center gap-2">
                                <div class="super self-start text-xl">£</div>
                                <div class="text-6xl">289</div>
                            </div>
                            <div class="self-end text-lg text-center text-gray-500 mt-10">(20% discount)</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-wrapper text-center mt-12 mb-16">
            <a class="btn btn--outline btn--rounded nb-pop-up-button" href="mailto:london@beaandco.com?subject=New%20Home%20Childcare%20Enquiry">Join</a>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";

const toggleShowMore = function () {
    this.showMore = !this.showMore
}
const showMore = ref(true)

</script>

<style scoped>

.nb-pop-up-button {
    @apply shadow-xl text-xl;
    width: 275px
}
</style>
