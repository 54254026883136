<template>
    <div class="page-filters-section mb-10 lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/creative/profile/view" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/view')}">Overview</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/profile/addresses" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/addresses')}">Addresses</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/profile/images" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/images')}">Images</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/profile/about" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/about')}">About</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/profile/relations" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/relations')}">Relations</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/profile/team" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/team')}">Team</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/profile/skills" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/profile/skills')}">Skills</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>

</style>
