<template>
	<div class="banner" :style="{ backgroundImage: 'url(/img/web/' + image + ')' }">
		<div class="text-center">
			<div v-if="logo">
				<div class="spacer50"></div>
				<div class="banner-logo">
					<img src="/img/web/logo.png" alt="">
				</div>
			</div>
			<div class="banner-title" :class="{'mb-12' : logo}">{{ title }}</div>
		</div>
		<div class="banner-bg">
            <div class="banner-slide" :style="{ backgroundImage: 'url(/img/web/' + image + ')' }"></div>
		</div>
	</div>
</template>
<script>
	// import VueSlickCarousel from 'vue-slick-carousel'
	// import 'vue-slick-carousel/dist/vue-slick-carousel.css'

	export default {
		props: {
			logo: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: null
			},
            image: {
                type: String,
                default() {
                    return 'logo-dark.png'
                }
            },
		},
		data() {
			return {
			}
		},
		// components: { VueSlickCarousel },
	}
</script>
<style lang="scss" scoped>
	.banner {position: relative; display: flex; align-items: center; justify-content: center; overflow-y: hidden; padding: 150px 0 0; height: 100%; min-height: 350px; background-size: cover; background-position: center center;
		&:after {content:''; background: rgba(21, 21, 21, 0.3); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1;}
		.banner-bg {position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;}
		.banner-logo {position: relative; z-index: 2; max-width: 120px; margin: 0 auto 50px;}
		.banner-title {max-width: 860px; position: relative; z-index: 2; opacity: 0.8; color: #fff; font-weight: 700; font-size: 60px; text-align: center; line-height: 1.1;
			@media(max-width: 767px) {font-size: 90px;}
			@media(max-width: 699px) {font-size: 70px;}
			@media(max-width: 599px) {font-size: 60px;}
			@media(max-width: 499px) {font-size: 50px;}
			@media(max-width: 374px) {font-size: 40px;}
		}
	}
</style>
