<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/system-settings/rates" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/rates')}">Rates</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/members-team-review-frequency" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/members-team-review-frequency')}">Frequency</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/tags" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/tags')}">Tags</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/notifications')}">Alerts</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Members Team Review Frequency</h4>
                </div>
                <div class="col">
                    Last Update: <span class="font-semibold">{{ setting.updated_at }}</span>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="form.post('/admin/system-settings/members-team-review-frequency')">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Select Frequency</label>
                            <select name="value" v-model="form.value"  :class="{'error' : form.errors.value}">
                                <option disabled>Select Frequency</option>
                                <option value="weekly">Weekly</option>
                                <option value="fortnightly">Fortnightly</option>
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="bi-annually">Bi-Annually</option>
                                <option value="annually">Annually</option>
                            </select>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors" />
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Submit">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {computed} from "vue"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    setting: {
        type: Object,
        required: true
    }
})

const form = useForm({
    value: props.setting.value,
})

const canSubmit = computed(() => {
    return !form.processing && form.value !== ''
})


</script>

<style lang="postcss">
body {
}
</style>
