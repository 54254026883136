<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/system-settings/rates" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/rates')}">Rates</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/members-team-review-frequency" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/members-team-review-frequency')}">Frequency</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/tags" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/tags')}">Tags</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/system-settings/notifications" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/system-settings/notifications')}">Alerts</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div class="page-filters-section">
        <div class="filter-bar">
            <AdminSystemSettingsTagsPages />
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <form action="">
                            <input type="text" v-model="searchTerm">
                            <button :disabled="true">
                                <i class="fas fa-search"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Types</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Name</th>
                        <th>Categories Count</th>
                        <th>Tags Count</th>
                        <th></th>
                    </tr>
                    <tr v-for="(type, index) in items" :key="index">
                        <td>
                            <div class="text-gray-600">{{ type.name }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ type.categories_count }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ type.tags_count }}</div>
                        </td>
                        <td>
                            <InertiaLink :href="'/admin/system-settings/tags/types/edit/' + type.id" class="btn btn--sm btn--nowrap">
                                <i class="fas fa-pen"></i>
                                Edit
                            </InertiaLink>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AdminSystemSettingsTagsPages from "@components/admin/AdminSystemSettingsTagsPages"
import usePaginator from "@composables/usePaginator"
import {computed, ref} from "vue";

const searchTerm = ref(null)

const apiUrl = computed(() => {
    if (searchTerm.value !== null) {
        return '/admin/system-settings/tags/types/list?search=' + searchTerm.value
    } else {
        return '/admin/system-settings/tags/types/list'
    }
})

const {items, hasMore, loadMore, fetchItems } = usePaginator(apiUrl)

fetchItems(false)


</script>

<style lang="postcss">
</style>
