<template>
    <div class="page-wrapper">
        <div class="sidebar-wrapper">
            <perfect-scrollbar class="sidebar-wrapper-inner">
                <sidebar></sidebar>
            </perfect-scrollbar>
        </div>
        <div class="page-contents">
            <div class="page-top mt-5">
                <div class="page-header">
                    <div class="flex justify-between items-center">
                        <div class="col">
                            <div class="page-heading">
                                <h3>{{ title }}</h3>
                                <p v-html="subtitle"></p>
                            </div>
                        </div>
                        <div class="col hidden lg:block">
                            <div class="flex">
                                <div class="col">
                                    <InertiaLink :href="'/' + role + '/account/details'">
                                        <div class="avatar avatar--sm mx-auto" v-bind:style="{ backgroundImage: 'url('+avatar+')' }"></div>
                                    </InertiaLink>
                                </div>
                                <div class="col pl-2">
                                    <InertiaLink :href="'/' + role + '/account/notifications'" as="button" class="btn--icon btn--secondary btn--circle">
                                        <i class="fas fa-bell"></i>
                                    </InertiaLink>
                                </div>
                                <div class="col pl-2">
                                    <InertiaLink href="/logout" method="post" as="button" class="btn--icon btn--secondary btn--circle">
                                        <i class="fas fa-sign-out-alt"></i>
                                    </InertiaLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-middle mt-10">
                <slot></slot>
            </div>
            <div class="page-bottom">
                <div class="footer">
                    <p>© {{ new Date().getFullYear() }} BEAUK HOLDINGS LTD.
<!--                        Powered by <a href="https://www.net-buddies.com/" target="_blank">Net Buddies</a>-->
                    </p>
                </div>
            </div>
        </div>
        <div class="actionbar-spacer"></div>
        <div class="action-bar" style="z-index: 9999">
            <div class="action-bar-nav flex justify-between text-center">
                <div class="col" v-for="(nav, index) in actionNav" :key="index">
                    <InertiaLink :href="nav.url" :class="{'active': $page.url.startsWith(nav.url)}">
                        <i :class="nav.icon"></i> <br />
                        <span class="text-sm">{{ nav.label }}</span>
                        <span
                            v-if="nav.label === 'Account' && $page.props?.auth?.user?.notifications"
                            class="bg-secondary text-white rounded-full text-sm"
                            style="border-radius: 50%; padding: 2px; width: 20px; height: 20px; position:absolute;top: 10px;right: 2px;"
                        >
                                    {{ $page.props.auth.user.notifications > 99 ? '99+' : $page.props.auth.user.notifications }}
                                </span>
                    </InertiaLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue"
import { Link, usePage } from "@inertiajs/inertia-vue3"
import Sidebar from '@components/layout/Sidebar.vue'
import useRole from "@composables/useRole"
//import { ref } from 'vue'
//const collapsed = ref(true);

const { role, isAdmin, isCreative, isMember } = useRole(null)

const avatar = computed(() => {
    return usePage().props?.value?.auth?.user?.avatar
});

const adminActionNav = [
    {icon:'fas fa-users', label: 'Users', url:'/admin/users'},
    {icon:'fas fa-calendar-alt', label: 'Bookings', url:'/admin/bookings'},
    {icon:'fas fa-coins', label: 'Finance', url:'/admin/finance'},
    {icon:'fas fa-cog', label: 'Settings', url:'/admin/system-settings'},
    {icon:'fas fa-exclamation-circle', label: 'Account', url:'/admin/account'},
];
const creativeActionNav = []

if (usePage().props?.value?.auth?.user?.is_approved) {
    creativeActionNav.push({icon:'fas fa-calendar-alt', label: 'Bookings', url:'/creative/bookings'})
}
creativeActionNav.push(
    {icon:'fas fa-user', label: 'Profile', url:'/creative/profile'},
    {icon:'fas fa-cog', label: 'Account', url:'/creative/account'}
)

const memberActionNav = [
    {icon:'fas fa-calendar-alt', label: 'Bookings', url:'/member/bookings'},
    {icon:'fas fa-user', label: 'Profile', url:'/member/profile'},
    {icon:'fas fa-cog', label: 'Account', url:'/member/account'}
];

const actionNav = isAdmin.value ? adminActionNav : (isCreative.value ? creativeActionNav : memberActionNav)
const title = computed(() => usePage().props.value.title ?? 'Welcome back')
const subtitle = computed(() => usePage().props.value.subtitle)

</script>

<style lang="postcss">
.page-wrapper {
    transition:width 333ms;
    @apply pl-0 lg:pl-72 min-h-full;
}
.page-wrapper.sidebar-collapsed {
    @apply lg:pl-24;
}
.sidebar-wrapper {
    transition:width 333ms;
    @apply w-72 fixed left-0 top-0 h-full bg-white overflow-hidden hidden lg:block;
}
.sidebar-wrapper-inner {
    @apply h-full overflow-y-auto overflow-x-hidden ;
}
.page-contents {
    min-height:100vh;
    @apply flex flex-col;
}
.page-middle {
    @apply flex-1
}
.footer {
    @apply py-4 text-gray-500 text-sm mt-10 text-center
}
@media(max-width:1399px) and (min-width: 1023px) {
    .page-wrapper {
        @apply pl-24;
    }
    .sidebar-wrapper {
        @apply w-24
    }
}

.action-bar-nav .col a{
    @apply text-primary;
}
.action-bar-nav .col a.active{
    @apply text-black;
}

.action-bar {
    @apply fixed bottom-0 left-0 w-full bg-white lg:hidden py-2 px-4;
}
.action-bar .col a {
    @apply block px-3 py-3 text-xl text-center rounded-lg hover:text-black transition;
}
.actionbar-spacer {
    @apply h-28 lg:hidden;
}
</style>
