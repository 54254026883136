<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/users/admins" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/admins')}">Admins</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/creatives" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/creatives')}">Creatives</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/members" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/members')}">Members</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/children" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/children')}">Children</InertiaLink>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <div class="flex">
                        <div class="col">
                            <InertiaLink href="/admin/users/members/create" class="btn">
                                <i class="fas fa-plus"></i>
                                Add Member
                            </InertiaLink>
                        </div>
                        <div class="col pl-2">
                            <InertiaLink href="/admin/users/broadcast/past" class="btn btn--secondary">
                                <i class="fas fa-plus"></i>
                                Broadcast
                            </InertiaLink>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <input v-model="searchTerm" type="text">
                        <button type="input">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchStatus">
                            <option value="" disabled="" selected>Status</option>
                            <option value="active">Active</option>
                            <option value="archived">Archived</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchVipStatus">
                            <option value="" disabled="" selected>VIP Status</option>
                            <option value="all">All</option>
                            <option value="vips">VIPs</option>
                            <option value="non-vips">Non-vips</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-check"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Members</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Name</th>
                        <th>Primary User</th>
                        <th>Primary Address</th>
                        <th>Payment Plan</th>
                        <th>Payment Method</th>
                        <th>Rate Tier</th>
                        <th>Reminder Period</th>
                    </tr>
                    <tr v-for="(member, index) in items" :key="index">
                        <td>
                            <InertiaLink :href="'/admin/users/members/' + member.id">
                                {{ member.name }} <i v-if="member.is_vip" class="fas fa-check-circle primary"></i>
                            </InertiaLink>
                        </td>
                        <td>
                            <div class="">{{ member.primary_user?.name }}</div>
                            <span class="text-gray-400 font-normal">{{ member.primary_user?.email }}</span><br>
                            <span class="text-gray-400 font-normal">{{ member.primary_user?.phone }}</span>
                        </td>
                        <td>
                            <div class="whitespace-pre-wrap">{{ member.primary_address }}</div>
                        </td>
                        <td>
                            <div class="text-gray-400 font-normal">{{ startCase(toLower(member.payment_plan)) }}</div>
                        </td>
                        <td>
                            <div class="text-gray-400 font-normal">{{ startCase(toLower(member.payment_method)) }}</div>
                        </td>
                        <td>
                            <div class="text-gray-400 font-normal">{{ member.member_rate_label ?? '£' + member.fixed_rate }}</div>
                        </td>
                        <td>
                            <div class="text-gray-400 font-normal">{{ member.reminder_period }}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import usePaginator from "@composables/usePaginator"
import {startCase, toLower} from "lodash"

const searchTerm = ref("")
const searchStatus = ref("active")
const searchVipStatus = ref("")

const apiUrl = computed(() => {
    let params = new URLSearchParams();
    if (searchTerm.value !== "") {
        params.append('terms', encodeURIComponent(searchTerm.value))
    }
    if (searchStatus.value === "active" || searchStatus.value === "archived") {
        params.append('status', encodeURIComponent(searchStatus.value))
    }
    if (searchVipStatus.value === "vips" || searchVipStatus.value === "non-vips") {
        params.append('vip_status', encodeURIComponent(searchVipStatus.value))
    }
    let suffix = params.toString()
    return '/admin/users/members/api' + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)
fetchItems(false)

</script>

<style lang="postcss">
</style>
