<template>
    <footer class="footer" style="background-color: #f7f7f7">
        <div class="container">
            <div class="footer_row">
                <div class="footer_col">
                    <a class="footer-logo" href="#"><img alt="" src="/img/web/logo-dark.png" style="margin: 0 auto;"></a>
                </div>
                <div class="footer_col">
                    <ul class="footer-nav items-center">
                        <li><a href="/privacy">Privacy</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/home-childcare">Home Childcare</a></li>
                        <li><a href="/hotels-and-homestay">Hotels & Homestay</a></li>
                        <li><a href="/apply">Careers</a></li>
                    </ul>
                </div>
                <div class="footer_col">
                    <ul class="social">
                        <li><a href="https://www.facebook.com/beaandcouk" target="_blank"><img
                            alt="" src="/img/web/facebook.svg"></a></li>
                        <li><a href="https://twitter.com/beacoUK" target="_blank"><img alt=""
                                                                                       src="/img/web/twitter.svg"></a></li>
                        <li><a href="https://www.instagram.com/beacouk/" target="_blank"><img
                            alt="" src="/img/web/instagram-logo.svg"></a></li>
                    </ul>
                </div>
            </div>
            <div class="inner text-center">
                &copy; {{ new Date().getFullYear() }} Bea & Co.
            </div>
        </div>
    </footer>
</template>

<script setup>
</script>

<style scoped>
@media(max-width: 767px) {
    .footer-nav {
        margin-bottom: 12px !important;
    }
}
</style>
