<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ title }}</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1">
                    <div class="col">
                        <div class="input-wrapper">
                            <h6 class="sub-title">Tags</h6>
                            <TagPicker v-model="tags" :allow-create="false" />
                        </div>
                        <div class="p-5"></div>
                        <div class="input-wrapper mb-5">
                            <h6 class="sub-title">Select</h6>
                            <Multiselect
                                ref="selector"
                                v-model="form.users"
                                mode="tags"
                                :placeholder="'Select ' + target"
                                :filterResults="true"
                                :createTag="false"
                                :minChars="0"
                                :resolveOnLoad="true"
                                :delay="0"
                                :searchable="true"
                                :object="true"
                                trackBy="name"
                                label="name"
                                :options="async function(query) {
                                  return await fetchUsers(query)
                                }"
                            >
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag p-10" :class="option.is_flagged ? 'multiselect-tag--not-approved' : (option.is_star ? 'multiselect-tag--admin' : '')">
                                        <img v-if="showAvatars" class="user-image" :src="option.avatar"> {{ option.name }}  <i v-if="option.is_star" class="fas fa-star text-white ml-1"></i> <i v-if="option.is_flagged" class="fas fa-exclamation-circle text-white"></i>
                                        <span
                                            v-if="!disabled"
                                            class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                        >
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <div class="btn btn--sm btn" :class="option.is_flagged ? 'multiselect-tag--not-approved' : (option.is_star ? 'multiselect-tag--admin' : '')">
                                        <img v-if="showAvatars" class="user-image" :src="option.avatar"> {{ option.name }}  <i v-if="option.is_star" class="fas fa-star text-white ml-1"></i> <i v-if="option.is_flagged" class="fas fa-exclamation-circle text-white"></i>
                                    </div>
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted, ref, watch} from "vue"
import Multiselect from '@vueform/multiselect'
import axios from "axios";
import TagPicker from "@components/utilities/TagsPicker"

const props = defineProps({
    title: {
        type: String,
        default: 'Edit Team'
    },
    target: {
        type: String,
        default: 'Users'
    },
    showAvatars: {
        type: Boolean,
        default: true
    },
    sourceUrl: {
        type: String,
        required: true
    },
    url: {
        type: String,
        required: true
    },
    users: {
        type: Array,
        required: true
    }
})

const selector = ref(null)

const tags = ref([])
const searchTags = computed(() => tags.value?.map(tag => tag.id))

const users = ref([])

const emits = defineEmits(['closed'])

const form = useForm({
    users: props.users,
})
const canSubmit = computed(() => {
    return !form.processing && form.isDirty
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})

watch(searchTags, () => selector.value?.refreshOptions())

const fetchUsers = async (terms) => {
    let options = []

    let url = props.sourceUrl

    let params = new URLSearchParams();

    if (terms) {
        params.append('terms', encodeURIComponent(terms))
    }
    if (searchTags.value?.length) {
        params.append('tags', encodeURIComponent(searchTags.value))
    }

    let suffix = params.toString()
    url +=  suffix ? '?' + suffix : ''

    await axios.get(url)
        .then((resp) => {
            options = resp.data.users
        })
        .catch((err) => {
            console.log(err)
        })

    return options
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="postcss">
.multiselect-tag {
     @apply bg-primary;
 }
.multiselect-tag--not-approved {
     @apply bg-secondary-100 hover:bg-secondary-300 text-light;
 }
.multiselect-tag--admin {
     @apply bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600;
 }
.user-image {
    height: 22px;
    margin: 0 6px 0 0;
    border-radius: 50%;
    display: inline;
}
</style>
