<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/users/admins" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/admins')}">Admins</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/creatives" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/creatives')}">Creatives</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/members" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/members')}">Members</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/children" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/children')}">Children</InertiaLink>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <div class="flex">
                        <div v-if="isSuperAdmin" class="col">
                            <InertiaLink href="/admin/users/admins/create" class="btn">
                                <i class="fas fa-plus"></i>
                                Create Admin
                            </InertiaLink>
                        </div>
                        <div class="col pl-2">
                            <InertiaLink href="/admin/users/broadcast/past" class="btn btn--secondary">
                                <i class="fas fa-plus"></i>
                                Broadcast
                            </InertiaLink>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <input v-model="searchTerm" type="text">
                        <button type="input">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchType">
                            <option value="" disabled="" selected>Type</option>
                            <option value="all">All Types</option>
                            <option value="admin">Standard</option>
                            <option value="super admin">Super</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-user"></i>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchAccessStatus">
                            <option value="" disabled="" selected>Access Status</option>
                            <option value="all">All Access Statuses</option>
                            <option value="locked">Locked</option>
                            <option value="access">Access</option>
                            <option value="archived">Archived</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Admins</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th style="text-align: center">Status</th>
                        <th>Last Login</th>
                    </tr>
                    <tr v-for="(user, index) in items" :key="index">
                        <td>
                            <InertiaLink :href="!isSuperAdmin ? '#' : '/admin/users/admins/' + user.id">
                                <div class="avatar avatar--sm" v-bind:style="{ backgroundImage: 'url(' + user.avatar + ')' }"></div>
                            </InertiaLink>
                        </td>
                        <td>
                            <InertiaLink :href="!isSuperAdmin ? '#' : '/admin/users/admins/' + user.id">
                                <div class="">{{ user.name }}</div>
                                <span class="text-gray-400 font-normal">{{ user.formatted_role }}</span>
                            </InertiaLink>
                        </td>
                        <td>
                            <div class="">{{ user.email }}</div>
                        </td>
                        <td style="text-align: center">
                            <UserListAccessStatusIcon :type="user.is_archived ? 'archived' : (user.is_locked_out ? 'locked' : 'access')" />
                        </td>
                        <td>
                            <div class="text-gray-600">{{ user.last_login }}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from "vue"
import usePaginator from "@composables/usePaginator"
import useRole from "@composables/useRole"
import UserListAccessStatusIcon from "@components/utilities/UserListAccessStatusIcon"

const searchTerm = ref("")
const searchType = ref("")
const searchAccessStatus = ref("")

const apiUrl = computed(() => {
    let params = new URLSearchParams();
    if (searchTerm.value !== "") {
        params.append('terms', encodeURIComponent(searchTerm.value))
    }
    if (searchType.value === "admin" || searchType.value === "super admin") {
        params.append('type', encodeURIComponent(searchType.value))
    }
    if (searchAccessStatus.value === "locked" || searchAccessStatus.value === "access" || searchAccessStatus.value === "archived") {
        params.append('access_status', encodeURIComponent(searchAccessStatus.value))
    }
    let suffix = params.toString()
    return '/admin/users/admins/api' + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)
fetchItems(false)

const { isSuperAdmin } = useRole(null)

</script>

<style lang="postcss">
</style>
