<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:default>
            <UsersList
                :users="booking.children?.details"
                title="Children"
                url-prefix="/admin/users/children/"
                :show-add="booking.can_edit"
                @add="showChildForm"
                :show-remove="booking.can_edit"
                @remove="removeChild"
            />
        </template>
        <template v-slot:extra>
            <div id="add-child-form">
                <div v-if="showingChildForm">
                    <div class="mt-10"></div>
                    <BookingChildAdd
                        :url="'/admin/bookings/' + booking.id + '/children/add'"
                        :children="otherMemberChildren"
                        @closed="hideChildForm"
                        title="Add Child"
                        :can-edit-block="booking.block?.id !== undefined"
                    />
                </div>
            </div>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import BookingChildAdd from "@components/utilities/BookingChildAdd"
import UsersList from "@components/utilities/UsersList"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    booking: {
        type: Object,
        required: true
    },
    otherMemberChildren: {
        type: Array,
        default: null
    }
})

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('add-child-form')
const removeChild = (child) => {
    if (child?.booking_child_id) {
        Inertia.post('/admin/bookings/' + props.booking.id + '/children/' + child.booking_child_id + '/remove')
    }
}
</script>
