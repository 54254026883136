<template>
    <div class="relative inline-block text-left z-30 w-64">
        <div>
            <button @focus="dropdown = true" @blur="blurred" type="button" class="relative w-full py-1.5 px-4 rounded-md inline-block cursor-pointer hover:bg-gray-50 hover:shadow transition-all font-medium bg-white flex justify-between shadow items-center" id="menu-button" aria-expanded="true" aria-haspopup="true">
                {{ activeValue?.label }}
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <div v-if="dropdown" class="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                    <div class="text-gray-700 cursor-pointer block px-4 py-2 text-sm hover:bg-gray-50" v-for="(item, index) in filters" :key="item.value" :class="{'bg-gray-50': item.value === modelValue}" @click="updateFilter(item)">
                        <slot :item="item">{{item.label}}</slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    filters: {
        type: Array,
        required: true
    },
    modelValue: {
        type: String,
        default: ""
    },
})


const emit = defineEmits(['update:modelValue'])

const activeValue = computed(() => props.filters.find(f => f.value === props.modelValue))

const dropdown = ref(false);

const updateFilter = (item) => {
    emit('update:modelValue', item.value)
    dropdown.value = false;
}

const blurred = () => {
    setTimeout(() => dropdown.value = false, 100)
}

</script>
