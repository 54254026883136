<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div v-show="!showingAvailabilityForm">
                <DailyAvailabilityList :schedule="user.availability?.weekly" @edit="showAvailabilityForm" />
                <div class="mt-10">
                    <div class="flex flex-wrap items-center justify-between gap-4">
                        <div class="col">
                            <h5 class="sub-title">Unavailable Periods</h5>
                        </div>
                        <div v-if="!user.is_archived" class="col">
                            <button @click="showAddUnavailablePeriodForm" class="btn btn--icon"><i class="fas fa-plus"></i></button>
                        </div>
                    </div>
                    <div v-if="user.availability?.unavailable_periods.length" class="table-wrapper">
                        <table class="table">
                            <tr>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Duration</th>
                                <th>Date Added</th>
                            </tr>
                            <tr v-for="(period, index) in user.availability.unavailable_periods" :key="index">
                                <td><div class="text-gray-600 font-normal">{{ period.start_date }}</div></td>
                                <td><div class="text-gray-600 font-normal">{{ period.end_date }}</div></td>
                                <td><div class="text-gray-500 font-extrabold">{{ period.duration }} day{{ (period.duration > 1 ? 's' : '')}}</div></td>
                                <td><div class="text-gray-600 font-normal">{{ period.created_at_short }}</div></td>
                                <td v-if="!user.is_archived">
                                    <button @click="removeUnavailablePeriod(period.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-availability-form">
                <div v-if="showingAvailabilityForm">
                    <DailyAvailabilityForm
                        :schedule="user.availability?.weekly"
                        :url="'/admin/users/creatives/' + user.id + '/availability'"
                        :show-close="true"
                        @closed="hideAvailabilityForm"
                    />
                </div>
            </div>
            <div id="add-unavailable-period-form">
                <div v-if="showingAddUnavailablePeriodForm">
                    <div class="mt-10"></div>
                    <UnavailablePeriodForm
                        :url="'/admin/users/creatives/' + user.id + '/unavailable-periods/add'"
                        @closed="hideAddUnavailablePeriodForm"
                    />
                </div>
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout";
import useToggleable from "@composables/useToggleable"
import UnavailablePeriodForm from "@components/utilities/UnavailablePeriodForm"
import DailyAvailabilityList from "@components/utilities/DailyAvailabilityList"
import DailyAvailabilityForm from "@components/utilities/DailyAvailabilityForm"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { showing: showingAvailabilityForm, show: showAvailabilityForm, hide: hideAvailabilityForm } = useToggleable('edit-availability-form')

const { showing: showingAddUnavailablePeriodForm, show: showAddUnavailablePeriodForm, hide: hideAddUnavailablePeriodForm } = useToggleable('add-unavailable-period-form')
const removeUnavailablePeriod = (unavailablePeriodId) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/unavailable-periods/' + unavailablePeriodId + '/remove', {}, {
        onBefore: () => confirm('Are you sure you want to remove the Unavailable Period?'),
        preserveScroll: true
    })
}

</script>
