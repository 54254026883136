<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Edit Tag</h4>
                    Impacts: <span class="font-semibold">{{ tag.users_count }} Users</span>
                </div>
                <div class="col">
                    Last Update: <span class="font-semibold">{{ tag.updated_at }}</span>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="profile-grid">
                <div class="col p-6">
                    ID
                    <h6 class="font-semibold mt-1">#{{ tag.id }}</h6>
                </div>
                <div class="col p-6">
                    Name
                    <h6 class="font-semibold mt-1">{{ tag.name }}</h6>
                </div>
                <div class="col p-6">
                    Type
                    <h6 class="font-semibold mt-1">{{ tag.type }}</h6>
                </div>
                <div class="col p-6">
                    Category
                    <h6 class="font-semibold mt-1">{{ tag.category }}</h6>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="form.post('/admin/system-settings/tags/tags/edit/' + tag.id)">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Name</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-tags"></i>
                                </div>
                                <input v-model="form.name" :class="{'error' : form.errors.name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Select Tag Type</label>
                            <select name="value" v-model="form.tag_type_id" :class="{'error' : form.errors.tag_type_id}">
                                <option disabled>Select Type</option>
                                <option v-for="(type, index) in types" :key="index" :value="type.id">{{ type.name }}</option>
                            </select>
                        </div>
                        <div v-if="form.tag_type_id" class="input-wrapper mb-10">
                            <label class="input-label">Select Tag Category</label>
                            <select name="value" v-model="form.tag_category_id" :class="{'error' : form.errors.tag_category_id}">
                                <option disabled>Select Category</option>
                                <option v-for="(category, index) in suitableCategories" :key="index" :value="category.id">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Mark as Admin Only</label>
                            <label class="switch">
                                <input type="checkbox" v-model="form.is_admin_only">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Mark as Approved</label>
                            <label class="switch">
                                <input type="checkbox" v-model="form.is_approved">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Remove Tag</h4>
                </div>
            </div>
            <div class="pb-10"></div>
                <div>
                    <p>Please note that this action is irreversible.</p>
                </div>
                <div class="input-submit mt-10">
                    <button @click="remove" :disabled="form.processing" class="btn btn--secondary">Remove Tag</button>
                </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    types: {
        type: Array,
        default: []
    },
    categories: {
        type: Array,
        default: []
    },
    tag: {
        type: Object,
        required: true
    }
})

const suitableCategories = computed(() => {
    return props.categories.filter(cat => cat.tag_type_id === form.tag_type_id)
})

const form = useForm({
    name: props.tag.name,
    tag_type_id: props.tag.tag_type_id,
    tag_category_id: props.tag.tag_category_id,
    is_admin_only: props.tag.is_admin_only,
    is_approved: props.tag.is_approved,
})

const canSubmit = computed(() => {
    return form.isDirty && !form.processing && form.name !== '' && form.tag_type_id !== ''
})

const remove = () => {
    Inertia.post('/admin/system-settings/tags/tags/remove/' + props.tag.id, null, {
        onBefore: () => confirm('Are you sure you want to delete this Tag?')
    })
}


</script>
