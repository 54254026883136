<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <MemberProfileFilters  />
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h3 class="page-title">{{ member.name }}</h3>
                </div>
                <div class="col">
                    <button @click="showChildForm" class="btn">
                        <i class="fas fa-plus"></i>
                        Add Child
                    </button>
                </div>
            </div>
            <UsersList
                :users="member.children"
                title="Children"
                url-prefix="/member/profile/children/"
                :show-add="false"
                name-attr="first_name"
            />
        </div>
    </div>
    <div id="add-child-form">
        <div v-if="showingChildForm">
            <div class="mt-10"></div>
            <UserAdd
                url="/member/profile/children/add"
                :member-id="member.id"
                @closed="hideChildForm"
                title="Add Child"
                :show-title="false"
            />
        </div>
    </div>
</template>

<script setup>
import UsersList from "@components/utilities/UsersList"
import UserAdd from "@components/utilities/UserAdd"
import useToggleable from "@composables/useToggleable";
import MemberProfileFilters from "@components/member/MemberProfileFilters"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('add-child-form')
</script>

<style lang="postcss">
body {
}
</style>
