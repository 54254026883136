<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <div class="mt-10 whitespace-pre-wrap">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Payments</h5>
                    </div>
                    <div class="col">
                        Displaying: <span class="font-semibold">{{ items.length }}</span>
                    </div>
                </div>
                <div class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Total</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        <tr v-for="(invoice, index) in items" :key="index">
                            <td>
                                <div class="text-gray-600">{{ invoice.breakdown?.total }} <span v-if="invoice.notes?.length" class="pl-1"><i class="far fa-sticky-note"></i></span></div>
                                <div class="text-gray-600 font-normal">{{ '(' + invoice.breakdown?.bookings_count + ' Booking' + (invoice.breakdown?.bookings_count > 1 ? 's' : '') + ')' }}</div>
                            </td>
                            <td>
                                <div class="text-gray-600 font-normal">{{ invoice.created_at_timestamp }}</div>
                                <div class="text-gray-600 font-normal" :class="{'text-secondary' : invoice.is_overdue}">{{ invoice.due_at_timestamp }}</div>
                            </td>
                            <td>
                                <div v-if="invoice.status === 'Paid'" class="text-primary">Paid</div>
                                <div v-else-if="invoice.status === 'Cancelled'">Cancelled</div>
                                <div v-else-if="invoice.is_overdue" class="text-secondary">Overdue</div>
                                <div v-else>Pending</div>
                            </td>
                            <td>
                                <div class="flex gap-2">
                                    <div class="col">
                                        <InertiaLink :href="'/admin/finance/invoices/' + invoice.id" class="btn btn--sm btn--nowrap">
                                            <i class="fas fa-pen"></i>
                                            Details
                                        </InertiaLink>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="hasMore">
                    <div class="pt-10"></div>
                    <div class="text-center">
                        <button @click="loadMore" class="btn btn--gray btn--sm">
                            Load More
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout"
import usePaginator from "@composables/usePaginator"
import {ref} from "vue";

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const apiUrl = ref('/admin/users/members/' + props.member.id + '/payments/api')
const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
