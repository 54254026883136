<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col">
                    <a v-if="invoice.file" class="btn" :href="invoice.file" target="_blank">
                        <i class="fas fa-print"></i>
                        Details
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h3 class="page-title">Invoice {{ invoice.id }}</h3>
                </div>
                <div v-if="canEditInvoice" class="col">
                    <button @click="showForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Invoice
                    </button>
                </div>
            </div>
            <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                <div class="col">
                    Created:
                    <span class="font-semibold">{{ invoice.created_at }}</span>
                </div>
                <div class="col">
                    Last Edit:
                    <span class="font-semibold">{{ invoice.updated_at }}</span>
                </div>
            </div>
            <div class="profile-grid">
                <div class="col p-6">
                    Member
                    <h6 class="font-semibold mt-1">{{ invoice.member.name }}</h6>
                </div>
                <div class="col p-6">
                    Total
                    <h6 class="font-semibold mt-1">{{ invoice.breakdown.total }}</h6>
                </div>
                <div class="col p-6">
                    Bookings
                    <h6 class="font-semibold mt-1">{{ invoice.breakdown.bookings_count }}</h6>
                </div>
                <div class="col p-6">
                    Status
                    <h6 class="font-semibold mt-1">{{ invoice.status }} <span v-if="invoice.is_overdue" class="text-secondary">(Overdue)</span></h6>
                </div>
            </div>
            <div class="mt-14">
                <h5 class="sub-title">Dates</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Issued</th>
                            <td>{{ invoice.created_at_timestamp }}</td>
                        </tr>
                        <tr>
                            <th>Due</th>
                            <td>{{ invoice.due_at_timestamp }}</td>
                        </tr>
                        <tr v-if="invoice.status !== 'Cancelled'">
                            <th>Completed</th>
                            <td>{{ invoice.completed_at_timestamp ?? '-' }}</td>
                        </tr>
                        <tr v-else>
                            <th>Cancelled</th>
                            <td>{{ invoice.updated_at_timestamp }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-14">
                <h5 class="sub-title">Financial Breakdown</h5>
                <table class="transaction-table">
                    <tbody>
                    <tr v-for="(booking, bookingIndex) in invoice.breakdown?.bookings" :key="bookingIndex">
                        <th>
                            <InertiaLink :href="'/admin/bookings/' + booking.id">
                                <span v-if="booking.block_id">
                                    Block: {{ booking.block_id }}
                                    <br>
                                </span>
                                {{ booking.id }}
                                <br>
                                {{ booking.start_timestamp }}
                                <br>
                                {{ booking.duration }}
                                <br>
                                Creative: {{ booking.creative_name }}
                            </InertiaLink>
                        </th>
                        <td>
                            £{{ booking.total }}
                            <span v-if="booking.expenses_total">
                                <br>
                                <span class="text-sm text-gray-400 font-normal">(+£{{ booking.expenses_total }})</span>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{{ invoice.breakdown?.total }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <NotesList
                :notes="invoice.notes"
                :show-add="true"
                @add="showNoteForm"
            />
        </div>
    </div>
    <div id="edit-invoice-form">
        <div v-if="showingForm" class="page-card mt-10">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Edit Invoice</h4>
                    </div>
                    <div class="col">
                        <button @click="hideForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="pb-10"></div>
                <form @submit.prevent="submitForm">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-10">
                                <label class="input-label">Status</label>
                                <select name="value" v-model="form.status" :class="{'error' : form.errors.status}">
                                    <option disabled>Select Type</option>
                                    <option v-for="(type, index) in statuses" :key="index" :value="type.value">{{ type.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="form.errors"/>

                    <div class="flex flex-wrap justify-between items-center gap-4">
                        <div class="col">
                            <div class="input-submit mt-10">
                                <input :disabled="form.processing || !form.isDirty" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                         </div>

                        <div v-show="form.status === 'pending'" class="col">
                            <button type="button" @click.stop.prevent="voidInvoice" class="btn bg-red-500 hover:bg-red-300">
                                <i class="fas fa-trash"></i>
                                VOID INVOICE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div id="add-note-form">
        <NotesAdd
            v-if="showingNoteForm"
            :url="'/admin/finance/invoices/' + invoice.id + '/add-note'"
            @closed="hideNoteForm"
        />
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import NotesList from "@components/utilities/NotesList"
import NotesAdd from "@components/utilities/NotesAdd"
import useToggleable from "@composables/useToggleable";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    invoice: {
        type: Object,
        required: true
    }
})

const { showing: showingNoteForm, show: showNoteForm, hide: hideNoteForm } = useToggleable('add-note-form')
const { showing: showingForm, show: showForm, hide: hideForm } = useToggleable('edit-invoice-form')

const canEditInvoice = computed(() => {
    return props.invoice.raw?.status === 'pending'
})

const form = useForm({
    status: props.invoice.raw?.status,
})

const submitForm = () => {
    form.post('/admin/finance/invoices/' + props.invoice.id + '/update', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        preserveState: false,
        onSuccess: () => hideForm(),
    })
}

const statuses = [
    {name: 'Pending', value: 'pending'},
    {name: 'Paid', value: 'paid'},
    {name: 'Cancelled', value: 'cancelled'},
]

const voidInvoice = () => {
    Inertia.post("/admin/finance/invoices/" + props.invoice.id + "/void", null, {
        onBefore: () => confirm('Are you sure you want to void this invoice?'),
    })
}


</script>

<style lang="postcss">
body {
}
</style>
