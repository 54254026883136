<template>
    <div class="container">
        <div class="pricing-header text-center">
            <h3 class="text-center-sm text-center-xs" style="color: #A1EDE3">Fees</h3>
            <div class="message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu sem et orci dapibus
                laoreet sed sit amet ipsum. Integer posuere ante dapibus posuere aliquet non porta gravida eget metus.
            </div>
        </div>
        <div class="spacer20"></div>
        <div v-if="!showMore" class="text-center">
            <button class="btn btn-rounded" @click="toggleShowMore">View More</button>
        </div>
        <div v-if="showMore && prices.length>0" class="pricing-table--wrapper">
            <div v-for="(price, index) in prices" :key="'Price_'+index" class="pricing-table__col">
                <div class="pricing-table pt">
                    <div class="pt__header">
                        <div class="pt__header-title">{{ price.title }}</div>
                        <div class="spacer20"></div>
                        <div v-if="price.description" class="message">{{ price.description }}</div>
                        <div class="spacer20"></div>
                        <div class="pt__price price">
                            <div class="price--symbol">from £</div>
                            <div class="price--amount">{{ price.monthly_price }}</div>
                            <div class="price--duration">/hour</div>
                        </div>
                        <div class="price-plus fw-m">-</div>
                        <div class="pt__price price">
                            <div class="price--symbol">£</div>
                            <div class="price--amount">{{ price.member_price }}</div>
                            <div class="price--duration">annual membership fee</div>
                        </div>
                    </div>
                    <div class="pt__body">
                        <ul class="pt__list pricing-list">
                            <li v-for="(price_list, ind) in price.pricing_list" :key="'Price_List_'+ind">
                                <span class="pricing-list__icon"><img alt="" src="/img/web/check.svg"></span>
                                {{ price_list }}
                            </li>
                        </ul>
                    </div>
                    <!--          <div class="pt__footer">-->
                    <!--            <inertia-link href="/contact" class="btn">Enquire</inertia-link>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PricingComponent",
    data() {
        return {
            showMore: false,
            prices: [
            {
                title: 'Home',
                description: 'Home membership covers childcare at the member’s home or from any other designated location.',
                monthly_price: 12,
                member_price: 240,
                pricing_list: [
                    'No booking fees',
                    'Any location',
                    'Dedicated office support',
                    'Childcare team',
                    'Maximum flexibility',
                ],
            },
            {
                title: 'Town',
                description: 'Town membership is for members who require a lighter childcare option and covers bookings outside of the members home, such as in town to cover meetings.',
                monthly_price: 12,
                member_price: 120,
                pricing_list: [
                    'No booking fees',
                    'Dedicated office support',
                    'Childcare team',
                    'Good flexibility',
                ],
            }
            ],
        }
    },
    methods: {
        toggleShowMore: function () {
            this.showMore = !this.showMore
        }
    },
    computed: {
        getItems() {
            return this.prices.filter((item) => {
                return !item.is_hidden
            })
        }
    }
}
</script>

<style scoped>

</style>
