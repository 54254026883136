<template>
    <AdminUsersChildrenDetailsLayout :child="child">
        <template v-slot:default>
            <TagsList
                :tags="child.tags"
                :show-add="!child.is_archived"
                tag-url-prefix="/admin/system-settings/tags/tags/edit"
                @add="showTagsForm"
            />
        </template>
        <template v-slot:extra>
            <div id="edit-tags-form">
                <TagsEdit
                    v-if="showingTagsForm"
                    :url="'/admin/users/children/' + child.id + '/edit-tags'"
                    :tags="child.tags"
                    @closed="hideTagsForm"
                />
            </div>
        </template>
    </AdminUsersChildrenDetailsLayout>
</template>

<script setup>
import AdminUsersChildrenDetailsLayout from "@components/admin/AdminUsersChildrenDetailsLayout"
import useToggleable from "@composables/useToggleable"
import TagsEdit from "@components/utilities/TagsEdit"
import TagsList from "@components/utilities/TagsList"

const props = defineProps({
    child: {
        type: Object,
        required: true
    }
})

const { showing: showingTagsForm, show: showTagsForm, hide: hideTagsForm } = useToggleable('edit-tags-form')
</script>
