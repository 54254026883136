<template>
    <div class="parent-banner">
        <slider-banner-component v-if="hasMultipleImages" :images="images" :logo="true" :title="title" /> <!-- TODO -->
        <single-banner-component v-else :image="images[0]" :logo="isHomePage" :title="title" />
    </div>
</template>

<script setup>
import SliderBannerComponent from "@components/web/SliderBannerComponent";
import SingleBannerComponent from "@components/web/SingleBannerComponent";
import {usePage} from "@inertiajs/inertia-vue3";
import {computed} from "vue";


const images = computed(() => usePage().props.value?.bannerImages)
const title = computed(() => usePage().props.value?.title ?? 'Bea & Co')

const hasMultipleImages = computed(() => images.value?.length && images.value?.length > 1)
const isHomePage = computed(() => usePage().url.value === '/')

</script>
<style lang="scss" scoped>
</style>
