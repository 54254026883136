<template>
    <div class="testimonial--wrapper">
        <h2 class="text-center mb-12">{{ title }}</h2>
        <!-- <GalleryComponent v-if="showGallery" :images="images" /> -->
        <div class="container mt-20">
            <div class="testimonial">
                <Swiper class="testimonial__slider pt-5" v-bind="settings">
                    <SwiperSlide v-for="(item, index) in testimonials" :key="index"  class="testimonial__slide">
                        <div class="">
                            <div class="testimonial__item bg-white shadow-lg rounded-2xl shadow-gray-50 p-8 transform-gpu">
                                <div class="flex items-center">
                                    <div class="pr-4">
                                        <div style="background-image: url('/img/admin-logo-square.png')" class="w-16 h-16 rounded-full bg-gray-50 bg-cover bg-center"></div>
                                    </div>
                                    <div class="flex-1">
                                        <div class="fw-m text-lg">{{ item.name }}</div>
                                        <div class="relative pr-28">
                                            <div class="text-black">
                                                <div class="text-ellipsis overflow-hidden">{{ item.profession }}</div>
                                            </div>
                                            <div class="ratings text-yellow-500 absolute right-0 top-0 text-lg">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-xl font-semibold mt-8 mb-7 text-black">"{{item.subtitle}}"</div>
                                <div class="text-center font-normal text-black text-lg">"{{ item.review }}"</div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <div class="flex justify-center items-center pt-9">
                        <div class="custom-arrow arrow-left">
                            <i class="fa fa-chevron-left"></i>
                        </div>
                        <div class="swiper-pagination"></div>
                        <div class="custom-arrow arrow-right">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </Swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
// TODO styles are broken
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/pagination";
import "swiper/css/navigation";
import GalleryComponent from "./GalleryComponent.vue";

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    showGallery: {
        type: Boolean,
        default: false
    }
})

const images = [
    "/img/web/gallery/gallery_1.jpg",
    "/img/web/gallery/gallery_2.jpg",
    "/img/web/gallery/gallery_3.jpg",
    "/img/web/gallery/gallery_4.jpg",
    "/img/web/gallery/gallery_5.jpg",
    "/img/web/gallery/gallery_6.jpg",
    "/img/web/gallery/gallery_7.jpg",
    "/img/web/gallery/gallery_8.jpg",
    "/img/web/gallery/gallery_9.jpg",
    "/img/web/gallery/gallery_10.jpg",
    "/img/web/gallery/gallery_1.jpg",
    "/img/web/gallery/gallery_2.jpg",
    "/img/web/gallery/gallery_3.jpg",
    "/img/web/gallery/gallery_4.jpg",
    "/img/web/gallery/gallery_5.jpg",
    "/img/web/gallery/gallery_6.jpg",
    "/img/web/gallery/gallery_7.jpg",
    "/img/web/gallery/gallery_8.jpg",
    "/img/web/gallery/gallery_9.jpg",
    "/img/web/gallery/gallery_10.jpg",
]

const testimonials = [
    {

        name: "Gemma B.",
        profession: "Parent",
        subtitle: 'We recommend them to any friends looking for childcare.',
        review: "We have used Bea & Co since November 2015 when our eldest was just 15 weeks old. They are flexible, keenly priced, and all the sitters have always been brilliant. Quite how they retain their personal and approachable level of service, alongside their professional logistics, I don’t know. But they do. We recommend them to any friends looking for childcare, and they have never let us down.",
    },
    {

        name: "Catherine S.",
        profession: "Parent",
        subtitle: 'Completely Unique. I can’t recommend them highly enough.',
        review: "Bea & Co are a completely unique. They provide really great childcare, last minute at an affordable rate. Their staff are reliable, personable and fantastic with our children. Also as a creative, I value that we are supporting other creatives by using Bea & Co.  I can’t recommend them highly enough.",
    },
    {

        name: "Gemma H.",
        profession: "Parent",
        subtitle: 'My son loved their warmth, fun, energy and creativity.',
        review: "Bea & Co helped me out with my son from when he was a few month's old until he turned 4 and I can say hand on heart that all of their creatives were fantastic!   My son loved their warmth, fun, energy and creativity.  The booking team was always uber efficient and friendly, and Amy & co always went the extra mile to find someone, whether it was for a last minute booking, or help during Lockdown, they always came up trumps.  ",
    },
    {

        name: "Robert C.",
        profession: "Parent",
        subtitle: 'Getting such a high calibre of child carer is incredible.',
        review: "Bea & Co have been lifesavers for us. They're so efficient and friendly to deal with, and every creative they have sent has been brilliant. Getting such a high calibre of child carer is incredible — and for us in particular having a service that's so flexible has been amazingly useful.",
    },
    {

        name: "Serena G.",
        profession: "Parent",
        subtitle: 'Best agency ever!',
        review: "Best agency ever! They completely understand my needs and I got the best nanny I could ask! The boys have had a really great time and can’t wait to see him again!",
    },
    {

        name: "Mark B.",
        profession: "Parent",
        subtitle: 'Always brilliant…full of energy & invention.',
        review: "Bea & Co have consistently provided a personal, reliable and joyous service. The sitters they have sent to us have always been brilliant - they are full of energy and invention. We recommend them to any friends looking for childcare, and they have never let us down.",
    },
    {
        name: "MyAnna B.",
        profession: "Parent",
        subtitle: 'Forever grateful a friend recommended you.',
        review: "I'm forever grateful a friend of mine recommended you... it literally has changed our lives because we really struggled to get childcare that was useful for our family. It's always done with huge love and real ease and that's transformative when you're trying to work as well as bringing up a child and juggling a household.",
    }
]

const settings = {
    modules: [Navigation, Pagination],
    speed: 500,
    autoplay: true,
    loop: true,
    slidesPerView: 3,
    centeredSlides:true,
    initialSlide: 0,
    breakpoints: {
        320: {
            slidesPerView: 1
        },
        1024: {
            slidesPerView: 3
        }
    },
    navigation: {
        nextEl: '.arrow-right',
        prevEl: '.arrow-left',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    }
}

</script>

<script>
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    // components: {VueSlickCarousel},
    data() {
        return {
            settings: {
                "dots": true,
                "arrows": true,
                "centerMode": true,
                "centerPadding": "10px",
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "initialSlide": 2,
                "pagination":{
                    clickable: true,
                }
            }
        }
    },
}
</script>
<style lang="scss" scopped>
.testimonial {
    &__slide {
        padding: 0 15px;
    }

    &__message {
        padding: 30px 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4em;
        color: #000;
        transition: 666ms;
        flex: 1;
    }

    &_footer {
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 666ms;
    }

    &__name {
        color: #000;
    }

    &__designation {
        font-size: 0.9em;
    }

    &__avatar {
        display: inline-block;
        width: 36px;
        height: 36px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        margin-right: 15px;
    }
    .swiper-wrapper {
        align-items: stretch;
        .swiper-slide {
            height: auto;
        }
    }
    // .swiper-slide-active &__message {
    //     opacity: 1;
    //     border-top: 3px solid #A1EDE3;
    //     background: #efefef;
    // }

    // .swiper-slide-active &_footer {
    //     background: #A1EDE3;
    //     color: #999;
    // }

    .swiper-slide-active &__name {
        //color: #fff;
        //font-size: 20px;
    }
}

</style>
<style lang="postcss">
    .testimonial__item {
        @apply scale-75 transition-transform h-full;
    }
    .swiper-slide-active  .testimonial__item {
        @apply scale-100;
    }
    .swiper-pagination {
        @apply static px-8 w-auto h-4 flex gap-2;
        .swiper-pagination-bullet {
            @apply text-transparent w-4 h-4 mx-4 rounded-full bg-gray-500;
            &.swiper-pagination-bullet-active {
                @apply bg-gray-600;
            }
        }
    }
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        @apply w-auto;
    }
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        @apply mx-3;
    }
    .custom-arrow {
        @apply text-2xl;
    }
</style>
