<template>
    <MemberSettingsFilters />
    <div class="page-filters-section mb-10 block lg:hidden">
        <div class="filter-bar">
            <InertiaLink href="/logout" method="post" as="button" class="btn btn--secondary">
                <i class="fas fa-sign-out-alt"></i> Log out
            </InertiaLink>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Change Details</h4>
                </div>
                <div class="col">
                    Last Update: <span class="font-semibold">{{ user.updated_at }}</span>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="detailsForm.put('/user/profile-information')">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Name</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="far fa-user"></i>
                                </div>
                                <input v-model="detailsForm.name" :class="{'error' : detailsForm.errors?.updateProfileInformation?.name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Email</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="far fa-envelope"></i>
                                </div>
                                <input v-model="detailsForm.email" :class="{'error' : detailsForm.errors?.updateProfileInformation?.email}" placeholder="Email"
                                       type="email">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="detailsForm.errors?.updateProfileInformation"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmitDetails" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Change Details">
                </div>
            </form>
        </div>
    </div>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Change Password</h4>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="submitPasswordForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Current Password</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-lock"></i>
                                </div>
                                <input v-model="passwordForm.current_password" :class="{'error' : passwordForm.errors?.updatePassword?.current_password}"
                                       placeholder="Password" type="password">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">New Password</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-lock"></i>
                                </div>
                                <input v-model="passwordForm.password" :class="{'error' : passwordForm.errors?.updatePassword?.password}"
                                       placeholder="Password" type="password">
                            </div>
                        </div>
                        <div class="input-wrapper mb-1">
                            <label class="input-label">New Password Confirmation</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-lock"></i>
                                </div>
                                <input v-model="passwordForm.password_confirmation"
                                       :class="{'error' : passwordForm.errors?.updatePassword?.password_confirmation}" placeholder="Password"
                                       type="password">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="passwordForm.errors?.updatePassword"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmitPassword" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Change Password">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {computed} from "vue"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import MemberSettingsFilters from "@components/member/MemberSettingsFilters"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const detailsForm = useForm({
    name: props.user.name,
    email: props.user.email,
})

const canSubmitDetails = computed(() => {
    return !detailsForm.processing && detailsForm.name !== '' && detailsForm.email !== '' && (detailsForm.name !== props.user.name || detailsForm.email !== props.user.email)
})

const passwordForm = useForm({
    current_password: '',
    password: '',
    password_confirmation: ''
})

const canSubmitPassword = computed(() => {
    return !passwordForm.processing && passwordForm.current_password !== '' && passwordForm.password !== '' && passwordForm.password_confirmation !== ''
})

const submitPasswordForm = () => {
    passwordForm.put('/user/password', {
        onSuccess: visit => {
            passwordForm.reset()
        },
    })
}


</script>

<style lang="postcss">
body {
}
</style>
