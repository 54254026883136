<template>
    <MemberChildDetailsLayout :child="child">
        <template v-slot:action>
            <div v-if="!child.is_archived" class="col">
                <button @click="showChildForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update About
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <h5 class="sub-title">Interests</h5>
                <p>{{ child.about?.interests ?? '-' }}</p>
                <h5 class="sub-title">Medical</h5>
                <p>{{ child.about?.medical ?? '-' }}</p>
                <h5 class="sub-title">Routine</h5>
                <p>{{ child.about?.routine ?? '-' }}</p>
                <h5 class="sub-title">Other</h5>
                <p>{{ child.about?.other ?? '-' }}</p>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-child-form">
                <div v-if="showingChildForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit Child</h4>
                            </div>
                            <div class="col">
                                <button @click="hideChildForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitChildForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Interests</label>
                                            <textarea v-model="childForm.interests" :class="{'error' : childForm.errors?.interests}" name="interests" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Medical</label>
                                            <textarea v-model="childForm.medical" :class="{'error' : childForm.errors?.medical}" name="medical" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Routine</label>
                                            <textarea v-model="childForm.routine" :class="{'error' : childForm.errors?.routine}" name="routine" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Other</label>
                                            <textarea v-model="childForm.other" :class="{'error' : childForm.errors?.other}" name="other" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="childForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!childForm.isDirty || childForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </MemberChildDetailsLayout>
</template>

<script setup>
import MemberChildDetailsLayout from "@components/member/MemberChildDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    child: {
        type: Object,
        required: true
    }
})

const childForm = useForm({
    interests: props.child.interests,
    medical: props.child.medical,
    routine: props.child.routine,
    other: props.child.other,
})

const submitChildForm = () => {
    childForm.post('/member/profile/children/' + props.child.id + '/update/about', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideChildForm(),
    })
}

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('edit-child-form')

</script>
