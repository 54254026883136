<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:action>
            <div v-if="booking.can_edit" class="col">
                <button @click="showBookingForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update Booking
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <h5 class="sub-title">Financial Summary</h5>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Children (Member)</th>
                            <td>{{ booking.children.member }}</td>
                        </tr>
                        <tr>
                            <th>Children (Non-Member)</th>
                            <td>{{ booking.children.non_member }}</td>
                        </tr>
                        <tr>
                            <th>Duration (Hours)</th>
                            <td>{{ booking.finances?.duration_raw }}</td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Creative: Base</th>
                            <td>{{ booking.finances?.creative_base_total }}</td>
                        </tr>
                        <tr>
                            <th>Creative: Expenses</th>
                            <td>
                                {{ booking.finances?.creative_expenses_total }} <br>
                                <span v-if="booking.finances?.creative_expenses_total_percentage" class="text-gray-400 font-normal">({{ booking.finances?.creative_expenses_total_percentage }}%)</span>
                            </td>

                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Member: Base</th>
                            <td>{{ booking.finances?.member_base_total }}</td>
                        </tr>
                        <tr>
                            <th>Member: Expenses</th>
                            <td>
                                {{ booking.finances?.member_expenses_total }} <br>
                                <span v-if="booking.finances?.member_expenses_total_percentage" class="text-gray-400 font-normal">({{ booking.finances?.member_expenses_total_percentage }}%)</span>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Expenses Total</td>
                            <td>{{ booking.finances?.expenses_total }}</td>
                        </tr>
                        <tr>
                            <td>Creative Total</td>
                            <td>{{ booking.finances?.creative_total }}</td>
                        </tr>
                        <tr>
                            <td>Member Total</td>
                            <td>{{ booking.finances?.member_total }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-booking-form" class="">
                <div v-if="showingBookingForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Update Booking</h4>
                            </div>
                            <div class="col">
                                <button @click="hideBookingForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-5"></div>
                        <form @submit.prevent="submitForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="creative-rates-group">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Creative Rate Type</label>
                                            <select v-model="form.creative_rate_type">
                                                <option value="tier">Default Rates</option>
                                                <option value="custom_base">Custom Base Rate</option>
                                                <option value="custom_hourly">Custom Hourly Rate</option>
                                            </select>
                                        </div>
                                        <div v-if="form.creative_rate_type === 'custom_base'">
                                            <div class="input-wrapper mb-5">
                                                <label class="input-label">Creative Custom Base Rate</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="form.creative_base_total" :class="{'error' : form.errors.creative_base_total}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="form.creative_rate_type === 'custom_hourly'">
                                            <div class="input-wrapper mb-5">
                                                <label class="input-label">Creative Custom Hourly Rate</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="form.creative_hourly" :class="{'error' : form.errors.creative_hourly}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="my-10">
                                    <div class="member-rates-group">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Member Rate Type</label>
                                            <select v-model="form.member_rate_type">
                                                <option value="tier">Default Rates</option>
                                                <option value="custom_base">Custom Base Rate</option>
                                                <option value="custom_hourly">Custom Hourly Rate</option>
                                            </select>
                                        </div>
                                        <div v-if="form.member_rate_type === 'custom_base'">
                                            <div class="input-wrapper mb-5">
                                                <label class="input-label">Member Custom Base Rate</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="form.member_base_total" :class="{'error' : form.errors.member_base_total}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="form.member_rate_type === 'custom_hourly'">
                                            <div class="input-wrapper mb-5">
                                                <label class="input-label">Member Custom Hourly Rate</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="form.member_hourly" :class="{'error' : form.errors.member_hourly}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="booking.block?.id">
                                        <div class="input-wrapper mb-5 mt-5">
                                            <label class="input-label">Edit Block</label>
                                            <label class="switch">
                                                <input type="checkbox" v-model="form.edit_block">
                                                <span class="switch-slider"></span>
                                            </label>
                                        </div>
                                        <div v-if="form.edit_block" class="alert-box alert-box--error mt-10 mb-10">
                                            <div class="alert-box-icon">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                            <p>This action will update all of the Bookings within the Block.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <FormErrorsGroup :errors="form.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300" type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3"
import {computed, ref, watch} from "vue"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingBookingForm, show: showBookingForm, hide: hideBookingForm } = useToggleable('edit-booking-form')


const form = useForm({
    creative_rate_type: props.booking.finances?.creative_rate_type,
    member_rate_type: props.booking.finances?.member_rate_type,

    creative_base_total: props.booking.finances?.custom_creative_base_total,
    member_base_total: props.booking.finances?.custom_member_base_total,

    creative_hourly: props.booking.finances?.custom_creative_hourly,
    member_hourly: props.booking.finances?.custom_member_hourly,
    edit_block: false,
})

const resetForm = () => {
    form.creative_rate_type = props.booking.finances?.creative_rate_type
    form.member_rate_type = props.booking.finances?.member_rate_type

    form.creative_base_total = props.booking.finances?.custom_creative_base_total
    form.member_base_total = props.booking.finances?.custom_member_base_total

    form.creative_hourly = props.booking.finances?.custom_creative_hourly
    form.member_hourly = props.booking.finances?.custom_member_hourly
    form.edit_block = false
}

watch(() => form.creative_rate_type, () => {
    form.creative_base_total = null
    form.creative_hourly = null
})
watch(() => form.member_rate_type, () => {
    form.member_base_total = null
    form.member_hourly = null
})

const canSubmit = computed(() => {
    return !form.processing
    && (
        form.creative_rate_type === 'tier' ||
        ( form.creative_rate_type === 'custom_hourly' && form.creative_hourly !== '' && form.creative_hourly !== null ) ||
        ( form.creative_rate_type === 'custom_base' && form.creative_base_total !== '' && form.creative_base_total !== null )
    )
    && (
        form.member_rate_type === 'tier' ||
        ( form.member_rate_type === 'custom_hourly' && form.member_hourly !== '' && form.member_hourly !== null ) ||
        ( form.member_rate_type === 'custom_base' && form.member_base_total !== '' && form.member_base_total !== null )
    )
})

const submitForm = () => {
    form.post('/admin/bookings/' + props.booking.id + '/update-financial', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => {
            hideBookingForm()
            resetForm()
        }
    })
}

</script>
