<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Short Listed Creatives</h5>
                    </div>
                    <div v-if="booking.can_edit && !booking.creative?.id" class="col">
                        <button @click="showShortlistForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                    <div class="col">
                        Applications:
                        <span class="font-semibold">{{ booking.eligible_creatives?.total_applied + ' / ' + booking.eligible_creatives?.total }}</span>
                    </div>
                </div>
                <div v-if="booking.eligible_creatives?.list?.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Avatar</th>
                            <th>Name</th>
                            <th>Application Date</th>
                            <th></th>
                        </tr>
                        <tr v-for="(creative, index) in booking.eligible_creatives.list" :key="index">
                            <td>
                                <InertiaLink :href="'/admin/users/creatives/' + creative.id">
                                    <div class="avatar avatar--sm" v-bind:style="{ backgroundImage: 'url(' + creative.avatar + ')' }"></div>
                                </InertiaLink>
                            </td>
                            <td>
                                <InertiaLink :href="'/admin/users/creatives/' + creative.id">
                                    <div class="">{{ creative.professional_name }} <i v-if="creative.is_star" class="fas fa-star primary"></i> <i v-if="creative.is_flagged" class="fas fa-exclamation-circle secondary"></i></div>
                                    <span class="text-gray-400 font-normal">{{ creative.name }}</span>
                                </InertiaLink>
                            </td>
                            <td>
                                <div v-if="!creative.has_applied" class="text-secondary">-</div>
                                <div v-else class="">{{ creative.application_date }}</div>
                            </td>
                            <td v-if="booking.creative?.id === creative.id">
                                <button disabled class="btn btn--sm"><i class="fas fa-check"></i> Assigned</button>
                            </td>
                            <td v-else-if="booking.can_edit">
                                <button @click="selectCreative(creative.id)" class="btn btn--sm"><i class="fas fa-check"></i> Assign</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-shortlist-form">
                <UsersPicker
                    v-if="showingShortlistForm"
                    title="Edit Short List"
                    target="Creatives"
                    :source-url="'/admin/bookings/' + booking.id + '/shortlist/suitable'"
                    :url="'/admin/bookings/' + booking.id + '/shortlist/selected'"
                    :users="booking.eligible_creatives?.list"
                    @closed="hideShortlistForm"
                />
            </div>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {Inertia} from "@inertiajs/inertia";
import UsersPicker from "@components/utilities/UsersPicker"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingShortlistForm, show: showShortlistForm, hide: hideShortlistForm } = useToggleable('edit-shortlist-form')

const selectCreative = (creativeId) => {
    Inertia.post('/admin/bookings/' + props.booking.id + '/shortlist/' + creativeId + '/select', {}, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onBefore: () => confirm('Are you sure you want to assign this creative?')
    })
}
</script>
