<template>
    <AdminAnalyticsFilters />
    <div class="lg:flex">
        <div class="col lg:flex-1 lg:w-8/12 lg:pr-6">
            <div class="page-card">
                <div class="page-card-content mb-4 md:mb-6 lg:mb-8">
                    <div class="flex justify-between items-center pb-6">
                        <div class="col">
                            <div class="text-2xl font-medium text-dark">What's New</div>
                        </div>
                    </div>
                    <perfect-scrollbar class="lg:max-h-96 lg:h-96 overflow-y-auto voverflow-x-hidden overview-card">
                        <div class="bookings">
                            <InertiaLink :href="'/admin/bookings/' + booking.id" class="bookings-item mb-1" v-for="(booking, index) in whatsnew" :key="index">
                                <div class="bg-silver rounded-xl py-4 px-6 border border-gray-100">
                                    <div class="md:flex items-center">
                                        <div class="col flex-1">
                                            <div class="flex items-center">
                                                <div class="col">
                                                    <div class="text-md font-semibold pb-2 text-dark">{{booking.first_for_member ? (booking.member?.name + ' Family') : booking.creative?.name }}</div>
                                                    <div class="flex text-sm text-left">
                                                        <div class="col mr-2">
                                                            <i class="fa fa-calendar"></i>
                                                        </div>
                                                        <div class="col">{{ booking.kb_start_date + ' | ' + booking.kb_times }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col w-60">
                                            <div class="flex items-center pt-5 md:pt-0 md:justify-end">
                                                <div v-if="booking.first_for_member && booking.first_for_creative" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-red-500 ml-2">BOTH</div>
                                                <div v-else-if="booking.first_for_member" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-secondary ml-2">MEMBER</div>
                                                <div v-else-if="booking.first_for_creative" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-primary-500 ml-2">CREATIVE</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style="margin-bottom: 0.5rem"></p> <!-- TODO replace with spacer v-for level -->
                            </InertiaLink>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div class="col lg:flex-1 lg:w-4/12 lg:pt-0">
            <div class="page-card">
                <div class="page-card-content mb-4 md:mb-6 lg:mb-8">
                    <div class="flex justify-between items-center pb-4">
                        <div class="col">
                            <div class="text-2xl font-medium text-dark">Statistics</div>
                        </div>
                    </div>
                    <perfect-scrollbar class="lg:max-h-96 lg:h-96 overflow-y-auto voverflow-x-hidden overview-card">
                        <div class="auto-grid">
                            <div class="col my-3" v-for="(statistic, index) in statistics" :key="index">
                                <div class="text-lg pb-4 text-dark">{{ statistic.title }}</div>
                                <div class="text-3xl pb-1 text-dark">{{ statistic.value }}</div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
    </div>
    <div class="lg:flex">
        <div class="col lg:flex-1 lg:w-8/12 lg:pr-6">
            <div class="page-card">
                <div class="page-card-content mb-4 md:mb-6 lg:mb-8">
                    <div class="flex justify-between items-center pb-6">
                        <div class="col">
                            <div class="text-2xl font-medium text-dark">Bookings</div>
                        </div>
                        <div class="col">
                            <button @click="$inertia.visit('/admin/bookings')" class="w-6 h-6 leading-6 rounded-full bg-primary-300 hover:bg-primary text-white text-center text-xs">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <perfect-scrollbar class="lg:max-h-96 lg:h-96 overflow-y-auto voverflow-x-hidden overview-card">
                        <div class="bookings">
                            <InertiaLink :href="'/admin/bookings/' + booking.id" class="bookings-item mb-1" v-for="(booking, index) in bookings" :key="index">
                                <div class="bg-silver rounded-xl py-4 px-6 border border-gray-100">
                                    <div class="md:flex items-center">
                                        <div class="col flex-1">
                                            <div class="flex items-center">
                                                <div class="col">
                                                    <div class="text-md font-semibold pb-2 text-dark">{{booking.member?.name}} Family <i v-if="booking.member?.is_vip" class="fas fa-check-circle primary"></i></div>
                                                    <div class="flex text-sm text-left align-middle">
                                                        <div class="col mr-2">
                                                            <i class="fa fa-calendar"></i>
                                                        </div>
                                                        <div class="col">
                                                            {{ booking.kb_start_date + ' | ' + booking.kb_times }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col w-60">
                                            <div class="flex items-center pt-5 md:pt-0 md:justify-end">
                                                <div v-if="!booking.has_creative" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-yellow-500"><i class="fas fa-user"></i></div>
                                                <div v-if="booking.flag === 9" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-red-500 ml-2"><i class="fas fa-flag"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style="margin-bottom: 0.5rem"></p> <!-- TODO replace with spacer v-for level -->
                            </InertiaLink>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div class="col lg:flex-1 lg:w-8/12 lg:pt-0">
            <div class="page-card">
                <div class="page-card-content mb-4 md:mb-6 lg:mb-8">
                    <div class="flex justify-between items-center pb-6">
                        <div class="col">
                            <div class="text-2xl font-medium text-dark">Documents</div>
                        </div>
                        <div class="col">
                            <button @click="$inertia.visit('/admin/users/creatives')" class="w-6 h-6 leading-6 rounded-full bg-primary-300 hover:bg-primary text-white text-center text-xs">
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <perfect-scrollbar class="lg:max-h-96 lg:h-96 overflow-y-auto voverflow-x-hidden overview-card">
                        <div class="bookings">
                            <InertiaLink :href="'/admin/users/creatives/' + document.creative_id + '/documents'" class="bookings-item mb-1" v-for="(document, index) in documents" :key="index">
                                <div class="bg-silver rounded-xl py-4 px-6 border border-gray-100">
                                    <div class="md:flex items-center">
                                        <div class="col flex-1">
                                            <div class="flex items-center">
                                                <div class="w-10 h-10 rounded-full bg-yellow-200 bg-cover bg-center bg-no-repeat mr-4" v-bind:style="{ backgroundImage: 'url(' + document.creative_avatar + ')' }"></div>
                                                <div class="col">
                                                    <div class="text-md font-semibold pb-2 text-dark">{{document.creative_name}}</div>
                                                    <div class="flex text-sm text-center">
                                                        <div class="col mr-2">
                                                            <i class="fa fa-file"></i>
                                                        </div>
                                                        <div class="col">{{document.type}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col w-60">
                                            <div class="flex items-center pt-5 md:pt-0 md:justify-end">
                                                <div v-if="document.tag === 'pending'" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-primary-500 ml-2">PENDING</div>
                                                <div v-else class="tag inline-block py-1 px-3 rounded text-white text-xs bg-red-500">EXPIRED</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style="margin-bottom: 0.5rem"></p> <!-- TODO replace with spacer v-for level -->
                            </InertiaLink>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
    </div>
    <div class="page-card">
        <div  id="notes-list" class="page-card-content mb-4 md:mb-6 lg:mb-8">
            <div class="flex justify-between items-center pb-6">
                <div class="col">
                    <div class="text-2xl font-medium text-dark">Notes
                        <span class="text-sm font-medium text-primary cursor-pointer ml-2" :class="{'underline': !notesFilterComplete}" @click="notesFilterComplete = false">Pending</span>
                        <span class="text-sm font-medium text-primary cursor-pointer ml-2" :class="{'underline': notesFilterComplete}" @click="notesFilterComplete = true">Complete</span>
                    </div>
                </div>
                <div class="col">
                    <button v-if="!showingNoteForm" @click="showNoteForm" class="w-6 h-6 leading-6 rounded-full bg-primary-300 hover:bg-primary text-white text-center text-xs">
                        <i class="fas fa-plus"></i>
                    </button>
                    <button v-else @click="hideNoteForm" class="w-6 h-6 leading-6 rounded-full bg-red-500 text-white text-center text-xs">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>

            <div>
                <div v-if="showingNoteForm" class="mt-5 mb-10">
                    <div class="">
                        <form @submit.prevent="submitNoteForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Note</label>
                                        <textarea v-model="noteForm.note" name="note" rows="5"></textarea>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <TagPicker v-model="noteForm.tags" :allow-create="false" />
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="noteForm.errors"/>
                            <button :disabled="!canSubmitNote" @click="submitNoteForm" type="button" class="btn btn--sm btn--secondary mt-5">
                                <i class="fa fa-plus mr-3"></i>
                                Add Note
                            </button>
                        </form>
                    </div>
                    <hr class="mb-5 mt-10">
                </div>
            </div>

            <perfect-scrollbar class="lg:max-h-96 lg:h-96 overflow-y-auto voverflow-x-hidden overview-card">
                <div class="border-2 border-gray-50 rounded-xl p-5 mb-2" v-for="(note, index) in activeNotes" :key="note.id">
                    <div class="flex flex-row">
                        <label class="pt-1">
                            <input type="checkbox" v-model="note.is_checked" class="notes-checkbox" @change="noteCheckToggled(note)">
                        </label>
                        <div class="ml-10">
                            <div class="text-secondary">{{ note.text }}</div>
                            <div v-if="note.tags?.length" class="mt-5 flex gap-4">
                                <div v-for="(tag, tagIndex) in note.tags" :key="tagIndex" class="tag inline-block py-1 px-3 rounded text-white text-xs bg-primary-500">
                                    {{ tag }}
                                </div>
                            </div>
                            <div class="text-sm mt-5">
                                {{ note.author_name }}, {{ note.created_at }}
                            </div>
                        </div>
                        <div class="ml-auto">
                            <button @click="removeNote(note.id)" class="w-6 h-6 leading-6 rounded-full bg-red-500 text-white text-center text-xs">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <div class="page-card">
        <div class="page-card-content mb-4 md:mb-6 lg:mb-8">
            <div class="flex justify-between items-center pb-6">
                <div class="col">
                    <div class="text-2xl font-medium text-dark">Notifications</div>
                </div>
                <div class="col">
                    <button @click="$inertia.visit('/admin/account/notifications')" class="w-6 h-6 leading-6 rounded-full bg-primary-300 hover:bg-primary text-white text-center text-xs">
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </div>
            </div>
            <perfect-scrollbar class="lg:max-h-96 lg:h-96 overflow-y-auto voverflow-x-hidden overview-card">
                <component :is="notification.action_url ? 'InertiaLink' : 'div'" :href="notification.action_url ?? ''" class="bookings-item mb-1" v-for="(notification, index) in notifications" :key="index">
                    <div class="bg-silver rounded-xl py-4 px-6 border border-gray-100">
                        <div class="text-md font-semibold pb-2 text-dark">{{ notification.text }}</div>
                        <div class="flex text-sm text-center">{{ notification.created_at }}</div>
                        <div class="flex text-sm text-center">{{ notification.subtext }}</div>
                    </div>
                    <p style="margin-bottom: 0.5rem"></p> <!-- TODO replace with spacer v-for level -->
                </component>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue"
import AdminAnalyticsFilters from "@components/admin/AdminAnalyticsFilters"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import useToggleable from "@composables/useToggleable"
import TagPicker from "@components/utilities/TagsPicker"

const props = defineProps({
    whatsnew: {
        type: Array,
        default: []
    },
    statistics: {
        type: Array,
        default: []
    },
    bookings: {
        type: Array,
        default: []
    },
    documents: {
        type: Array,
        default: []
    },
    notes: {
        type: Array,
        default: []
    },
    notifications: {
        type: Array,
        default: []
    }
})

const notesFilterComplete = ref(false)
// const activeNotes = computed(() => props.notes)
const activeNotes = computed(() => props.notes.filter(n => n.is_checked === notesFilterComplete.value))

const { showing: showingNoteForm, show: showNoteForm, hide: hideNoteForm } = useToggleable('notes-list')

const noteForm = useForm({
    note: null,
    tags: [],
})


const canSubmitNote = computed(() => {
    return !noteForm.processing && noteForm.note
})
const cancelNote = () => {
    noteForm.reset()
    hideNoteForm()
}
const submitNoteForm = () => {
    noteForm.post('/admin/analytics/overview/notes/add', {
        preserveScroll: (page) => true,
        onSuccess: () => cancelNote()
    })
}

const removeNote = (noteId) => {
    noteForm.post('/admin/analytics/overview/notes/' + noteId + '/remove', {
        preserveScroll: (page) => true,
    })
}

const noteCheckToggled = (note) => {
    if (!note.is_checked) {
        uncheckNote(note.id)
    } else {
        checkNote(note.id)
    }
}

const checkNote = (noteId) => {
    noteForm.post('/admin/analytics/overview/notes/' + noteId + '/check', {
        preserveScroll: (page) => true,
    })
}
const uncheckNote = (noteId) => {
    noteForm.post('/admin/analytics/overview/notes/' + noteId + '/uncheck', {
        preserveScroll: (page) => true,
    })
}



</script>
<style lang="postcss">
.auto-grid {
    --auto-grid-min-size: 16rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-gap: 1rem;
}
.overview-card {
    min-height: 38rem;
}
</style>
