<template>
    <CreativeProfileFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center gap-10">
                <div class="col">
                    <div class="page-card-avatar avatar avatar--xl" v-bind:style="{ backgroundImage: 'url(' + user.avatar + ')' }"></div>
                </div>
                <div class="col">
                    <h3 class="page-title">{{ user.professional_name }}</h3>
                </div>
                <div class="col ml-auto">
                    <button @click="showTagsForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Skills
                    </button>
                </div>
            </div>
            <TagsList
                :tags="user.tags"
                :show-add="false"
                @add="showTagsForm"
                title="Skills"
            />
        </div>
    </div>
    <div id="edit-tags-form">
        <TagsEdit
            v-if="showingTagsForm"
            url="/creative/profile/skills"
            title="Update Skills"
            :tags="user.tags"
            :allow-create="true"
            @closed="hideTagsForm"
        />
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import TagsEdit from "@components/utilities/TagsEdit"
import TagsList from "@components/utilities/TagsList"
import useToggleable from "@composables/useToggleable"
import CreativeProfileFilters from "@components/creative/CreativeProfileFilters"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})
const { showing: showingTagsForm, show: showTagsForm, hide: hideTagsForm } = useToggleable('edit-tags-form')
</script>

<style lang="postcss">
body {
}
</style>
