<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ title }}</h4>
                </div>
                <div v-if="showClose" class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div v-if="!memberId" class="input-wrapper mb-10">
                            <label class="input-label">Select Member</label>
                            <select name="value" v-model="form.member" :class="{'error' : form.errors.member}">
                                <option disabled>Select Member</option>
                                <option v-for="(member, index) in members" :key="index" :value="member.id">
                                    {{ member.id + ' ' + member.name + (member.is_vip ? ' (VIP)' : '') }}
                                </option>
                            </select>
                        </div>
                        <div v-if="showTitle" class="input-wrapper mb-5">
                            <label class="input-label">Title</label>
                            <div class="input-holder">
                                <input v-model="form.title" :class="{'error' : form.errors?.title}" placeholder="Title"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">First Name</label>
                            <div class="input-holder">
                                <input v-model="form.first_name" :class="{'error' : form.errors?.first_name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Last Name</label>
                            <div class="input-holder">
                                <input v-model="form.last_name" :class="{'error' : form.errors?.last_name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" :value="title">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    showClose: {
        type: Boolean,
        default: true
    },
    members: {
        type: Array,
        default: []
    },
    memberId: {
        type: Number,
        default: null
    },
    title: {
        type: String,
        default: 'Add User'
    },
    showTitle: {
        type: Boolean,
        default: true
    },
})

const emits = defineEmits(['closed'])

const form = useForm({
    title: null,
    first_name: null,
    last_name: null,
    member: props.memberId,
})

const canSubmit = computed(() => {
    return !form.processing && form.first_name && form.last_name && form.member
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})
</script>
