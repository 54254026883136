<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <UsersList
                :users="member.children"
                title="Children"
                url-prefix="/admin/users/children/"
                @add="showChildForm"
                :show-add="!member.is_archived"
            />
        </template>
        <template v-slot:extra>
            <div id="add-child-form">
                <div v-if="showingChildForm">
                    <div class="mt-10"></div>
                    <UserAdd
                        url="/admin/users/children/create/"
                        :member-id="member.id"
                        @closed="hideChildForm"
                        title="Add Child"
                        :show-title="false"
                    />
                </div>
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import UsersList from "@components/utilities/UsersList"
import UserAdd from "@components/utilities/UserAdd"

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})
const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('add-child-form')

</script>
