<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <NotesList
                :notes="user.notes"
                :show-add="!user.is_archived"
                @add="showNoteForm"
            />
        </template>
        <template v-slot:extra>
            <div id="add-note-form">
                <NotesAdd
                    v-if="showingNoteForm"
                    :url="'/admin/users/creatives/' + user.id + '/add-note'"
                    @closed="hideNoteForm"
                />
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout";
import useToggleable from "@composables/useToggleable"
import NotesList from "@components/utilities/NotesList"
import NotesAdd from "@components/utilities/NotesAdd"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { showing: showingNoteForm, show: showNoteForm, hide: hideNoteForm } = useToggleable('add-note-form')

</script>
