<template>
	<div class="card hover:bg-primary-dark hover:text-white cursor-pointer" @click="$inertia.visit(urlPrefix + booking.id)">
		<div class="md:flex md:flex-col relative md:pl-0">
			<div class="col">
				<div class="w-full text-center">
					<h5 class="card-title">
                        <div class="avatar avatar--md" style="margin: auto;" v-bind:style="{ backgroundImage: 'url(' + booking.member.avatar + ')' }"></div>
                        <div class="pb-5"></div>
                        <div v-if="!isForCreative ">
                            {{ booking.creative?.name ?? 'Creative' }}
                        </div>
                        <div v-else>
                            {{ booking.member.name }} <i v-if="booking.member.is_vip" class="fas fa-check-circle primary"></i>
                        </div>
                    </h5>
                    <p class="card-text group-hover:text-white">{{ booking.location ?? '-' }}</p>
					<p class="card-text group-hover:text-white">{{ booking.start_timestamp }}</p>
					<p class="card-text group-hover:text-white">{{ booking.end_timestamp }}</p>
					<p class="card-text group-hover:text-white font-bold">({{ booking.duration }})</p>
					<div class="mb-6"></div>
                    <!-- TODO center align tags -->
                    <div>
                        <div class="tags flex flex-wrap gap-3 justify-self-auto">
                            <div v-for="(tag, index) in booking.tags" :key="index" class="col">
                                <span class="btn btn--sm btn--secondary">{{ tag }}</span>
                            </div>
                        </div>
					</div>
					<div class="mt-10">
                        <InertiaLink :href="urlPrefix + booking.id" class="btn btn--primary group-hover:bg-white group-hover:text-primary w-full">View Details</InertiaLink>
					</div>
                    <div class="mt-5 w-full text-center">
                        <p class="card-text">{{ booking.created_at }}</p>
    				</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import {computed} from "vue";

const props = defineProps({
    booking: {
        type: Object,
        required: true
    },
    isForCreative: {
        type: Boolean,
        required: true
    }
})

const urlPrefix = computed(() => props.isForCreative ? '/creative/bookings/' : '/member/bookings/')

</script>
<style lang="postcss">
</style>
