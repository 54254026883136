<template>
    <div class="mt-10">
        <div class="flex flex-wrap items-center justify-between gap-4">
            <div class="col">
                <h5 class="sub-title">{{ title }}</h5>
            </div>
            <div v-if="showAdd" class="col">
                <button @click="$emit('add')" class="btn btn--icon"><i class="fas fa-edit"></i></button>
            </div>
        </div>
        <div class="flex flex-wrap gap-4 mt-5">
            <div v-for="(tag, index) in tags" :key="index" class="col">
                <button v-if="!isSimple" @click="goToTag(tag.id)" class="btn btn--sm btn" :class="!tag.is_approved ? 'btn--light-red' : (tag.is_admin_only ? 'btn--light-blue' : '')">{{ tag.name }}</button>
                <button v-else class="btn btn--sm btn">{{ tag }}</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    tags: {
        type: Array,
        required: true
    },
    showAdd: {
        type: Boolean,
        default: true
    },
    tagUrlPrefix: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: 'Tags'
    },
    isSimple: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['add'])

const goToTag = (tagId) => {
    if (props.tagUrlPrefix) {
        Inertia.get(props.tagUrlPrefix + '/' + tagId)
    }
}
</script>

