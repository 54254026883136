<template>
    <CreativeAccountFilters />
    <div v-show="!showingAvailabilityForm" class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Manage Availability</h4>
                </div>
                <div class="col">
                    <button @click="showMasterForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Update Master Availability
                    </button>
                </div>
            </div>
            <DailyAvailabilityList :schedule="user.availability?.weekly" @edit="showAvailabilityForm" />
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Unavailable Periods</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showAddUnavailablePeriodForm" class="btn btn--icon"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
                <div v-if="user.availability?.unavailable_periods.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Duration</th>
                            <th>Date Added</th>
                        </tr>
                        <tr v-for="(period, index) in user.availability.unavailable_periods" :key="index">
                            <td><div class="text-gray-600 font-normal">{{ period.start_date }}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ period.end_date }}</div></td>
                            <td><div class="text-gray-500 font-extrabold">{{ period.duration }} day{{ (period.duration > 1 ? 's' : '')}}</div></td>
                            <td><div class="text-gray-600 font-normal">{{ period.created_at_short }}</div></td>
                            <td v-if="!user.is_archived">
                                <button @click="removeUnavailablePeriod(period.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div id="edit-master-form">
        <div v-if="showingMasterForm" class="page-card mt-10">
            <div class="page-card-content">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h4>Edit Master Availability</h4>
                    </div>
                    <div class="col">
                        <button @click="hideMasterForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div class="pb-10"></div>
                <form @submit.prevent="submitMasterForm">
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="col">
                            <div class="input-wrapper mb-5">
                                <label class="input-label">{{ masterForm.is_available ? 'Available' : 'Unavailable' }}</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="masterForm.is_available">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <FormErrorsGroup :errors="masterForm.errors"/>
                    <div class="input-submit mt-10">
                        <input :disabled="!masterForm.isDirty || masterForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                               type="submit" value="Update Master Availability">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div id="edit-availability-form">
        <div v-if="showingAvailabilityForm">
            <DailyAvailabilityForm
                style="margin-top: 0;"
                :schedule="user.availability?.weekly"
                url="/creative/account/availability/daily"
                :show-close="true"
                @closed="hideAvailabilityForm"
            />
        </div>
    </div>
    <div id="add-unavailable-period-form">
        <div v-if="showingAddUnavailablePeriodForm">
            <div class="mt-10"></div>
            <UnavailablePeriodForm
                url="/creative/account/availability/unavailable-periods/add"
                @closed="hideAddUnavailablePeriodForm"
            />
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {Inertia} from "@inertiajs/inertia"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import useToggleable from "@composables/useToggleable"
import UnavailablePeriodForm from "@components/utilities/UnavailablePeriodForm"
import CreativeAccountFilters from "@components/creative/CreativeAccountFilters"
import DailyAvailabilityList from "@components/utilities/DailyAvailabilityList"
import DailyAvailabilityForm from "@components/utilities/DailyAvailabilityForm"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { showing: showingMasterForm, show: showMasterForm, hide: hideMasterForm } = useToggleable('edit-master-form')

const masterForm = useForm({
    is_available: props.user.availability?.is_available,
})

const submitMasterForm = () => {
    masterForm.post('/creative/account/availability', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideMasterForm(),
    })
}

const { showing: showingAvailabilityForm, show: showAvailabilityForm, hide: hideAvailabilityForm } = useToggleable('edit-availability-form')

const { showing: showingAddUnavailablePeriodForm, show: showAddUnavailablePeriodForm, hide: hideAddUnavailablePeriodForm } = useToggleable('add-unavailable-period-form')
const removeUnavailablePeriod = (unavailablePeriodId) => {
    Inertia.post('/creative/account/availability/unavailable-periods/' + unavailablePeriodId + '/remove', {}, {
        onBefore: () => confirm('Are you sure you want to remove the Unavailable Period?'),
        preserveScroll: true
    })
}
</script>

<style lang="postcss" scoped>

.switch input:checked + .switch-slider {
    @apply bg-green-500;
}
</style>
