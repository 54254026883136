<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ title }}</h4>
                </div>
                <div v-if="showClose" class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-5"></div>
            <div class="profile-grid">
                <div class="col p-6">
                    Member
                    <h6 class="font-semibold mt-1">{{ activeMember?.name ?? '-' }}</h6>
                </div>
                <div class="col p-6">
                    Nb of Children
                    <h6 class="font-semibold mt-1">{{ booking?.children?.total ? booking.children.total : '-' }}</h6>
                </div>
                <div class="col p-6">
                    Duration
                    <h6 class="font-semibold mt-1">{{ duration }}</h6>
                </div>
                <div class="col p-6">
                    Expenses
                    <h6 class="font-semibold mt-1">-</h6>
                </div>
            </div>
            <div class="pb-5"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div v-if="!activeMember" class="input-wrapper mb-10">
                            <label class="input-label">Select Member</label>
                            <select name="value" v-model="form.member" :class="{'error' : form.errors.member}" @change="memberChanged">
                                <option disabled>Select Member</option>
                                <option v-for="(member, index) in members" :key="index" :value="member.id">{{ member.name }}</option>
                            </select>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Start Timestamp</label>
                            <input v-model="form.start_timestamp" :class="{'error' : form.errors.start_timestamp}" :max="maxNow" type="datetime-local">
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">End Timestamp</label>
                            <input v-model="form.end_timestamp" :min="minEndDatetime" :class="{'error' : form.errors.end_timestamp}" :max="maxNow" type="datetime-local">
                        </div>
                        <div v-if="form.start_timestamp && form.end_timestamp && duration < 3" class="alert-box alert-box--error mt-10 mb-10">
                            <div class="alert-box-icon">
                                <i class="fas fa-info-circle"></i>
                            </div>
                            <p>Please note that the Duration must be at least 3 hours.</p>
                        </div>
                        <div v-else-if="maxNow && isInTheFuture" class="alert-box alert-box--error mt-10 mb-10">
                            <div class="alert-box-icon">
                                <i class="fas fa-info-circle"></i>
                            </div>
                            <p>Please note that you cannot make future Bookings.</p>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Location</label>
                            <input v-model="form.location" :class="{'error' : form.errors.location}" type="text">
                        </div>
                        <div v-if="isAdmin && !isEdit">
                            <div class="input-wrapper mb-5 mt-5">
                                <label class="input-label">Repeat Booking</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="form.repeat_booking">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div v-show="form.repeat_booking">
                                <div class="input-wrapper mb-10">
                                    <label class="input-label">Repeat Until</label>
                                    <input v-model="form.repeat_until" :class="{'error' : form.errors.repeat_until}" type="date" :min="minRepeatDate">
                                </div>
                                <div class="input-wrapper mb-10">
                                    <label class="input-label">Repeat Frequency</label>
                                    <select name="value" v-model="form.repeat_frequency" :class="{'error' : form.errors.repeat_frequency}">
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="monthly">Monthly</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="isAdmin && isEdit && booking.block?.id">
                            <div class="input-wrapper mb-5 mt-5">
                                <label class="input-label">Edit Block</label>
                                <label class="switch">
                                    <input type="checkbox" v-model="form.edit_block">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                            <div v-if="form.edit_block" class="alert-box alert-box--error mt-10 mb-10">
                                <div class="alert-box-icon">
                                    <i class="fas fa-info-circle"></i>
                                </div>
                                <p>This action will update all of the Bookings within the Block.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300" type="submit" :value="isEdit ? 'Update' : 'Create'">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    title: {
        type: String,
        default: 'Manage Booking'
    },
    url: {
        type: String,
        required: true
    },
    members: {
        type: Array,
        default: []
    },
    maxNow: {
        type: Boolean,
        default: false
    },
    activeMember: {
        type: Object,
        default: null
    },
    activeStart: {
        type: String,
        default: ""
    },
    activeEnd: {
        type: String,
        default: ""
    },
    activeLocation: {
        type: String,
        default: ""
    },
    activeOverallStatus: {
        type: String,
        default: ""
    },
    activeCreativePaymentStatus: {
        type: String,
        default: ""
    },
    activeMemberPaymentStatus: {
        type: String,
        default: ""
    },
    showClose: {
        type: Boolean,
        default: false
    },
    isAdmin: {
        type: Boolean,
        default: false
    },
    booking: {
        type: Object,
        default: () => {}
    },
    isEdit: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['closed'])

const form = useForm({
    member: props.activeMember?.id,
    start_timestamp: props.activeStart,
    end_timestamp: props.activeEnd,
    location: props.activeLocation,
    repeat_booking: false,
    repeat_until: "",
    repeat_frequency: "daily",
    edit_block: false,
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty && form.member && form.start_timestamp && form.end_timestamp && duration.value && form.location
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

const selectedMember = computed(() => {
    return props.activeMember ?? props.members.find(m => m.id === form.member)
})

const maxNow = computed(() => {
    if (props.maxNow) {
        let d = new Date()
        return d.toISOString().slice(0,16)
    }
    return null
})
const isInTheFuture = computed(() => {
    if (form.start_timestamp && form.end_timestamp) {
        var now = new Date()
        var startDate = new Date(form.start_timestamp);
        var endDate = new Date(form.end_timestamp);
        if (startDate > now || endDate > now) {
            return true
        }
    }
    return false
})
const minEndDatetime = computed(() => {
    if (form.start_timestamp) {
        let d = new Date(form.start_timestamp)
        d.setHours(d.getHours() + 3)
        return d.toISOString().slice(0,16)
    }
    return null
})

const minRepeatDate = computed(() => {
    if (form.start_timestamp) {
        let d = new Date(form.start_timestamp)
        d.setDate(d.getDate() + 1)
        return d.toISOString().split("T")[0]
    }

    let d = new Date();
    d.setDate(d.getDate() + 1)
    return d.toISOString().split("T")[0]
})

const duration = computed(() => {
    if (form.start_timestamp && form.end_timestamp && form.end_timestamp > form.start_timestamp) {
        var fromDate = new Date(form.start_timestamp).getTime();
        var toDate = new Date(form.end_timestamp).getTime();

        // check is at least 3 hours
        if (toDate < fromDate + 10800000) {
            return 0
        }

        // get total seconds between the times
        var delta = Math.abs(toDate - fromDate) / 1000

        // calculate days
        const days = Math.floor(delta / 86400)
        delta -= days * 86400

        // calculate hours
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600

        // calculate minutes
        const minutes = Math.floor(delta / 60) % 60
        delta -= minutes * 60

        let totalHours = (days * 24) + hours

        return totalHours + ' ' + (totalHours !== 1 ? 'hours' : 'hour') + (minutes ? ' ' + minutes + ' ' + (minutes !== 1 ? 'minutes' : 'minute') : '')
    }
    return 0
})

</script>
