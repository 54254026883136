<template>
    <AdminBookingDetailsLayout :booking="booking">
        <template v-slot:action>
            <div v-if="booking.can_edit" class="col">
                <button @click="showBookingForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Update Booking
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4 my-4">
                    <div class="col">
                        Created:
                        <span class="font-semibold">{{ booking.created_at }}</span>
                    </div>
                    <div class="col">
                        Last Edit:
                        <span class="font-semibold">{{ booking.updated_at }}</span>
                    </div>
                </div>
                <div class="profile-grid">
                    <div class="col p-6">
                        <InertiaLink :href="'/admin/users/members/' + booking.member?.id">
                            Member
                            <h6 class="font-semibold mt-1">{{ booking.member.name }} <i v-if="booking.member.is_vip" class="fas fa-check-circle primary"></i></h6>
                        </InertiaLink>
                    </div>
                    <div class="col p-6">
                        Creative
                        <h6 class="font-semibold mt-1">{{ booking.creative?.name ?? '-' }}</h6>
                    </div>
                    <div class="col p-6">
                        Duration
                        <h6 class="font-semibold mt-1">{{ booking.duration }}</h6>
                    </div>
                    <div class="col p-6">
                        Status
                        <h6 class="font-semibold mt-1">{{ booking.status.overall }}</h6>
                    </div>
                </div>
                <div class="mt-10">
                    <h5 class="sub-title">Overview</h5>
                    <table class="transaction-table">
                        <tbody>
                        <tr>
                            <th>Block</th>
                            <td>{{ booking.block_summary }}</td>
                        </tr>
                        <tr>
                            <th>Start Timestamp</th>
                            <td>{{ booking.start_timestamp }}</td>
                        </tr>
                        <tr>
                            <th>End Timestamp</th>
                            <td>{{ booking.end_timestamp }}</td>
                        </tr>
                        <tr>
                            <th>Location</th>
                            <td>{{ booking.location ?? '-' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-booking-form" class="">
                <BookingForm
                    v-if="showingBookingForm"
                    class="mt-10"
                    title="Update Booking"
                    :url="'/admin/bookings/' + booking.id + '/update'"
                    :active-member="booking.member"
                    :active-start="booking.start_timestamp_raw"
                    :active-end="booking.end_timestamp_raw"
                    :active-location="booking.location"
                    :is-admin="true"
                    :active-overall-status="booking.overall_status_raw"
                    :active-creative-payment-status="booking.creative_payment_status_raw"
                    :active-member-payment-status="booking.member_payment_status_raw"
                    :show-close="true"
                    :is-edit="true"
                    @closed="hideBookingForm"
                    :booking="booking"
                />
            </div>
            <BookingRepeatForm v-if="!booking.block" :booking="booking" />
            <SoftDelete
                v-if="booking.can_remove"
                :is-archived="false"
                :archive-url="'/admin/bookings/' + booking.id + '/destroy'"
                :restore-url="'/admin/bookings/' + booking.id + '/destroy'"
                type="Booking"
                action="Remove"
            >
                <p>Please note that this action is irreversible.</p>
            </SoftDelete>
        </template>
    </AdminBookingDetailsLayout>
</template>

<script setup>
import AdminBookingDetailsLayout from "@components/admin/AdminBookingDetailsLayout";
import BookingRepeatForm from "@components/admin/BookingRepeatForm";
import useToggleable from "@composables/useToggleable"
import SoftDelete from "@components/utilities/SoftDelete"
import BookingForm from "@components/utilities/BookingForm"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingBookingForm, show: showBookingForm, hide: hideBookingForm } = useToggleable('edit-booking-form')


</script>
