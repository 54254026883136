<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Create Tag Category</h4>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="form.post('/admin/system-settings/tags/categories/create')">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Name</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-tags"></i>
                                </div>
                                <input v-model="form.name" :class="{'error' : form.errors.name}" placeholder="Name"
                                       type="text">
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Select Tag Type</label>
                            <select name="value" v-model="form.tag_type_id" :class="{'error' : form.errors.tag_type_id}">
                                <option disabled>Select Type</option>
                                <option v-for="(type, index) in types" :key="index" :value="type.id">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Create Tag Category">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    types: {
        type: Array,
        default: []
    }
})

const form = useForm({
    name: "",
    tag_type_id: "",
})

const canSubmit = computed(() => {
    return !form.processing && form.name !== '' && form.tag_type_id !== ''
})


</script>
