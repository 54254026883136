<template>
    <div class="user-page">
        <div class="card card--user">
            <div class="logo w-24 h-24 mx-auto">
                <img :src="logoUrl" alt="">
            </div>
            <div class="pb-12"></div>
            <div class="text-center mb-14">
                <h3 class="font-semibold">Register</h3>
                <div class="pb-3"></div>
                <p class="text-lg text-gray-400">
                    <span v-if="isMember">Member Registration</span>
                    <span v-else-if="isCreative">Creative Registration</span>
                </p>
            </div>
            <form @submit.prevent="form.post('/register')">
                <div class="input-wrapper mb-5">
                    <label class="input-label">Title</label>
                    <div class="input-holder">
                        <input v-model="form.title" :class="{'error' : form.errors.title}" placeholder="Title" type="text">
                    </div>
                </div>
                <div class="input-wrapper mb-5">
                    <label class="input-label">First Name</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="far fa-user"></i>
                        </div>
                        <input v-model="form.first_name" :class="{'error' : form.errors.first_name}" placeholder="First Name" type="text">
                    </div>
                </div>
                <div class="input-wrapper mb-5">
                    <label class="input-label">Last Name</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="far fa-user"></i>
                        </div>
                        <input v-model="form.last_name" :class="{'error' : form.errors.last_name}" placeholder="Last Name" type="text">
                    </div>
                </div>
                <div class="input-wrapper mb-5">
                    <label class="input-label">Email</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="far fa-envelope"></i>
                        </div>
                        <input v-model="form.email" :class="{'error' : form.errors.email}" placeholder="Email" type="email">
                    </div>
                </div>
                <div class="input-wrapper mb-5">
                    <label class="input-label">Password</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <input v-model="form.password" :class="{'error' : form.errors.password}" placeholder="Password" type="password">
                    </div>
                </div>
                <div class="input-wrapper mb-1">
                    <label class="input-label">Password Confirmation</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <input v-model="form.password_confirmation" :class="{'error' : form.errors.password_confirmation}" placeholder="Password" type="password">
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors" />
                <div class="input-submit mt-10">
                    <input :disabled="form.processing || form.title === '' || form.first_name === '' || form.last_name === '' || form.email === '' || form.password === '' || form.password_confirmation === ''" class="bg-secondary hover:bg-primary transition duration-300 w-full" type="submit" value="REGISTER">
                </div>
            </form>
            <div class="pt-32"></div>
            <div class="text-center">Already have an account?
                <InertiaLink :href="'/login?role=' + props.role" class="font-semibold text-primary pl-3">Sign In
                </InertiaLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import useRole from "@composables/useRole"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    role: {
        type: String,
        default: 'member'
    }
})

const {logoUrl, isCreative, isMember} = useRole(props.role)

const form = useForm({
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    type: props.role
})
</script>

<style lang="postcss">
.user-page {
    @apply h-screen w-full flex flex-wrap items-center justify-center overflow-y-auto p-5;
}

.card--user {
    @apply xl:w-3/12 lg:w-6/12 md:w-8/12 w-full;
}

.sep-text {
    @apply flex items-center my-10 text-gray-400;
}

.sep-line {
    @apply h-0.5 block bg-gray-100 flex-1;
}
</style>
