<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Financial Details</h5>
                    </div>
                    <div v-if="!member.is_archived" class="col">
                        <button @click="showMemberForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <table class="transaction-table">
                    <tbody>
                        <tr>
                            <th>Payment Plan</th>
                            <td>{{ startCase(toLower(member.payment_plan)) }}</td>
                        </tr>
                        <tr>
                            <th>Payment Method</th>
                            <td>{{ startCase(toLower(member.payment_method)) }}</td>
                        </tr>
                        <tr>
                            <th>Card on file</th>
                            <td>{{ has_card_on_file ? 'Provided' : 'Not Provided' }}</td>
                        </tr>
                        <tr>
                            <th>Reminder Period</th>
                            <td>{{ member.reminder_period }} days</td>
                        </tr>
                        <tr>
                            <th>Rate Tier</th>
                            <td>{{ member.member_rate_label ?? '£' + member.fixed_rate + ' (Fixed)' }}</td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4">
                            <th>Bespoke Rate Reason</th>
                            <td>{{ member.bespoke_rate_reason }}</td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4">
                            <th>Bespoke Rate Type</th>
                            <td>{{ member.bespoke_rate_is_customised ? 'CUSTOM' : 'DEFAULT' }}</td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4 && member.bespoke_rate_is_customised">
                            <th>Bespoke First 3 Hours (1-2 Children)</th>
                            <td>
                                {{ '£' + member.bespoke_rate_creative_first_3_hours_upto_two_children }}
                                <br>
                                {{ '£' + member.bespoke_rate_member_first_3_hours_upto_two_children }}
                            </td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4 && member.bespoke_rate_is_customised">
                            <th>Bespoke First 3 Hours (3 Children)</th>
                            <td>
                                {{ '£' + member.bespoke_rate_creative_first_3_hours_upto_three_children }}
                                <br>
                                {{ '£' + member.bespoke_rate_member_first_3_hours_upto_three_children }}
                            </td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4 && member.bespoke_rate_is_customised">
                            <th>Bespoke First 3 Hours (4+ Children)</th>
                            <td>
                                {{ '£' + member.bespoke_rate_creative_first_3_hours_over_four_children }}
                                <br>
                                {{ '£' + member.bespoke_rate_member_first_3_hours_over_four_children }}
                            </td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4 && member.bespoke_rate_is_customised">
                            <th>
                                Bespoke Additional Hours (1-2 Children)
                                <br>
                                <span class="text-gray-400 font-normal text-sm">per hour</span>
                            </th>
                            <td>
                                {{ '£' + member.bespoke_rate_creative_additional_hour_upto_two_children }}
                                <br>
                                {{ '£' + member.bespoke_rate_member_additional_hour_upto_two_children }}
                            </td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4 && member.bespoke_rate_is_customised">
                            <th>
                                Bespoke Additional Hours (3 Children)
                                <br>
                                <span class="text-gray-400 font-normal text-sm">per hour</span>
                            </th>
                            <td>
                                {{ '£' + member.bespoke_rate_creative_additional_hour_upto_three_children }}
                                <br>
                                {{ '£' + member.bespoke_rate_member_additional_hour_upto_three_children }}
                            </td>
                        </tr>
                        <tr v-if="member.member_rate_id === 4 && member.bespoke_rate_is_customised">
                            <th>
                                Bespoke Additional Hours (4+ Children)
                                <br>
                                <span class="text-gray-400 font-normal text-sm">per hour</span>
                            </th>
                            <td>
                                {{ '£' + member.bespoke_rate_creative_additional_hour_over_four_children }}
                                <br>
                                {{ '£' + member.bespoke_rate_member_additional_hour_over_four_children }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-member-form">
                <div v-if="showingMemberForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit Member</h4>
                            </div>
                            <div class="col">
                                <button @click="hideMemberForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitMemberForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Payment Plan</label>
                                        <select v-model="memberForm.payment_plan" :class="{'error' : memberForm.errors.payment_plan}">
                                            <option value="weekly">Weekly</option>
                                            <option value="fortnightly">Fortnightly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Payment Method</label>
                                        <select v-model="memberForm.payment_method" :class="{'error' : memberForm.errors.payment_method}">
                                            <option value="direct debit">Direct Debit</option>
                                            <option value="bank transfers">Bank Transfers</option>
                                            <option value="card">Card</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Reminder Period (in days)</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-calendar-alt"></i>
                                            </div>
                                            <input v-model="memberForm.reminder_period" :class="{'error' : memberForm.errors?.reminder_period}" placeholder="Reminder Period"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <label class="input-label">Rate Tier</label>
                                        <select v-model="memberForm.member_rate_id" :class="{'error' : memberForm.errors.member_rate_id}" @change="rateTierChanged">
                                            <option value="1">Standard</option>
                                            <option value="2">Partnership</option>
                                            <option value="3">Non-Member</option>
                                            <option value="4">Bespoke</option>
                                            <option value="">Fixed Rate</option>
                                        </select>
                                    </div>
                                    <div v-if="!memberForm.member_rate_id" class="input-wrapper mb-5">
                                        <label class="input-label">Fixed Rate</label>
                                        <div class="input-holder input-holder--icon">
                                            <div class="input-holder-icon">
                                                <i class="fas fa-pound-sign"></i>
                                            </div>
                                            <input v-model="memberForm.fixed_rate" :class="{'error' : memberForm.errors?.fixed_rate}" placeholder="Fixed Rate"
                                                   type="text">
                                        </div>
                                    </div>
                                    <div v-else-if="memberForm.member_rate_id == '4'">
                                        <hr class="mt-10 mb-10">
                                        <h6 class="sub-title">Bespoke Rates</h6>
                                        <div class="input-wrapper mb-10">
                                            <label class="input-label">Reason</label>
                                            <textarea v-model="memberForm.bespoke_rate_reason" name="bespoke_rate_reason" rows="5"></textarea>
                                        </div>
                                        <div class="input-wrapper mb-10">
                                            <label class="input-label">{{ memberForm.bespoke_rate_is_customised ? 'Custom Bespoke Rates' : 'Default Bespoke Rates' }}</label>
                                            <label class="switch">
                                                <input type="checkbox" v-model="memberForm.bespoke_rate_is_customised" @change="clearBespokeRatesErrors">
                                                <span class="switch-slider"></span>
                                            </label>
                                        </div>
                                        <div v-if="memberForm.bespoke_rate_is_customised">

                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Member Rate: 1-2 Children (First 3 Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_member_first_3_hours_upto_two_children" :class="{'error' : memberForm.errors.bespoke_rate_member_first_3_hours_upto_two_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Member Rate: 3 Children (First 3 Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_member_first_3_hours_upto_three_children" :class="{'error' : memberForm.errors.bespoke_rate_member_first_3_hours_upto_three_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Member Rate: 4+ Children (First 3 Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_member_first_3_hours_over_four_children" :class="{'error' : memberForm.errors.bespoke_rate_member_first_3_hours_over_four_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Member Rate: 1-2 Children (Additional Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_member_additional_hour_upto_two_children" :class="{'error' : memberForm.errors.bespoke_rate_member_additional_hour_upto_two_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Member Rate: 3 Children (Additional Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_member_additional_hour_upto_three_children" :class="{'error' : memberForm.errors.bespoke_rate_member_additional_hour_upto_three_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Member Rate: 4+ Children (Additional Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_member_additional_hour_over_four_children" :class="{'error' : memberForm.errors.bespoke_rate_member_additional_hour_over_four_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <hr class="mt-10 mb-10">
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Creative Rate: 1-2 Children (First 3 Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_creative_first_3_hours_upto_two_children" :class="{'error' : memberForm.errors.bespoke_rate_creative_first_3_hours_upto_two_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Creative Rate: 3 Children (First 3 Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_creative_first_3_hours_upto_three_children" :class="{'error' : memberForm.errors.bespoke_rate_creative_first_3_hours_upto_three_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Creative Rate: 4+ Children (First 3 Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_creative_first_3_hours_over_four_children" :class="{'error' : memberForm.errors.bespoke_rate_creative_first_3_hours_over_four_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Creative Rate: 1-2 Children (Additional Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_creative_additional_hour_upto_two_children" :class="{'error' : memberForm.errors.bespoke_rate_creative_additional_hour_upto_two_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Creative Rate: 3 Children (Additional Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_creative_additional_hour_upto_three_children" :class="{'error' : memberForm.errors.bespoke_rate_creative_additional_hour_upto_three_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                            <div class="input-wrapper mb-10">
                                                <label class="input-label">Creative Rate: 4+ Children (Additional Hours)</label>
                                                <div class="input-holder input-holder--icon">
                                                    <div class="input-holder-icon">
                                                        <i class="fas fa-pound-sign"></i>
                                                    </div>
                                                    <input v-model="memberForm.bespoke_rate_creative_additional_hour_over_four_children" :class="{'error' : memberForm.errors.bespoke_rate_creative_additional_hour_over_four_children}"
                                                           min="0" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="mt-10 mb-10">
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="memberForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!memberForm.isDirty || memberForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout";
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3";
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {toLower, startCase } from "lodash";

const props = defineProps({
    member: {
        type: Object,
        required: true
    },
    has_card_on_file: {
        type: Boolean,
        required: true
    }
})

const memberForm = useForm({
    payment_plan: props.member.payment_plan,
    payment_method: props.member.payment_method,
    fixed_rate: props.member.fixed_rate,
    member_rate_id: props.member.member_rate_id ?? "",
    reminder_period: props.member.reminder_period,

    bespoke_rate_is_customised: props.member.bespoke_rate_is_customised,
    bespoke_rate_reason: props.member.bespoke_rate_reason,
    bespoke_rate_creative_first_3_hours_upto_two_children: props.member.bespoke_rate_creative_first_3_hours_upto_two_children,
    bespoke_rate_creative_first_3_hours_upto_three_children: props.member.bespoke_rate_creative_first_3_hours_upto_three_children,
    bespoke_rate_creative_first_3_hours_over_four_children: props.member.bespoke_rate_creative_first_3_hours_over_four_children,
    bespoke_rate_creative_additional_hour_upto_two_children: props.member.bespoke_rate_creative_additional_hour_upto_two_children,
    bespoke_rate_creative_additional_hour_upto_three_children: props.member.bespoke_rate_creative_additional_hour_upto_three_children,
    bespoke_rate_creative_additional_hour_over_four_children: props.member.bespoke_rate_creative_additional_hour_over_four_children,
    bespoke_rate_member_first_3_hours_upto_two_children: props.member.bespoke_rate_member_first_3_hours_upto_two_children,
    bespoke_rate_member_first_3_hours_upto_three_children: props.member.bespoke_rate_member_first_3_hours_upto_three_children,
    bespoke_rate_member_first_3_hours_over_four_children: props.member.bespoke_rate_member_first_3_hours_over_four_children,
    bespoke_rate_member_additional_hour_upto_two_children: props.member.bespoke_rate_member_additional_hour_upto_two_children,
    bespoke_rate_member_additional_hour_upto_three_children: props.member.bespoke_rate_member_additional_hour_upto_three_children,
    bespoke_rate_member_additional_hour_over_four_children: props.member.bespoke_rate_member_additional_hour_over_four_children,
})

const submitMemberForm = () => {
    memberForm.post('/admin/users/members/' + props.member.id + '/update/financial', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        preserveState: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideMemberForm(),
    })
}

const { showing: showingMemberForm, show: showMemberForm, hide: hideMemberForm } = useToggleable('edit-member-form')

const rateTierChanged = () => {
    memberForm.bespoke_rate_is_customised = false
    clearBespokeRatesErrors()
}
const clearBespokeRatesErrors = () => memberForm.clearErrors('bespoke_rate_is_customised', 'bespoke_rate_reason', 'bespoke_rate_creative_first_3_hours_upto_two_children', 'bespoke_rate_creative_first_3_hours_upto_two_children', 'bespoke_rate_creative_first_3_hours_upto_three_children', 'bespoke_rate_creative_first_3_hours_upto_three_children', 'bespoke_rate_creative_first_3_hours_upto_three_children', 'bespoke_rate_creative_first_3_hours_over_four_children', 'bespoke_rate_creative_additional_hour_upto_two_children', 'bespoke_rate_creative_additional_hour_upto_three_children', 'bespoke_rate_creative_additional_hour_over_four_children', 'bespoke_rate_member_first_3_hours_upto_two_children', 'bespoke_rate_member_first_3_hours_upto_three_children', 'bespoke_rate_member_first_3_hours_over_four_children', 'bespoke_rate_member_additional_hour_upto_two_children', 'bespoke_rate_member_additional_hour_upto_three_children', 'bespoke_rate_member_additional_hour_over_four_children')

</script>
