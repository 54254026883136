<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10 whitespace-pre-wrap">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">About</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showUserForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <p>{{ user.about?.about ?? '-' }}</p>
                <h5 class="sub-title">Childcare Experience</h5>
                <p>{{ user.about?.childcare_experience ?? '-' }}</p>
                <h5 class="sub-title">Creative Experience</h5>
                <p>{{ user.about?.creative_experience ?? '-' }}</p>
                <h5 class="sub-title">Favourite Children's Story</h5>
                <p>{{ user.about?.favourite_childrens_story ?? '-' }}</p>
                <h5 class="sub-title">Superpower</h5>
                <p>{{ user.about?.superpower ?? '-' }}</p>
                <h5 class="sub-title">Medical</h5>
                <p>{{ user.about?.medical ?? '-' }}</p>
                <h5 class="sub-title">Criminal Convictions</h5>
                <p>{{ user.about?.criminal_convictions ?? '-' }}</p>
            </div>
        </template>
        <template v-slot:extra>
            <div id="edit-user-form">
                <div v-if="showingUserForm" class="page-card mt-10">
                    <div class="page-card-content">
                        <div class="flex flex-wrap items-center justify-between gap-4">
                            <div class="col">
                                <h4>Edit User</h4>
                            </div>
                            <div class="col">
                                <button @click="hideUserForm" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <div class="pb-10"></div>
                        <form @submit.prevent="submitUserForm">
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <div class="col">
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">About</label>
                                            <textarea v-model="userForm.about" :class="{'error' : userForm.errors?.about}" name="about" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Childcare Experience</label>
                                            <textarea v-model="userForm.childcare_experience" :class="{'error' : userForm.errors?.childcare_experience}" name="childcare_experience" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Creative Experience</label>
                                            <textarea v-model="userForm.creative_experience" :class="{'error' : userForm.errors?.creative_experience}" name="creative_experience" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Favourite children's story</label>
                                            <textarea v-model="userForm.favourite_childrens_story" :class="{'error' : userForm.errors?.favourite_childrens_story}" name="favourite_childrens_story" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Superpower</label>
                                            <textarea v-model="userForm.superpower" :class="{'error' : userForm.errors?.superpower}" name="superpower" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Criminal Convictions</label>
                                            <textarea v-model="userForm.criminal_convictions" :class="{'error' : userForm.errors?.criminal_convictions}" name="criminal_convictions" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="input-wrapper mb-5">
                                        <div class="input-wrapper mb-5">
                                            <label class="input-label">Medical</label>
                                            <textarea v-model="userForm.medical" :class="{'error' : userForm.errors?.medical}" name="medical" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormErrorsGroup :errors="userForm.errors"/>
                            <div class="input-submit mt-10">
                                <input :disabled="!userForm.isDirty || userForm.processing" class="bg-secondary hover:bg-primary transition duration-300"
                                       type="submit" value="Update">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout"
import useToggleable from "@composables/useToggleable"
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const userForm = useForm({
    about: props.user.about?.about,
    childcare_experience: props.user.about?.childcare_experience,
    creative_experience: props.user.about?.creative_experience,
    favourite_childrens_story: props.user.about?.favourite_childrens_story,
    superpower: props.user.about?.superpower,
    criminal_convictions: props.user.about?.criminal_convictions,
    medical: props.user.about?.medical
})

const submitUserForm = () => {
    userForm.post('/admin/users/creatives/' + props.user.id + '/update/about', {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => hideUserForm(),
    })
}

const { showing: showingUserForm, show: showUserForm, hide: hideUserForm } = useToggleable('edit-user-form')

</script>
