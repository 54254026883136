<template>
    <div class="-mt-10">
        <div class="flex justify-center">
            <perfect-scrollbar class="lg:overflow-x-scroll w-full">
                <div class="lg:flex gap-4">
                    <div class="col-3 kanban-col-wrap kanban-col-wrap-tall flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Application received</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <draggable class="list-group min-h-full px-6 pb-4" :list="creativesReceived" group="booking" itemKey="id" :animation="200" ghost-class="ghost-card" @change="addedToReceived">
                                <template #item="{ element, index }">
                                    <CreativeApplicationKanbanCard class="cursor-move" :key="index" :creative="element" />
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap kanban-col-wrap-tall flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Invited for interview</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <draggable class="list-group min-h-full px-6 pb-4" :list="creativesInterview" group="booking" itemKey="id" :animation="200" ghost-class="ghost-card" @change="addedToInterview">
                                <template #item="{ element, index }">
                                    <CreativeApplicationKanbanCard class="cursor-move" :key="index" :creative="element" />
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap kanban-col-wrap-tall flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Successful. Referencing</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <draggable class="list-group min-h-full px-6 pb-4" :list="creativesReferencing" group="booking" itemKey="id" :animation="200" ghost-class="ghost-card" @change="addedToReferencing">
                                <template #item="{ element, index }">
                                    <CreativeApplicationKanbanCard class="cursor-move" :key="index" :creative="element" />
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap kanban-col-wrap-tall flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Passed. Awaiting Profile</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <draggable class="list-group min-h-full px-6 pb-4" :list="creativesAwaitingProfile" group="booking" itemKey="id" :animation="200" ghost-class="ghost-card" @change="addedToAwaitingProfile">
                                <template #item="{ element, index }">
                                    <CreativeApplicationKanbanCard class="cursor-move" :key="index" :creative="element" />
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap kanban-col-wrap-tall flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Waiting on DBS</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <draggable class="list-group min-h-full px-6 pb-4" :list="creativesAwaitingDbs" group="booking" itemKey="id" :animation="200" ghost-class="ghost-card" @change="addedToAwaitingDbs">
                                <template #item="{ element, index }">
                                    <CreativeApplicationKanbanCard class="cursor-move" :key="index" :creative="element" />
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap kanban-col-wrap-tall flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Ready to Onboard</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <draggable class="list-group min-h-full px-6 pb-4" :list="creativesReady" group="booking" itemKey="id" :animation="200" ghost-class="ghost-card" @change="addedToReady">
                                <template #item="{ element, index }">
                                    <CreativeApplicationKanbanCard class="cursor-move" :key="index" :creative="element" />
                                </template>
                            </draggable>
                        </perfect-scrollbar>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from "vue"
import draggable from 'vuedraggable'
import CreativeApplicationKanbanCard from "@components/admin/CreativeApplicationKanbanCard"
import usePaginator from "@composables/usePaginator"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    creativesReceived: {
        type: Array,
        default: []
    },
    creativesInterview: {
        type: Array,
        default: []
    },
    creativesReferencing: {
        type: Array,
        default: []
    },
    creativesAwaitingProfile: {
        type: Array,
        default: []
    },
    creativesAwaitingDbs: {
        type: Array,
        default: []
    },
    creativesReady: {
        type: Array,
        default: []
    },
})

const updateApplicationStatus = (userId, newStatus) => {
    Inertia.post('/admin/users/creatives/applications', {
        user_id: userId,
        status: newStatus,
    }, {
        preserveState: true,
        preserveScroll: true
    })
}

const addedToReceived = (e) => {
    if (e.added && e.added?.element?.id) {
        updateApplicationStatus(e.added.element.id, 'received')
    }
}
const addedToInterview = (e) => {
    if (e.added && e.added?.element?.id) {
        updateApplicationStatus(e.added.element.id, 'interview')
    }
}
const addedToReferencing = (e) => {
    if (e.added && e.added?.element?.id) {
        updateApplicationStatus(e.added.element.id, 'referencing')
    }
}
const addedToAwaitingProfile = (e) => {
    if (e.added && e.added?.element?.id) {
        updateApplicationStatus(e.added.element.id, 'awaiting_profile')
    }
}
const addedToAwaitingDbs = (e) => {
    if (e.added && e.added?.element?.id) {
        updateApplicationStatus(e.added.element.id, 'awaiting_dbs')
    }
}
const addedToReady = (e) => {
    if (e.added && e.added?.element?.id) {
        updateApplicationStatus(e.added.element.id, 'ready')
    }
}

</script>

<style lang="postcss">
.kanban-col-wrap {
    background-color: rgba(221, 226, 232, 1);
    @apply pt-6 rounded-3xl mb-6 lg:mb-0;
}

@media(min-width:1024px) {
    .kanban-col-wrap-tall,
    .kanban-col-wrap-tall .kanban-col,
    .kanban-col-wrap-tall .kanban-col .list-group {
        min-height: 72vh;
    }
}

.kanban-col-wrap .kanban-col .list-group {
    @apply flex flex-col gap-4
}
@media(min-width:1024px) {
    .kanban-col-wrap {
        min-width: 280px;
        width: 280px;
    }
}
@media(max-width:1023px) {
    .kanban-col-wrap .kanban-col .list-group {min-height: 40vh;}
}
.ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border: 1px solid #4299e1;
}


</style>
