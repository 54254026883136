<template>
    <booking-card class="relative">
        <InertiaLink :href="'/admin/bookings/' + booking.id" class="">
            <div class="mb-2 flex justify-between">
                <div class="block text-md font-semibold mb-1">{{booking.member?.name}} Family <i v-if="booking.member?.is_vip" class="fas fa-check-circle primary"></i></div>
                <button @click.prevent="flagsOverlay = true" class="w-6 h-6 leading-6 rounded-full bg-flag text-white text-center text-xs" :class="flagClass">
                    <i v-if="flagValue === 9" class="fas fa-flag"></i>
                    <i v-else class="fas fa-user"></i>
                </button>
            </div>
            <div class="flex justify-between items-center">
                <div>
                    <div class="text-accent text-sm">{{ booking.kb_start_date }}</div>
                    <div class="text-accent text-sm">{{ booking.kb_times }}</div>
                    <div v-if="booking.block" class="text-accent text-sm">part of {{ booking.block.bookings_count - 1 }} other dates</div>
                </div>
                <button v-if="booking.block" @click.prevent="showBlock" class="w-6 h-6 leading-6 rounded-full bg-secondary hover:bg-primary text-white text-center text-xs">
                    <i class="fas fa-redo"></i>
                </button>
            </div>
        </InertiaLink>
        <transition name="scale-in">
            <div class="absolute top-0 left-0 bottom-0 right-0 bg-white rounded-xl p-4" v-if="flagsOverlay">
                <div class=" flex justify-center items-center">
                    <div class="col grid grid-cols-4 gap-4">
                        <div class="col" v-for="item in 8" :key="item">
                            <button class="w-6 h-6 leading-6 rounded-full bg-flag text-white text-center text-xs shadow hover:opacity-50" :class="'bg-flag-' + item" @click="changeFlag(item)">
                                <i v-if="item === 9 || (item === 10 && booking.flag === 9)" class="fas fa-flag"></i>
                                <i v-else class="fas fa-user"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col grid grid-cols-1 gap-4 border-l-2 border-gray-300 ml-4 pl-4">
                        <div class="col" v-for="item in 2" :key="item">
                            <button class="w-6 h-6 leading-6 rounded-full bg-flag text-white text-center text-xs shadow hover:opacity-50" :class="'bg-flag-' + (item + 8)" @click="changeFlag(item + 8)">
                                <i v-if="(item + 8) === 9 || ((item + 8) === 10 && booking.flag === 9)" class="fas fa-flag"></i>
                                <i v-else class="fas fa-user"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </booking-card>
</template>

<script setup>
import {computed, ref, watch, onMounted} from "vue"
import BookingCard from "@components/admin/BookingCard"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const flagValue = ref(props.booking.flag)
const flagClass = computed(() => {
    return 'bg-flag-' + flagValue.value
})

const changeFlag = (flagNumber) => {
    Inertia.post('/admin/bookings/' + props.booking.id + '/update-flag', {
        flag: flagNumber
    }, {
        preserveScroll: true,
        onFinish: (p) => {
            flagValue.value = flagNumber > 0 ? flagNumber : null
            flagsOverlay.value = false
        }
    })
}
const flagsOverlay = ref(false);

const emit = defineEmits(['show-block'])

const showBlock = () => {
    if (props.booking.block?.id) {
        emit('show-block', props.booking.block.id)
    }
}
</script>
<style lang="postcss">
.bg-flag {
    background-color: lightgray;
}
.bg-flag-1 {
    background-color: black; /** black */
}
.bg-flag-2 {
    background-color: #00008b; /** dark blue */
}
.bg-flag-3 {
    background-color: #7cc7e1; /** light blue */
}
.bg-flag-4 {
    background-color: green; /** green */
}
.bg-flag-5 {
    background-color: #fce903; /** dark yellow */
}
.bg-flag-6 {
    background-color: #fc9303; /** orange */
}
.bg-flag-7 {
    background-color: mediumpurple; /** purple */
}
.bg-flag-8 {
    background-color: #ec677d; /** pink */
}
.bg-flag-9 {
    background-color: red;
}

.scale-in-enter-active,
.scale-in-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.scale-in-enter-from,
.scale-in-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
</style>
