<template>
    <CreativeBookingDetailsLayout :booking="booking">
        <template v-slot:action>
            <div v-if="booking.can_edit" class="col">
                <button @click="showExpenseForm" class="btn">
                    <i class="fas fa-edit"></i>
                    Add Expense
                </button>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10">
                <h5 class="sub-title">Expenses</h5>
                <div class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Title</th>
                            <th>Price</th>
                            <th>Receipt</th>
                            <th>Notes</th>
                        </tr>
                        <tr v-for="(expense, index) in booking.expenses" :key="index">
                            <td>
                                <div class="text">{{ expense.title }}</div>
                            </td>
                            <td>
                                <div class="">{{ expense.price }}</div>
                            </td>
                            <td>
                                <a v-if="expense.receipt" :href="expense.receipt" target="_blank" class="text-primary">View Receipt</a>
                            </td>
                            <td>
                                <div class="">{{ expense.notes }}</div>
                            </td>
                            <td v-if="booking.can_edit">
                                <button @click="removeExpense(expense.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                            </td>
                        </tr>
                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td style="text-align: left">{{ booking.expenses_total }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="add-expense-form">
                <div v-if="showingExpenseForm">
                    <div class="mt-10"></div>
                    <ExpenseForm
                        :url="'/creative/bookings/' + booking.id + '/expenses/add'"
                        @closed="hideExpenseForm"
                    />
                </div>
            </div>
        </template>
    </CreativeBookingDetailsLayout>
</template>

<script setup>
import CreativeBookingDetailsLayout from "@components/creative/CreativeBookingDetailsLayout";
import useToggleable from "@composables/useToggleable"
import {Inertia} from "@inertiajs/inertia";
import ExpenseForm from "@components/utilities/ExpenseForm"

const props = defineProps({
    booking: {
        type: Object,
        required: true
    }
})

const { showing: showingExpenseForm, show: showExpenseForm, hide: hideExpenseForm } = useToggleable('add-expense-form')
const removeExpense = (expenseId) => {
    Inertia.post('/creative/bookings/' + props.booking.id + '/expenses/' + expenseId + '/remove')
}
</script>
