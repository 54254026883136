import {computed} from "vue"
import {usePage} from "@inertiajs/inertia-vue3"

export default function useRole(role) {
    if (role === null) {
        role = usePage().props?.value?.auth?.user?.role ?? 'member'
    }

    const logoUrl = computed(() => {
        if (role === 'admin' || role === 'super admin') {
            return '/img/admin-logo-square.png'
        } else if (role === 'creative') {
            return '/img/creative-logo-square.png'
        } else {
            return '/img/member-logo-square.png'
        }
    })

    const isAdmin = computed(() => role === 'admin' || role === 'super admin')
    const isSuperAdmin = computed(() => usePage().props?.value?.auth?.user?.role === 'super admin')
    const isCreative = computed(() => role === 'creative')
    const isMember = computed(() => role === 'member')

    if (role === 'super admin') {
        role = 'admin'
    }

    return {
        logoUrl,
        isSuperAdmin,
        isAdmin,
        isCreative,
        isMember,
        role
    }
}
