<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto lg:hidden mb-10">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/member/profile/view" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/profile/view')}">Overview</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/member/profile/children" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/profile/children')}">Children</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/member/profile/users" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/profile/users')}">Users</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/member/profile/addresses" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/profile/addresses')}">Addresses</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/member/profile/team" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/member/profile/team')}">Team</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>

</style>
