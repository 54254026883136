<template>
    <div class="mt-20">
        <div class="col-lg-3 centered-div">
            <!-- <h3 class="text-center-sm text-center-xs">How we can help</h3> -->
            <div class="space50"></div>
        </div>
        <div class="row">
            <div class="space30 d-lg-none d-xl-none"></div>
            <div class="col-lg-12 centered-div">
                <div class="row gutter-50 process-wrapper line text-center justify-content-center">
                    <div v-for="(item, index) in processes" :key="'Process_'+index" class="col-md-3"
                         style="cursor: pointer;" @click="toggleActive(item)" @mouseover="toggleActive(item)">
                        <span :class="{active: active.title === item.title}" class="icon icon-bg bg-default mb-20"><span
                            class="icon-img" style="font-size: 24px;"><i style="vertical-align: initial" :class="item.icon"></i></span></span>
                        <h5>{{ item.title }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="space50"></div>
        <div>
            <div v-if="active">
                <div class="text-center container">
                    <div class="h-72 sm:h-48 md:h-32 overflow-auto">
                        <div class="message">{{ active.description }}</div>
                    </div>

                    <div v-if="active.buttonLabel && active.buttonLink" class="button-wrapper text-center my-8">
                        <InertiaLink v-if="active.buttonLink !== 'email'" class="btn btn--outline btn--rounded" :href="active.buttonLink">{{ active.buttonLabel }}</InertiaLink>
                        <a v-else class="btn btn--outline btn--rounded" :href="'mailto:london@beaandco.com?subject=' + active.subject">{{ active.buttonLabel }}</a>
                    </div>
                </div>
                <div v-if="active.bannerImages?.length" class="mt-12">
                    <slider-banner-component :images="active.bannerImages" :logo="false" title="" />
                </div>
                <div class="space50"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SliderBannerComponent from "@components/web/SliderBannerComponent";
import {ref} from "vue";

const processes = [
    {
        title: "Childcare",
        description: "All our child sitters are cleared by the UK government’s Disclosure and Barring Service DBS (CRB) and paediatric first aid trained. We match our families with the very best in flexible creative childcare at home and on the road, for every scenario.",
        icon: "fas fa-children",
        buttonLink: "/home-childcare", // TODO
        buttonLabel: "More",
        bannerImages: ['home_services_childcare_1.jpg', 'home_services_childcare_2.jpg', 'home_services_childcare_3.jpg', 'home_services_childcare_4.jpg', 'home_services_childcare_5.jpg', 'home_services_childcare_6.jpg', 'home_services_childcare_7.jpg']
    },
    {
        title: "Hotels & Homestay",
        description: "We work with a number of the UK’s most prestigious hotels, country clubs and homestay properties. Our multi-lingual, service-trained nannies are experts in crafting adventures in all environments to make sure your child’s stay is just as enjoyable as your own.",
        icon: "fas fa-bell-concierge",
        buttonLink: "/hotels-and-homestay", // TODO
        buttonLabel: "More",
        bannerImages: ['home_services_hotels_1.jpg', 'home_services_hotels_2.jpg', 'home_services_hotels_3.jpg', 'home_services_hotels_4.jpg', 'home_services_hotels_5.jpg', 'home_services_hotels_6.jpg', 'home_services_hotels_7.jpg', ]
    },
    {
        title: "International Travel",
        subject: "New%20International%20Travel%20Enquiry",
        description: "Our nannies are well used to touring on complex travel schedules and are masters of finding magic in the face of any delays. We regularly travel with families for business or holiday, on land or sea, either for the duration or simply as a travel-aid in the air.",
        icon: "fas fa-plane",
        buttonLink: "email", // TODO
        buttonLabel: "Contact Us",
        bannerImages: ['home_services_international_1.jpg', 'home_services_international_2.jpg', 'home_services_international_3.jpg', 'home_services_international_4.jpg',  'home_services_international_5.jpg',  'home_services_international_6.jpg', ]
    },
    {
        title: "Events & Entertainment",
        subject: "New%20Events%20%26%20Entertainment%20Enquiry",
        description: "We work closely with event organiser and entertainment companies across festivals, large scale public events, conferences, weddings and private parties, helping stakeholders effortlessly deliver the best family-friendly experiences to their guests and delegates.",
        icon: "fas fa-ticket",
        buttonLink: "email", // TODO
        buttonLabel: "Contact Us",
        bannerImages: ['home_services_events_1.jpg', 'home_services_events_2.jpg', 'home_services_events_3.jpg', 'home_services_events_4.jpg']
    },
    // {
    //     title: "Entertainment Companies",
    //     description: "We work regularly for production and entertainment companies and can provide fully certified chaperones at all stages of theatre, television and film projects, both nationally and internationally.",
    //     icon: "fas fa-calendar",
    //     buttonLink: "/entertainment-companies", // TODO
    //     buttonLabel: "More",
    //     bannerImages: ['banners/home0.jpg', 'banners/home2.jpg', 'banners/home3.jpg']
    // },
    // {
    //     title: "Business Solutions",
    //     description: "We work closely with businesses to cater for internal childcare packages, in house events, and partnerships that enrich client and customer experience. Our creative talent pool gives us the innovative and personable edge in providing a service tailor-made to fit your company culture.",
    //     icon: "fas fa-calendar",
    //     buttonLink: "email", // TODO
    //     buttonLabel: "Contact Us",
    // },
]
const active = ref(processes[0])
const toggleActive = (process) => active.value = process

</script>

<style scoped>
span.active {
    background-color: #F4F4F4 !important;
    color: #a1ede3 !important;
}
</style>
