<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Add Note</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitNoteForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Note</label>
                            <textarea v-model="noteForm.note" name="note" rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="noteForm.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmitNote" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Add Note">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"

const props = defineProps({
    url: {
        type: String,
        required: true
    }
})

const emits = defineEmits(['closed'])

const noteForm = useForm({
    note: null,
})
const canSubmitNote = computed(() => {
    return !noteForm.processing && noteForm.note
})

const submitNoteForm = () => {
    noteForm.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    noteForm.reset()
})
</script>
