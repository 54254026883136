<template>
    <div class="page-filters-section block lg:hidden">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/finance/transactions" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/finance/transactions')}">Transactions</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/invoices" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/finance/invoices')}">Invoices</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/remittances" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/finance/remittances')}">Remittance</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pb-10"></div>
        </div>
    </div>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/finance/invoices?status=pending" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/finance/invoices?status=pending'}">Pending</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/invoices?status=paid" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/finance/invoices?status=paid'}">Paid</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/invoices?status=overdue" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/finance/invoices?status=overdue'}">Overdue</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/finance/invoices?status=cancelled" as="button" class="filter-nav-btn" :class="{'active': $page.url === '/admin/finance/invoices?status=cancelled'}">Cancelled</InertiaLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="page-filters-section mt-10">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="col">
                            <div class="search-form">
                                <input v-model="searchTerm" type="text">
                                <button :disabled="true">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="flex">
                        <div class="col block">
                            <a :href="'/admin/finance/invoices/export?status=' + status" target="_blank" class="btn">
                                <i class="fas fa-print"></i>
                                Export to CSV
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-10"></div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Invoices</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="pt-2"></div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Member</th>
                        <th>Total</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    <tr v-for="(invoice, index) in items" :key="index">
                        <td>
                            <InertiaLink :href="'/admin/users/members/' + invoice.member.id">
                                <div class="text-gray-600">{{ invoice.member.name }}</div>
                            </InertiaLink>
                        </td>
                        <td>
                            <div class="text-gray-600">{{ invoice.breakdown?.total }} <span v-if="invoice.notes?.length" class="pl-1"><i class="far fa-sticky-note"></i></span></div>
                            <div class="text-gray-600 font-normal">{{ '(' + invoice.breakdown?.bookings_count + ' Booking' + (invoice.breakdown?.bookings_count > 1 ? 's' : '') + ')' }}</div>
                        </td>
                        <td>
                            <div class="text-gray-600 font-normal">{{ invoice.created_at_timestamp }}</div>
                            <div class="text-gray-600 font-normal" :class="{'text-secondary' : invoice.is_overdue}">{{ invoice.due_at_timestamp }}</div>
                        </td>
                        <td>
                            <div v-if="invoice.status === 'Paid'" class="text-primary">Paid</div>
                            <div v-else-if="invoice.status === 'Cancelled'">Cancelled</div>
                            <div v-else-if="invoice.is_overdue" class="text-secondary">Overdue</div>
                            <div v-else>Pending</div>
                        </td>
                        <td>
                            <div class="flex gap-2">
                                <div class="col">
                                    <InertiaLink :href="'/admin/finance/invoices/' + invoice.id" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-pen"></i>
                                        Details
                                    </InertiaLink>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import usePaginator from "@composables/usePaginator"
import {computed, ref, watch} from "vue"

const props = defineProps({
    status: {
        type: String,
        default: 'pending'
    }
})

const searchTerm = ref("")

const apiUrl = computed(() => {
    let url = '/admin/finance/invoices/list'

    let params = new URLSearchParams()
    params.append('status', encodeURIComponent(props.status))

    if (searchTerm.value !== "") {
        params.append('terms', encodeURIComponent(searchTerm.value))
    }

    let suffix = params.toString()
    return url + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
<style lang="postcss">
</style>
