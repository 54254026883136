<template>
    <div class="home">
        <div class="container">
            <process-component :processes="processes"/>
        </div>
        <gallery-component :gallery="gallery"/>
    </div>
</template>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";
import PricingComponent from "@components/web/PricingComponent";
import TestimonialsComponent from "@components/web/TestimonialsComponent";
import ProcessComponent from "@components/web/ProcessComponent";
import GalleryComponent from "@components/web/GalleryComponent";

export default {
    layout: [BaseLayout, WebLayout],
    name: "Service",
    props: {
        banner_images: {
            type: Array,
            default() {
                return []
            }
        },
        processes: {
            type: Array,
            default() {
                return []
            }
        },
        testimonials: {
            type: Array,
            default() {
                return []
            }
        },
        banner_header_service: {
            type: Object,
            default() {
                return null
            }
        },
        intro_text_service: {
            type: Object,
            default() {
                return null
            }
        },
        gallery: {
            type: Array,
            default() {
                return []
            }
        },
    },
    components: {
        PricingComponent,
        TestimonialsComponent,
        ProcessComponent,
        GalleryComponent,
    }
}
</script>

<style scoped>

</style>
