<template>
    <CreativeAccountFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Document History</h4>
                </div>
                <div class="col">
                    <button @click="showAddDocForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Add Document
                    </button>
                </div>
            </div>
            <div class="mt-10">
                <div v-if="user.documents.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Type & Authority</th>
                            <th>Number</th>
                            <th>File</th>
                            <th>Date Added</th>
                            <th>Expiry Date</th>
                        </tr>
                        <tr v-for="(doc, index) in user.documents" :key="index">
                            <td>
                                <div class="">{{ doc.type }}</div>
                                <span class="font-normal" >{{ doc.issuing_authority }}</span>
                            </td>
                            <td><div class="">{{ doc.number }}</div></td>
                            <td><a :href="doc.file_url" target="_blank" class="text-primary">{{ doc.filename.substr(0, 3) + '...' + doc.filename.substr(-10) }}</a></td>
                            <td><div class="text-gray-600 font-normal">{{ doc.created_at_short }}</div></td>
                            <td>
                                <div :class="{'text-secondary': doc.is_expired}">{{ doc.expiry_date }}</div>
                                <span class="font-normal" :class="{'text-secondary': doc.is_expired}">{{ doc.expiry_date_short }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div id="add-document-form">
        <div v-if="showingAddDocForm">
            <div class="mt-10"></div>
            <DocumentForm
                url="/creative/account/documents/add"
                @closed="hideAddDocForm"
            />
        </div>
    </div>
</template>

<script setup>
import useToggleable from "@composables/useToggleable"
import DocumentForm from "@components/utilities/DocumentForm"
import CreativeAccountFilters from "@components/creative/CreativeAccountFilters"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})
const { showing: showingAddDocForm, show: showAddDocForm, hide: hideAddDocForm } = useToggleable('add-document-form')

</script>

<style lang="postcss">
body {
}
</style>
