<template>
  <div class="home">
    <nav-component/>
    <banner-component :title="banner_header_service ? banner_header_service.title: null" :images="banner_images"/>
    <div class="container">
      <div v-if="false && intro_text_service">
        <div class="spacer50"></div>
        <h2 style="color: #A1EDE3">{{ intro_text_service.title }}</h2>
        <div class="message" style="white-space: pre-line">{{ intro_text_service.description }}</div>
      </div>
      <process-component :processes="processes"/>
    </div>
    <gallery-component :gallery="gallery"/>
    <div class="container">
      <div class="spacer50"></div>
<!--      <div class="spacer30"></div>-->
<!--      <pricing-component :fees_header="fees_header" :prices="prices"/>-->
<!--      <div class="spacer50"></div>-->
<!--      <div class="inline-banner"></div>-->
      <div class="spacer50"></div>
      <testimonials-component :testimonials="testimonials"/>
      <div class="spacer100"></div>
    </div>
    <footer-component/>
  </div>
</template>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";
import PricingComponent from "@components/web/PricingComponent";
import TestimonialsComponent from "@components/web/TestimonialsComponent";
import ProcessComponent from "@components/web/ProcessComponent";
import GalleryComponent from "@components/web/GalleryComponent";

export default {
    layout: [BaseLayout, WebLayout],
  name: "Service",
  props: {
    banner_images: {
      type: Array,
      default() {
        return []
      }
    },
    processes: {
      type: Array,
      default() {
        return []
      }
    },
    testimonials: {
      type: Array,
      default() {
        return []
      }
    },
    banner_header_service: {
      type: Object,
      default() {
        return null
      }
    },
    intro_text_service: {
      type: Object,
      default() {
        return null
      }
    },
    gallery: {
      type: Array,
      default (){return []}
    },
  },
  components: {
    PricingComponent,
    TestimonialsComponent,
    ProcessComponent,
    GalleryComponent,
  }
}
</script>

<style scoped>

</style>
