<template>
    <div class="editor" v-if="editor">
        <menu-bar v-if="editable" class="editor__header" :editor="editor" />
        <perfect-scrollbar class="editor__content">
            <editor-content :editor="editor" />
        </perfect-scrollbar>
    </div>
</template>

<script setup>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import MenuBar from '@/components/utilities/TipTapMenuBar.vue'
import {onMounted, onUnmounted, ref} from "vue";

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    editable: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['update:modelValue'])

const editor = new Editor({
    editable: props.editable,
    extensions: [
        StarterKit,
        TextAlign.configure({
            types: ['heading', 'paragraph'],
        }),
    ],
    onUpdate: () => {
        emit('update:modelValue', editor.getHTML())
    },
    content: props.modelValue,
})

onMounted(() => {
    let elements = document.getElementsByClassName('editor__content')
    if (elements) {
        let div = elements[0]
        if (div) {
            let greeting = document.createElement("p")
            greeting.innerText = "Dear First_Name"
            greeting.style.fontStyle = "italic"
            greeting.style.cursor = "default"
            div.prepend(greeting)
        }
    }
})

onUnmounted(() => {
    editor.destroy()
})
</script>

<style lang="postcss">
.editor {
    height: 44.5rem;
    @apply flex flex-col bg-white border-2 border-primary rounded-xl;
}
.editor__header {
    @apply flex items-center flex-wrap p-2 border-b-2 border-primary;
}
.editor__content {
    -webkit-overflow-scrolling: touch;
    @apply px-4 py-4 flex-1 overflow-x-hidden overflow-y-auto;
}
</style>
<style>
.ProseMirror > * + * {
    margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
    padding: 0 1rem;
}
.ProseMirror ul {list-style:initial;}
.ProseMirror ol {list-style: decimal;}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.1;
}

.ProseMirror code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
}

.ProseMirror pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}
.ProseMirror pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
}

.page-card-content p {
    margin-bottom: 0.5rem;
    line-height: 1.2rem;
}
</style>
