<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto lg:hidden mb-10">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/creative/bookings/type/upcoming" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/bookings/type/upcoming')}">Upcoming</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/bookings/type/past" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/bookings/type/past')}">Past</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/creative/bookings/type/find" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/creative/bookings/type/find')}">Find Bookings</InertiaLink>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <div class="flex">
                        <div class="col">
                            <InertiaLink href="/creative/bookings/create" class="btn">
                                <i class="fas fa-plus"></i>
                                Create Booking
                            </InertiaLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>

</style>
