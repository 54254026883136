<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10 whitespace-pre-wrap">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Payments</h5>
                    </div>
                    <div class="col">
                        Displaying: <span class="font-semibold">{{ items.length }}</span>
                    </div>
                </div>
                <div class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Total</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        <tr v-for="(remittance, index) in items" :key="index">
                            <td>
                                <div class="text-gray-600">{{ remittance.breakdown?.total }} <span v-if="remittance.notes?.length" class="pl-1"><i class="far fa-sticky-note"></i></span></div>
                                <div class="text-gray-600 font-normal">{{ '(' + remittance.breakdown?.bookings_count + ' Booking' + (remittance.breakdown?.bookings_count > 1 ? 's' : '') + ')' }}</div>
                            </td>
                            <td>
                                <div class="text-gray-600 font-normal">{{ remittance.created_at_timestamp }}</div>
                            </td>
                            <td>
                                <div v-if="remittance.status === 'Paid'" class="text-primary">Paid</div>
                                <div v-else-if="remittance.status === 'Cancelled'">Cancelled</div>
                                <div v-else class="text-secondary">Pending</div>
                            </td>
                            <td>
                                <div class="col">
                                    <InertiaLink :href="'/admin/finance/remittances/' + remittance.id" class="btn btn--sm btn--nowrap">
                                        <i class="fas fa-pen"></i>
                                        Details
                                    </InertiaLink>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="hasMore">
                    <div class="pt-10"></div>
                    <div class="text-center">
                        <button @click="loadMore" class="btn btn--gray btn--sm">
                            Load More
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout"
import usePaginator from "@composables/usePaginator"
import {ref} from "vue";

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const apiUrl = ref('/admin/users/creatives/' + props.user.id + '/payments/api')
const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
