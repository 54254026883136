<template>
    <div class="user-page">
        <div class="card card--user">
            <div class="logo w-24 h-24 mx-auto">
                <img :src="logoUrl" alt="">
            </div>
            <div class="pb-12"></div>
            <div class="text-center mb-14">
                <h3 class="font-semibold">Welcome</h3>
                <div class="pb-3"></div>
                <p class="text-lg text-gray-400">{{ upperFirst(role) }} Login</p>
            </div>
            <form @submit.prevent="form.post('/login')">
                <div class="input-wrapper mb-5">
                    <label class="input-label">Email</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="far fa-envelope"></i>
                        </div>
                        <input v-model="form.email" :class="{'error' : form.errors.email}" placeholder="Email" type="email">
                    </div>
                </div>
                <div class="input-wrapper mb-1">
                    <label class="input-label">Password</label>
                    <div class="input-holder input-holder--icon">
                        <div class="input-holder-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <input v-model="form.password" :class="{'error' : form.errors.password}" placeholder="Password"
                               type="password">
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors" />
                <div class="input-submit mt-10">
                    <input :disabled="form.processing || form.email === '' || form.password === ''" class="bg-secondary hover:bg-primary transition duration-300 w-full"
                           type="submit"
                           value="SIGN IN">
                </div>
            </form>
            <div class="sep-text">
                <span class="sep-line"></span>
            </div>
            <div class="text-center">
                <InertiaLink :href="'/forgot-password?role=' + role" class="text-primary">Forgot password</InertiaLink>
            </div>
            <div v-if="props.role !== 'admin'">
                <div class="pt-32"></div>
                <div class="text-center">Don't have an account?
                    <InertiaLink :href="'/register?role=' + props.role" class="font-semibold text-primary pl-3">Sign Up</InertiaLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import useToast from "@composables/useToast"
import useRole from "@composables/useRole"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {onMounted} from "vue";
import {upperFirst} from "lodash";

const props = defineProps({
    role: {
        type: String,
        default: 'member'
    },
    status: {
        type: Object,
        default: null
    }
})

const { toast } = useToast()
onMounted(() => {
    if (props.status !== null && props.status?.type && props.status?.message) {
        if (props.status.type === 'danger') {
            toast('danger', props.status.message)
        } else if (props.status.type === 'success') {
            toast('success', props.status.message)
        } else {
            toast('info', props.status.message)
        }
    }
})

const {logoUrl} = useRole(props.role)

const form = useForm({
    email: "",
    password: "",
    role: props.role
})

</script>

<style lang="postcss">
.user-page {
    @apply h-screen w-full flex flex-wrap items-center justify-center overflow-y-auto p-5;
}

.card--user {
    @apply xl:w-3/12 lg:w-6/12 md:w-8/12 w-full;
}

.sep-text {
    @apply flex items-center my-5 text-gray-400;
}

.sep-line {
    @apply h-0.5 block bg-gray-100 flex-1;
}
</style>
