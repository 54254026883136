<template>
    <div class="home">
        <div class="container">
            <div class="spacer50"></div>
            <story-component :stories="stories"></story-component>
            <div class="spacer50"></div>
        </div>
    </div>
</template>

<script>
import BaseLayout from "@components/layout/BaseLayout.vue";
import WebLayout from "@components/layout/WebLayout.vue";
import PartnersComponent from "@components/web/PartnersComponent";
import StoryComponent from "@components/web/StoryComponent";

export default {
    layout: [BaseLayout, WebLayout],
    name: "About",
    props: {
        banner_images: {
            type: Array,
            default() {
                return []
            }
        },
        partners: {
            type: Array,
            default() {
                return []
            }
        },
        banner_header_about: {
            type: Object,
            default() {
                return null
            }
        },
        stories: {
            type: Array,
            default() {
                return []
            }
        }
    },
    components: {
        PartnersComponent, StoryComponent
    },
    data() {
        return {}
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
