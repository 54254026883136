<template>
    <CreativeProfileFilters />
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center gap-10">
                <div class="col">
                    <div class="page-card-avatar avatar avatar--xl" v-bind:style="{ backgroundImage: 'url(' + user.avatar + ')' }"></div>
                </div>
                <div class="col">
                    <h3 class="page-title">{{ user.professional_name }}</h3>
                </div>
                <div class="col ml-auto">
                    <button @click="showAddAddressForm" class="btn">
                        <i class="fas fa-edit"></i>
                        Add Address
                    </button>
                </div>
            </div>
            <AddressesList
                :addresses="user.addresses"
                @add="showAddAddressForm"
                :show-add="false"
                @edit="editAddress"
            />
        </div>
    </div>
    <div id="add-address-form">
        <div v-if="showingAddAddressForm">
            <div class="mt-10"></div>
            <AddressForm
                url="/creative/profile/address/add"
                :is-add="true"
                @closed="hideAddAddressForm"
            />
        </div>
    </div>
    <div id="edit-address-form">
        <div v-if="showingEditAddressForm">
            <div class="mt-10"></div>
            <AddressForm
                :url="'/creative/profile/address/' + addressToEdit.id + '/edit'"
                :remove-url="'/creative/profile/address/' + addressToEdit.id + '/remove'"
                :is-add="false"
                :address="addressToEdit"
                @closed="hideEditAddressForm"
            />
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue"
import useToggleable from "@composables/useToggleable"
import AddressForm from "@components/utilities/AddressForm"
import AddressesList from "@components/utilities/AddressesList"
import CreativeProfileFilters from "@components/creative/CreativeProfileFilters"

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const { showing: showingAddAddressForm, show: showAddAddressForm, hide: hideAddAddressForm } = useToggleable('add-address-form')
const { showing: showingEditAddressForm, show: showEditAddressForm, hide: hideEditAddressForm } = useToggleable('edit-address-form')
const addressToEdit = ref(null)
const editAddress = (address) => {
    hideEditAddressForm()
    addressToEdit.value = address
    setTimeout(() => showEditAddressForm(), 10)
}
</script>

<style lang="postcss">
body {
}
</style>
