<template>
    <AdminUsersMemberDetailsLayout :member="member">
        <template v-slot:action>
            <div v-if="member.statistics?.bookings_count" class="col">
                <InertiaLink :href="'/admin/bookings/kanban?member_id=' + member.id" class="btn btn--sm btn--secondary">
                    <i class="fa fa-filter mr-3"></i>
                    Column View
                </InertiaLink>
            </div>
        </template>
        <template v-slot:default>
            <div class="mt-10 whitespace-pre-wrap">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Bookings</h5>
                    </div>
                    <div class="col">
                        Displaying: <span class="font-semibold">{{ items.length }}</span>
                    </div>
                </div>
                <div class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Creative</th>
                            <th>Member Total</th>
                            <th>Creative Total</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                        <tr v-for="(booking, index) in items" :key="index">
                            <td>
                                <InertiaLink :href="'/admin/users/creatives/' + booking.creative.id">
                                    <div class="text-gray-600">{{ booking.creative.name }}</div>
                                </InertiaLink>
                            </td>
                            <td>
                                <div class="text-gray-600">{{ booking.finances?.member_total }}</div>
                            </td>
                            <td>
                                <div class="text-gray-600">{{ booking.finances?.creative_total }}</div>
                            </td>
                            <td>
                                <div class="text-gray-600 font-normal">{{ booking.start_timestamp }}</div>
                                <div class="text-gray-600 font-normal">{{ booking.end_timestamp }}</div>
                                <span class="text-gray-600">({{ booking.duration }})</span>
                            </td>
                            <td>
                                <div v-if="['Unfulfilled', 'Pending'].includes(booking.status.overall)" class="text-secondary">{{ booking.status.overall }}</div>
                                <div v-else-if="['Upcoming', 'Approved'].includes(booking.status.overall)" class="text-primary">{{ booking.status.overall }}</div>
                                <div v-else>{{ booking.status.overall }}</div>
                            </td>
                            <td>
                                <div class="flex gap-2">
                                    <div class="col">
                                        <InertiaLink :href="'/admin/bookings/' + booking.id" class="btn btn--sm btn--nowrap">
                                            <i class="fas fa-pen"></i>
                                            Details
                                        </InertiaLink>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="hasMore">
                    <div class="pt-10"></div>
                    <div class="text-center">
                        <button @click="loadMore" class="btn btn--gray btn--sm">
                            Load More
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </AdminUsersMemberDetailsLayout>
</template>

<script setup>
import AdminUsersMemberDetailsLayout from "@components/admin/AdminUsersMemberDetailsLayout"
import usePaginator from "@composables/usePaginator"
import {ref} from "vue";

const props = defineProps({
    member: {
        type: Object,
        required: true
    }
})

const apiUrl = ref('/admin/users/members/' + props.member.id + '/bookings/api')
const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)

fetchItems(false)
</script>
