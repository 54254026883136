<template>
    <div class="flex flex-wrap justify-between items-center">
        <div class="col w-full lg:w-auto">
            <ul class="filter-nav">
                <li>
                    <InertiaLink href="/admin/system-settings/tags/tags" as="button" class="filter-nav-btn" :class="{'active': isTagsActive}">Tags</InertiaLink>
                </li>
                <li>
                    <InertiaLink href="/admin/system-settings/tags/categories" as="button" class="filter-nav-btn" :class="{'active': isCategoriesActive}">Categories</InertiaLink>
                </li>
                <li>
                    <InertiaLink href="/admin/system-settings/tags/types" as="button" class="filter-nav-btn" :class="{'active': isTypesActive}">Types</InertiaLink>
                </li>
            </ul>
        </div>
        <div class="col hidden lg:block">
            <div class="flex">
                <div class="col">
                    <InertiaLink :href="createUrl" class="btn">
                        <i class="fas fa-plus"></i>
                        Create {{ createLabel }}
                    </InertiaLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {Inertia} from "@inertiajs/inertia"

const isTagsActive = computed(() => {
    return Inertia.page.url.startsWith('/admin/system-settings/tags/tags')
})
const isCategoriesActive = computed(() => {
    return Inertia.page.url.startsWith('/admin/system-settings/tags/categories')
})
const isTypesActive = computed(() => {
    return Inertia.page.url.startsWith('/admin/system-settings/tags/types')
})

const createLabel = computed(() => {
    return isTypesActive.value ? 'Type' : (isCategoriesActive.value ? 'Category' : 'Tag')
})
const createUrl = computed(() => {
    return "/admin/system-settings/tags/" + (isTypesActive.value ? 'types' : (isCategoriesActive.value ? 'categories' : 'tags')) + "/create"
})

</script>

<style scoped>

</style>
