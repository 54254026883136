<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Add Unavailable Period</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <label class="input-label">Start Date</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <input v-model="form.start_date" :class="{'error' : form.errors?.start_date}" placeholder="Start Date"
                                       type="date">
                            </div>
                        </div>
                        <div class="input-wrapper mb-5">
                            <label class="input-label">End Date</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <input v-model="form.end_date" :min="minEndDate" :class="{'error' : form.errors?.end_date}" placeholder="End Date"
                                       type="date">
                            </div>
                        </div>
                        <div v-if="duration" class="input-wrapper mb-5">
                            <label class="input-label">Duration</label>
                            <div class="input-holder input-holder--icon">
                                <div class="input-holder-icon">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <input disabled :value="duration + ' day' + (duration > 1 ? 's' : '')" type="text" class="text-gray-500 font-extrabold">
                            </div>
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Add Unavailable Period">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted} from "vue"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    },
})

const emits = defineEmits(['closed'])

const form = useForm({
    start_date: null,
    end_date: null
})

const duration = computed(() => {
    if (!form.start_date || !form.end_date) {
        return 0
    }

    let oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    let firstDate = new Date(form.start_date)
    firstDate.setHours(0, 0,0)
    let secondDate = new Date(form.end_date)
    secondDate.setHours(0, 0,0)

    if (secondDate < firstDate) {
        return 0
    }

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay))) + 1
})

const minEndDate = computed(() => {
    if (form.start_date) {
        let d = new Date(form.start_date)
        return d.toISOString().slice(0,10)
    }
    return null
})

const canSubmit = computed(() => {
    return !form.processing && form.isDirty && form.start_date && form.end_date && duration.value
})

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    form.reset()
})
</script>
