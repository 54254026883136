<template>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ alert.notification }}</h4>
                    Impacts: <span class="font-semibold capitalize">{{ alert.recipient_role }}</span>
                </div>
                <div class="col">
                    Last Update: <span class="font-semibold">{{ alert.updated_at }}</span>
                </div>
            </div>
            <div class="pb-20"></div>
            <form @submit.prevent="form.post('/admin/system-settings/notifications/' + alert.id)">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col">
                        <div v-if="timeIsRequired">
                            <div class="input-wrapper mb-10">
                                <label class="input-label">Time Value</label>
                                <input v-model="form.time_value" :class="{'error' : form.errors.time_value}"
                                       type="number" min="1">
                            </div>
                            <div class="input-wrapper mb-10">
                                <label class="input-label">Time Type</label>
                                <select name="value" v-model="form.time_type" :class="{'error' : form.errors.time_type}">
                                    <option disabled>Time Type</option>
                                    <option value="months">Months</option>
                                    <option value="days">Days</option>
                                    <option value="hours">Hours</option>
                                    <option value="minutes">Minutes</option>
                                </select>
                            </div>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Deliver via App (In-App)</label>
                            <label class="switch">
                                <input type="checkbox" v-model="form.deliver_via_app" @change="form.app_text = form.deliver_via_app ?  alert.app_text : ''">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                        <div class="pt-1"></div>
                        <div v-if="form.deliver_via_app" class="input-wrapper mb-9">
                            <label class="input-label">App Text</label>
                            <textarea v-model="form.app_text" name="app_text" rows="3"></textarea>
                        </div>
                        <div class="input-wrapper mb-10">
                            <label class="input-label">Deliver via Email</label>
                            <label class="switch">
                                 <input type="checkbox" v-model="form.deliver_via_email" @change="form.email_text = form.deliver_via_email ?  alert.email_text : ''">
                                <span class="switch-slider"></span>
                            </label>
                        </div>
                        <div class="pt-1"></div>
                        <div v-if="form.deliver_via_email" class="input-wrapper mb-9">
                            <label class="input-label">Email Body</label>
                            <TipTapEditor v-model="form.email_text" />
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="form.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmit" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import {computed} from "vue"
import TipTapEditor from "@components/utilities/TipTapEditor"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"

const props = defineProps({
    alert: {
        type: Object,
        required: true
    }
})

const timeIsRequired = computed(() => {
    return props.alert.time_value !== null && props.alert.time_type !== null
})

const form = useForm({
    time_value: props.alert.time_value,
    time_type: props.alert.time_type,
    deliver_via_app: props.alert.deliver_via_app,
    app_text: props.alert.app_text,
    deliver_via_email: props.alert.deliver_via_email,
    email_text: props.alert.email_text,
})

const canSubmit = computed(() => {
    return !form.processing &&
        form.isDirty &&
        ( (!timeIsRequired.value && form.time_value === null && form.time_type === null) || (timeIsRequired.value && form.time_value !== null && form.time_type !== null) ) &&
        ( (!form.deliver_via_app && form.app_text === "") || (form.deliver_via_app && form.app_text !== "" && form.app_text !== null) )
        // && ( (!form.deliver_via_email && form.email_text === null) || (form.deliver_via_email && form.email_text !== null) )
})


</script>

<style lang="postcss">
body {
}
</style>
