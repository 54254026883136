<template>
    <div>
        <div class="pricing-header text-center">
            <h2 class="text-center mb-12">Our Family</h2>
        </div>
        <div class="teams-wrapper teams" style="justify-content: center">
            <div v-for="(member, index) in members" :key="index" class="teams__item">
                <div class="teams__card">
                    <div class="teams__card-inner">
                        <div class="teams__details z-10">
                            <div class="teams__details--top">
                                <div class="fz-sm fw-m">{{ member.title }}</div>
                                <div class="teams__name">{{ member.name }}</div>
                            </div>
                            <div class="teams__desc">{{ member.bio }}</div>
                        </div>
                        <div v-if="member.has_photo && member.photo" class="images">
                            <div :style="{ 'background-image': 'url(' + member.photo + ')' }"
                                 class="teams__image teams__image_original"></div>
                            <div :style="{ 'background-image': 'url(' + member.photo_two + ')' }"
                                 class="teams__image teams__image_hover"></div>
                        </div>
                        <div v-else class="teams__image"
                             style="background-image: url('/img/web/members/anonymous.png')"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

const members = [
    {
        id: 1,
        name: "Amy",
        title: "Founder",
        bio: "Amy founded Bea & Co in 2012 after noticing the positive affects her natural creative approach was having on children she cared for. As those original children approach their teens, Amy’s role is focussed on the bigger creative childcare picture for the Company over the next period of growth. Originally from Devon, Amy is a mum of 2 little humans and 1 noisy miniature dachshund. ",
        has_photo: true,
        photo: "/img/web/home_family_1.jpg",
        photo_two: "/img/web/home_family_1_2.jpg",
    },
    {
        id: 2,
        name: "Rebecca",
        title: "Families & Community Manager",
        bio: "Rebecca started as one of our creative sitters, moving over to the office team in 2018. With a background in theatre, Rebecca has always been passionate about creative engagement and its importance in child development. Dog mum to office pup Inka, Rebecca loves spending time with family and escaping to the countryside.",
        has_photo: true,
        photo: "/img/web/home_family_2.jpg",
        photo_two: "/img/web/home_family_2_2.jpg",
    },
    {
        id: 3,
        name: "Ellie",
        title: "Families Coordinator",
        bio: "Ellie first joined Bea & Co in 2018 as a creative sitter, forming some wonderful bonds with families in London. In 2021 Ellie moved back to Yorkshire and became our Families Coordinator. Up north, Ellie loves long runs outdoors, cosy pubs with live music and having family on her doorstep.",
        has_photo: true,
        photo: "/img/web/home_family_3.jpg",
        photo_two: "/img/web/home_family_3_2.jpg",
    },
    {
        id: 4,
        name: "James",
        title: "Director",
        bio: "Looks after all things business development for Bea & Co. Dad of 2. Loves to be outdoors and longs for the ocean. Big fan of architecture, trees, cars, surfing and spreadsheets!",
        has_photo: true,
        photo: "/img/web/home_family_4_2.jpg",
        photo_two: "/img/web/home_family_4.jpg",
    },
    {
        id: 5,
        name: "Otis",
        title: "",
        bio: "",
        has_photo: true,
        photo: "/img/web/home_family_5.jpg",
        photo_two: "/img/web/home_family_5_2.jpg",
    },
    {
        id: 6,
        name: "Inka",
        title: "",
        bio: "",
        has_photo: true,
        photo: "/img/web/home_family_6.jpg",
        photo_two: "/img/web/home_family_6.jpg",
    },
]

</script>

<style lang="scss" scoped>
.teams-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.teams {
    margin: 0 -10px;

    &__item {
        padding: 10px;
        width: 25%;
    }

    &__name {
        font-size: 26px;
        color: #1c1c1c;
    }

    &__desc {
        color: #333;
        align-self: flex-end;
        font-size: 0.9rem;
        font-weight: 600
    }

    &__card {
        position: relative;

        &-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            // transition: transform 0.1s;
            // transform-style: preserve-3d;
        }

        // &:hover .teams__card-inner {
        //   transform: rotateY(180deg);
        // }
    }

    &__image {
        padding-top: 100%;
        background-size: cover;
        background-position: center;
    }

    &__details {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        // transform: rotateY(180deg);
        padding: 15px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        text-align: left;

        &--top {
            flex: 1;
        }
    }

    &__image,
    &__details {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    @media(max-width: 992px) {
        &__item {
            width: 33.3333%
        }
    }
    @media(max-width: 767px) {
        &__item {
            width: 50%
        }
    }
    @media(max-width: 499px) {
        &__item {
            width: 100%
        }
    }

    .teams__details {
        opacity: 0;
    }

    .teams__image_hover {
        display: none;
    }

    .teams__card:hover {
        .teams__image_original {
            display: none;
        }

        .teams__details {
            background-color: rgba(255, 255, 255, 0.4);
            opacity: 1;
        }

        .teams__image_hover {
            display: block;
        }
    }
}
</style>
