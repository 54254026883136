<template>
    <div class="container">
        <div class="pricing-header text-center mb-10">
            <h2 class="mb-12">Fees</h2>
        </div>
        <div class="spacer20"></div>
        <div v-if="!showMore" class="text-center">
            <button class="btn btn-rounded" @click="toggleShowMore">View More</button>
        </div>
        <div class="pricing-table-wrapper" v-if="showMore">
            <div class="flex flex-col gap-5 items-center lg:flex-row">
                <div class="pricing-col lg:w-1/4">
                    <div class="border border-gray-200 rounded-lg">
                        <div class="py-5 px-8 text-center border-b border-gray-200 text-xl font-semibold rounded-t-lg">Evening</div>
                        <div class="py-5 px-6">
                            <div class="pricing-table__price flex justify-center pb-2 gap-1 text-gray-500">
                                <div class="text-sm self-end">up to 4 hours</div>
                            </div>
                            <div class="pricing-table__price flex justify-center gap-2">
                                <div class="super self-start text-xl">£</div>
                                <div class="text-6xl">120</div>
                            </div>
                            <div class="self-end text-lg text-center text-gray-500 mt-10"></div>
                            <div class="pt-8">The simple pleasures of an evening out! Best suited to those planning an evening meal or drinks. Our creative sitter will be on hand whilst you get ready, prepare any meals required, make superb aquatic adventures, and deliver a story time that will still be remembered at awards season!  </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-col lg:w-1/4">
                    <div class="border rounded-lg border-gold" style="border-width: 3px;">
                        <div class="py-5 px-8 text-center border-b text-xl font-semibold rounded-t-lg py-6 border-gold">Half Day</div>
                        <div class="py-5 px-6" style="padding: 75px 25px;">
                            <div class="pricing-table__price flex justify-center pb-2 gap-1 text-gray-500">
                                <div class="text-sm self-end">up to 6 hours</div>
                            </div>
                            <div class="pricing-table__price flex justify-center gap-2">
                                <div class="super self-start text-xl">£</div>
                                <div class="text-6xl">175</div>
                            </div>
                            <div class="pt-8">Perfect for a half day of meetings or a long lunch event. Arrange one of our team to create a morning or afternoon of fun with your children whether that is exploring the city, or den-making in the country, we’ll be on hand with a plan for you to approve.</div>
                        </div>
                    </div>
                </div>
                <div class="pricing-col lg:w-1/4">
                    <div class="border border-gray-200 rounded-lg">
                        <div class="py-5 px-8 text-center border-b border-gray-200 text-xl font-semibold rounded-t-lg">Full Day</div>
                        <div class="py-5 px-6">
                            <div class="pricing-table__price flex justify-center pb-2 gap-1 text-gray-500">
                                <div class="text-sm self-end">up to 10 hours</div>
                            </div>
                            <div class="pricing-table__price flex justify-center gap-2">
                                <div class="super self-start text-xl">£</div>
                                <div class="text-6xl">285</div>
                            </div>
                            <div class="self-end text-lg text-center text-gray-500 mt-10"></div>
                            <div class="pt-8">Best for those who want an extra pair of hands on the road, or some expert knowledge and good fun on a full family day trip. Alternatively, hand over the reins to our nanny for the full-day solo and we will create a day to remember, bespoke to your needs.</div>
                        </div>
                    </div>
                </div>
                <div class="pricing-col lg:w-1/4">
                    <div class="border border-gray-200 rounded-lg">
                        <div class="py-5 px-8 text-center border-b border-gray-200 text-xl font-semibold rounded-t-lg">Membership</div>
                        <div class="py-5 px-6">
                            <div class="pricing-table__price flex justify-center pb-2 gap-1 text-gray-500">
                                <div class="text-sm self-end">regular bookings over a limited period</div>
                            </div>
                            <div class="pricing-table__price flex justify-center gap-2">
                                <div class="super self-start text-xl">£</div>
                                <div class="text-6xl">POA</div>
                            </div>
                            <div class="self-end text-lg text-center text-gray-500 mt-10"></div>
                            <div class="pt-8">If you have an extended trip planned, speak to us about how to integrate a fun-filled childcare schedule into your itinerary. </div>
                            <div class="pricing-table__price pt-6 mt-10 pt-12">
                                <a class="btn w-full btn--outline nb-gold-button" href="mailto:london@beaandco.com?subject=Long-stay%20Hotels%20%26%20Homestay%20enquiry">Membership</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-wrapper text-center mt-12">
            <a class="btn btn--outline btn--rounded nb-gold-button" href="mailto:london@beaandco.com?subject=Hotels%20%26%20Homestay%20enquiry">Email us</a>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";

const toggleShowMore = function () {
    this.showMore = !this.showMore
}
const showMore = ref(true)

</script>


<style scoped>
.nb-gold-button {
    @apply bg-white text-black border-gold hover:bg-gold hover:text-white hover:border-gold;
}
</style>
