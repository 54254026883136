<template>
    <Multiselect
        v-model="modelValue"
        mode="tags"
        placeholder="Select tags"
        :filterResults="false"
        :createTag="allowCreate"
        :minChars="1"
        :resolveOnLoad="true"
        :delay="0"
        :searchable="true"
        :object="true"
        trackBy="name"
        label="name"
        v-on:change="$emit('update:modelValue', $event)"
        :options="async function(query) {
          return await fetchTags(query)
        }"
    >
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
            <div class="multiselect-tag p-10" :class="!option.is_approved ? 'multiselect-tag--not-approved' : (option.is_admin_only ? 'multiselect-tag--admin' : '')">
                {{ option.name }}
                <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                >
                    <span class="multiselect-tag-remove-icon"></span>
                </span>
            </div>
        </template>
        <template v-slot:option="{ option }">
            <div class="btn btn--sm btn" :class="!option.is_approved ? 'multiselect-tag--not-approved' : (option.is_admin_only ? 'multiselect-tag--admin' : '')">
                {{ option.name }}
            </div>
        </template>
    </Multiselect>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted, ref} from "vue"
import Multiselect from '@vueform/multiselect'
import axios from "axios";

const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
    allowCreate: {
        type: Boolean,
        default: true
    },
})

const emits = defineEmits(['update:modelValue'])

const fetchTags = async (terms) => {
    let options = []

    let url = '/tags'

    if (terms) {
        let params = new URLSearchParams();
        params.append('terms', encodeURIComponent(terms))

        let suffix = params.toString()
        url +=  suffix ? '?' + suffix : ''
    }

    await axios.get(url)
        .then((resp) => {
            options = resp.data.items
        })
        .catch((err) => {
            console.log(err)
        })

    return options
}

</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="postcss">
.multiselect-tag--not-approved {
     @apply bg-secondary-100 hover:bg-secondary-300 text-light;
 }
.multiselect-tag--admin {
     @apply bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600;
 }

.multiselect {
    @apply flex text-gray-800 w-full rounded-xl border border-gray-100 focus:border-primary-500 focus:ring focus:ring-primary-dark focus:ring-opacity-100 py-3 pl-5 sm:pr-5;
}
.multiselect-tags {
    @apply gap-4 pl-0 pr-2 sm:pr-4;
}
.multiselect-tag {
    @apply bg-primary whitespace-normal text-left max-w-full border-0 text-white font-medium cursor-pointer bg-primary hover:bg-primary-dark transition cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed pl-4 pr-9 sm:pr-2 w-auto py-2 rounded-lg text-sm relative
}
.multiselect.is-active .multiselect-placeholder {
    @apply hidden;
}
.multiselect-tag-remove {
    @apply absolute right-0 top-0 bottom-0 m-auto px-2 py-2 sm:relative sm:p-1 sm:ml-2 sm:mr-0;
}
.multiselect-tags-search-wrapper {height:29.5px;}
.multiselect-tags-search {
    @apply focus:ring-1 px-4 py-1.5 rounded-lg ring-gray-100 leading-5;
}
</style>
