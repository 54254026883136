<template>
    <div style="margin-top: -30px;">
    <InertiaLink href="/admin/bookings/create" class="btn mb-5">
        <i class="fas fa-plus"></i>
        Create Booking
    </InertiaLink>
    <div class="lg:flex flex-wrap items-center justify-between pb-8">
        <div class="col pt-4">
            <div class="filters flex gap-4">
                <Dropdown :filters="timeFilters" v-model="timeValue" />
                <Dropdown :filters="memberTypeFilters" v-model="memberTypeValue" />
                <Dropdown :filters="bookingTypeFilters" v-model="bookingTypeValue" />
                <Dropdown :filters="bookingFlagFilters" v-model="bookingFlagValue" v-slot="slotProps">
                    <button v-if="slotProps.item.value !== 'Booking Flag'" @click.stop.prevent class="w-6 h-6 leading-6 rounded-full bg-flag text-white text-center text-xs bg-flag" :class="'bg-flag-' + slotProps.item.value">
                        <i v-if="slotProps.item.value === 9" class="fas fa-flag"></i>
                        <i v-else class="fas fa-user"></i>
                    </button>
                    <span :class="{'ml-2': slotProps.item.value !== 'Booking Flag'}">{{ slotProps.item.label }}</span>
                </Dropdown>
            </div>
        </div>
        <div class="col pt-4">
            <InertiaLink href="/admin/bookings/type/all" class="btn btn--sm btn--secondary">
                <i class="fa fa-filter mr-3"></i>
                Table View
            </InertiaLink>
        </div>
    </div>
    <div class="-mb-10">
        <div class="flex justify-center">
            <perfect-scrollbar class="lg:overflow-x-scroll w-full">
                <div class="lg:flex gap-4">
                    <div class="col-3 kanban-col-wrap flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Booking Request</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <div class="list-group px-6 pb-4">
                                <div v-for="(booking, index) in bookingsRequests" :key="booking.id">
                                    <BookingKanbanCard :booking="booking" @show-block="filterBlock" />
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">In progress</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <div class="list-group px-6 pb-4">
                                <div v-for="(booking, index) in bookingsInProgress" :key="booking.id">
                                    <BookingKanbanCard :booking="booking" @show-block="filterBlock" />
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Creatives Applied</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <div class="list-group px-6 pb-4">
                                <div v-for="(booking, index) in bookingsCreativesApplied" :key="booking.id">
                                    <BookingKanbanCard :booking="booking" @show-block="filterBlock" />
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Awaiting Confirmation</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <div class="list-group px-6 pb-4">
                                <div v-for="(booking, index) in bookingsAwaitingConfirmation" :key="booking.id">
                                    <BookingKanbanCard :booking="booking" @show-block="filterBlock" />
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-3 kanban-col-wrap flex flex-col pb-6 lg:pb-0">
                        <div class="flex justify-between pb-3 px-6">
                            <div class="font-semibold tracking-wide text-lg">Hold</div>
                        </div>
                        <perfect-scrollbar class="kanban-col flex-1">
                            <div class="list-group px-6 pb-4">
                                <div v-for="(booking, index) in bookingsHold" :key="booking.id">
                                    <BookingKanbanCard :booking="booking" @show-block="filterBlock" />
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from "vue"
import BookingKanbanCard from "@components/admin/BookingKanbanCard"
import Dropdown from "@components/utilities/Dropdown"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    filterTime: {
        type: String,
        default: "Time",
    },
    filterMemberType: {
        type: String,
        default: "Member Type",
    },
    filterBookingType: {
        type: String,
        default: "Booking Type",
    },
    filterMemberId: {
        type: Number,
        default: null,
    },
    filterBlockId: {
        type: Number,
        default: null,
    },
    filterBookingFlag: {
        type: String,
        default: "Booking Flag",
    },
    bookingsRequests: {
        type: Array,
        required: true
    },
    bookingsInProgress: {
        type: Array,
        required: true
    },
    bookingsCreativesApplied: {
        type: Array,
        required: true
    },
    bookingsAwaitingConfirmation: {
        type: Array,
        required: true
    },
    bookingsHold: {
        type: Array,
        required: true
    },
})
function useFilters() {
    const timeValue = ref(props.filterTime)
    const timeFilters = [
        {label: 'Time', value: 'Time'},
        {label: 'This week', value: 'week'},
        {label: 'This month', value: 'month'},
        {label: 'Next month', value: 'next_month'},
        {label: 'Next 3 months', value: '3_months'},
    ]

    const memberTypeValue = ref(props.filterMemberType)
    const memberTypeFilters = [
        {label: 'Member Type', value: 'Member Type'},
        {label: 'VIP', value: 'vip'},
        {label: 'Standard', value: 'standard'},
    ]

    if (props.filterMemberId) {
        memberTypeFilters.push({label: 'Family', value: 'family'})
    }

    const bookingTypeValue = ref(props.filterBookingType)
    const bookingTypeFilters = [
        {label: 'Booking Type', value: 'Booking Type'},
        {label: 'Single', value: 'single'},
        {label: 'Repeat', value: 'repeat'},
    ]

    const bookingFlagValue = ref(props.filterBookingFlag)
    const bookingFlagFilters = [
        {label: 'Booking Flag', value: 'Booking Flag'},
        {label: 'No Flag', value: "0"},
        {label: 'Flag', value: "9"},
        {label: 'User 1', value: "1"},
        {label: 'User 2', value: "2"},
        {label: 'User 3', value: "3"},
        {label: 'User 4', value: "4"},
        {label: 'User 5', value: "5"},
        {label: 'User 6', value: "6"},
        {label: 'User 7', value: "7"},
        {label: 'User 8', value: "8"},
    ]

    const memberIdValue = ref(props.filterMemberId)
    const blockIdValue = ref(props.filterBlockId)

    const filterBlock = (e) => blockIdValue.value = blockIdValue.value === e ? null : e

    const filterUrl = computed(() => {
        let url = '/admin/bookings/kanban'

        let params = new URLSearchParams()

        if (timeValue.value === "week" || timeValue.value === "month" || timeValue.value === "next_month" || timeValue.value === "3_months") {
            params.append('time', encodeURIComponent(timeValue.value))
        }

        if (memberTypeValue.value === "vip" || memberTypeValue.value === "standard") {
            params.append('member_type', encodeURIComponent(memberTypeValue.value))
            memberIdValue.value = null
        }
        if (memberTypeValue.value === "Member Type") {
            memberIdValue.value = null
        }

        if (bookingTypeValue.value === "single" || bookingTypeValue.value === "repeat") {
            params.append('booking_type', encodeURIComponent(bookingTypeValue.value))
            if (bookingTypeValue.value === "single") {
                blockIdValue.value = null
            }
        }

        if (memberIdValue.value) {
            params.append('member_id', encodeURIComponent(memberIdValue.value))
        }

        if (blockIdValue.value) {
            params.append('block_id', encodeURIComponent(blockIdValue.value))
        }

        if (bookingFlagValue.value !== 'Booking Flag') {
            params.append('flag', encodeURIComponent(bookingFlagValue.value))
        }

        let suffix = params.toString()

        return url + (suffix ? '?' + suffix : '')
    })


    watch(filterUrl, (newVal, oldVal) => reloadPage())

    return {timeValue, timeFilters, memberTypeValue, memberTypeFilters, bookingTypeValue, bookingTypeFilters, bookingFlagValue, bookingFlagFilters, filterBlock, filterUrl }
}
const {timeValue, timeFilters, memberTypeValue, memberTypeFilters, bookingTypeValue, bookingTypeFilters, bookingFlagValue, bookingFlagFilters, filterBlock, filterUrl } = useFilters()


const reloadPage = () => {
    Inertia.visit(filterUrl.value, {
        preserveState: false
    })
}

</script>
<style lang="postcss" scoped>
    .kanban-col-wrap {
        background-color: rgba(221, 226, 232, 1);
        @apply pt-6 rounded-3xl mb-6 lg:mb-0;
        min-height: 50vh;
    }
    .kanban-col-wrap .kanban-col .list-group {
        @apply flex flex-col gap-4
    }
    @media(min-width:1024px) {
        .kanban-col-wrap {
            min-width: 280px;
            width: 280px;
        }
    }
    @media(max-width:1023px) {
        .kanban-col-wrap .kanban-col .list-group {min-height: 40vh;;}
    }
    .ghost-card {
      opacity: 0.5;
      background: #F7FAFC;
      border: 1px solid #4299e1;
    }
</style>
