<template>
    <div>
        <template v-for="(item, index) in items">
            <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
            <menu-item v-else :key="index" v-bind="item" />
        </template>
    </div>
</template>

<script>
import MenuItem from '@/components/utilities/TipTapMenuItem.vue'

export default {
    components: {
        MenuItem,
    },

    props: {
        editor: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            items: [
                {
                    icon: 'bold',
                    title: 'Bold',
                    action: () => this.editor.chain().focus().toggleBold().run(),
                    isActive: () => this.editor.isActive('bold'),
                },
                {
                    icon: 'italic',
                    title: 'Italic',
                    action: () => this.editor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editor.isActive('italic'),
                },
                {
                    icon: 'strikethrough',
                    title: 'Strike',
                    action: () => this.editor.chain().focus().toggleStrike().run(),
                    isActive: () => this.editor.isActive('strike'),
                },
                {
                    type: 'divider',
                },
                {
                    icon: 'list-unordered',
                    title: 'Bullet List',
                    action: () => this.editor.chain().focus().toggleBulletList().run(),
                    isActive: () => this.editor.isActive('bulletList'),
                },
                {
                    icon: 'list-ordered',
                    title: 'Ordered List',
                    action: () => this.editor.chain().focus().toggleOrderedList().run(),
                    isActive: () => this.editor.isActive('orderedList'),
                },
                {
                    type: 'divider',
                },
                {
                    icon: 'align-left',
                    title: 'Align Left',
                    action: () => this.editor.chain().focus().setTextAlign('left').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'left' }),
                },
                {
                    icon: 'align-center',
                    title: 'Align Center',
                    action: () => this.editor.chain().focus().setTextAlign('center').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'center' }),
                },
                {
                    icon: 'align-right',
                    title: 'Align Right',
                    action: () => this.editor.chain().focus().setTextAlign('right').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'right' }),
                },
                {
                    type: 'divider',
                },
                {
                    icon: 'text-wrap',
                    title: 'Hard Break',
                    action: () => this.editor.chain().focus().setHardBreak().run(),
                },
                {
                    icon: 'format-clear',
                    title: 'Clear Format',
                    action: () => this.editor.chain()
                        .focus()
                        .clearNodes()
                        .unsetAllMarks()
                        .run(),
                },
                {
                    icon: 'arrow-go-back-line',
                    title: 'Undo',
                    action: () => this.editor.chain().focus().undo().run(),
                },
                {
                    icon: 'arrow-go-forward-line',
                    title: 'Redo',
                    action: () => this.editor.chain().focus().redo().run(),
                },
            ],
        }
    },
}
</script>

<style lang="postcss">
.divider {
    background-color: rgba(#000, 0.1);
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    @apply w-px h-6 bg-gray-300;
}
</style>
