<template>
    <div class="">
        <div class="space100"></div>
        <div class="centered-div">
            <h2 class="text-center">Process Explained</h2>
            <div class="space50"></div>
        </div>
        <div class="row">
            <div class="space30 d-lg-none d-xl-none"></div>
            <div class="col-lg-12 centered-div">
                <div class="row gutter-50 process-wrapper line text-center">
                    <div v-for="(item, index) in processes" :key="'Process_'+index" class="col-md-4"
                         style="cursor: pointer;" @click="toggleActive(item)">
                        <span :class="{active: active.title === item.title}" class="icon icon-bg bg-default mb-20"><span
                            class="icon-img" style="font-size: 24px;"><i :class="item.icon"></i></span></span>
                        <h5>{{ item.title }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="space50"></div>
        <div>
            <div v-if="active">
                <div v-if="active.title === 'Apply'" class="container">
                    <div class="w-full md:w-2/3" style="margin: auto">
                        <div>
                            <div class="spacer20"></div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <input v-model="form.first_name" name="first_name" placeholder="First Name" type="text">
                                </label>
                                <div v-if="form.errors.first_name" class="alert-required mt-2">
                                    {{ form.errors.first_name }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <input v-model="form.last_name" name="last_name" placeholder="Last Name" type="text">
                                </label>
                                <div v-if="form.errors.last_name" class="alert-required mt-2">
                                    {{ form.errors.last_name }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <input v-model="form.email" name="email" placeholder="Email Address" type="email">
                                </label>
                                <div v-if="form.errors.email" class="alert-required mt-2">
                                    {{ form.errors.email }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <input v-model="form.phone" name="phone" placeholder="Phone Number" type="tel">
                                </label>
                                <div v-if="form.errors.phone" class="alert-required mt-2">
                                    {{ form.errors.phone }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <input v-model="form.location" name="location" placeholder="Where are you based?" type="text">
                                </label>
                                <div v-if="form.errors.location" class="alert-required mt-2">
                                    {{ form.errors.location }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <textarea v-model="form.about" name="about" placeholder="In a few sentences tell us about yourself." rows="3"></textarea>
                                </label>
                                <div v-if="form.errors.about" class="alert-required mt-2">
                                    {{ form.errors.about }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <textarea v-model="form.experience" name="experience" placeholder="What is your experience with children?" rows="3"></textarea>
                                </label>
                                <div v-if="form.errors.experience" class="alert-required mt-2">
                                    {{ form.errors.experience }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <textarea v-model="form.favourite_book" name="favourite_book" placeholder="What is your favourite children's book and why?" rows="3"></textarea>
                                </label>
                                <div v-if="form.errors.favourite_book" class="alert-required mt-2">
                                    {{ form.errors.favourite_book }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <textarea v-model="form.superpower" name="superpower" placeholder="If you had one superpower what would it be, and why?" rows="3"></textarea>
                                </label>
                                <div v-if="form.errors.superpower" class="alert-required mt-2">
                                    {{ form.errors.superpower }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <label>
                                    <textarea v-model="form.referee" name="referee" placeholder="Please give the name(s) of current Bea & Co creatives who would be willing to support your application" rows="3"></textarea>
                                </label>
                                <div v-if="form.errors.referee" class="alert-required mt-2">
                                    {{ form.errors.referee }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <div class="input-group-wrapper">
                                    <div class="label">Are you registered as self employed?</div>
                                    <div class="spacer20"></div>
                                    <label class="custom-radio">
                                        yes
                                        <input v-model="form.self_employed" name="self_employed" value="yes" type="radio">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custom-radio">
                                        no
                                        <input v-model="form.self_employed" name="self_employed" value="no" type="radio">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div v-if="form.errors.self_employed" class="alert-required mt-2">
                                    {{ form.errors.self_employed }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <div class="input-group-wrapper">
                                    <div class="label">By submitting your application to us you give your permission for us to contact the current creatives you have suggested as supporters of your application. You also give your permission for us to contact the referees listed in your attached CV</div>
                                    <div class="spacer20"></div>
                                    <label class="custom-radio">
                                        yes
                                        <input v-model="form.terms" name="terms" value="yes" type="radio">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custom-radio">
                                        no
                                        <input v-model="form.terms" name="terms" value="no" type="radio">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div v-if="form.errors.terms" class="alert-required mt-2">
                                    {{ form.errors.terms }}
                                </div>
                            </div>
                            <div class="input-wrapper mb-40">
                                <div class="input-group-wrapper">
                                    <div class="label">Upload your CV</div>
                                    <div class="spacer20"></div>
                                    <input type="file" @input="form.attachment = $event.target.files[0]">
                                </div>
                                <div v-if="form.errors.attachment" class="alert-required mt-2">
                                    {{ form.errors.attachment }}
                                </div>
                            </div>
                        </div>
                        <div class="spacer50"></div>
                        <div class="button-wrapper text-center">
                            <button class="btn btn--outline btn--rounded" type="button" @click="submitForm" :disabled="form.processing">Send Application</button>
                        </div>
                    </div>
                </div>
                <div v-else-if="active.title === 'About'" class="">
                    <div class="spacer50"></div>
                    <StoryComponent/>
                </div>
                <div v-else-if="active.title === 'What we look for'">
                    <div class="spacer20"></div>
                    <div class="text-left">
                        <div class="message mb-8">We want to work with best in class creative people who love working with children.</div>
                        <div class="message mb-8">We welcome only the very best and most committed creatives to our team. We look for the highest levels of communication, presentation and energy. As of 2023 we are fortunate enough to work with over 200 creatives who all maintain successful careers in theatre, film, television, dance, music, art and design whilst working with our families.</div>
                        <div class="message mb-8">Our services span both part time & ad-hoc nannies, and wrap-around care, in London, across the UK and internationally. </div>
                        <div class="message mb-8">We work across 3 main areas: Home childcare, Hotels & Homestay and Events & Entertainment. Our nannies and sitters have the opportunity to work across all sectors depending on experience, and desire to undertake specialist training. As a team member you benefit from unrivalled flexibility, travel opportunities and access to as many or as few hours as suits your schedule.</div>
                        <div class="message mb-8">We are guided by the following core values:</div>
                        <h2>Bea Kind</h2>
                        <p class="message">We care about the well-being and success of every person. Being kind is at the core of our company values and we approach every task with an open heart and open mind.</p>
                        <div class="spacer5"></div>
                        <h2>Bea Open-minded</h2>
                        <p class="message">We have a "can do" attitude. We say yes, then work out the finer details behind the scenes after. We support each other to achieve those goals.</p>
                        <div class="spacer5"></div>
                        <h2>Bea Exceptional</h2>
                        <p class="message">We go to infinity and beyond to provide more than just great customer service. Bea & Co is a company that truly cares for our families, for our partners and for each and every member of the team. Only exceptional is good enough.</p>
                        <div class="spacer5"></div>
                        <h2>Bea Accountable</h2>
                        <p class="message">We are honest, open, ethical, and fair. People trust us to adhere to our word and always be on the front-foot. We make choices with integrity and we remember that the way we conduct ourselves has an impact on others as well as ourselves.</p>
                        <div class="spacer5"></div>
                        <h2>Bea Collaborative</h2>
                        <p class="message">We work as a team. We are aware of those around us and are always ready to jump in to help or share ideas.</p>
                        <div class="spacer5"></div>
                        <h2>Bea Adventurous</h2>
                        <p class="message">We're inspired by the fearlessness of the little ones we look after! We strive to be adventurous, playful, and daring, always broadening our horizons and reaching for the stars.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// TODO flash messages
import StoryComponent from "@components/web/StoryComponent";
import {ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";

const processes = [
    {
        title: "About",
        icon: "fas fa-calendar"
    },
    {
        title: "What we look for",
        icon: "fas fa-calendar"
    },
    {
        title: "Apply",
        icon: "fas fa-calendar"
    },
]
const active = ref(processes[0])
const toggleActive = (process) => active.value = process

const form = useForm({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    location: "",
    about: "",
    experience: "",
    favourite_book: "",
    superpower: "",
    referee: "",
    application_form: null,
    self_employed: null,
    terms: null,
    attachment: null,
})

const submitForm = () => {
    form.post("/apply", {
        preserveState: (page) => Object.keys(page.props.errors).length,
        preserveScroll: (page) => Object.keys(page.props.errors).length,
    })
}
</script>

<style scoped>
input, textarea {
    font-size: 14px;
}
.alert-required {
    color: red;
}

.alert-success {
    color: green;
    border: 2px solid greenyellow;
    background-color: floralwhite;
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    white-space: pre-wrap;
}

span.active {
    background-color: #F4F4F4 !important;
    color: #a1ede3 !important;
}
</style>
