<template>
    <div>
        <h5 class="sub-title">Total Bookings - MoM%</h5>
        <LineChart v-bind="lineChartProps"/>
    </div>
</template>

<script setup>
import {Chart, registerables} from 'chart.js'
import { LineChart, useLineChart } from 'vue-chart-3';
import {computed, ref} from 'vue'

Chart.register(...registerables)

const props = defineProps({
    monthLabels: {
        type: Array,
        required: true
    },
    yearLabels: {
        type: Array,
        required: true
    },
    datasets: {
        type: Object,
        required: true
    }
})

const chartData = computed(() => ({
    labels: props.monthLabels,
    datasets: [
        {
            label: props.yearLabels[props.yearLabels.length - 3],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 3]],
            backgroundColor: '#fe3562',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: props.yearLabels[props.yearLabels.length - 2],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 2]],
            backgroundColor: '#9CABC7',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: props.yearLabels[props.yearLabels.length - 1],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 1]],
            backgroundColor: '#8ff0e8',
            tension: 0.4,
            responsive: true,
            legend:false
        }
    ]
}))

const {lineChartProps, lineChartRef} = useLineChart({
    chartData,
})
</script>

<style>
</style>
