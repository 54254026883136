<template>
  <div class="banner">
    <div class="text-center" v-if="!sliderOnly">
      <div class="spacer50"></div>
      <div class="banner-logo">
        <img v-if="logo" src="/img/web/logo.png" alt="">
      </div>
      <div class="banner-title">{{ title }}</div>
    </div>
    <div class="banner-bg">
        <Swiper
            class="banner-slider banner-swiper"
            v-bind="settings"
        >
            <SwiperSlide v-for="(item, index) in images" :key="index">
                <div class="banner-slide" :style="{ backgroundImage: 'url(/img/web/' + item + ')' }"></div>
            </SwiperSlide>
        </Swiper>
    </div>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

const props = defineProps({
    images: {
        type: Array,
        default() {
            return ['logo-dark.png']
        }
    },
    logo: {
        type: Boolean,
        default: true
    },
    title: {
        type: String,
        default: 'Creative Sitters & Chaperones'
    },
    sliderOnly: {
        type: Boolean,
        default: false
    }
})

const settings = {
    modules: [Autoplay],
    speed: 2000,
    autoplay: {
        delay: 5000
    },
    loop: true,
    slidesPerView: 3,
    initialSlide: 0,
    breakpoints: {
        320: {
            slidesPerView: 1
        },
        1024: {
            slidesPerView: 3
        }
    }
}

</script>


<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  padding: 100px 0;

  &:after {
    content: '';
    background: rgba(21, 21, 21, 0.3);
    //background: rgba(161, 237, 227, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .banner-logo {
    position: relative;
    z-index: 2;
    max-width: 120px;
    margin: 0 auto 50px;
  }

  .banner-title {
    max-width: 860px;
    position: relative;
    z-index: 2;
    opacity: 0.8;
    color: #fff;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    line-height: 1.1;
    @media(max-width: 767px) {
      font-size: 90px;
    }
    @media(max-width: 699px) {
      font-size: 70px;
    }
    @media(max-width: 599px) {
      font-size: 60px;
    }
    @media(max-width: 499px) {
      font-size: 50px;
    }
    @media(max-width: 374px) {
      font-size: 40px;
    }
  }

  .banner-slider {
    .banner-slide {
      height: 100%;
      position: relative;
      background-size: cover;
      background-position: center center;
    }
  }
}
.banner-swiper {
  height:100%;
  .swiper-wrapper {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  }
}

</style>
