import {computed, ref, watch} from "vue"
import axios from "axios"

export default function usePaginator (apiUrl) {
    const items = ref([])

    const preUrl = ref(apiUrl.value)
    const nextUrl = ref(null)

    const hasMore = computed(() => {
        return nextUrl.value !== null && nextUrl.value !== undefined
    })

    const loadMore = async () => {
        if (hasMore.value) {
            await fetchItems(true)
        }
    }
    const fetchItems = async (more) => {
        if (!more) {
            items.value = []
            nextUrl.value = null
        }
        let url = more ? nextUrl.value : apiUrl.value
        preUrl.value = url

        await axios.get(url)
            .then((resp) => {
                items.value.push.apply(items.value, resp.data.items)
                nextUrl.value = resp.data.next_url
            })
            .catch((err) => {
                console.log(err)
            })
    }

    watch(apiUrl, (newVal, oldVal) => {
        fetchItems(false)
    })

    return { items, preUrl, nextUrl, hasMore, loadMore, fetchItems}
}
