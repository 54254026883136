<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center gap-4">
                <div class="col w-full lg:w-auto">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/users/admins" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/admins')}">Admins</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/creatives" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/creatives')}">Creatives</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/members" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/members')}">Members</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/users/children" as="button" class="filter-nav-btn" :class="{'active': $page.url.startsWith('/admin/users/children')}">Children</InertiaLink>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <div class="flex">
                        <div class="col">
                            <InertiaLink href="/admin/users/children/create" class="btn">
                                <i class="fas fa-plus"></i>
                                Add Child
                            </InertiaLink>
                        </div>
                        <div class="col pl-2">
                            <InertiaLink href="/admin/users/broadcast/past" class="btn btn--secondary">
                                <i class="fas fa-plus"></i>
                                Broadcast
                            </InertiaLink>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-10"></div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
                <div class="col">
                    <div class="search-form">
                        <input v-model="searchTerm" type="text">
                        <button type="input">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchStatus">
                            <option value="" disabled="" selected>Status</option>
                            <option value="active">Active</option>
                            <option value="archived">Archived</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-lock"></i>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="filter-select">
                        <select v-model="searchDobMonth">
                            <option value="" selected>All Months</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <div class="filter-select-icon">
                            <i class="fas fa-calendar-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-10"></div>
    </div>
    <div class="page-card">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>Children</h4>
                </div>
                <div class="col">
                    Displaying: <span class="font-semibold">{{ items.length }}</span>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table">
                    <tr>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th>Family</th>
                        <th>Date of Birth</th>
                        <th style="text-align: center">Status</th>
                    </tr>
                    <tr v-for="(child, index) in items" :key="index">
                        <td>
                            <InertiaLink :href="'/admin/users/children/' + child.id">
                                <div class="avatar avatar--sm" v-bind:style="{ backgroundImage: 'url(' + child.avatar + ')' }"></div>
                            </InertiaLink>
                        </td>
                        <td>
                            <InertiaLink :href="'/admin/users/children/' + child.id">
                                <div class="">{{ child.name }}</div>
                                <span class="text-gray-400 font-normal">{{ child.nickname }}</span>
                            </InertiaLink>
                        </td>
                        <td>
                            <InertiaLink :href="'/admin/users/members/' + child.member?.id">
                                {{ child.member?.name }} <i v-if="child.member?.is_vip" class="fas fa-check-circle primary"></i>
                            </InertiaLink>
                        </td>
                        <td>
                            <div class="">{{ child.date_of_birth + ' ' + '(' + child.age + ')' }}</div>
                        </td>
                        <td style="text-align: center; padding-right: 1rem;">
                            <UserListAccessStatusIcon v-if="child.is_archived" type="archived" />
                            <UserListStatusIcon v-else :is-approved="true" />
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="hasMore">
                <div class="pt-10"></div>
                <div class="text-center">
                    <button @click="loadMore" class="btn btn--gray btn--sm">
                        Load More
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue"
import usePaginator from "@composables/usePaginator"
import UserListAccessStatusIcon from "@components/utilities/UserListAccessStatusIcon"
import UserListStatusIcon from "@components/utilities/UserListStatusIcon"

const searchTerm = ref("")
const searchStatus = ref("active")
const searchDobMonth = ref("")

const apiUrl = computed(() => {
    let params = new URLSearchParams();
    if (searchTerm.value !== "") {
        params.append('terms', encodeURIComponent(searchTerm.value))
    }
    if (searchStatus.value === "archived") {
        params.append('status', encodeURIComponent(searchStatus.value))
    }
    if (searchDobMonth.value > 0 && searchDobMonth.value < 13) {
        params.append('dob_month', encodeURIComponent(searchDobMonth.value))
    }
    let suffix = params.toString()
    return '/admin/users/children/api' + (suffix ? '?' + suffix : '')
})

const {items, hasMore, loadMore, fetchItems} = usePaginator(apiUrl)
fetchItems(false)

</script>

<style lang="postcss">
</style>
