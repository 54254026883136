<template>
    <AdminUsersCreativeDetailsLayout :user="user">
        <template v-slot:default>
            <div class="mt-10">
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div class="col">
                        <h5 class="sub-title">Document History</h5>
                    </div>
                    <div v-if="!user.is_archived" class="col">
                        <button @click="showAddDocForm" class="btn btn--icon"><i class="fas fa-edit"></i></button>
                    </div>
                </div>
                <div v-if="user.documents.length" class="table-wrapper">
                    <table class="table">
                        <tr>
                            <th>Type & Authority</th>
                            <th>Number</th>
                            <th>File</th>
                            <th>Date Added</th>
                            <th>Expiry Date</th>
                            <th>Reviewed On</th>
                        </tr>
                        <tr v-for="(doc, index) in user.documents" :key="index">
                            <td>
                                <div class="">{{ doc.type }}</div>
                                <span class="font-normal" >{{ doc.issuing_authority }}</span>
                            </td>
                            <td><div class="">{{ doc.number }}</div></td>
                            <td><a :href="doc.file_url" target="_blank" class="text-primary">{{ doc.filename.substr(0, 3) + '...' + doc.filename.substr(-10) }}</a></td>
                            <td><div class="text-gray-600 font-normal">{{ doc.created_at_short }}</div></td>
                            <td>
                                <div :class="{'text-secondary': doc.is_expired}">{{ doc.expiry_date }}</div>
                                <span class="font-normal" :class="{'text-secondary': doc.is_expired}">{{ doc.expiry_date_short }}</span>
                            </td>
                            <td>
                                <div v-if="doc.is_reviewed">
                                    <div>{{ doc.reviewed_at_short }}</div>
                                    <span class="font-normal">{{ doc.reviewed_by }}</span>
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td v-if="!user.is_archived">
                                <button @click="approveDocument(doc.id)" class="btn btn--sm"><i class="fas fa-check"></i> Approve</button>
                            </td>
                            <td v-if="!user.is_archived">
                                <button @click="removeDocument(doc.id)" class="btn btn--sm btn--secondary"><i class="fas fa-trash"></i> Remove</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:extra>
            <div id="add-document-form">
                <div v-if="showingAddDocForm">
                    <div class="mt-10"></div>
                    <DocumentForm
                        :url="'/admin/users/creatives/' + user.id + '/documents/add'"
                        @closed="hideAddDocForm"
                    />
                </div>
            </div>
        </template>
    </AdminUsersCreativeDetailsLayout>
</template>

<script setup>
import AdminUsersCreativeDetailsLayout from "@components/admin/AdminUsersCreativeDetailsLayout"
import useToggleable from "@composables/useToggleable"
import DocumentForm from "@components/utilities/DocumentForm"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})
const { showing: showingAddDocForm, show: showAddDocForm, hide: hideAddDocForm } = useToggleable('add-document-form')

const approveDocument = (docId) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/documents/' + docId + '/approve', {}, {
        preserveScroll: true
    })
}
const removeDocument = (docId) => {
    Inertia.post('/admin/users/creatives/' + props.user.id + '/documents/' + docId + '/remove', {}, {
        onBefore: () => confirm('Are you sure you want to remove the document?'),
        preserveScroll: true,
    })
}

</script>
