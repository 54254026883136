<template>
    <div class="page-filters-section">
        <div class="filter-bar">
            <div class="flex flex-wrap justify-between items-center">
                <div class="col w-full lg:w-auto lg:hidden mb-10">
                    <ul class="filter-nav">
                        <li>
                            <InertiaLink href="/admin/bookings/type/all" as="button" class="filter-nav-btn" :class="{'active': isAllActive}">All</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/bookings/type/upcoming" as="button" class="filter-nav-btn" :class="{'active': isUpcomingActive}">Upcoming</InertiaLink>
                        </li>
                        <li>
                            <InertiaLink href="/admin/bookings/type/past" as="button" class="filter-nav-btn" :class="{'active': isPastActive}">Past</InertiaLink>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <div class="flex gap-4">
                        <div class="col">
                            <InertiaLink href="/admin/bookings/create" class="btn w-48">
                                Create Booking
                            </InertiaLink>
                        </div>
                        <div class="col block lg:hidden">
                            <InertiaLink href="/admin/analytics" class="btn btn--secondary">
                                Analytics
                            </InertiaLink>
                        </div>
                        <div class="col block lg:hidden">
                            <InertiaLink href="/admin/users/creatives/applications" class="btn btn--secondary">
                                Applications
                            </InertiaLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {Inertia} from "@inertiajs/inertia"

const isAllActive = computed(() => {
    return Inertia.page.url.startsWith('/admin/bookings/type/all')
})
const isUpcomingActive = computed(() => {
    return Inertia.page.url.startsWith('/admin/bookings/type/upcoming')
})
const isPastActive = computed(() => {
    return Inertia.page.url.startsWith('/admin/bookings/type/past')
})

</script>

<style scoped>

</style>
