import {ref} from "vue"

export default function useToggleable(targetId) {
    const showing = ref(false)

    const show = () => {
        showing.value = true
        setTimeout(() => document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' }), 10)
    }
    const hide = () => {
        showing.value = false
    }

    return {
        showing,
        show,
        hide
    }
}
