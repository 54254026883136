<template>
    <MemberBookingDetailsLayout :booking="booking">
        <template v-slot:default>
            <UsersList
                :users="booking.children?.details"
                title="Children"
                url-prefix="/member/profile/children/"
                :show-add="false"
                :show-remove="false"
            />
        </template>
    </MemberBookingDetailsLayout>
</template>

<script setup>
import MemberBookingDetailsLayout from "@components/member/MemberBookingDetailsLayout"
import useToggleable from "@composables/useToggleable"
import BookingChildAdd from "@components/utilities/BookingChildAdd"
import UsersList from "@components/utilities/UsersList"
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    booking: {
        type: Object,
        required: true
    },
    otherMemberChildren: {
        type: Array,
        default: null
    }
})

const { showing: showingChildForm, show: showChildForm, hide: hideChildForm } = useToggleable('add-child-form')
const removeChild = (child) => {
    if (child?.booking_child_id) {
        Inertia.post('/member/bookings/' + props.booking.id + '/children/' + child.booking_child_id + '/remove')
    }
}
</script>
