<template>
    <div class="page-card mt-10">
        <div class="page-card-content">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="col">
                    <h4>{{ title }}</h4>
                </div>
                <div class="col">
                    <button @click="$emit('closed')" class="btn btn--icon btn--secondary"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="pb-10"></div>
            <form @submit.prevent="submitTagsForm">
                <div class="grid grid-cols-1 md:grid-cols-1">
                    <div class="col">
                        <div class="input-wrapper mb-5">
                            <TagPicker v-model="tagsForm.tags" :allow-create="allowCreate" />
                        </div>
                    </div>
                </div>
                <FormErrorsGroup :errors="tagsForm.errors"/>
                <div class="input-submit mt-10">
                    <input :disabled="!canSubmitTags" class="bg-secondary hover:bg-primary transition duration-300"
                           type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrorsGroup from "@components/utilities/FormErrorsGroup"
import {computed, onUnmounted, ref} from "vue"
import TagPicker from "@components/utilities/TagsPicker"

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    tags: {
        type: Array,
        required: true
    },
    title: {
        type: String,
        default: 'Update Tags'
    },
    allowCreate: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['closed'])

const tagsForm = useForm({
    tags: props.tags,
})
const canSubmitTags = computed(() => {
    return !tagsForm.processing && tagsForm.isDirty
})

const submitTagsForm = () => {
    tagsForm.post(props.url, {
        preserveScroll: (page) => Object.keys(page.props.errors).length,
        onSuccess: () => emits('closed')
    })
}

onUnmounted(() => {
    tagsForm.reset()
})

</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="postcss">

</style>
