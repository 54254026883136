<template>
    <AdminUsersChildrenDetailsLayout :child="child">
        <template v-slot:default>
            <NotesList
                :notes="child.notes"
                :show-add="!child.is_archived"
                @add="showNoteForm"
            />
        </template>
        <template v-slot:extra>
            <div id="add-note-form">
                <NotesAdd
                    v-if="showingNoteForm"
                    :url="'/admin/users/children/' + child.id + '/add-note'"
                    @closed="hideNoteForm"
                />
            </div>
        </template>
    </AdminUsersChildrenDetailsLayout>
</template>

<script setup>
import AdminUsersChildrenDetailsLayout from "@components/admin/AdminUsersChildrenDetailsLayout"
import useToggleable from "@composables/useToggleable"
import NotesList from "@components/utilities/NotesList"
import NotesAdd from "@components/utilities/NotesAdd"

const props = defineProps({
    child: {
        type: Object,
        required: true
    }
})

const { showing: showingNoteForm, show: showNoteForm, hide: hideNoteForm } = useToggleable('add-note-form')
</script>
